import { useRealmId } from "@easybiz/context";
import { collection, getFirestore, limit, onSnapshot, query, where } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (notBusinessCode, customerId, search) => {
  const realmId = useRealmId();
  const [have, setHave] = useState(false);

  useEffect(() => {
    setHave(false);
    if (realmId && notBusinessCode) {
      let queryObj = query(
        collection(getFirestore(), `realms/${realmId}/orders`),
        where("business.id", "!=", notBusinessCode),
        limit(1)
      );

      if (customerId) {
        queryObj = query(queryObj, where("customer.id", "==", customerId));
      } else if (search) {
        queryObj = query(queryObj, where("searchables", "array-contains", search));
      }

      return onSnapshot(queryObj, (result) => setHave(!result.empty));
    }
  }, [realmId, notBusinessCode, customerId, search]);

  return have;
};
