import { useGeoLocationBlocker } from "@easybiz/pos";
import { Alert, Avatar, Card, Space } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";

function Bold({ children }) {
  return <b>{children}</b>;
}

export default function () {
  const [permission, setPermission] = useState("geolocation" in navigator);
  const { title, open, permissionError, alert } = useGeoLocationBlocker(permission, Bold);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(() => {}, setPermission);
    }
  }, []);

  return (
    <Dialog
      title={title}
      open={open}
      closable={false}
      footer={permissionError ? undefined : false}
      okText={permissionError ? <FormattedMessage defaultMessage={"Reload"} /> : null}
      onOk={permissionError ? () => window.location.reload(true) : null}
    >
      <Space direction="vertical" className="full-width" size={"large"}>
        <Alert type="error" message={alert?.title} description={alert?.description} />
        {Array.isArray(alert?.browsers) && (
          <Space>
            {alert?.browsers.map((browser) => (
              <a key={browser.href} href={browser.href} target="_blank" rel="noreferrer">
                <Card hoverable>
                  <Card.Meta avatar={<Avatar src={browser.icon} />} title={browser.title} />
                </Card>
              </a>
            ))}
          </Space>
        )}
        {alert?.guideImg && <Card style={{ width: "100%" }} cover={<img src={alert?.guideImg} />} />}
      </Space>
    </Dialog>
  );
}
