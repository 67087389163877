import { GiftOutlined } from "@ant-design/icons";
import { LoadMoreButton, Title } from "@easybiz/web-admin";
import { DateDisplay } from "@easybiz/component";
import { usePointsHistoryQuery } from "@easybiz/web-firebase";
import { List, Typography } from "antd";
import React, { useState } from "react";

export default ({ customerId, header }) => {
  const [limit, setLimit] = useState(5);
  const { transactions, loading } = usePointsHistoryQuery({ customerId, limit });

  return (
    <List
      dataSource={transactions || []}
      size="small"
      header={header}
      loading={!transactions}
      renderItem={(transaction) => {
        const { operator, createDate, amount, pointAfter, remarks } = transaction.data();
        const isMinus = amount < 0;

        return (
          <List.Item
            extra={
              <div className="flex flex-align-end">
                <Title type={isMinus ? "danger" : "success"}>
                  {isMinus ? "-" : "+"}
                  {Math.abs(amount)}
                </Title>
                <Typography.Text>
                  <GiftOutlined /> {pointAfter}
                </Typography.Text>
              </div>
            }
          >
            <List.Item.Meta
              title={remarks}
              description={
                <div>
                  {operator.name} <DateDisplay {...createDate} />
                </div>
              }
            />
          </List.Item>
        );
      }}
      footer={<LoadMoreButton loading={loading} dataSource={transactions} onClick={() => setLimit(limit + 5)} />}
    />
  );
};
