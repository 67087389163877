import { ArrowDownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ dataSource, loading, onClick }) {
  if (dataSource && dataSource.length > 0) {
    return (
      <Button loading={Boolean(loading)} block icon={<ArrowDownOutlined />} type="link" onClick={onClick}>
        <FormattedMessage tagName="span" defaultMessage="Load more" />
      </Button>
    );
  } else {
    return null;
  }
}
