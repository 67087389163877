import {
  AccountAccessProvider,
  ServerAPIProvider,
  AuthenticationProvider,
  ConfigurationProvider,
  CustomerScreenProvider,
  DeviceRegistrationProvider,
  EmailStateProvider,
  InstallationProvider,
  LiveStateProvider,
  LocalSettingProvider,
  NamingProvider,
  PaymentMethodProvider,
  PhotoGalleryProvider,
  PrintingProvider,
  PushNotificationProvider,
  RealmProvider,
  SettingProvider,
  SubscriptionStateProvider,
  ThemingProvider,
  UploadProvider,
} from "@easybiz/context";
import {
  CUSTOMER_PROFILE_EMAIL,
  CUSTOMER_PROFILE_NAME,
  CUSTOMER_PROFILE_TEL,
  formatBusinessData,
} from "@easybiz/utils";
import { useMemo } from "react";
import useReactAppTheme from "../useReactAppTheme";

export default function ({
  platform,
  appVersion,
  children,
  account,
  client,
  installationId,
  haveNetwork,
  onOTAUpdate,
  onOTACheck,
  otaNewVersion,
  realmDoc,
  businessDoc,
  deviceDoc,
  licenseDoc,
  customerScreenDoc,
  offlinePaymentMethods,
  locale,
  onUpdateLocale,
  offlineEnabled,
  onSignOut,
  toast,
  ESCEncoder,
  usbIO,
  bluetoothIO,
  connectPrinter,
  httpsFunction,
  storageUpload,
  avatarUrl,
  pushGranted,
  installed,
  installEvent,
  isMobile,
  LocalStorage,
}) {
  const theme = useReactAppTheme();
  const businessSetting = useMemo(() => businessDoc && formatBusinessData(businessDoc), [businessDoc]);
  const profileConfig = useMemo(() => {
    const customerFields = Array.isArray(realmDoc?.get("posSettings.customerFields"))
      ? realmDoc?.get("posSettings.customerFields")
      : [CUSTOMER_PROFILE_NAME];
    return {
      askName: customerFields.indexOf(CUSTOMER_PROFILE_NAME) >= 0,
      askEmail: customerFields.indexOf(CUSTOMER_PROFILE_EMAIL) >= 0,
      askTel: customerFields.indexOf(CUSTOMER_PROFILE_TEL) >= 0,
    };
  }, [realmDoc]);

  return (
    <ConfigurationProvider
      toast={toast}
      platform={platform}
      installationId={installationId}
      appVersion={appVersion}
      locale={locale}
      haveNetwork={haveNetwork}
      client={client}
      offlineEnabled={offlineEnabled}
      pushGranted={pushGranted}
      onUpdateLocale={onUpdateLocale}
    >
      <NamingProvider realmDoc={realmDoc}>
        <ThemingProvider theme={theme}>
          <InstallationProvider
            installed={installed}
            installEvent={installEvent}
            isMobile={isMobile}
            onOTAUpdate={onOTAUpdate}
            onOTACheck={onOTACheck}
            otaNewVersion={otaNewVersion}
          >
            <PrintingProvider
              ESCEncoder={ESCEncoder}
              usbIO={usbIO}
              bluetoothIO={bluetoothIO}
              connectPrinter={connectPrinter}
            >
              <CustomerScreenProvider customerScreenDoc={customerScreenDoc}>
                <AuthenticationProvider
                  realmId={realmDoc ? realmDoc.id : account?.realmId}
                  account={account}
                  onSignOut={onSignOut}
                  avatarUrl={avatarUrl}
                  accountName={account && realmDoc?.get(`accounts.${account.uid}`)}
                >
                  <AccountAccessProvider account={account} realmDoc={realmDoc}>
                    <SubscriptionStateProvider licenseDoc={licenseDoc} realmDoc={realmDoc}>
                      <RealmProvider businessCode={account?.businessCode} realmDoc={realmDoc}>
                        <DeviceRegistrationProvider deviceDoc={deviceDoc}>
                          <LiveStateProvider>
                            <SettingProvider businessSetting={businessSetting} profileConfig={profileConfig}>
                              <EmailStateProvider>
                                <UploadProvider storageUpload={storageUpload}>
                                  <PaymentMethodProvider
                                    client={client}
                                    offlinePaymentMethods={offlinePaymentMethods}
                                    realmDoc={realmDoc}
                                    businessDoc={businessDoc}
                                  >
                                    <PhotoGalleryProvider>
                                      <ServerAPIProvider httpsFunction={httpsFunction}>
                                        <LocalSettingProvider LocalStorage={LocalStorage}>
                                          <PushNotificationProvider>{children}</PushNotificationProvider>
                                        </LocalSettingProvider>
                                      </ServerAPIProvider>
                                    </PhotoGalleryProvider>
                                  </PaymentMethodProvider>
                                </UploadProvider>
                              </EmailStateProvider>
                            </SettingProvider>
                          </LiveStateProvider>
                        </DeviceRegistrationProvider>
                      </RealmProvider>
                    </SubscriptionStateProvider>
                  </AccountAccessProvider>
                </AuthenticationProvider>
              </CustomerScreenProvider>
            </PrintingProvider>
          </InstallationProvider>
        </ThemingProvider>
      </NamingProvider>
    </ConfigurationProvider>
  );
}
