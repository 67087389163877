import { CLIENT_CONSOLE, CLIENT_CUSTOMER_APP, CLIENT_MOBILE, CLIENT_POS } from "./constants/index.js";

export default function (account, claims, client) {
  // TODO: Change to use permission array
  if (!claims?.realmId) return null;

  switch (client) {
    case CLIENT_POS:
    case CLIENT_MOBILE:
    case CLIENT_CONSOLE:
      // Admin
      if (!Array.isArray(claims?.roles)) return null;

      if (client === CLIENT_POS) {
        if (!claims?.businessCode) return null;
      }
      break;
    case CLIENT_CUSTOMER_APP:
      // Customer
      if (!claims?.customerId) {
        return null;
      }
  }

  const {
    realmId,
    customerId,
    permissions,
    realmMaster,
    pendingSetup,
    easybussuper,
    ebAccess,
    businessCode,
    pairingCode,
    connectDeviceId,
    installId,
    licenceId,
    specialists,
    limitAgents,
    storePlants,
    limitBusinesses,
    procedures,
    staffId,
    staffName,
    clockInTime,
    auth_time,
    customerScreen,
  } = claims;

  return {
    realmId,
    uid: account.uid,
    authTime: auth_time,
    ...(account.displayName && { name: account.displayName }),
    ...(staffId && {
      checkInStaff: {
        id: staffId,
        name: staffName,
        ...(clockInTime && { clockInTime }),
      },
    }),
    ...(customerScreen && { customerScreen: true }),
    ...(customerId && { customerId }),
    ...(account.email && { email: account.email }),
    ...(account.emailVerified && { emailVerified: account.emailVerified }),
    ...(account.phoneNumber && { phoneNumber: account.phoneNumber }),
    ...(account.photoURL && { avatarUrl: account.photoURL }),
    ...(Array.isArray(permissions) && { permissions }),
    ...(Array.isArray(limitBusinesses) && { limitBusinesses }),
    ...(Array.isArray(procedures) && procedures.length > 0 && { procedures }),
    ...(Array.isArray(limitAgents) && limitAgents.length > 0 && { limitAgents }),
    ...(Array.isArray(storePlants) && storePlants.length > 0 && { storePlants }),
    ...(Array.isArray(specialists) &&
      specialists.length > 0 && {
        specialists,
        isAgent: true,
      }),
    ...(pendingSetup && { pendingSetup }),
    ...(businessCode && { businessCode }),
    ...(realmMaster && { realmMaster }),
    ...(licenceId && { licenceId }),
    ...((easybussuper || ebAccess) && { platformMode: true }),
    ...(pairingCode && { pairingCode }),
    ...(connectDeviceId && { connectDeviceId }),
    ...(installId && { installId }),
  };
}
