"use client";

import { createContext, useContext, useMemo } from "react";

const ConfigurationContext = createContext();

export function ConfigurationProvider({
  appVersion,
  platform,
  installationId,
  client,
  toast,
  haveNetwork,
  offlineEnabled,
  pushGranted,
  locale,
  onUpdateLocale,
  children,
}) {
  const context = useMemo(() => {
    return {
      platform,
      haveNetwork,
      client,
      locale,
      onUpdateLocale,
      offlineEnabled,
      installationId,
      toast,
      pushGranted,
      appVersion,
    };
  }, [
    platform,
    toast,
    locale,
    onUpdateLocale,
    client,
    installationId,
    offlineEnabled,
    haveNetwork,
    pushGranted,
    appVersion,
  ]);
  return <ConfigurationContext.Provider value={context}>{children}</ConfigurationContext.Provider>;
}

export const usePlatform = () => useContext(ConfigurationContext).platform;
export const useInstallationId = () => useContext(ConfigurationContext).installationId;
export const useToast = () => useContext(ConfigurationContext).toast;
export const useNotificationPermission = () => useContext(ConfigurationContext).pushGranted;
export const useLocale = () => useContext(ConfigurationContext).locale;
export const useSetLocale = () => useContext(ConfigurationContext).onUpdateLocale;
export const useClientType = () => useContext(ConfigurationContext).client;
export const useIsOfflineEnabled = () => useContext(ConfigurationContext).offlineEnabled;
export const useHaveNetwork = () => useContext(ConfigurationContext).haveNetwork;
export const useAppVersion = () => {
  return useContext(ConfigurationContext).appVersion || process.env.REACT_APP_VERSION;
};

// Static configuration
export const useStripeKey = () => process.env.REACT_APP_stripe_pk || process.env.NEXT_PUBLIC_stripe_pk;
export const useStripeTestKey = () => process.env.REACT_APP_stripe_pk_test || process.env.NEXT_PUBLIC_stripe_pk_test;
export const useStripeClientId = () => process.env.REACT_APP_tripe_client_id || process.env.NEXT_PUBLIC_tripe_client_id;
export const useStripeTestClientId = () =>
  process.env.REACT_APP_tripe_client_id || process.env.NEXT_PUBLIC_tripe_client_id_test;
export const useImageHost = () => process.env.REACT_APP_image_host || process.env.NEXT_PUBLIC_image_host;
export const useStorageHost = () => process.env.REACT_APP_storage_host || process.env.NEXT_PUBLIC_storage_host;
export const usePushNotificationVapidKey = () => process.env.REACT_APP_vapidKey || process.env.NEXT_PUBLIC_vapidKey;
export const useSiteKey = () => process.env.REACT_APP_siteKey || process.env.NEXT_PUBLIC_siteKey;
export const useGoogleMapApiKey = () => process.env.REACT_APP_google_map_pk || process.env.NEXT_PUBLIC_google_map_pk;
export const usePrinterServiceId = () =>
  process.env.REACT_APP_PRINTER_SERVICE_ID || process.env.NEXT_PUBLIC_PRINTER_SERVICE_ID;
export const usePortalHost = () =>
  `https://${process.env.REACT_APP_PLATFORM_HOST || process.env.NEXT_PUBLIC_PLATFORM_HOST}`;
export const useMobileDownloadLink = () => process.env.REACT_APP_MOBILE_LINK || process.env.NEXT_PUBLIC_MOBILE_LINK;
export const usePOSDownloadLink = () => process.env.REACT_APP_POS_LINK || process.env.NEXT_PUBLIC_POS_LINK;
export const useWebHostingDomain = () => process.env.REACT_APP_WEBDOMAIN || process.env.NEXT_PUBLIC_WEBDOMAIN;
export const usePOSUrl = () =>
  `https://pos.${process.env.REACT_APP_PLATFORM_HOST || process.env.NEXT_PUBLIC_PLATFORM_HOST}`;
export const useStorageBucket = () => `gs://easybiz-asean`;
