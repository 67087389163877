import { AlertOutlined, GiftOutlined, ShopOutlined, ShoppingCartOutlined, UserAddOutlined, WalletOutlined } from "@ant-design/icons";
import { AmountDisplay, DateDisplay, NameDisplay } from "@easybiz/component";
import { toRecordCustomer } from "@easybiz/utils";
import { CustomerDisplay, CustomerMemberTags } from "@easybiz/web-admin";
import { usePointsWalletDoc, useTopUpWalletDoc } from "@easybiz/web-firebase";
import { Alert, Divider, List, Tag, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ customer, onSelect }) => {
  const { remarks, orderDate, registerDate, corporate, businessCodes, members, index } = customer.data() || {};
  const membership = usePointsWalletDoc(customer.id);
  const wallet = useTopUpWalletDoc(customer.id);
  const points = (membership && membership.get("point")) || 0;

  return (
    <>
      <List.Item
        id={index && `${index}`}
        className="selectable"
        onClick={() => onSelect(toRecordCustomer(customer))}
        extra={
          <div className="flex flex-align-end">
            {orderDate ? (
              <Typography.Text type="secondary">
                <ShoppingCartOutlined /> <DateDisplay date={orderDate} />
              </Typography.Text>
            ) : null}
            {registerDate ? (
              <Typography.Text type="secondary">
                <UserAddOutlined /> <DateDisplay date={registerDate} />
              </Typography.Text>
            ) : null}
          </div>
        }
      >
        <List.Item.Meta
          title={
            <Typography.Title level={5} style={{ margin: 0 }}>
              <CustomerDisplay customer={customer.data()} />
            </Typography.Title>
          }
          description={
            <span>
              {Array.isArray(businessCodes)
                ? businessCodes.map((code) => (
                    <Tag key={code} icon={<ShopOutlined />}>
                      <NameDisplay nameKey={code} />
                    </Tag>
                  ))
                : null}
              {corporate ? (
                <Tag color="warning">
                  <FormattedMessage tagName="span" defaultMessage="Corporate" />
                </Tag>
              ) : null}
              {wallet && wallet.exists() && (
                <Tag color="success" icon={<WalletOutlined />}>
                  <AmountDisplay value={wallet.get("credit")} />
                </Tag>
              )}
              {points > 0 && (
                <Tag color="processing" icon={<GiftOutlined />}>
                  {points}
                </Tag>
              )}
              {members && <CustomerMemberTags members={members} />}
            </span>
          }
        />
      </List.Item>
      {remarks && (
        <>
          <Alert type="warning" icon={<AlertOutlined />} showIcon message={remarks} onClick={() => onSelect(toRecordCustomer(customer))} />
          <Divider style={{ marginTop: 8, marginBottom: 0 }} />
        </>
      )}
    </>
  );
};
