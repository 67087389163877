import { useDisplayMode, useSetDisplayMode } from "@easybiz/context";
import { useOrderAvailableReturnItems } from "@easybiz/hook";
import { OrderLabelSelectList } from "@easybiz/web-admin";
import { Typography } from "antd";
import { FormattedMessage } from "react-intl";

export default function RedoItemsSelectionPanel() {
  const displayMode = useDisplayMode();
  const setDisplayMode = useSetDisplayMode();
  const { order, selects } = displayMode;
  const items = useOrderAvailableReturnItems(order, true);

  return (
    <div className="flex flex-fill self-scroll padding">
      <OrderLabelSelectList
        header={
          <Typography.Title level={5}>
            <FormattedMessage defaultMessage={"Select Redo Items"} />
          </Typography.Title>
        }
        order={order}
        selectNotes={selects || []}
        items={items}
        onSelectChange={(indexes) => {
          setDisplayMode({
            ...displayMode,
            selects: indexes.map((index) => ({
              ...selects?.find((select) => select.index === index),
              index,
            })),
          });
        }}
        onNoteChange={(note, index) => {
          setDisplayMode({
            ...displayMode,
            selects: [...selects.slice(0, index), { ...selects[index], note }, ...selects.slice(index + 1)],
          });
        }}
        selects={selects?.map((select) => select.index)}
        multiple
        vertical
      />
    </div>
  );
}
