import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { formatPrice } from "@easybiz/utils";
import { Button, message, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import QtyInputPopover from "../QtyInputPopover";
import Title from "../Title";

const SHAPE_SQUARE = "square";
const SHAPE_ROUND = "round";

export default function ({ value, measure, onChange, width, height, radius }) {
  const [open, setVisible] = useState(false);
  const [params, setParams] = useState(null);

  const intl = useIntl();

  useEffect(() => {
    setParams(radius ? { radius, measure } : width && height ? { width, height, measure } : null);
  }, [open]);

  const { initValue, prefix, title, suffix, buttonText, buttonType, onDone } = useMemo(() => {
    switch (params?.shape) {
      case SHAPE_SQUARE: {
        return {
          title: <FormattedMessage defaultMessage="Calculate from width x height" />,
          suffix: intl.formatMessage({ defaultMessage: "ft" }),
          buttonText: <FormattedMessage defaultMessage={"Next"} />,
          buttonType: "default",
          prefix: params?.width
            ? intl.formatMessage({ defaultMessage: "Height = " })
            : intl.formatMessage({ defaultMessage: "Width = " }),
          onDone: (value) => {
            if (params?.width) {
              if (value <= 0)
                return message.info(
                  intl.formatMessage({
                    defaultMessage: "Please enter height",
                  })
                );

              setParams({
                width: params?.width,
                height: value,
                qty: formatPrice(params.width * value),
                measure: `${params.width} ft(width) x ${value} ft(height)`,
              });
            } else {
              if (value <= 0) return message.info(intl.formatMessage({ defaultMessage: "Please enter width" }));

              setParams({ ...params, width: value });
            }
          },
        };
      }
      case SHAPE_ROUND: {
        return {
          title: <FormattedMessage defaultMessage="Calculate from radius" />,
          suffix: intl.formatMessage({ defaultMessage: "ft" }),
          buttonText: <FormattedMessage defaultMessage={"Next"} />,
          buttonType: "default",
          prefix: intl.formatMessage({ defaultMessage: "Radius = " }),
          onDone: (value) => {
            if (value <= 0) return message.info(intl.formatMessage({ defaultMessage: "Please enter radius" }));

            setParams({
              radius: value,
              qty: formatPrice(Math.PI * parseFloat(value) * parseFloat(value)),
              measure: `PI x ${value}^2(Radius)`,
            });
          },
        };
      }
      default:
        return {
          title: <FormattedMessage defaultMessage="How Many sqft?" />,
          suffix: intl.formatMessage({ defaultMessage: "sqft" }),
          buttonType: params?.qty ? "primary" : null,
          buttonText: params?.qty ? (
            value ? (
              <FormattedMessage defaultMessage={"Update"} />
            ) : (
              <FormattedMessage defaultMessage={"Add"} />
            )
          ) : null,
          prefix: params?.qty ? "=" : null,
          initValue: params?.qty || value,
          onDone: (update) => {
            if (!value && update === 0) {
              return message.info(
                intl.formatMessage({
                  defaultMessage: "Please enter how many sqft",
                })
              );
            }

            setVisible(false);
            onChange({ pcs: 1, ...params, qty: update });
          },
        };
    }
  }, [value, params, onChange]);

  return (
    <QtyInputPopover
      removeable
      open={open}
      onOpenChange={setVisible}
      placement={"left"}
      title={title}
      buttonText={buttonText}
      buttonType={buttonType}
      decimal
      value={initValue}
      prefix={prefix}
      suffix={suffix}
      onDone={onDone}
      extra={
        <Space direction="vertical">
          {!params?.shape && (
            <Space align="center">
              <Title>
                <FormattedMessage defaultMessage={"Calculator"} />
              </Title>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setParams({ shape: SHAPE_SQUARE });
                }}
              >
                <FormattedMessage defaultMessage={"Square"} />
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setParams({ shape: SHAPE_ROUND });
                }}
              >
                <FormattedMessage defaultMessage={"Round"} />
              </Button>
            </Space>
          )}
          {params?.radius ? (
            <Title>
              <FormattedMessage defaultMessage={"PI x {count}^2(radius)"} values={{ count: params?.radius }} />
            </Title>
          ) : null}
          {params?.width && params?.height ? (
            <Title>
              {params.width}(<FormattedMessage defaultMessage={"width"} />) x {params.height}(
              <FormattedMessage defaultMessage={"height"} />)
            </Title>
          ) : null}
        </Space>
      }
    >
      <Button icon={value ? <EditOutlined /> : <PlusOutlined />}>
        <Title type={value ? "success" : null}>
          {value ? (
            <FormattedMessage tagName={"span"} defaultMessage="{count} sqft" values={{ count: value }} />
          ) : (
            <FormattedMessage tagName={"span"} defaultMessage={"Add"} />
          )}
        </Title>
      </Button>
    </QtyInputPopover>
  );
}
