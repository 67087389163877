import { UnitDisplay } from "@easybiz/component";
import { isDecimalUnit, UNIT_PCS } from "@easybiz/utils";
import { Button } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import QtyInputPopover from "../QtyInputPopover";

export default function ({ unitInfo, onChange }) {
  const [open, setOpen] = useState(false);
  const isDecimal = isDecimalUnit(unitInfo?.unit);
  const isPcs = unitInfo?.unit === UNIT_PCS;
  const disabled = isDecimal || isPcs;

  return (
    <QtyInputPopover
      title={<FormattedMessage tagName="span" defaultMessage="Pieces per unit" />}
      value={unitInfo?.pcsPerUnit}
      prefix={
        <span>
          1 <UnitDisplay unit={unitInfo?.unit} /> =
        </span>
      }
      suffix={<UnitDisplay unit={UNIT_PCS} />}
      onDone={(pcsPerUnit) => {
        onChange({ ...unitInfo, pcsPerUnit });
        setOpen(false);
      }}
      open={disabled ? false : open}
      onOpenChange={disabled ? null : setOpen}
    >
      <Button disabled={disabled}>
        {isDecimal ? (
          <FormattedMessage tagName="span" defaultMessage="Manual input" />
        ) : (
          <span>
            1 <UnitDisplay unit={unitInfo?.unit} /> ={" "}
            {isPcs ? 1 : typeof unitInfo?.pcsPerUnit === "number" ? unitInfo?.pcsPerUnit : "?"}
            <UnitDisplay unit={UNIT_PCS} />
          </span>
        )}
      </Button>
    </QtyInputPopover>
  );
}
