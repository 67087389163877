import { CalendarOutlined, FileDoneOutlined } from "@ant-design/icons";
import { DateDisplay } from "@easybiz/component";
import { translate } from "@easybiz/utils";
import { Button, Checkbox, Input, List, Space } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LabelButton from "../LabelButton";
import OrderPhotoGallery from "../OrderPhotoGallery";
import Title from "../Title";

export default function OrderLabelSelectList({
  disabled,
  order,
  items,
  selects,
  selectNotes,
  header,
  onSelectChange,
  onNoteChange,
  size: listSize,
  split,
  onDetails,
  onSchedule,
  footer,
}) {
  const photos = order?.get("photos") || [];
  const intl = useIntl();

  return (
    <List
      itemLayout="vertical"
      dataSource={items || []}
      header={header}
      split={split}
      size={listSize}
      renderItem={(item) => {
        const { product, option, labelIndexes, returnLabels, note, qty, date } = item;

        const photoIndexes = onNoteChange
          ? labelIndexes
          : labelIndexes?.filter((sortIndex) => photos.find((photo) => photo.l === sortIndex));

        let itemSelcts, checked, indeterminate;

        if (Array.isArray(selects) && Array.isArray(labelIndexes) && labelIndexes.length > 0) {
          itemSelcts = labelIndexes.filter((sortIndex) => selects.indexOf(sortIndex) >= 0);
          checked = Boolean(itemSelcts && itemSelcts.length === labelIndexes?.length);
          indeterminate = Boolean(itemSelcts && itemSelcts?.length > 0 && itemSelcts?.length < labelIndexes?.length);
        }

        return (
          <List.Item
            extra={
              <div>
                {onSelectChange ? (
                  <Title type={checked ? "success" : "warning"}>
                    (
                    {`${itemSelcts?.length || 0}/${intl.formatMessage(
                      { defaultMessage: "{count} pcs" },
                      { count: labelIndexes?.length }
                    )}`}
                    )
                  </Title>
                ) : (
                  <Title>
                    x{qty}
                    {product?.unit}
                  </Title>
                )}
              </div>
            }
            className={itemSelcts?.length > 0 ? `selected` : null}
            actions={
              onDetails || onSchedule
                ? [
                    <Space>
                      {onDetails && (
                        <Button icon={<FileDoneOutlined />} onClick={() => onDetails(item)}>
                          <FormattedMessage tagName={"span"} defaultMessage="Details" />
                        </Button>
                      )}
                      {onSchedule ? (
                        <Button icon={<CalendarOutlined />} onClick={() => onSchedule(item)} danger={!date}>
                          {date ? (
                            <span>
                              <DateDisplay date={date} />
                            </span>
                          ) : (
                            <FormattedMessage tagName="span" defaultMessage="Collection not set" />
                          )}
                        </Button>
                      ) : null}
                    </Space>,
                  ]
                : null
            }
          >
            <List.Item.Meta
              avatar={
                onSelectChange ? (
                  <Checkbox
                    disabled={disabled}
                    indeterminate={indeterminate}
                    checked={checked}
                    onChange={() => {
                      if (checked) {
                        // Un select all
                        onSelectChange(selects.filter((index) => (labelIndexes || []).indexOf(index) < 0));
                      } else {
                        const newSelects = [...(selects || []), ...(labelIndexes || [])];
                        onSelectChange(newSelects.filter((id, index) => newSelects.indexOf(id) === index));
                      }
                    }}
                  />
                ) : null
              }
              title={`${translate(product)} | ${translate(option)}`}
              description={
                (note || photoIndexes?.length > 0 || onSelectChange) && (
                  <Space direction="vertical" size="small" className="full-width">
                    {note && (
                      <div>
                        <b>*{note}</b>
                      </div>
                    )}
                    {photoIndexes?.map((itemIndex) => {
                      const selectIndex = (selects || []).indexOf(itemIndex);
                      const selected = selectIndex >= 0;
                      const gallery = (
                        <OrderPhotoGallery
                          key={`${itemIndex}`}
                          order={order}
                          itemIndex={itemIndex}
                          showStatusTag={Boolean(onNoteChange)}
                          selects={selects}
                          onSelectChange={onSelectChange}
                          limit={5}
                          disabled={disabled}
                        />
                      );

                      if (onNoteChange) {
                        const selectNote = selectNotes?.find((select) => select.index === itemIndex);

                        return (
                          <div>
                            {gallery}
                            <Input
                              disabled={!selected}
                              placeholder={intl.formatMessage({
                                defaultMessage: "Redo note",
                              })}
                              value={selectNote?.note || ""}
                              onChange={(e) => onNoteChange(e.target.value, selectIndex)}
                            />
                          </div>
                        );
                      } else {
                        return gallery;
                      }
                    })}
                    {onSelectChange && (
                      <Space wrap>
                        {(labelIndexes || []).map((itemIndex) =>
                          photoIndexes?.indexOf(itemIndex) >= 0 ? null : (
                            <LabelButton
                              disabled={disabled}
                              key={`${itemIndex}`}
                              sortIndex={itemIndex}
                              selects={selects}
                              onChange={onSelectChange}
                              salesReturn={Array.isArray(returnLabels) && returnLabels.includes(itemIndex)}
                            />
                          )
                        )}
                      </Space>
                    )}
                  </Space>
                )
              }
            />
          </List.Item>
        );
      }}
      footer={footer}
    />
  );
}
