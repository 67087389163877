import { Button } from "antd";
import React from "react";

export default function ({ children, ...props }) {
  return (
    <Button {...props} style={{ width: 64 }}>
      {children}
    </Button>
  );
}
