import { InteractionOutlined } from "@ant-design/icons";
import { useCheckoutCustomer } from "@easybiz/checkout";
import { AmountDisplay } from "@easybiz/component";
import { useRecyclables } from "@easybiz/context";
import { usePointsWalletDoc } from "@easybiz/web-firebase";
import { Alert, Button, List, Typography } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import PointsHistoryList from "./PointsHistoryList";
import PointsRedeemDrawer from "./PointsRedeemDrawer";

export default function MainActionGiftPointsPanel () {
  const recyclables = useRecyclables();
  const customer = useCheckoutCustomer();
  const membership = usePointsWalletDoc(customer?.id);
  const [recycleVisible, setRecycleVisible] = useState(false);

  return (
    <div className="padding-half">
      <Typography.Title level={5}>
        <FormattedMessage defaultMessage={"Gift points"} />
      </Typography.Title>
      {customer ? (
        <List
          loading={!membership}
          dataSource={[{}]}
          renderItem={(item) => (
            <List.Item
              extra={
                recyclables > 0 && (
                  <Button icon={<InteractionOutlined />} onClick={() => setRecycleVisible(true)}>
                    <FormattedMessage defaultMessage={"Recycle"} tagName="span" />
                  </Button>
                )
              }
            >
              <List.Item.Meta
                title={
                  <Typography.Title level={3} style={{ margin: 0 }}>
                    <AmountDisplay value={membership?.get("point")} minimumFractionDigits={0} pure />
                  </Typography.Title>
                }
                description={
                  <FormattedMessage
                    defaultMessage={"{customerName}'s balance"}
                    values={{ customerName: customer?.name }}
                  />
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <div className="padding-half">
          <Alert
            type="info"
            message={
              <FormattedMessage
                defaultMessage={"Search & select customer account to check points history or process recycle"}
              />
            }
          />
        </div>
      )}
      {customer && (
        <PointsHistoryList
          header={<FormattedMessage defaultMessage={"Customer points history"} />}
          customerId={customer?.id}
        />
      )}
      <PointsRedeemDrawer customer={customer} open={recycleVisible} onClose={() => setRecycleVisible(false)} />
    </div>
  );
}
