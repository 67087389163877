import { useBusinessAgentId } from "@easybiz/context";

export default (statusDoc, workingStore) => {
  const agentId = useBusinessAgentId();
  const data = statusDoc?.get("data");

  if (data) {
    const businessCode = agentId || workingStore;
    const counts = Object.keys(data)
      .filter((id) => (businessCode ? businessCode === id : true))
      .map((key) => data[key]);

    if (counts.length > 0) {
      return parseInt(counts.reduce((a, b) => a + b));
    }
  } else {
    return 0;
  }
};
