import { Alert, Checkbox, Form, Input, Space } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import NumericEditor from "../NumericEditor";

export default ({ settings, onChange }) => {
  const intl = useIntl();
  const { offsetVertical, density, font, descriptionBitMap } = settings || {};

  return (
    <Form layout="vertical">
      <Form.Item label={<FormattedMessage defaultMessage="Printing density" />}>
        <Space direction="vertical" className="full-width">
          <NumericEditor
            value={typeof density === "number" ? density : 15}
            min={1}
            max={15}
            onChange={(density) => onChange({ ...settings, density })}
          />
          <Alert
            type="info"
            message={
              <FormattedMessage defaultMessage="Carbon density, set from 1 to 15 (15 is the deepest), default to 15." />
            }
          />
        </Space>
      </Form.Item>
      <Form.Item label={<FormattedMessage defaultMessage="Vertical offset" />}>
        <Space direction="vertical" className="full-width">
          <NumericEditor
            unit={intl.formatMessage({ defaultMessage: "dots" })}
            value={typeof offsetVertical === "number" ? offsetVertical : 0}
            min={0}
            max={80}
            offset={8}
            onChange={(offsetVertical) => onChange({ ...settings, offsetVertical })}
          />
          <Alert
            type="info"
            message={
              <FormattedMessage defaultMessage="Label printing starting point, you only need to add offset if your label have vertical alignment issue (First line been cutoff)." />
            }
          />
        </Space>
      </Form.Item>
      <Form.Item label={<FormattedMessage defaultMessage="Local language font" />}>
        <Space direction="vertical" className="full-width">
          <Input
            value={font}
            onChange={(e) => onChange({ ...settings, font: e.target.value.toUpperCase() })}
            placeholder={intl.formatMessage({ defaultMessage: "Default to English small" })}
          />
          <Alert
            type="info"
            message={
              <FormattedMessage defaultMessage="Please check your printer's manual for the supported font code, you may only need to set this if you want to print out language other than English." />
            }
          />
          <Checkbox checked={descriptionBitMap || false}
            onChange={e => onChange({ descriptionBitMap: e.target.checked })}><FormattedMessage defaultMessage={'Print label body as image (Only enable this option if your language can not be print out due to printer language support issue.)'} /></Checkbox>
        </Space>
      </Form.Item>
    </Form>
  );
};
