import {
  JOB_TYPE_DELIVERY,
  JOB_TYPE_PICKUP,
  JOB_TYPE_SERVICE
} from "@easybiz/utils";
import { useIntl } from "react-intl";

export default function JobTitle({ type, recurring }) {
  const intl = useIntl();

  let title;

  switch (type) {
    case JOB_TYPE_PICKUP:
      title = intl.formatMessage({ defaultMessage: "Pick up" });
      break;
    case JOB_TYPE_DELIVERY:
      title = intl.formatMessage({ defaultMessage: "Delivery" });
      break;
    case JOB_TYPE_SERVICE:
      title = intl.formatMessage({ defaultMessage: "Service" });
      break;
    default:
      return null;
  }

  return `${
    recurring
      ? `${intl.formatMessage({ defaultMessage: "Recurring" })}(${
          recurring.index + 1
        }/${recurring.total}) `
      : ''
  }${title || ""}`;
}
