export default function (regX, order) {
  const { orderNo, name, tels, contacts } = order.data() || {};

  return (
    regX.test(orderNo) ||
    regX.test(name) ||
    regX.test(
      Array.isArray(tels) ? tels.map((tel) => tel.number).join(",") : ``
    ) ||
    regX.test(Array.isArray(contacts) ? contacts.join(",") : ``)
  );
}
