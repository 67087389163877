import { CaretRightFilled } from "@ant-design/icons";
import { useAsyncCollectionOptions } from "@easybiz/request";
import { Spin, Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";
import QuickSwitchCalendar from "../QuickSwitchCalendar";
import ScheduleDateCalendarTags from "../ScheduleDateCalendarTags";

export default function ({ collectionOptions, order, schedule, onSelect }) {
  const { options } = useAsyncCollectionOptions({
    serviceId: order?.get("service.id"),
    businessCode: order?.get("business.id"),
    date: order?.get("date"),
    orderId: order?.id,
    serviceTypes: order?.get("serviceTypes"),
    combine: true,
  });
  const dateOptions = collectionOptions || options;
  const now = dayjs();

  return (
    <QuickSwitchCalendar
      minDate={now}
      value={schedule ? dayjs(schedule.date) : null}
      dateCellRender={(date) => {
        const day = date.format("YYYY-MM-DD");
        const today = now.format("YYYY-MM-DD");
        const option = dateOptions?.find((data) => data.date === day);

        return (
          <>
            {option && <ScheduleDateCalendarTags {...option} />}
            {day === today && (
              <Tag color={"processing"} icon={<CaretRightFilled />}>
                <FormattedMessage defaultMessage={"Today"} />
              </Tag>
            )}
            {day >= today && !dateOptions && <Spin />}
          </>
        );
      }}
      onSelect={(date) => {
        if (onSelect) {
          const dateOption = dateOptions?.find((data) => data.date === date.format("YYYY-MM-DD"));
          onSelect(dateOption || { date: date.format("YYYY-MM-DD") });
        }
      }}
    />
  );
}
