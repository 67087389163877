import { CopyOutlined } from "@ant-design/icons";
import {
  useCheckoutCustomer,
  useCheckoutService,
  useSetCheckoutCustomer,
  useSetCheckoutParams,
  useSetCheckoutService,
} from "@easybiz/checkout";
import { useBusinesses, useHaveNetwork, useRealmId, useSetDisplayMode, useSetOpenOrderId } from "@easybiz/context";
import { TAB_NEW_ORDER, toRecordCustomer } from "@easybiz/utils";
import { doc, getDoc, getFirestore, setDoc } from "@firebase/firestore";
import { Button, message } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PopConfirm from "../PopConfirm";

export default ({ order, disabled }) => {
  const realmId = useRealmId();
  const setService = useSetCheckoutService();
  const setCustomer = useSetCheckoutCustomer();
  const services = useBusinesses();
  const checkoutCustomer = useCheckoutCustomer();
  const checkoutService = useCheckoutService();
  const updateParams = useSetCheckoutParams();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const copyService = services?.find((service) => service.id === order?.get("service.id"));
  const haveNetwork = useHaveNetwork();
  const setDisplayMode = useSetDisplayMode();
  const setOpenOrderId = useSetOpenOrderId();

  const onCopy = async () => {
    if (!order.exists()) {
      return message.error(intl.formatMessage({ defaultMessage: "Order not found" }));
    }

    const items = (order.get("items") || []).map(({ cartIndex, labelIndexes, ...item }) => item);

    try {
      setLoading(true);

      if (checkoutService?.id === order.get("service.id") && checkoutCustomer?.id === order.get("customer.id")) {
        // Customer already selected, directly set
        updateParams({
          items,
        });
      } else {
        let customer = order.get("customer");

        // Get latest customer account info if have network
        if (haveNetwork) {
          const customerDoc = await getDoc(doc(getFirestore(), `realms/${realmId}/customers/${customer.id}`));

          if (!customerDoc.exists()) {
            return message.error(
              intl.formatMessage({
                defaultMessage: "Customer account already been deleted",
              })
            );
          }

          customer = toRecordCustomer({
            ...customerDoc.data(),
            id: customerDoc.id,
          });
        }

        await setDoc(
          doc(
            getFirestore(),
            `realms/${realmId}/shopping_carts/${order.get("business.id")}-${order.get("service.id")}-${order.get(
              "customer.id"
            )}`
          ),
          { items }
        );

        setService(copyService);
        setCustomer(customer);
      }

      setDisplayMode({
        mainActionTab: TAB_NEW_ORDER,
      });
      setOpenOrderId(null);
    } finally {
      setLoading(false);
    }
  };

  return copyService ? (
    <PopConfirm
      title={<FormattedMessage defaultMessage={"Copy order items into new order preview?"} />}
      onConfirm={onCopy}
    >
      <Button disabled={disabled} type="text" icon={<CopyOutlined />} loading={loading} />
    </PopConfirm>
  ) : null;
};
