import { useClientType } from "@easybiz/context";
import {
  CLIENT_CONSOLE,
  LINE_TYPE_IMAGE,
  LINE_TYPE_PARAGRAPH,
  LINE_TYPE_QRCODE,
  LINE_TYPE_TERMS,
  LINE_TYPE_TITLE,
} from "@easybiz/utils";
import { Card, Space } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import DownloadReceiptButton from "../DownloadReceiptButton";
import PrintReceiptButton from "../PrintReceiptButton";

export default function ({ title, lines, loading, printing, downloadTitle }) {
  const client = useClientType();

  return (
    <Card
      style={{ width: 436, fontFamily: "monospace", margin: 0 }}
      bodyStyle={{ padding: 16, backgroundColor: "white" }}
      title={title}
      extra={
        printing ? (
          <Space>
            {client === CLIENT_CONSOLE && <DownloadReceiptButton lines={lines} fileName={downloadTitle} />}
            <PrintReceiptButton disabled={loading} lines={lines} hideSetting />
          </Space>
        ) : null
      }
      loading={loading}
    >
      <div>
        {(lines || []).map(({ align, type, large, content, width, bold, reverse }, index) => {
          switch (type) {
            case LINE_TYPE_IMAGE:
              return (
                <div key={`${index}`} className={`flex flex-align-${align}`}>
                  <img src={content} style={{ width: width || "100%", display: "block" }} />
                </div>
              );
            case LINE_TYPE_TITLE:
              return (
                <h2
                  key={`${index}`}
                  style={{
                    textAlign: align,
                    backgroundColor: reverse ? "black" : null,
                    color: reverse ? "white" : null,
                  }}
                >
                  {content}
                </h2>
              );
            case LINE_TYPE_TERMS:
              return (
                <div key={`${index}`} style={{ textAlign: align, fontSize: 10 }}>
                  {content}
                </div>
              );
            case LINE_TYPE_PARAGRAPH: {
              return (
                <div key={`${index}`}>
                  {content instanceof Array ? (
                    content.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          textAlign: align,
                          whiteSpace: "pre",
                          ...(bold &&
                            bold.indexOf(index) >= 0 && {
                              fontWeight: "bold",
                            }),
                        }}
                      >
                        {item}
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        textAlign: align,
                        whiteSpace: "pre",
                        ...(bold && { fontWeight: "bold" }),
                      }}
                    >
                      {content}
                    </div>
                  )}
                </div>
              );
            }
            case LINE_TYPE_QRCODE:
              return (
                <div key={`${index}`} style={{ textAlign: align }}>
                  <QRCodeSVG value={content} size={large ? 128 : 64} />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </Card>
  );
}
