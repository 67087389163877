import { OPERATION_GROUP_POS, POS_OPERATION_CASH_DRAWER_OPEN } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const onSubmit = useCallback(() => {
    return [OPERATION_GROUP_POS, POS_OPERATION_CASH_DRAWER_OPEN];
  }, []);

  return useActionCallback(onSubmit, callback);
};
