import { DisconnectOutlined } from "@ant-design/icons";
import { useOfflineAlert } from "@easybiz/component";
import { useErrorColor } from "@easybiz/context";
import { Alert, Button } from "antd";
import React from "react";

export default function OfflineAlert() {
  const alert = useOfflineAlert();
  const errorColor = useErrorColor();

  if (alert) {
    return (
      <div className="padding-half">
        <Alert
          type="error"
          showIcon
          icon={<DisconnectOutlined style={{ color: errorColor, fontSize: 20 }} />}
          message={alert.title}
          action={
            <Button block onClick={() => window.location.reload(true)}>
              {alert.reloadBtn}
            </Button>
          }
        />
      </div>
    );
  } else {
    return null;
  }
}
