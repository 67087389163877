import {
  LogoutOutlined,
  PrinterOutlined,
  QrcodeOutlined,
  QuestionOutlined,
  SettingOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { AppUpdateButton, LanguageSettingButton, SignOutDialog } from "@easybiz/web-admin";
import { NameDisplay } from "@easybiz/component";
import { useBusinessCode, useIsOfflineEnabled, useOTAUpdate, usePrinterConnect } from "@easybiz/context";
import { PRINTER_TYPE_LABEL, PRINTER_TYPE_RECEIPT } from "@easybiz/utils";
import { Button, Col, Drawer, Form, Row, Switch, Typography } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import POSOfflineLineSettingDialog from "./POSOfflineLineSettingDialog";
import POSSettingDialog from "./POSSettingDialog";
import PrintingDialog from "./PrintingDialog";

export default function ShopDrawer({ open, onClose }) {
  const businessCode = useBusinessCode();
  const update = useOTAUpdate();
  const [printingVisible, setPrintingVisible] = useState(false);
  const [settingVisible, setSettingVisible] = useState(false);
  const [signOutVisible, setSignOutVisible] = useState(false);
  const [toggleOffline, setToogleOffline] = useState(false);
  const offlineEnabled = useIsOfflineEnabled();
  const connectPrinter = usePrinterConnect();

  return (
    <Drawer
      title={<NameDisplay nameKey={businessCode} />}
      push={false}
      placement="left"
      width={470}
      closable={true}
      destroyOnClose
      onClose={onClose}
      open={open}
    >
      <Form layout="vertical">
        <Form.Item label={<FormattedMessage defaultMessage={"Printing"} />}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Button
                icon={<PrinterOutlined />}
                size="large"
                block
                onClick={() => connectPrinter({ printerType: PRINTER_TYPE_RECEIPT })}
              >
                <FormattedMessage tagName={"span"} defaultMessage="Receipt printer" />
              </Button>
            </Col>
            <Col span={12}>
              <Button
                icon={<TagOutlined />}
                size="large"
                block
                onClick={() => connectPrinter({ printerType: PRINTER_TYPE_LABEL })}
              >
                <FormattedMessage tagName={"span"} defaultMessage="Label printer" />
              </Button>
            </Col>
            <Col span={12}>
              <Button icon={<SettingOutlined />} size="large" block onClick={() => setSettingVisible(true)}>
                <FormattedMessage tagName={"span"} defaultMessage="Local settings" />
              </Button>
            </Col>
            <Col span={12}>
              <Button icon={<QrcodeOutlined />} size="large" block onClick={() => setPrintingVisible(true)}>
                <FormattedMessage tagName={"span"} defaultMessage="Print" />
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label={<FormattedMessage defaultMessage={"System"} />}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <LanguageSettingButton block size="large" />
            </Col>
            <Col span={12}>
              <Button size="large" block icon={<QuestionOutlined />} onClick={() => window.Intercom("show")}>
                <FormattedMessage tagName="span" defaultMessage="Help" />
              </Button>
            </Col>
            <Col span={12}>
              <AppUpdateButton showVersion block />
            </Col>
            <Col span={12} className="flex flex-center flex-align-center">
              {update ? (
                <Typography.Text type="success" strong>
                  <FormattedMessage defaultMessage={"Cloud POS new version is ready for update"} />
                </Typography.Text>
              ) : (
                <Switch
                  checked={offlineEnabled}
                  checkedChildren={<FormattedMessage defaultMessage={"Offline support enabled"} />}
                  unCheckedChildren={<FormattedMessage defaultMessage={"Offline support disabled"} />}
                  onChange={() => setToogleOffline(true)}
                />
              )}
            </Col>
            <Col span={24} />
            <Col span={24} />
            <Col span={6} />
            <Col span={12}>
              <Button
                size="large"
                type="link"
                danger
                icon={<LogoutOutlined />}
                onClick={() => setSignOutVisible(true)}
                block
              >
                <FormattedMessage tagName="span" defaultMessage="Sign out store" />
              </Button>
            </Col>
            <Col span={6} />
          </Row>
        </Form.Item>
      </Form>
      <PrintingDialog open={printingVisible} onCancel={() => setPrintingVisible(false)} />
      <POSSettingDialog open={settingVisible} onCancel={() => setSettingVisible(false)} />
      <SignOutDialog open={signOutVisible} onCancel={() => setSignOutVisible(false)} />
      <POSOfflineLineSettingDialog open={toggleOffline} onCancel={() => setToogleOffline(false)} />
    </Drawer>
  );
}
