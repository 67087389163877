export const EASYBIZ_PRODUCT_ID_POS = "POS";
export const EASYBIZ_PRODUCT_ID_ONLINE = "ONLINE";
export const EASYBIZ_PRODUCT_ID_APP = "APP";
export const EASYBIZ_PRODUCT_ID_WORKFLOW = "WORKFLOW";
export const EASYBIZ_PRODUCT_ID_PLANT = "FACTORY";

export const PRICE_STATUS_PUBLIC = "public";
export const PRICE_STATUS_PRIVATE = "private";

export const CLIENT_POS = "pos";
export const CLIENT_MOBILE = "mobile";
export const CLIENT_CONSOLE = "console";
export const CLIENT_CUSTOMER_APP = "customer_app";
export const CLIENT_PLATFORM_DASHBOARD = "dashboard";

export const PLATFORM_WEB = "web";
export const PLATFORM_IOS = "ios";
export const PLATFORM_ANDROID = "android";

export const PLATFORM_OPERATION_ERROR_REPORT = "error_report";
export const PLATFORM_OPERATION_NEW_NOTICE = 'new_notice';

export const SUBSCRIPTION_OPERATION_AUTO_ASSIGN_DOMAIN_CHECK = "auto_assign_domain_check";
export const SUBSCRIPTION_OPERATION_SIGN_IN_EMAIL_CHECK = "sign_in_email_check";
export const SUBSCRIPTION_OPERATION_EMAIL_OTP_SEND = 'email_otp_send';
export const SUBSCRIPTION_OPERATION_START = 'create_account';
export const SUBSCRIPTION_OPERATION_PASSWORD_FORGOT = "password_forgot";
export const SUBSCRIPTION_OPERATION_PASSWORD_RESET = "password_reset";
export const SUBSCRIPTION_OPERATION_EMAIL_SEND_VERIFY_LINK = "email_send_verify_link";
export const SUBSCRIPTION_OPERATION_EMAIL_VERIFY = "email_verify";

export const PLATFORM_OPERATION_DELETE_ACCOUNT = "delete account";

export const OPERATOR_SYSTEM = {
  id: 'system',
  name: 'System'
};

export const BUG_STATUS_OPEN = 'open';
export const BUG_STATUS_ATTENDING = 'attending';
export const BUG_STATUS_SOLVED = 'solved';

export const TEMPLATE_REALM_ID = 'tGMa7fl6xnX2U6Z8Z0sR';