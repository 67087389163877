export const POS_OPERATION_CLOCK_IN = "clock_in";
export const POS_OPERATION_CLOCK_OUT = "clock_out";
export const POS_OPERATION_CLOSE_OF_DAY_PREVIEW = "close_of_day_preview";
export const POS_OPERATION_CLOSE_OF_DAY_SUBMIT = "close_of_day_submit";
export const POS_OPERATION_CLOSE_OF_DAY_WITHDRAW = "close_of_day_withdraw";
export const POS_OPERATION_POINTS_REDEEM = "points_redeem";
export const POS_OPERATION_ORDER_HANDOVER = "order_handover";
export const POS_OPERATION_CASH_DRAWER_OPEN = "cash_drawer_open";
export const POS_OPERATION_CASH_DRAWER_START = "cash_drawer_start";
export const POS_OPERATION_CASH_DRAWER_END = "cash_drawer_end";
export const POS_OPERATION_TOP_UP_REPORT = "top_up_report";
export const POS_OPERATION_CASH_DRAWER_CASH_IN_OUT = "cash_drawer_cash_in_out";
export const POS_OPERATION_PREVIEW_COLLECTION_OPTIONS = "preview_collection_operations";
export const POS_OPERATION_DEVICE_REGISTER = "pos_device_register";
export const POS_OPERATION_DEVICE_PAIRING_TOKEN = "pos_device_pairing_token";

export const POS_BATCH_CHECK_OUT = 'PENDING SHOP COLLECTION';
export const POS_BATCH_CHECK_IN = 'PENDING SHOP RECEIVE';

export const PAIRING_MODE_CUSTOMER_SCREEN = 'customer_screen';
export const PAIRING_MODE_MOBILE_OPERATION = 'mobile_operation';
export const PAIRING_MODE_PAYMENT_TERMINAL = 'payment_terminal';

export const TAB_NEW_ORDER = 'new_order';
export const TAB_OUTSTANDING = 'outstanding';
export const TAB_ORDER_HISTORY = 'order_history';
export const TAB_ADVANCE_CREDIT = 'advance_credit';
export const TAB_POINTS = 'points';
export const TAB_MEMBER = 'member';

export const DISPLAY_MODE_SCHEDULE = 'schedule';
export const DISPLAY_MODE_CASH_DRAWER = 'cash_drawer';
export const DISPLAY_MODE_CONFIRM_ORDER = 'confirm_order';
export const DISPLAY_MODE_CHECK_OUT = 'check_out';
export const DISPLAY_MODE_CHECK_IN = 'check_in';
export const DISPLAY_MODE_COLLECTION = 'collection';
export const DISPLAY_MODE_PRODUCTION = 'production';
export const DISPLAY_MODE_SALES = 'sales';
export const DISPLAY_MODE_REDO = 'redo';