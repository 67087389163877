import {
  PAIRING_MODE_CUSTOMER_SCREEN,
  PAIRING_MODE_MOBILE_OPERATION,
  PAIRING_MODE_PAYMENT_TERMINAL,
} from "@easybiz/utils";
import { FormattedMessage } from "react-intl";

export default function ({ pairingMode }) {
  switch (pairingMode) {
    case PAIRING_MODE_CUSTOMER_SCREEN:
      return <FormattedMessage defaultMessage={"Customer screen"} />;
    case PAIRING_MODE_MOBILE_OPERATION:
      return <FormattedMessage defaultMessage={"Mobile operation"} />;
    case PAIRING_MODE_PAYMENT_TERMINAL:
      return <FormattedMessage defaultMessage={"Payment terminal"} />;
    default:
      return null;
  }
}
