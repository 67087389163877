import { NumberOutlined } from "@ant-design/icons";
import { useCheckoutCustomer } from "@easybiz/checkout";
import { Button, message, Popover } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PromoCodeInput from "../PromoCodeInput";
import Title from "../Title";

export default ({ onApplied, disabled }) => {
  const customer = useCheckoutCustomer();
  const [open, setVisible] = useState(false);
  const intl = useIntl();

  return (
    <Popover
      trigger={"click"}
      title={
        <Title>
          <FormattedMessage defaultMessage={"Use Promo Code"} />
        </Title>
      }
      open={open}
      onOpenChange={(open) => {
        if (open && !customer) {
          return message.info(
            intl.formatMessage({
              defaultMessage: "Please select customer account first",
            })
          );
        }

        setVisible(open);
      }}
      destroyTooltipOnHide
      content={
        <PromoCodeInput
          onApplied={(promoCode) => {
            setVisible(false);
            onApplied(promoCode);
          }}
        />
      }
    >
      <Button icon={<NumberOutlined />} disabled={disabled}>
        <FormattedMessage tagName="span" defaultMessage="Promo code" />
      </Button>
    </Popover>
  );
};
