export const CUSTOMER_SCREEN_PROFILE_FILL_IN = "PROFILE_FILL_IN";
export const CUSTOMER_SCREEN_ORDER = "ORDER_SIGN";
export const CUSTOMER_SCREEN_TOP_UP_OPTIONS = "TOP_UP_OPTIONS";
export const CUSTOMER_SCREEN_TOP_UP = "TOP_UP_SIGN";
export const CUSTOMER_SCREEN_ITEM_DETAIL = "ITEM_DETAIL";
export const CUSTOMER_SCREEN_COLLECTION = "COLLECTION_SIGN";
export const CUSTOMER_SCREEN_DATE_TIME_OPTIONS = "DATE_TIME_OPTIONS";
export const CUSTOMER_SCREEN_ARTICLE = "ARTICLE";
export const CUSTOMER_SCREEN_PAYMENT_INTENT = "PAYMENT_NEXT_ACTION";

export const CUSTOMER_OPERATION_PROFILE_SAVE = 'profile_update';
export const CUSTOMER_OPERATION_PROMO_CODE_CHECK = "promo_code_check";
export const CUSTOMER_OPERATION_ACCOUNT_AUTO_SIGN_UP = 'account_auto_sign_up';
export const CUSTOMER_OPERATION_BUSINESS_ZONE_CHECK = 'business_zone_check';
export const CUSTOMER_OPERATION_TIME_SLOT_OPTIONS = "time_slot_options";
export const CUSTOMER_OPERATION_TIME_SLOT_BOOK = 'time_slot_book';
export const CUSTOMER_OPERATION_TIME_SLOT_CANCEL = 'time_slot_cancel';
export const CUSTOMER_OPERATION_ACCOUNT_REFERRAL_LINK = "account_referral_link";
export const CUSTOMER_OPERATION_ACCOUNT_INSTALL = "account_install";
export const CUSTOMER_OPERATION_EMAIL_OTP_SEND = 'email_otp_send';
export const CUSTOMER_OPERATION_EMAIL_OTP_VERIFY = 'email_otp_verify';
export const CUSTOMER_OPERATION_SMS_OTP_SEND = 'sms_otp_send';
export const CUSTOMER_OPERATION_SMS_OTP_VERIFY = 'sms_otp_verify';
export const CUSTOMER_OPERATION_MARKETING_ONBOARD = 'marketing_onboard';
export const CUSTOMER_OPERATION_MEMBER_CLUB_JOIN = "member_club_join";
export const CUSTOMER_OPERATION_CREDIT_TOP_UP = "credit_top_up";
export const CUSTOMER_OPERATION_PAYMENT_METHOD_SETUP = 'payment_method_setup';
export const CUSTOMER_OPERATION_PAYMENT_METHOD_REMOVE = "payment_method_remove";
export const CUSTOMER_OPERATION_PAYMENT_METHOD_FETCH = 'payment_method_fetch';
export const CUSTOMER_OPERATION_PAGE_VISIT = "page_visit";
export const CUSTOMER_OPERATION_PAYMENT_INTENT_CANCEL = "payment_intent_cancel";
export const CUSTOMER_OPERATION_ADDRESS_EDIT = "address_edit";
export const CUSTOMER_OPERATION_NOTIFICATION_MARK_ALL_READ = 'notification_mark_all_read';
export const CUSTOMER_OPERATION_RECURRING_EDIT = "recurring_edit";
export const CUSTOMER_OPERATION_ARTICLE_READ = 'article_read';

export const CUSTOMER_PROFILE_NAME = "name";
export const CUSTOMER_PROFILE_TEL = "tel";
export const CUSTOMER_PROFILE_EMAIL = "email";

export const DEFAULT_NICE_AVATAR = {
  "sex": "man",
  "faceColor": "#F9C9B6",
  "earSize": "small",
  "eyeStyle": "circle",
  "noseStyle": "round",
  "mouthStyle": "smile",
  "shirtStyle": "hoody",
  "glassesStyle": "none",
  "hairColor": "#77311D",
  "hairStyle": "normal",
  "hatStyle": "none",
  "hatColor": "#FC909F",
  "eyeBrowStyle": "up",
  "shirtColor": "#F4D150",
  "bgColor": "#506AF4"
};