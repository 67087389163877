import React from "react";
import ContactsDisplay from "../ContactsDisplay";

export default function ({ customer, showEmail, style }) {
  return customer ? (
    <span style={style}>
      {customer.index ? <b>[{customer.index}] </b> : null}
      {customer.name || ""} <ContactsDisplay {...customer} />{" "}
      {showEmail && customer.email ? ` | ${customer.email}` : ""}
    </span>
  ) : null;
}
