import { useCallback } from "react";

export default () => {
  const onPrint = useCallback(async (printer, commands) => {
    for (let content of commands) {
      if (typeof content === "string") {
        const iconv = await import("iconv-lite");
        content = iconv.encode(content, "GB18030");
      }

      // BLUETOOTH
      let index = 0;
      while (content.slice(index, index + 20).length) {
        await printer.writeValue(content.slice(index, index + 20));
        index += 20;

        await new Promise((resolve) => {
          setTimeout(() => resolve(), 2);
        });
      }
    }
  }, []);

  if (typeof navigator !== "undefined" && navigator.bluetooth) {
    return onPrint;
  } else {
    // Not support
    return null;
  }
};
