"use client";

import { useCustomerAddressEdit } from "@easybiz/request";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function useCustomerAddressEditPanel(customerId, editAddress, onComplete, initDefault) {
  const [address, setAddress] = useState();
  const [isDefault, setIsDefault] = useState(false);
  const intl = useIntl();
  const { onSubmit, loading } = useCustomerAddressEdit((succeed, response) => {
    if (succeed) {
      onComplete(response.address);
    }
  });

  useEffect(() => {
    setIsDefault(initDefault || false);

    if (editAddress) {
      const { customer, sortIndex, ...address } = editAddress;
      setAddress(address);
    } else {
      setAddress(null);
    }
  }, [editAddress]);

  return {
    address,
    onChange: setAddress,
    title: editAddress ? (
      <FormattedMessage defaultMessage="Edit Address" />
    ) : (
      <FormattedMessage defaultMessage="New Address" />
    ),
    onOk: () => {
      onSubmit({
        customerId,
        addressId: editAddress?.id,
        isDefault,
        address,
      });
    },
    loading,
    emptyPrompt: <FormattedMessage defaultMessage="Search and select address" />,
    line1: {
      title: <FormattedMessage defaultMessage="Address Line 1" />,
      placeholder: intl.formatMessage({
        defaultMessage: "Street/Block",
      }),
    },
    line2: {
      title: <FormattedMessage defaultMessage="Address Line 2" />,
      placeholder: intl.formatMessage({ defaultMessage: "Unit no" }),
    },
    defaultCheckBox: <FormattedMessage defaultMessage="Set as default address" />,
  };
}
