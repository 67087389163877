import {
  useAutoCusReceipt,
  useAutoInternalReceipt,
  useAutoLabel,
  useClientType,
  useRegisteredDevice,
} from "@easybiz/context";
import { usePrinter } from "@easybiz/hook";
import { CLIENT_POS, PAYMENT_ID_CASH } from "@easybiz/utils";
import useCreate from "./useCreate";

export default (callback) => {
  const printer = usePrinter();
  const client = useClientType();
  const registerDevice = useRegisteredDevice();
  const { receiptPrinter, labelPrinter } = registerDevice?.data() || {};
  const autoCusReceipt = useAutoCusReceipt();
  const autoInternalReceipt = useAutoInternalReceipt();
  const autoTagging = useAutoLabel();

  return useCreate(async (success, response, request) => {
    callback(success, response, request);

    // Auto printing
    if (success) {
      const autoReceipt = Number.isInteger(autoCusReceipt) && autoCusReceipt > 0;
      const autoInternal = Number.isInteger(autoInternalReceipt) && autoInternalReceipt > 0;

      if (receiptPrinter && (autoReceipt || autoInternal)) {
        const { receipt } = response;
        // Auto printing
        if (autoReceipt && client === CLIENT_POS && request.checkoutParams?.payment?.id === PAYMENT_ID_CASH) {
          await printer.openDrawer(true);
        }

        let customerLines, internalReceipts;

        if (Array.isArray(receipt.mutiLines)) {
          customerLines = receipt.mutiLines[0]?.lines;
          internalReceipts = receipt.mutiLines.slice(1);
        } else {
          customerLines = receipt.lines;
        }

        if (Array.isArray(customerLines) && autoReceipt) {
          for (let index = 0; index < autoCusReceipt; index++) {
            await printer.print({ lines: customerLines });
          }
        }

        if (Array.isArray(internalReceipts) && autoInternal) {
          for (const { lines } of internalReceipts) {
            if (Array.isArray(lines)) {
              for (let index = 0; index < autoInternalReceipt; index++) {
                await printer.print({ lines });
              }
            }
          }
        }
      }

      if (labelPrinter && autoTagging) {
        const { newOrder } = response;
        printer.print({ labelOrder: newOrder });
      }
    }
  });
};
