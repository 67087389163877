import { useDisplayName } from "@easybiz/context";

export default function ({ business, businessCode, orderNo, agent }) {
  const businessName = useDisplayName(businessCode || business?.id);

  if (agent) {
    return `#${orderNo} (${agent.name})`;
  } else {
    return `#${orderNo}${businessName ? ` (${businessName})` : ""}`;
  }
}
