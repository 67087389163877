import formatLabeLines from "./formatLabeLines.js";

function encodeText(text) {
  const encoder = new TextEncoder();
  return encoder.encode(text);
}

function concatenateUint8Arrays(arrays) {
  let totalLength = 0;
  arrays.forEach((arr) => (totalLength += arr.length));

  const result = new Uint8Array(totalLength);
  let offset = 0;

  arrays.forEach((arr) => {
    result.set(arr, offset);
    offset += arr.length;
  });

  return result;
}

function drawTextWrapped(context, text, x, y, maxWidth, lineHeight) {
  const words = text.split(" ");
  let line = "";

  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + " ";
    const metrics = context.measureText(testLine);
    const testWidth = metrics.width;

    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);
      line = words[n] + " ";
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
}

function convertImageDataToBinary(text, canvasWidth, canvasHeight) {
  const fontSize = 26;

  // Create canvas dynamically
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set canvas size
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // Set font and text settings
  context.font = `${fontSize}px Arial`;
  context.fillStyle = "white";
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.fillStyle = "black";

  // Draw wrapped text
  drawTextWrapped(context, text, 0, fontSize, canvasWidth, fontSize);

  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

  const pixels = imageData.data;
  const width = imageData.width;
  const height = imageData.height;
  const binaryData = [];

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x += 8) {
      let byte = 0;
      let mask = 128;
      for (let bit = 0; bit < 8; bit++) {
        const pixelIndex = (y * width + (x + bit)) * 4;
        const r = pixels[pixelIndex];
        const g = pixels[pixelIndex + 1];
        const b = pixels[pixelIndex + 2];
        const a = pixels[pixelIndex + 3];

        const grayscale = 0.299 * r + 0.587 * g + 0.114 * b;
        const isWhite = grayscale > 128 || a < 128; // Text should be black, background should be white
        if (isWhite) {
          byte |= mask;
        }
        mask >>= 1;
      }
      binaryData.push(byte);
    }
  }
  return binaryData;
}

function getSmallTag(
  { fixedHeight, fixedWidth, leftIndex, rightIndex, qrcode, info, icons, title, description },
  settings
) {
  const labelWidth = Number.isInteger(fixedWidth) ? fixedWidth : 110;
  const MM = 8;
  const { offsetVertical, density, font } = settings || {};
  const labelHeight = fixedHeight || 10;
  const wdith = 104 * MM;
  const contentLeft = 4 * 3 * MM + 4; // 0.5 mm GAP
  const contentRight = wdith - contentLeft;

  const formatIndex = `${leftIndex || ""}`.slice(0, 5);
  const indexRight = wdith - formatIndex.length * 3 * MM;

  let titleLeft = contentLeft;
  const showRightQr = qrcode && !info;
  if (qrcode) {
    titleLeft = titleLeft + 9 * MM + 12;
  }

  const infoMax = 10;
  const infoOffset = 8;
  const infoLines = [].concat.apply(
    [],
    (info || "").split("\n").map((seg) => (seg ? formatLabeLines(seg || "", infoMax) : [seg]))
  );
  const hasIcon = Array.isArray(icons) && icons.length > 0;

  const content = `
    SIZE ${labelWidth} mm, ${labelHeight} mm
    GAP 0 mm, 0 mm
    SPEED 5
    DENSITY ${typeof density === "number" ? density : 15}
    SHIFT ${typeof offsetVertical === "number" ? offsetVertical : 0}
    SET CUTTER 1
    CLS
    TEXT 0,16,"4",0,1,2,"${formatIndex}"
    TEXT ${indexRight},16,"4",0,1,2,"${`${rightIndex || ""}`.slice(0, 5)}"
    ${qrcode ? `QRCODE ${contentLeft},2,L,3,A,0,"${qrcode}"` : ""}
    ${showRightQr ? `QRCODE ${contentRight - 9 * MM - MM},2,L,3,A,0,"${qrcode}"` : ""}
    TEXT ${titleLeft},${MM},"4",0,1,1,"${`${title || ""}`.slice(0, qrcode && info ? 16 : info || qrcode ? 19 : 26)}"
    ${(icons || [])
      .map((code, index) => {
        const boxWidth = 18 * `${code}`.length;
        const offset = [
          0,
          ...icons.slice(0, index).map((code) => {
            const boxWidth = 18 * `${code}`.length;
            return boxWidth + MM;
          }),
        ].reduce((a, b) => a + b);
        const x = titleLeft + offset + 4;
        const y = MM + 5 * MM;

        return `
        BOX ${x - 6},${y - 6},${x + boxWidth},${y + 26},3
        TEXT ${x},${y},"3",0,1,1,"${code}"
      `;
      })
      .join("\r\n")}
    TEXT ${titleLeft + (hasIcon ? 30 * icons.length + 8 : 0)},${MM + 5 * MM},"${font || 3}",0,1,1,"${`${
    description || ""
  }`.slice(0, (qrcode && info ? 24 : info || qrcode ? 29 : 39) - (hasIcon ? icons.length * 2 : 0))}"
    ${infoLines.map((paragraph, index) => `TEXT 570,${infoOffset + index * 24},"3",0,1,1,"${paragraph}"`).join("\r\n")}
    PRINT 1,1
    CLS
  `;

  return content;
}

function getLargeTag(
  {
    qrcode,
    singleQr,
    qrSize,
    qrEcc,
    title,
    reverseTitle,
    description,
    info,
    icons,
    appendix,
    fixedHeight,
    fixedWidth,
    leftIndex,
    rightIndex,
    accessory,
  },
  settings
) {
  const { offsetVertical, density, font, descriptionBitMap } = settings || {};
  const labelWidth = Number.isInteger(fixedWidth) ? fixedWidth : 110;
  const dotWidth = labelWidth * 8;

  let labelHeight = 20,
    titleMax = 20,
    infoMax = 10;

  if (typeof fixedHeight === "number") {
    labelHeight = fixedHeight;
  }

  const qrCodeSize = Number.isInteger(qrSize) && qrSize >= 1 && qrSize <= 10 ? qrSize : 4;
  const infoLines = [].concat.apply(
    [],
    (info || "").split("\n").map((seg) => (seg ? formatLabeLines(seg || "", infoMax) : [seg]))
  );

  let sideLeft, sideRight;

  if (leftIndex) {
    if (leftIndex.length === 1) {
      // Large mode
      sideLeft = `TEXT 0,10,"5",0,2,2,"${leftIndex}"`;
      sideRight = `TEXT ${dotWidth - 115},10,"5",0,2,2,"${rightIndex}"`;
    } else if (leftIndex.length <= 3) {
      // Middle mode
      sideLeft = `TEXT 55,10,"5",90,1,1,"${leftIndex}"`;
      sideRight = `TEXT ${dotWidth - 60},10,"5",90,1,1,"${rightIndex}"`;
    } else {
      // Small mode
      sideLeft = `TEXT 55,10,"4",90,2,1,"${`${leftIndex}`.slice(0, 5)}"`;
      sideRight = `TEXT ${dotWidth - 60},10,"4",90,2,1,"${`${rightIndex}`.slice(0, 5)}"`;
    }
  }

  const contentStart = 55 + qrCodeSize * 30;
  const infoStart = dotWidth - 270 - (!qrcode || singleQr ? 0 : qrCodeSize * 30);
  let contentWidth = infoStart - contentStart - 8;
  contentWidth -= contentWidth % 8;
  const titleMaxLength = Math.floor(contentWidth / 23);
  const infoOffset = `${title}`.length > titleMaxLength ? 40 : 4;

  const descriptionLines = [].concat.apply(
    [],
    (description || "")
      .split("\n")
      .filter((seg) => seg)
      .map((seg) => {
        return formatLabeLines(seg, [
          ...Array.apply(null, Array(infoLines.length))
            .map(() => 20)
            .slice(infoOffset === 4 ? 1 : 0),
          30,
        ]);
      })
  );

  return concatenateUint8Arrays([
    encodeText(`SIZE ${labelWidth} mm, ${labelHeight} mm\r\n`),
    encodeText(`GAP 0 mm, 0 mm\r\n`),
    encodeText(`SPEED 5\r\n`),
    encodeText(`DENSITY ${typeof density === "number" ? density : 15}\r\n`),
    encodeText(`SHIFT ${typeof offsetVertical === "number" ? offsetVertical : 0}\r\n`),
    encodeText(`SET CUTTER 1\r\n`),
    encodeText(`CLS\r\n`),
    ...(sideLeft ? [encodeText(`${sideLeft}\r\n`)] : []),
    ...(accessory ? [encodeText(`TEXT 125,10,"3",90,1,1,"ACCESSORY"\r\n`)] : []),
    ...(!accessory && qrcode ? [encodeText(`QRCODE 63,4,${qrEcc || "M"},${qrCodeSize},A,0,"${qrcode}"\r\n`)] : []),
    ...(appendix ? [encodeText(`TEXT ${125 - appendix.length * 13},120,"3",0,1,1,"${appendix}"\r\n`)] : []),
    encodeText(
      `TEXT ${contentStart},4,"4",0,1,1,"${`${title || ""}`.slice(0, Math.floor((contentWidth + 270) / 24))}"\r\n`
    ),
    ...(reverseTitle ? [encodeText(`REVERSE ${contentStart - 4},0,${contentWidth},${36}\r\n`)] : []),
    ...(descriptionBitMap
      ? [
          encodeText(`BITMAP ${contentStart},37,${Math.ceil(contentWidth / 8)},${labelHeight * 6},0,`),
          convertImageDataToBinary(descriptionLines.join(" "), contentWidth, labelHeight * 6),
          encodeText("\r\n"),
        ]
      : descriptionLines.map((paragraph, index) =>
          encodeText(`TEXT ${contentStart},${37 + index * 26},"${font || 3}",0,1,1,"${paragraph}"\r\n`)
        )),
    ...infoLines.map((paragraph, index) =>
      encodeText(`TEXT ${infoStart},${infoOffset + index * 28},"3",0,1,1,"${paragraph}"\r\n`)
    ),
    ...(icons || []).flatMap((code, index) => {
      const shift = icons
        .slice(0, index + 1)
        .map((code) => code.length)
        .reduce((a, b) => a + b);
      const length = `${code}`.length;
      const x = dotWidth - 105 - (!qrcode || singleQr ? 0 : qrCodeSize * 30) - 24 * shift - index * 10;
      const y = 85 + infoOffset;

      return [
        encodeText(`BOX ${x - 6},${y - 6},${x + 18 * length},${y + 26},3\r\n`),
        encodeText(`TEXT ${x},${y},"3",0,1,1,"${code}"\r\n`),
      ];
    }),
    ...(accessory ? [encodeText(`TEXT ${dotWidth - 160},10,"3",90,1,1,"ACCESSORY"\r\n`)] : []),
    ...(!accessory && qrcode && !singleQr
      ? [encodeText(`QRCODE ${dotWidth - 107 - qrCodeSize * 30},4,${qrEcc || "M"},${qrCodeSize},A,0,"${qrcode}"\r\n`)]
      : []),
    ...(sideRight ? [encodeText(`${sideRight}\r\n`)] : []),
    ...(appendix ? [encodeText(`TEXT ${dotWidth - 160 - appendix.length * 13},120,"3",0,1,1,"${appendix}"\r\n`)] : []),
    encodeText(`PRINT 1,1\r\n`),
    encodeText(`CLS\r\n`),
  ]);
}

export default function (label, settings) {
  if (typeof label.fixedHeight !== "number" || label.fixedHeight <= 10) {
    return getSmallTag(label, settings);
  } else {
    return getLargeTag(label, settings);
  }
}
