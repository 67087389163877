"use client";

import { useEffect, useState } from "react";

function toStatus({ effectiveType, downlink, rtt }) {
  return {
    ...(effectiveType && { effectiveType }),
    ...(downlink && { downlink }),
    ...(typeof rtt === "number" && { rtt }),
  };
}

export default () => {
  const [status, setStatus] = useState();

  useEffect(() => {
    if (navigator.connection) {
      setStatus(toStatus(navigator.connection));
      navigator.connection.onchange = () =>
        setStatus(toStatus(navigator.connection));
    }
  }, []);

  return status;
};
