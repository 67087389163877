import dayjs from "dayjs";

export default function (days, date) {
  if (date && Number.isInteger(days)) {
    if (days === 0) {
      // Blocked
      return true;
    } else if (days === 1) {
      // Same day only
      return dayjs().format("YYYY-MM-DD") !== date;
    } else {
      return date < dayjs().subtract(days, "day").format("YYYY-MM-DD");
    }
  }
}
