import dayjs from "dayjs";
import { PROMOTION_ID_BIRTHDAY, PROMOTION_ID_PUBLIC } from "./constants/index.js";

export default (promotionDoc, businessCode, serviceId, customer, date) => {
  const {
    limitServiceMap,
    limitBusinesses,
    valids,
    expiretime,
    firstTimeOnly,
    wholeMonth,
    ...promoFields
  } = promotionDoc.data() || {};

  if (
    Array.isArray(limitBusinesses) &&
    limitBusinesses.length > 0 &&
    !limitBusinesses.find((code) => code === businessCode)
  ) {
    return null;
  }

  if (firstTimeOnly && !customer?.firstTime) {
    return null;
  }

  if (limitServiceMap && !limitServiceMap[serviceId]) {
    return null;
  }

  if (promotionDoc.id === PROMOTION_ID_BIRTHDAY) {
    if (!customer?.birthday) return null;

    if (wholeMonth) {
      if (dayjs(customer.birthday).format("MM") !== dayjs(date).format("MM")) {
        return null;
      }
    } else if (
      dayjs(customer.birthday).format("MM-DD") !== dayjs(date).format("MM-DD")
    ) {
      return null;
    }
  }

  const limitServiceTypes = limitServiceMap && limitServiceMap[serviceId];

  return {
    ...promoFields,
    id: promotionDoc.id,
    ...(customer?.id &&
      (valids || []).find((id) => id === customer.id) && { member: true }),
    ...((valids || []).find(
      (id) => [date, PROMOTION_ID_PUBLIC].indexOf(id) >= 0
    ) && { campaign: true }),
    ...(Array.isArray(limitServiceTypes) &&
      limitServiceTypes.length > 0 && { limitServiceTypes }),
    ...(Number.isInteger(expiretime) &&
      dayjs.unix(expiretime).isBefore(dayjs()) && {
        disabled: true,
        expired: true,
        expiretime,
      }),
  };
};
