"use client";

import {
  formatPrice,
  LINE_ITEM_TYPE_ADJUSTMENTS,
  LINE_ITEM_TYPE_CREDIT_NOTE,
  LINE_ITEM_TYPE_EXPRESS_CHARGE,
  LINE_ITEM_TYPE_GRAND_TOTAL,
  LINE_ITEM_TYPE_GST,
  LINE_ITEM_TYPE_ITEM,
  LINE_ITEM_TYPE_MINIMUM_CHARGE,
  LINE_ITEM_TYPE_PAYABLE,
  LINE_ITEM_TYPE_PROMOTION,
  LINE_ITEM_TYPE_ROUNDING,
  LINE_ITEM_TYPE_SERVICE_TYPE,
  LINE_ITEM_TYPE_SUBTOTAL,
} from "@easybiz/utils";
import { useMemo } from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";

export default function useOrderPricingList(pricing, params) {
  const { pricingTBD, showDisabledPromo, salesReturn } = params || {};
  const {
    items,
    serviceTypes,
    subTotalAmount,
    grossSales,
    netSales,
    totalWaived,
    promotions,
    adjustments,
    expressAmount,
    gstAmount,
    gstRate,
    minimumSurchargeAmount,
    minimumOrder,
    roundingAmount,
    totalUnit,
    delivery,
    creditNotes,
  } = pricing || {};

  return useMemo(() => {
    const adjusted = promotions?.length > 0 || expressAmount > 0 || adjustments?.length > 0;

    return {
      dataSource: [
        ...(items || []).map((item, itemIndex) => {
          return {
            key: `item-${itemIndex}`,
            type: LINE_ITEM_TYPE_ITEM,
            data: { ...item, itemIndex },
          };
        }),
        ...(serviceTypes || [])
          .map((serviceType) => {
            if (typeof serviceType.minCharge === "number" && serviceType.minCharge > 0) {
              return {
                key: serviceType.id,
                type: LINE_ITEM_TYPE_SERVICE_TYPE,
                data: serviceType,
              };
            }
          })
          .filter((data) => data),
        ...(minimumSurchargeAmount
          ? [
              {
                type: LINE_ITEM_TYPE_MINIMUM_CHARGE,
                title: <FormattedMessage id="difference.to.minimum" defaultMessage="Difference to minimum" />,
                amount: minimumSurchargeAmount,
                data: minimumOrder,
              },
            ]
          : []),
        {
          type: LINE_ITEM_TYPE_SUBTOTAL,
          title:
            salesReturn && !adjusted && !gstAmount ? (
              <FormattedMessage id="total.return.amount" defaultMessage={"Total return amount"} />
            ) : (
              <FormattedMessage id="subtotal" defaultMessage="Subtotal" />
            ),
          amount: subTotalAmount,
          data: totalUnit,
        },
        ...(adjusted
          ? [
              ...(promotions || [])
                .filter((promotion) => showDisabledPromo || promotion.applied)
                .map((promotion) => ({
                  key: promotion.id,
                  type: LINE_ITEM_TYPE_PROMOTION,
                  data: promotion,
                })),
              ...(expressAmount
                ? [
                    {
                      type: LINE_ITEM_TYPE_EXPRESS_CHARGE,
                      amount: expressAmount,
                      data: delivery,
                    },
                  ]
                : []),
              ...(adjustments || []).map((adjustment) => ({
                key: adjustment.id,
                type: LINE_ITEM_TYPE_ADJUSTMENTS,
                data: adjustment,
              })),
              {
                type: LINE_ITEM_TYPE_GRAND_TOTAL,
                title:
                  salesReturn && !gstAmount ? (
                    <FormattedMessage id="total.return.amount" defaultMessage={"Total return amount"} />
                  ) : (
                    <FormattedMessage id="grand.total" defaultMessage="Grand Total" />
                  ),
                amount: grossSales,
              },
            ]
          : []),
        ...(gstAmount
          ? [
              {
                type: LINE_ITEM_TYPE_GST,
                amount: gstAmount,
                title: (
                  <FormattedMessage
                    id="text.include.rate"
                    defaultMessage="Tax ({taxRate}%)"
                    values={{ taxRate: gstRate }}
                  />
                ),
              },
            ]
          : []),
        ...(roundingAmount !== 0
          ? [
              {
                type: LINE_ITEM_TYPE_ROUNDING,
                amount: roundingAmount,
                title: <FormattedMessage id="rounding" defaultMessage={"Rounding"} />,
              },
            ]
          : []),
        ...(creditNotes || []).map(({ v, t, n, o, lineTotal, description }) => {
          if (t) {
            return {
              type: LINE_ITEM_TYPE_CREDIT_NOTE,
              title: `${n}`,
              amount: -v,
              data: (
                <>
                  <FormattedDate value={parseInt(`${t}000`)} month="long" year="numeric" day="2-digit" />{" "}
                  <FormattedTime value={parseInt(`${t}000`)} /> {o}
                </>
              ),
            };
          } else {
            // Backward support
            return {
              title: description,
              amount: lineTotal,
            };
          }
        }),
        {
          type: LINE_ITEM_TYPE_PAYABLE,
          title: salesReturn ? (
            <FormattedMessage id="total.return.amount" defaultMessage={"Total return amount"} />
          ) : pricingTBD ? (
            <FormattedMessage id="estimated.payable.amount" defaultMessage="Estimated Payable Amount" />
          ) : (
            <FormattedMessage id="payable.amount" defaultMessage="Payable Amount" />
          ),
          amount: totalWaived ? formatPrice(netSales - totalWaived) : netSales,
        },
      ],
    };
  }, [
    showDisabledPromo,
    items,
    subTotalAmount,
    grossSales,
    netSales,
    totalWaived,
    promotions,
    adjustments,
    expressAmount,
    gstAmount,
    gstRate,
    minimumSurchargeAmount,
    minimumOrder,
    roundingAmount,
    totalUnit,
    delivery,
    salesReturn,
    pricingTBD,
  ]);
}
