"use client";

import React, { createContext, useContext, useMemo, useRef, useState } from "react";

const UploadContext = createContext();

export function UploadProvider({ children, storageUpload }) {
  const uploadRef = useRef([]);

  const [refresh, setRefresh] = useState();

  const context = useMemo(() => {
    return {
      storageUpload,
      deletePhoto: (index) => {
        if (Number.isInteger(index)) {
          if (Array.isArray(uploadRef.current) && uploadRef.current[index]) {
            uploadRef.current = [...uploadRef.current.slice(0, index), ...uploadRef.current.slice(index + 1)];
            setRefresh(Date.now());
          }
        } else if (typeof index === "string") {
          const realIndex = Array.isArray(uploadRef.current)
            ? uploadRef.current.findIndex((photo) => photo.id === index)
            : -1;

          if (realIndex >= 0) {
            uploadRef.current = [...uploadRef.current.slice(0, realIndex), ...uploadRef.current.slice(realIndex + 1)];
            setRefresh(Date.now());
          }
        } else {
          // Delete all
          uploadRef.current = [];
          setRefresh(Date.now());
        }
      },
      getUploads: ({ groupKey, orderId, itemIndex }) => {
        return (uploadRef.current || []).filter((data) => {
          if (groupKey) {
            return data.groupKey === groupKey;
          } else if (itemIndex) {
            return data.orderId === orderId && `${data.itemIndex}` === `${itemIndex}`;
          } else {
            return data.orderId === orderId;
          }
        });
      },
      asyncUpload: (...uploads) => {
        uploads.forEach(({ uri, ...metadata }) => {
          const photoId = storageUpload(uri, metadata, (photoId) => {
            // Upload completed remove
            const index = uploadRef.current.findIndex((data) => data.id === photoId);
            if (index >= 0) {
              if (metadata.groupKey) {
                delete uploadRef.current[index].uri;
              } else {
                uploadRef.current.splice(index, 1);
              }
              setRefresh(Date.now());
            }
          });

          uploadRef.current.unshift({ ...metadata, uri, id: photoId });
        });

        setRefresh(Date.now());
      },
    };
  }, [refresh, storageUpload]);

  return <UploadContext.Provider value={context}>{children}</UploadContext.Provider>;
}

export const useAsyncUpload = () => useContext(UploadContext).asyncUpload;
export const useAsyncPhotos = (params) => useContext(UploadContext).getUploads(params);
export const useAsyncDeletePhoto = () => useContext(UploadContext).deletePhoto;
export const useDirectUpload = () => useContext(UploadContext).storageUpload;
