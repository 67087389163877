"use client";

import dayjs from "dayjs";
import { createContext, useContext, useMemo } from "react";

const SubscriptionStateContext = createContext();

export function SubscriptionStateProvider({ licenseDoc, realmDoc, children }) {
  const context = useMemo(() => {
    return {
      subscription: realmDoc?.get("subscription"),
      licenceInvalid: licenseDoc?.get("valid") == false,
      ...(licenseDoc?.get("valid") &&
        licenseDoc.get("expired") && {
          licenceDue: dayjs
            .unix(licenseDoc.get("expireUTCTimetamp"))
            .add(licenseDoc.get("warningPeriod") || 0, "day")
            .unix(),
        }),
      ...(licenseDoc?.get("trialing") && {
        trialDue: licenseDoc.get("expireUTCTimetamp"),
      }),
    };
  }, [realmDoc, licenseDoc]);

  return <SubscriptionStateContext.Provider value={context}>{children}</SubscriptionStateContext.Provider>;
}

export const useSubscription = () => useContext(SubscriptionStateContext).subscription;
export const useSubscriptionStatus = () => useContext(SubscriptionStateContext).subscription?.status;
export const useLicenseExpired = () => useContext(SubscriptionStateContext).licenceInvalid;
export const useLicenseDuePeriod = () => useContext(SubscriptionStateContext).licenceDue;
export const useLicenseTrailingEnds = () => useContext(SubscriptionStateContext).trialDue;
