import { BorderOutlined, MinusSquareFilled } from "@ant-design/icons";
import { Space, Typography } from "antd";
import React from "react";

export default ({ passcode, digit = 4, hidden }) => {
  return (
    <div className="flex flex-align-center" style={{ fontSize: 28 }}>
      <Space size={"middle"}>
        {Array.apply(null, Array(digit)).map((nu, index) => {
          const value = typeof passcode === "string" ? passcode[index] : null;
          return value ? (
            hidden ? (
              <MinusSquareFilled style={{ fontSize: 30 }} />
            ) : (
              <div style={{ width: 28, height: 44 }} className="flex flex-center flex-align-center">
                <Typography.Text style={{ fontSize: 30 }}>{value}</Typography.Text>
              </div>
            )
          ) : (
            <BorderOutlined style={{ fontSize: 30 }} />
          );
        })}
      </Space>
    </div>
  );
};
