"use client";

import { useLabelSettings, useNamingContext } from "@easybiz/context";
import { formatLabelContent } from "@easybiz/utils";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

export default (order, limitIndexes) => {
  const context = useNamingContext();
  const labelSettings = useLabelSettings();

  return useMemo(() => {
    const { labels, items, photos } = order?.data() || {};

    return [].concat.apply(
      [],
      (labels || []).map((labelId) => {
        const sortIndex = Array.isArray(labels) ? labels.indexOf(labelId) + 1 : -1;

        if (Array.isArray(limitIndexes) && limitIndexes.indexOf(sortIndex) < 0) {
          return [];
        }

        const item =
          Array.isArray(items) &&
          items.find(({ labelIndexes }) => Array.isArray(labelIndexes) && labelIndexes.indexOf(sortIndex) >= 0);
        const { accessories } = item || {};
        const label = formatLabelContent(order, labelId, context, null, labelSettings);

        return [
          {
            key: labelId,
            labelId,
            label,
            sortIndex,
            note: label.note,
            photos: photos?.filter((photo) => photo.l === sortIndex),
          },
          ...(Array.isArray(accessories)
            ? [].concat.apply(
                [],
                accessories.map((accessory, accesoryIndex) => {
                  return Array.apply(null, Array(accessory.qty)).map((a, qtyIndex) => {
                    return {
                      key: `${labelId}-${accesoryIndex}-${qtyIndex}`,
                      labelId,
                      accessory: <FormattedMessage defaultMessage={"Accessory"} />,
                      label: formatLabelContent(
                        order,
                        labelId,
                        context,
                        { ...accessory, index: qtyIndex + 1 },
                        labelSettings
                      ),
                      sortIndex,
                    };
                  });
                })
              )
            : []),
        ];
      })
    );
  }, [order, context, limitIndexes, labelSettings]);
};
