import { useRealmId } from "@easybiz/context";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (orderId) => {
  const realmId = useRealmId();
  const [historyDoc, setHistoryDoc] = useState();

  useEffect(() => {
    if (realmId && orderId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/order_history/${orderId}`), setHistoryDoc);
    }
  }, [realmId, orderId]);

  return historyDoc;
};
