import { Input, Space } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import ServiceNotesPanel from "../ServiceNotesPanel";

export default ({ commons, freeNote, onChange }) => {
  const intl = useIntl();

  const onNoteChange = ({ freeNote, commons }) => {
    onChange({
      freeNote: freeNote || null,
      commons: Array.isArray(commons) && commons.length > 0 ? commons : null,
      note: [freeNote, ...(commons || [])].filter((data) => data).join("; "),
    });
  };

  return (
    <Space direction="vertical" className="full-width padding-left-half" size={"middle"}>
      <Input.TextArea
        autoFocus
        value={freeNote}
        placeholder={intl.formatMessage({ defaultMessage: "Line item note" })}
        onChange={(e) =>
          onNoteChange({
            freeNote: e.target.value,
            commons,
          })
        }
      />
      <ServiceNotesPanel selects={commons} onChange={(selects) => onNoteChange({ freeNote, commons: selects })} />
    </Space>
  );
};
