import { OPERATION_GROUP_POS, POS_OPERATION_POINTS_REDEEM } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const onSubmit = useCallback(({ count, redeemItemId, customerId }) => {
    return [
      OPERATION_GROUP_POS,
      POS_OPERATION_POINTS_REDEEM,
      {
        count,
        redeemItemId,
        customerId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback);
};
