"use client";

import {
  useClientType,
  useDisabledColor,
  useErrorColor,
  useErrorContainerColor,
  usePOSSettings,
  usePrimaryColor,
  useTertiaryColor,
  useTertiaryContainerColor,
} from "@easybiz/context";
import { CLIENT_POS, formatPrice, isDayLimited } from "@easybiz/utils";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AmountDisplay from "./AmountDisplay";
import DateDisplay from "./DateDisplay";
import PaymentMethodDisplay from "./PaymentMethodDisplay";

export default function useOrderPaymentStatusList(order) {
  const { refundLimitDays } = usePOSSettings();
  const client = useClientType();
  const { netSales, totalWaived, totalPaid, voided, pricingTBD, paymentIntent } = order?.data() || {};
  const overPaidAmount = formatPrice((totalPaid || 0) - Math.max(0, (netSales || 0) - (totalWaived || 0)));
  const primaryColor = usePrimaryColor();
  const errorColor = useErrorColor();
  const infoColor = useTertiaryColor();
  const disabledColor = useDisabledColor();
  const errorBackground = useErrorContainerColor();
  const tertiaryContainer = useTertiaryContainerColor();
  const intl = useIntl();

  return useMemo(() => {
    const { netSales, payments, totalWaived, totalBalance, paid } = order?.data() || {};

    return {
      dataSource: order && [
        {
          title: <FormattedMessage defaultMessage="Payable Amount" />,
          strong: true,
          amount: <AmountDisplay value={formatPrice(netSales - totalWaived)} />,
          ...(pricingTBD && {
            tag: <FormattedMessage defaultMessage={"Pending confirm"} />,
            tagBackground: errorBackground,
          }),
        },
        ...(payments || [])
          .filter((payment) => !payment.pt)
          .map((payment) => {
            const { v, t, o, m, n, points, backDate, deposit } = payment;
            const isRefund = v < 0;
            const refund = payments.find((other) => other.pt === t);
            const refunded = Boolean(refund);
            const limited = isRefund
              ? true
              : client === CLIENT_POS && isDayLimited(refundLimitDays, dayjs.unix(t).format("YYYY-MM-DD"));

            return {
              title: isRefund ? (
                <FormattedMessage
                  defaultMessage={"Refund by {option}"}
                  values={{ option: <PaymentMethodDisplay option={m} /> }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage={"Paid by {option}"}
                  values={{
                    option: (
                      <>
                        <PaymentMethodDisplay option={m} />
                        {Number.isInteger(points)
                          ? ` (${intl.formatMessage({ defaultMessage: "{number} points used" }, { number: points })})`
                          : null}
                      </>
                    ),
                  }}
                />
              ),
              description: (
                <>
                  <DateDisplay timestamp={t} />
                  {` ${o}${n ? ` (${n})` : ""}`}
                </>
              ),
              ...((deposit || backDate) && {
                infos: [
                  ...(deposit ? [<FormattedMessage defaultMessage={"Deposit payment"} />] : []),
                  ...(backDate
                    ? [
                        <FormattedMessage
                          defaultMessage={"Back date payment on {date}"}
                          values={{
                            date: <DateDisplay date={backDate} />,
                          }}
                        />,
                      ]
                    : []),
                ],
              }),
              ...(refunded && {
                alerts: [
                  <>
                    <DateDisplay timestamp={refund.t} /> {refund.o} {refund.n ? `(${refund.n})` : ""}
                  </>,
                ],
              }),
              color: refunded ? disabledColor : isRefund ? null : primaryColor,
              amount: <AmountDisplay value={-v} accounting />,
              ...(refunded
                ? {
                    tag: <FormattedMessage defaultMessage={"Refunded"} />,
                    tagBackground: errorBackground,
                  }
                : isRefund || limited
                ? null
                : {
                    refundablePayment: payment,
                  }),
            };
          }),
        ...(totalBalance > 0 && paymentIntent
          ? [
              {
                title: (
                  <FormattedMessage
                    defaultMessage={"Customer intent pay by {paymentMethod}"}
                    values={{
                      paymentMethod: <PaymentMethodDisplay option={paymentIntent.option} />,
                    }}
                  />
                ),
                tag: <FormattedMessage defaultMessage={"Pending"} />,
                tagBackground: tertiaryContainer,
                paymentIntent,
              },
            ]
          : []),
        overPaidAmount > 0
          ? {
              title: <FormattedMessage defaultMessage={"Amount Overpaid"} />,
              amount: <AmountDisplay value={overPaidAmount} />,
              color: infoColor,
              strong: true,
            }
          : {
              title: <FormattedMessage defaultMessage={"Total Outstanding"} />,
              amount: voided ? (
                <FormattedMessage defaultMessage={"Voided"} />
              ) : paid ? (
                <FormattedMessage defaultMessage={"Paid"} />
              ) : (
                <AmountDisplay value={totalBalance} />
              ),
              strong: true,
              color: voided ? disabledColor : paid ? primaryColor : errorColor,
            },
      ],
    };
  }, [order, client, refundLimitDays]);
}
