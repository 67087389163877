"use client";

import { createContext, useContext, useMemo, useState } from "react";

const PhotoGalleryContext = createContext();

export function PhotoGalleryProvider({ children }) {
  const [gallery, setGallery] = useState();

  const context = useMemo(() => {
    return {
      gallery,
      setGallery,
    };
  }, [gallery]);

  return <PhotoGalleryContext.Provider value={context}>{children}</PhotoGalleryContext.Provider>;
}

export const useGallery = () => useContext(PhotoGalleryContext).gallery;
export const useOpenGallery = () => useContext(PhotoGalleryContext).setGallery;
