import { OPERATION_GROUP_ORDER, ORDER_OPERATION_PAYMENT_METHOD_UPDATE, PAYMENT_ID_STRIPE } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  return useActionCallback(
    ({ paymentIntent, orderId }) => {
      if (!paymentIntent) {
        return intl.formatMessage({ defaultMessage: "Please select payment method" });
      } else if (paymentIntent.id === PAYMENT_ID_STRIPE) {
        return intl.formatMessage({ defaultMessage: "Please enter your card" });
      }

      return [OPERATION_GROUP_ORDER, ORDER_OPERATION_PAYMENT_METHOD_UPDATE, { orderId, paymentIntent }];
    },
    callback,
    intl.formatMessage({ defaultMessage: "Payment method updated" })
  );
};
