import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (realmId, licenceId) => {
  const [licenseDoc, setLicenseDoc] = useState();

  // License doc
  useEffect(() => {
    if (realmId && licenceId) {
      return onSnapshot(
        doc(getFirestore(), `billings/${realmId}/licences/${licenceId}`),
        setLicenseDoc
      );
    }
  }, [realmId, licenceId]);

  return licenseDoc;
};
