import { useBusinessAgentId, useProcessingDepartment } from "@easybiz/context";
import { useProcedureTitle } from "@easybiz/hook";
import { PROCEDURE_PLANT_SERVICING } from "@easybiz/utils";

export default function ({ procedure, showAsTitle, processing }) {
  const agentId = useBusinessAgentId();
  const title = useProcedureTitle(procedure, agentId, processing);
  const processingDepartment = useProcessingDepartment();

  if (showAsTitle && processingDepartment && procedure === PROCEDURE_PLANT_SERVICING) {
    return processingDepartment;
  } else {
    return title;
  }
}
