import { CheckOutlined, CloseOutlined, CrownFilled } from "@ant-design/icons";
import { useMainActionMembershipPanel } from "@easybiz/pos";
import { MemberBenefitDisplay } from "@easybiz/component";
import { translate } from "@easybiz/utils";
import { Alert, Avatar, Card, Col, Row, Space, Tag, Typography } from "antd";

export default function MainActionMembershipPanel({ customerDoc }) {
  const { header, selectAccountAlert, notAMember, members } = useMainActionMembershipPanel(customerDoc);

  return (
    <div className="padding-half self-scroll">
      <Typography.Title level={5}>{header}</Typography.Title>
      {members ? (
        <Row gutter={[8, 8]}>
          {members.map(({ id, title, badgeUrl, backgroundColor, status, expired, benefits }) => {
            return (
              <Col key={id} span={24}>
                <Card>
                  <Card.Meta
                    title={translate(title)}
                    avatar={
                      <Avatar src={badgeUrl} style={{ backgroundColor }} icon={badgeUrl ? null : <CrownFilled />} />
                    }
                    description={
                      <Space direction="vertical">
                        {benefits.map((benefit, index) => (
                          <Typography.Text key={`${index}`} strong type="success">
                            <MemberBenefitDisplay {...benefit} />
                          </Typography.Text>
                        ))}
                        <Tag
                          icon={expired ? <CloseOutlined /> : <CheckOutlined />}
                          color={expired ? "error" : "success"}
                        >
                          {status}
                        </Tag>
                      </Space>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : notAMember ? (
        <Card>
          <Card.Meta description={notAMember} />
        </Card>
      ) : (
        <Alert type="info" message={selectAccountAlert} />
      )}
    </div>
  );
}
