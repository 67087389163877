import { useBusinessCode, useRealmId } from "@easybiz/context";
import { POS_BATCH_CHECK_IN, POS_BATCH_CHECK_OUT } from "@easybiz/utils";
import { collection, getDocs, getFirestore, query, where } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (date, isCheckOut) => {
  const businessCode = useBusinessCode();
  const realmId = useRealmId();
  const [slips, setSlips] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (realmId && date) {
      setLoading(true);
      getDocs(
        query(
          collection(getFirestore(), `realms/${realmId}/handoverslips`),
          where("businessCode", "==", businessCode),
          where("checkStatus", "==", isCheckOut ? POS_BATCH_CHECK_OUT : POS_BATCH_CHECK_IN),
          where("createDate.date", "==", date)
        )
      ).then((result) => {
        setSlips(
          result.docs.sort((a, b) =>
            `${b.get("createDate.date")} ${b.get("createDate.time")}`.localeCompare(
              `${a.get("createDate.date")} ${a.get("createDate.time")}`
            )
          )
        );
        setLoading(false);
      });
    }
  }, [realmId, date, isCheckOut]);

  return [slips, loading];
};
