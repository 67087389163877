import {
  ERROR_CODE_CLOSE_OF_DAY_DATA_CHANGED
} from "@easybiz/utils";
import ErrorRecoverReportChanged from "../ErrorRecoverReportChanged";

export default function ({ error, onCancel }) {
  return (
    <>
      <ErrorRecoverReportChanged
        {...error}
        open={error?.code === ERROR_CODE_CLOSE_OF_DAY_DATA_CHANGED}
        onCancel={onCancel}
      />
    </>
  );
}
