import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (realmId, client, deviceId) => {
  const [deviceDoc, setDeviceDoc] = useState();

  // Device doc
  useEffect(() => {
    if (deviceId && client && realmId) {
      return onSnapshot(
        doc(
          getFirestore(),
          `realms/${realmId}/devices/${client}/installs/${deviceId}`
        ),
        setDeviceDoc
      );
    }
  }, [deviceId, realmId, client]);

  return deviceDoc;
};
