import {
  EVENT_TYPE_JOB_COMPLETED,
  EVENT_TYPE_JOB_FAILED,
  EVENT_TYPE_JOB_READY_FOR_SCHEDULE,
  EVENT_TYPE_JOB_SCHEDULED,
} from "./constants/index.js";

export default (eventType) => {
  return (
    [
      EVENT_TYPE_JOB_READY_FOR_SCHEDULE,
      EVENT_TYPE_JOB_SCHEDULED,
      EVENT_TYPE_JOB_FAILED,
      EVENT_TYPE_JOB_COMPLETED,
    ].indexOf(eventType) >= 0
  );
};
