import { AdminApplication, DeviceRegister, IntercomLauncher } from "@easybiz/component";
import {
  ADMIN_OPERATION_DEVICE_SIGN_OUT,
  CLIENT_POS,
  LANGUAGE_EN,
  OPERATION_GROUP_ADMIN,
  PLATFORM_WEB,
} from "@easybiz/utils";
import {
  ESCEncoder,
  ErrorBoundary,
  WebKeyboardListenerProvider,
  getDeviceInfo,
  useBluetoothIO,
  useOnlineStatus,
  useServiceWorkerUpdate,
  useUsbIO,
} from "@easybiz/web-component";
import {
  useAuthAccount,
  useBusinessDoc,
  useCallableFunction,
  useCustomerScreenDoc,
  useDeviceDoc,
  useFirebaseInstallId,
  useLicenseDoc,
  useOfflinePaymentMethodQuery,
  useRealmDoc,
} from "@easybiz/web-firebase";
import { signOut as firebaseSignOut, getAuth, signInWithCustomToken } from "@firebase/auth";
import { Button, ConfigProvider, message, notification } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import EmailStatusListener from "./EmailStatusListener";
import ErrorReporter from "./ErrorReporter";
import GlobalImageGallery from "./GlobalImageGallery";
import NotificationCenter from "./NotificationCenter";
import PrinterPairingDialog from "./PrinterPairingDialog";
import SystemNoticeDialog from "./SystemNoticeDialog";
import WebErrorRecovery from "./WebErrorRecovery";
import useWebLocale from "./useWebLocale";

const THEME = {
  token: {
    colorSuccessBg: process.env.REACT_APP_SECONDARY_CONTAINER_COLOR,
    colorPrimaryBg: process.env.REACT_APP_SECONDARY_CONTAINER_COLOR,
    colorInfoBg: process.env.REACT_APP_TERTIARY_CONTAINER_COLOR,
    colorWarningBg: "#fff8e1",
    colorWarning: process.env.REACT_APP_WARNING_COLOR,
    colorBgBase: process.env.REACT_APP_BACKGROUND_COLOR,
    colorError: process.env.REACT_APP_ERROR_COLOR,
    colorPrimary: process.env.REACT_APP_PRIMARY_COLOR,
    colorInfo: process.env.REACT_APP_TERTIARY_COLOR,
    colorSuccess: process.env.REACT_APP_PRIMARY_COLOR,
  },
};

export default function ({ children, client, offlineEnabled, CHINESE }) {
  const { locale, antdLocale, messages, onUpdateLocale } = useWebLocale(CHINESE);
  const account = useAuthAccount(client);
  const installationId = useFirebaseInstallId();
  const haveNetwork = useOnlineStatus();
  const onOTAUpdate = useServiceWorkerUpdate();
  const realmDoc = useRealmDoc(account?.realmId);
  const customerScreenDoc = useCustomerScreenDoc(
    client === CLIENT_POS ? account?.realmId : null,
    account?.connectDeviceId || installationId
  );
  const businessDoc = useBusinessDoc(account?.realmId, account?.businessCode);
  const deviceDoc = useDeviceDoc(account?.realmId, client, installationId);
  const offlinePaymentMethods = useOfflinePaymentMethodQuery(account?.realmId, client);
  const licenseDoc = useLicenseDoc(account?.realmId, account?.businessCode || account?.licenceId);
  const usbIO = useUsbIO();
  const bluetoothIO = useBluetoothIO();
  const [error, setError] = useState();
  const [notice, setNotice] = useState();
  const [pairingParams, setPairingParams] = useState();

  const onCloseError = useCallback(() => setError(null));

  const onSignOut = useCallback(
    async (title, description, button) => {
      return new Promise((resolve) => {
        if (haveNetwork) {
          httpsFunction({
            group: OPERATION_GROUP_ADMIN,
            type: ADMIN_OPERATION_DEVICE_SIGN_OUT,
            client,
          })
            .then(resolve)
            .catch(resolve);
        } else {
          resolve();
        }
      }).then(() => {
        firebaseSignOut(getAuth());

        if (typeof title === "string") {
          notification.warning({
            key: "expired",
            duration: null,
            message: title,
            description: typeof description === "string" ? description : null,
            ...(button && {
              btn: (
                <Button size="large" href={button.href} target="_blank">
                  {button.title}
                </Button>
              ),
            }),
          });
        }
      });
    },
    [haveNetwork, account?.businessCode, client]
  );

  const toast = useMemo(() => {
    return {
      error: (content, params) => {
        if (content) {
          message.error(content);
        }

        if (params) {
          setError({ ...params, message: content });
        }
      },
      info: (content) => message.info(content),
      success: (content) => message.success(content),
    };
  }, []);

  const httpsFunction = useCallableFunction();

  const onRegistered = useCallback(({ signInToken, dueSubscription, notice, intercomUser }) => {
    if (signInToken) {
      signInWithCustomToken(getAuth(), signInToken);
    }

    if (dueSubscription) {
      // TODO: Router
    } else if (notice) {
      setNotice(notice);
    }

    if (intercomUser && window.Intercom) {
      window.Intercom("boot", {
        ...intercomUser,
        custom_launcher_selector: `#intercomLauncher`,
        hide_default_launcher: true,
      });
    }
  }, []);

  return (
    <ConfigProvider locale={antdLocale} theme={THEME} componentSize={client === CLIENT_POS ? "large" : undefined}>
      <IntlProvider messages={messages} locale={locale || LANGUAGE_EN} defaultLocale={LANGUAGE_EN}>
        <ErrorBoundary
          reloadButton={
            <Button onClick={() => window.location.reload(true)}>
              <FormattedMessage defaultMessage="Reload App" />
            </Button>
          }
          onReport={(error) => (
            <ErrorReporter
              error={error}
              httpsFunction={httpsFunction}
              deviceId={installationId}
              realmId={account?.realmId}
              uid={account?.uid}
              client={client}
            />
          )}
        >
          <WebKeyboardListenerProvider>
            <AdminApplication
              platform={PLATFORM_WEB}
              account={account}
              client={client}
              installationId={installationId}
              haveNetwork={haveNetwork}
              realmDoc={realmDoc}
              businessDoc={businessDoc}
              customerScreenDoc={customerScreenDoc}
              deviceDoc={deviceDoc}
              licenseDoc={licenseDoc}
              offlinePaymentMethods={offlinePaymentMethods}
              locale={locale}
              onUpdateLocale={onUpdateLocale}
              offlineEnabled={offlineEnabled}
              toast={toast}
              ESCEncoder={ESCEncoder}
              onSignOut={onSignOut}
              onOTAUpdate={onOTAUpdate}
              usbIO={usbIO}
              bluetoothIO={bluetoothIO}
              connectPrinter={setPairingParams}
              httpsFunction={httpsFunction}
              LocalStorage={localStorage}
            >
              {children}
              <DeviceRegister
                accountDoc={businessDoc}
                multiStation={businessDoc?.get("multiStation")}
                getDeviceInfo={getDeviceInfo}
                onSignOut={onSignOut}
                onRegistered={onRegistered}
              />
              <IntercomLauncher
                onLaunched={(intercom) =>
                  window.Intercom("boot", {
                    ...intercom,
                    custom_launcher_selector: `#intercomLauncher`,
                    hide_default_launcher: true,
                  })
                }
              />
              <EmailStatusListener />
              <GlobalImageGallery />
              <NotificationCenter />
              <WebErrorRecovery error={error} onCancel={onCloseError} />
              <PrinterPairingDialog
                {...pairingParams}
                open={Boolean(pairingParams)}
                onCancel={() => setPairingParams(null)}
              />
              <SystemNoticeDialog notice={notice} open={Boolean(notice)} onCancel={() => setNotice(null)} />
            </AdminApplication>
          </WebKeyboardListenerProvider>
        </ErrorBoundary>
      </IntlProvider>
    </ConfigProvider>
  );
}
