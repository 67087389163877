import { AmountDisplay, useOrderPricingList } from "@easybiz/component";
import {
  LINE_ITEM_TYPE_ADJUSTMENTS,
  LINE_ITEM_TYPE_CREDIT_NOTE,
  LINE_ITEM_TYPE_EXPRESS_CHARGE,
  LINE_ITEM_TYPE_GRAND_TOTAL,
  LINE_ITEM_TYPE_GST,
  LINE_ITEM_TYPE_ITEM,
  LINE_ITEM_TYPE_MINIMUM_CHARGE,
  LINE_ITEM_TYPE_PAYABLE,
  LINE_ITEM_TYPE_PROMOTION,
  LINE_ITEM_TYPE_ROUNDING,
  LINE_ITEM_TYPE_SUBTOTAL,
} from "@easybiz/utils";
import { List, Typography } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PricingOverviewItemBase from "../PricingOverviewItemBase";
import PricingOverviewTypeAdjustment from "../PricingOverviewTypeAdjustment";
import PricingOverviewTypeExpress from "../PricingOverviewTypeExpress";
import PricingOverviewTypeItem from "../PricingOverviewTypeItem";
import PricingOverviewTypePromotion from "../PricingOverviewTypePromotion";

export default function ({
  header,
  pricing,
  onSelect,
  loading,
  editCartIndex,
  showDisabledPromo,
  editOrder,
  onTogglePromotion,
  onAdjustmentChange,
  onScheduleChange,
  onRemoveItem,
  disabled,
}) {
  const intl = useIntl();
  const { dataSource } = useOrderPricingList(pricing, { showDisabledPromo });

  return (
    <List
      size="small"
      rowKey={(data) => data.key || data.type}
      header={header}
      dataSource={dataSource}
      loading={loading}
      renderItem={({ type, data, title, fontSize, amount, extra }) => {
        switch (type) {
          case LINE_ITEM_TYPE_ITEM:
            return (
              <PricingOverviewTypeItem
                item={data}
                editOrder={editOrder}
                onSelect={onSelect}
                selected={editCartIndex === data.itemIndex}
                onRemoveItem={onRemoveItem}
                disabled={disabled}
              />
            );
          case LINE_ITEM_TYPE_SUBTOTAL:
            return (
              <PricingOverviewItemBase
                title={title || <FormattedMessage defaultMessage="Subtotal" />}
                fontSize={fontSize}
                amount={amount}
                qty={`${data}`}
                unit={intl.formatMessage({ defaultMessage: "units" })}
                extra={extra}
                strong
              />
            );
          case LINE_ITEM_TYPE_EXPRESS_CHARGE:
            return (
              <PricingOverviewTypeExpress
                delivery={data}
                amount={amount}
                onChange={onScheduleChange}
                disabled={disabled}
              />
            );
          case LINE_ITEM_TYPE_MINIMUM_CHARGE:
            return (
              <PricingOverviewItemBase
                title={<FormattedMessage defaultMessage="Difference to minimum" />}
                amount={amount}
              >
                {typeof data === "number" && (
                  <Typography.Text type="warning" strong>
                    <FormattedMessage
                      defaultMessage={"Minimum order {amount}"}
                      values={{
                        amount: <AmountDisplay value={data} />,
                      }}
                    />
                  </Typography.Text>
                )}
              </PricingOverviewItemBase>
            );
          case LINE_ITEM_TYPE_PROMOTION:
            return <PricingOverviewTypePromotion promotion={data} onToggle={onTogglePromotion} disabled={disabled} />;
          case LINE_ITEM_TYPE_ADJUSTMENTS:
            return (
              <PricingOverviewTypeAdjustment adjustment={data} onChange={onAdjustmentChange} disabled={disabled} />
            );
          case LINE_ITEM_TYPE_GRAND_TOTAL:
            return (
              <PricingOverviewItemBase
                title={title || <FormattedMessage defaultMessage="Grand Total" />}
                fontSize={fontSize}
                extra={extra}
                amount={amount}
                strong
              />
            );
          case LINE_ITEM_TYPE_GST:
            return <PricingOverviewItemBase title={title} amount={amount} strong />;
          case LINE_ITEM_TYPE_PAYABLE:
            return (
              <PricingOverviewItemBase
                title={title || <FormattedMessage defaultMessage="Payable Amount" />}
                fontSize={fontSize}
                extra={extra}
                amount={amount}
                strong
              />
            );
          case LINE_ITEM_TYPE_ROUNDING:
            return <PricingOverviewItemBase title={<FormattedMessage defaultMessage="Rounding" />} amount={amount} />;
          case LINE_ITEM_TYPE_CREDIT_NOTE:
            return (
              <PricingOverviewItemBase title={title} amount={amount}>
                {data}
              </PricingOverviewItemBase>
            );
          default:
            return null;
        }
      }}
    />
  );
}
