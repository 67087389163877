export const UNIT_PCS = "pcs";
export const UNIT_PAIR = "pair";
export const UNIT_SET = "set";
export const UNIT_BUNDLE = "bundle";
export const UNIT_KG = "kg";
export const UNIT_SQFT = "sqft";
export const UNIT_FT = "ft";
export const UNIT_INCH = "inch";
export const UNIT_BAG = "bag";
export const UNIT_HOUR = "hour";

export const CALCULATOR_RECTANGLE = "rectangle";
export const CALCULATOR_ROUND = "round";