import { BugOutlined, LoadingOutlined } from "@ant-design/icons";
import { useErrorReport } from "@easybiz/hook";
import { Alert } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ httpsFunction, realmId, deviceId, error, client }) => {
  const report = useErrorReport({ httpsFunction, realmId, deviceId, error, client });

  return (
    <div className="padding">
      <Alert
        icon={report ? <BugOutlined /> : <LoadingOutlined />}
        type="info"
        showIcon
        message={report ? report.title : <FormattedMessage defaultMessage={"Error reporting..."} />}
        description={report?.description}
      />
    </div>
  );
};
