import { AmountDisplay, UnitDisplay } from "@easybiz/component";
import { PROMO_TYPE_PERCENT, UNIT_PCS, translate } from "@easybiz/utils";
import { FormattedDate, FormattedMessage } from "react-intl";

export default (item, noPrice, includeUnitPrice) => {
  const {
    option,
    width,
    height,
    radius,
    addons,
    subOptions,
    selectSteps,
    accessories,
    date,
    unit,
    pcs,
    note,
    unitPrice,
    qty,
  } = item;
  // Prefill notes

  const stepOptionNotes = Array.isArray(selectSteps)
    ? [].concat.apply(
        [],
        selectSteps.map(({ value }) => {
          if (Array.isArray(value)) {
            return value.map(({ note }) => note).filter((note) => note);
          } else {
            return [];
          }
        })
      )
    : [];

  return [
    ...(includeUnitPrice
      ? [
          {
            title: (
              <FormattedMessage
                defaultMessage={"{unitPrice} x {qty} {unit}"}
                values={{
                  unitPrice: <AmountDisplay value={unitPrice} />,
                  qty,
                  unit: <UnitDisplay unit={unit} />,
                }}
              />
            ),
            strong: true,
          },
        ]
      : []),
    ...(date
      ? [
          {
            title: (
              <FormattedMessage
                defaultMessage={"Estimated complete by {date}"}
                values={{
                  date: <FormattedDate value={date} month="short" year="numeric" day="2-digit" weekday="short" />,
                }}
              />
            ),
            isDate: true,
          },
        ]
      : []),
    ...(translate(option)
      ? [
          {
            title: translate(option),
          },
        ]
      : []),
    ...(radius
      ? [
          {
            title: <FormattedMessage defaultMessage={"Size(round): radius = {radius}"} values={{ radius }} />,
          },
        ]
      : []),
    ...(width && height
      ? [
          {
            title: (
              <FormattedMessage
                defaultMessage={"Size(square): width = {width}, height = {height}"}
                values={{ width, height }}
              />
            ),
          },
        ]
      : []),
    ...(unit !== UNIT_PCS && typeof pcs === "number" && pcs > 1
      ? [
          {
            title: <FormattedMessage defaultMessage="{count} pcs" values={{ count: pcs }} />,
          },
        ]
      : []),
    ...(addons || []).map(({ title, surcharge }) => ({
      title: <FormattedMessage defaultMessage={"Add-on: {name}"} values={{ name: translate(title) }} />,
      ...(surcharge && { amount: surcharge }),
    })),
    ...(Array.isArray(subOptions)
      ? [].concat.apply(
          [],
          subOptions.map(({ title, code, price, discount, discountAmount, note }) => {
            return [
              {
                title: translate(title) || code,
                isSubOption: true,
                ...(!noPrice && {
                  amount: price,
                }),
              },
              ...(!noPrice && typeof discountAmount === "number" && discountAmount > 0
                ? [
                    {
                      title:
                        discount?.type === PROMO_TYPE_PERCENT ? (
                          <FormattedMessage
                            defaultMessage={"{percentage}% discount"}
                            values={{ percentage: discount.value }}
                          />
                        ) : (
                          <FormattedMessage defaultMessage={"Discount"} />
                        ),
                      amount: -discountAmount,
                      isMinus: true,
                    },
                  ]
                : []),
              ...(note
                ? [
                    {
                      isNote: true,
                      strong: true,
                      title: note,
                    },
                  ]
                : []),
            ];
          })
        )
      : []),
    ...[].concat.apply(
      [],
      (selectSteps || []).map(({ title, code, value, note }) => {
        let description;

        if (typeof value === "string") {
          description = value;
        } else if (value instanceof Array) {
          description = value.map(({ title, code }) => `${translate(title) || code}`).join(", ");
        }

        return [
          {
            isStep: true,
            ...(!description && { red: true }),
            title: `${translate(title) || code}: ${description || ""}`,
          },
          ...(note
            ? note.split("\n").map((line) => ({
                strong: true,
                title: `* ${line}`,
                isNote: true,
              }))
            : []),
        ];
      })
    ),
    ...(Array.isArray(accessories) && accessories.length > 0
      ? accessories.map(({ title, qty }) => ({
          isAccessory: true,
          title: (
            <FormattedMessage defaultMessage={"Accessory: {name}"} values={{ name: `${translate(title)} x ${qty}` }} />
          ),
        }))
      : []),
    ...stepOptionNotes.map((note) => {
      return {
        strong: true,
        title: `** ${note}`,
      };
    }),
    ...(note
      ? [
          {
            strong: true,
            title: `* ${note}`,
          },
        ]
      : []),
  ];
};
