import { LoadingOutlined, MailOutlined, SendOutlined } from "@ant-design/icons";
import { useRealmId, useSetSendingEmailId } from "@easybiz/context";
import { useAdminEventTemplateSend } from "@easybiz/request";
import { EVENT_TYPE_ORDER_BILL_RECEIPT } from "@easybiz/utils";
import { doc, getDoc, getFirestore } from "@firebase/firestore";
import { Button, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";

export default ({ orderId, customer, disabled }) => {
  const realmId = useRealmId();
  const [open, setVisible] = useState(false);
  const [email, setEmail] = useState();
  const [laoding, setLoading] = useState();
  const setEmailId = useSetSendingEmailId();
  const intl = useIntl();

  const { onSubmit, loading } = useAdminEventTemplateSend((success, response) => {
    if (success) {
      setEmailId(response.emailId);
      setVisible(false);
    }
  }, true);

  useEffect(() => {
    if (open) {
      setEmailId(null);
    }
  }, [open]);

  useEffect(() => {
    if (customer && open) {
      setLoading(true);
      getDoc(doc(getFirestore(), `realms/${realmId}/customers/${customer.id}`))
        .then((doc) => {
          setEmail(doc.get("email"));
        })
        .finally(() => setLoading(false));
    }
  }, [open]);

  return (
    <>
      <Button disabled={!customer || disabled} icon={<MailOutlined />} type='text' onClick={() => setVisible(true)}>
        <FormattedMessage tagName="span" defaultMessage="Email" />
      </Button>
      <Dialog
        title={<FormattedMessage tagName="span" defaultMessage="Send Email Bill / Receipt" />}
        okType={"primary"}
        value={email}
        open={open}
        confirmLoading={loading}
        onCancel={() => setVisible(false)}
        okIcon={<SendOutlined />}
        onOk={() =>
          onSubmit({
            eventType: EVENT_TYPE_ORDER_BILL_RECEIPT,
            email: EVENT_TYPE_ORDER_BILL_RECEIPT,
            orderId,
            sendTo: email,
          })
        }
        okText={<FormattedMessage tagName="span" defaultMessage="Send" />}
      >
        <Space direction="vertical" className="full-width">
          <Input
            prefix={
              <b>
                <FormattedMessage tagName="span" defaultMessage="Send to" description="Email recipient" />:{" "}
              </b>
            }
            placeholder={intl.formatMessage({
              defaultMessage: "Recipient email address",
            })}
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            suffix={laoding && <LoadingOutlined />}
          />
        </Space>
      </Dialog>
    </>
  );
};
