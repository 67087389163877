import {
  PRINTER_TYPE_LABEL,
  PRINTER_TYPE_RECEIPT,
  PRINTER_TYPE_STICKER,
} from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ printerType }) {
  switch (printerType) {
    case PRINTER_TYPE_LABEL:
      return <FormattedMessage defaultMessage="Label printer" />;
    case PRINTER_TYPE_STICKER:
      return <FormattedMessage defaultMessage="Pack sticker printer" />;
    case PRINTER_TYPE_RECEIPT:
      return <FormattedMessage defaultMessage="Receipt printer" />;
    default:
      return null;
  }
}
