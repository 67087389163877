import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ name }) {
  switch (name) {
    case "Customer receipt":
      return <FormattedMessage defaultMessage={"Customer receipt"} />;
    case "Internal receipt":
      return <FormattedMessage defaultMessage={"Internal receipt"} />;
    default:
      return name || <FormattedMessage defaultMessage={"Receipt"} />;
  }
}
