import { OPERATION_GROUP_POS, POS_OPERATION_CASH_DRAWER_START } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ amount }) => {
    if (typeof amount !== "number") {
      return intl.formatMessage({ defaultMessage: "Starting amount must be a number" });
    }

    return [
      OPERATION_GROUP_POS,
      POS_OPERATION_CASH_DRAWER_START,
      {
        amount,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Cash drawer started" }));
};
