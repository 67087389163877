import { FormattedMessage } from "react-intl";

export default function ({ location }) {
  const { latitude, longitude, distance } = location;

  return (
    <FormattedMessage
      defaultMessage={
        "[Multi-station] Your device location {latitude},{longitude} is {distance} kilometers away from your store address, your POS will be sign out soon."
      }
      values={{
        latitude: <b>{latitude}</b>,
        longitude: <b>{longitude}</b>,
        distance: <b>{distance}</b>,
      }}
    />
  );
}
