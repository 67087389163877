import { Title } from "@easybiz/web-admin";
import { AmountDisplay } from "@easybiz/component";
import { usePOSCashDrawerCashInOut } from "@easybiz/request";
import { formatPrice } from "@easybiz/utils";
import { Alert, Button, Col, Input, List, Row } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function ({ closeOfDay, cashDrawerBalance, nextDayDeposit, isCashIn, amount, onSucceed, onCancel }) {
  const intl = useIntl();
  const [note, setNote] = useState(closeOfDay ? "Daily cash out" : "");
  const { onSubmit, loading } = usePOSCashDrawerCashInOut((succeed) => {
    if (succeed) {
      onSucceed && onSucceed();
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List
          header={
            closeOfDay ? (
              <Alert
                banner
                type="info"
                message={
                  <FormattedMessage
                    defaultMessage={"Cash out amount auto calculated based on next day deposit of {amount}"}
                    values={{
                      amount: <AmountDisplay value={nextDayDeposit || 0} />,
                    }}
                  />
                }
              />
            ) : null
          }
          dataSource={[
            {
              title: isCashIn ? (
                <FormattedMessage defaultMessage={"Before Cash In"} />
              ) : (
                <FormattedMessage defaultMessage={"Before Cash Out"} />
              ),
              amount: cashDrawerBalance,
            },
            {
              title: isCashIn ? (
                <FormattedMessage defaultMessage={"Cash in amount"} />
              ) : (
                <FormattedMessage defaultMessage={"Cash out amount"} />
              ),
              amount,
              type: isCashIn ? "success" : "danger",
            },
            {
              title: isCashIn ? (
                <FormattedMessage defaultMessage={"After Cash In"} />
              ) : (
                <FormattedMessage defaultMessage={"After Cash Out"} />
              ),
              amount: formatPrice((cashDrawerBalance || 0) + (isCashIn ? amount : -amount), true),
            },
          ]}
          renderItem={({ title, amount, type }) => {
            const negitive = type === "danger";

            return (
              <List.Item
                actions={[
                  <Title key={type} type={type}>
                    <AmountDisplay value={negitive ? -amount : amount} accounting />
                  </Title>,
                ]}
              >
                <List.Item.Meta title={title} />
              </List.Item>
            );
          }}
        />
      </Col>
      <Col span={24}>
        <Input.TextArea
          size="large"
          disabled={loading}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder={intl.formatMessage({ defaultMessage: "Note" })}
        />
      </Col>
      <Col span={8}>
        <Button block size="large" onClick={onCancel} disabled={loading}>
          <FormattedMessage defaultMessage={"Edit"} />
        </Button>
      </Col>
      <Col span={16}>
        <Button
          block
          loading={loading}
          type="primary"
          onClick={() => {
            onSubmit({
              amount: isCashIn ? amount : -amount,
              note,
            });
          }}
        >
          {isCashIn ? (
            <FormattedMessage defaultMessage={"Confirm cash in"} />
          ) : (
            <FormattedMessage defaultMessage={"Confirm cash out"} />
          )}
        </Button>
      </Col>
    </Row>
  );
}
