import { CheckSquareOutlined, DollarOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Popover, Space } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import SalesReturnDialog from "../SalesReturnDialog";
import Title from "../Title";

export default function SalesReturnButton({ order, disabled }) {
  const [open, setVisible] = useState(false);
  const [selectItems, setSelectItems] = useState();

  return (
    <>
      <Popover
        placement="top"
        trigger={"click"}
        title={
          <Title>
            <FormattedMessage defaultMessage={"Sales return by..."} />
          </Title>
        }
        content={
          <Space direction="vertical" size={"middle"}>
            <Button
              block
              icon={<DollarOutlined />}
              onClick={() => {
                setVisible(false);
                setSelectItems(false);
              }}
            >
              <FormattedMessage tagName={"span"} defaultMessage={"By enter return amount"} />
            </Button>
            <Button
              block
              icon={<CheckSquareOutlined />}
              onClick={() => {
                setVisible(false);
                setSelectItems(true);
              }}
            >
              <FormattedMessage tagName={"span"} defaultMessage={"By select return items"} />
            </Button>
          </Space>
        }
        open={open}
        onOpenChange={disabled ? null : setVisible}
      >
        <Button block danger icon={<RollbackOutlined />} disabled={disabled}>
          <FormattedMessage tagName="span" defaultMessage="Sales return" />
        </Button>
      </Popover>
      <SalesReturnDialog
        order={order}
        open={typeof selectItems === "boolean"}
        selectItems={selectItems}
        onCancel={() => setSelectItems(null)}
      />
    </>
  );
}
