import { Input } from "antd";
import React from "react";
import { useIntl } from "react-intl";

export default function ({ payment, onChange, disabled, onFocusChange }) {
  const intl = useIntl();

  return (
    <Input
      disabled={disabled}
      placeholder={intl.formatMessage({ defaultMessage: "Payment reference" })}
      value={payment?.note}
      onChange={(e) => onChange({ ...payment, note: e.target.value })}
      status={payment?.noteRequired && !payment.note ? "error" : null}
      onBlur={onFocusChange ? () => onFocusChange(false) : null}
      onFocus={onFocusChange ? () => onFocusChange(true) : null}
    />
  );
}
