import { useCustomerTopUpOverview } from "@easybiz/component";
import { List, Space } from "antd";
import React, { useEffect } from "react";
import Title from "../Title";

export default ({ customer, wallet, topUpOption, onBalanceChange, extra }) => {
  const dataSource = useCustomerTopUpOverview(customer, wallet, topUpOption);

  useEffect(() => {
    if (wallet && onBalanceChange) {
      onBalanceChange(wallet.get("credit") ?? 0);
    }
  }, [wallet, onBalanceChange]);

  return (
    <List
      size="small"
      loading={!wallet}
      dataSource={dataSource}
      renderItem={({ title, description, content, newBalance, topUpAmount, giftAmount }) => {
        return (
          <List.Item
            extra={
              <Space>
                {newBalance && extra}
                <Title type={topUpAmount || giftAmount ? "success" : ""}>
                  {content}
                </Title>
              </Space>
            }
          >
            <List.Item.Meta title={title} description={description} />
          </List.Item>
        );
      }}
    />
  );
};
