import { useCheckoutEditOrder } from "@easybiz/checkout";
import { NameDisplay } from "@easybiz/component";
import {
  useBusinessCode,
  useCheckInStaff,
  useDisplayMode,
  useIsPendingStartDrawer,
  useSetDisplayMode,
} from "@easybiz/context";
import {
  DISPLAY_MODE_CASH_DRAWER,
  DISPLAY_MODE_COLLECTION,
  DISPLAY_MODE_PRODUCTION,
  DISPLAY_MODE_REDO,
  DISPLAY_MODE_SALES,
} from "@easybiz/utils";
import { ProductionCalendar, QuickSwitchCalendar, Title } from "@easybiz/web-admin";
import { Tabs } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import CashDrawer from "./CashDrawer";
import CollectionMonitorTag from "./CollectionMonitorTag";
import DailySalesTag from "./DailySalesTag";
import EditOrderPanel from "./EditOrderPanel";
import MainActionTabs from "./MainActionTabs";
import POSOrderDetailPanel from "./POSOrderDetailPanel";
import RedoOrderOverviewPanel from "./RedoOrderOverviewPanel";

export default function POSHomeRightPanel() {
  const editOrder = useCheckoutEditOrder();
  const pendingStartDrawer = useIsPendingStartDrawer();
  const checkInStaff = useCheckInStaff();
  const businessCode = useBusinessCode();
  const displayMode = useDisplayMode();
  const setDisplayMode = useSetDisplayMode();

  useEffect(() => {
    if (pendingStartDrawer && checkInStaff && displayMode?.key !== DISPLAY_MODE_CASH_DRAWER) {
      setDisplayMode({ key: DISPLAY_MODE_CASH_DRAWER });
    }
  }, [pendingStartDrawer, Boolean(checkInStaff)]);

  switch (displayMode?.key) {
    case DISPLAY_MODE_SALES:
      return (
        <Tabs
          activeKey={displayMode?.orderId || "calendar"}
          items={[
            {
              key: "calendar",
              children: (
                <div className="flex flex-fill self-scroll">
                  <QuickSwitchCalendar
                    value={displayMode.date}
                    onSelect={(date) => setDisplayMode({ ...displayMode, date })}
                    dateCellRender={(selectDate) => {
                      if (selectDate && selectDate.format("YYYY-MM-DD") <= dayjs().format("YYYY-MM-DD")) {
                        return <DailySalesTag businessCode={businessCode} date={selectDate.format("YYYY-MM-DD")} />;
                      }
                    }}
                  />
                </div>
              ),
            },
            ...(displayMode?.orderId
              ? [
                  {
                    key: displayMode.orderId,
                    children: (
                      <POSOrderDetailPanel
                        orderId={displayMode.orderId}
                        onClose={() => setDisplayMode({ ...displayMode, orderId: null })}
                      />
                    ),
                  },
                ]
              : []),
          ]}
          renderTabBar={() => null}
        />
      );
    case DISPLAY_MODE_CASH_DRAWER:
      return <CashDrawer onClose={() => setDisplayMode(null)} />;
    case DISPLAY_MODE_COLLECTION:
      return (
        <QuickSwitchCalendar
          value={displayMode.date}
          onSelect={(date) => setDisplayMode({ ...displayMode, date })}
          dateCellRender={(selectDate, sameMonth) => {
            if (selectDate && sameMonth) {
              return <CollectionMonitorTag date={selectDate.format("YYYY-MM-DD")} />;
            }
          }}
        />
      );
    case DISPLAY_MODE_PRODUCTION:
      return (
        <ProductionCalendar
          title={
            <Title type={"success"}>
              <NameDisplay nameKey={businessCode} />
            </Title>
          }
          supplierId={displayMode.supplierId}
          value={displayMode.date}
          onSelect={(date) => setDisplayMode({ ...displayMode, date })}
        />
      );
    case DISPLAY_MODE_REDO:
      return <RedoOrderOverviewPanel displayMode={displayMode} setDisplayMode={setDisplayMode} />;
    default:
      if (editOrder) {
        return <EditOrderPanel />;
      } else {
        return <MainActionTabs displayMode={displayMode} setDisplayMode={setDisplayMode} />;
      }
  }
}
