import { OrderNoDisplay } from "@easybiz/component";
import { useOrderDoc } from "@easybiz/web-firebase";
import { Drawer } from "antd";
import OrderDetailTabPanel from "../OrderDetailTabPanel";
import OrderEditCustomerButton from "../OrderEditCustomerButton";

// const OrderAdminActionsButton = lazy(() => import("../OrderAdminActionsButton"));

export default function ({ cashDrawer, selectTab, placement = "right", orderId, open, onClose }) {
  const order = useOrderDoc(orderId);

  return (
    <Drawer
      title={order && <OrderNoDisplay {...order.data()} />}
      extra={order && <OrderEditCustomerButton order={order} />}
      placement={placement}
      width={470}
      closable={true}
      destroyOnClose
      onClose={onClose}
      open={open}
      bodyStyle={{ padding: 16 }}
    >
      <OrderDetailTabPanel
        cashDrawer={cashDrawer}
        orderId={orderId}
        order={order}
        initTab={selectTab}
        placement={placement}
      />
      <br />
      <br />
      <br />
    </Drawer>
  );
}
