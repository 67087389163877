import { OPERATION_GROUP_ORDER, ORDER_OPERATION_NOTE } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ note, selects, sortIndex, allItems, orderId }) => {
    return [
      OPERATION_GROUP_ORDER,
      ORDER_OPERATION_NOTE,
      {
        note: Array.isArray(selects) ? [note, ...selects].filter((data) => data).join("; ") : note || "",
        ...(Number.isInteger(sortIndex) && { sortIndex }),
        ...(allItems && { allItems }),
        orderId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Note updated" }));
};
