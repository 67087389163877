import { useInstallationId } from "@easybiz/context";
import { usePOSDeviceRegister } from "@easybiz/request";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

export default (MobileAppLink, getDeviceInfo) => {
  const installId = useInstallationId();
  const { onSubmit, loading, response } = usePOSDeviceRegister();

  useEffect(() => {
    if (installId && onSubmit) {
      onSubmit({ ...getDeviceInfo(), installId });
    }
  }, [installId, onSubmit]);

  useEffect(() => {
    if (installId && onSubmit && Number.isInteger(response?.expireTime)) {
      const handler = setInterval(() => {
        if (response?.expireTime <= dayjs().unix()) {
          onSubmit({ ...getDeviceInfo(), installId });
        }
      }, 60000);

      return () => clearInterval(handler);
    }
  }, [response?.expireTime, installId, onSubmit]);

  return {
    title: <FormattedMessage defaultMessage={"Log in with a code"} />,
    subTitle: (
      <FormattedMessage
        defaultMessage={
          "Use this code in the console store page, or using the sign in POS function with your <a>EasyBiz Cloud App</a>"
        }
        values={{
          a: (t) => <MobileAppLink>{t}</MobileAppLink>,
        }}
      />
    ),
    qrTitle: <FormattedMessage defaultMessage={"QR Code"} />,
    qrSubTitle: (
      <FormattedMessage
        defaultMessage={"Scan this QR code using your <a>EasyBiz Cloud App</a>"}
        values={{
          a: (t) => <MobileAppLink>{t}</MobileAppLink>,
        }}
      />
    ),
    loading: loading,
    otp: response?.otp,
    displayOtp: response?.displayCode,
    onRetry: () => onSubmit({ ...getDeviceInfo(), installId }),
  };
};
