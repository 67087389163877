export const EVENT_TYPE_ORDER_CREATED = "order_created";
export const EVENT_TYPE_ORDER_RESCHEDULED = "order_rescheduled";
export const EVENT_TYPE_ORDER_SALES_RETURN = "order_sales_return";
export const EVENT_TYPE_ORDER_CASE_REPORT = "order_case_report";
export const EVENT_TYPE_ORDER_BILL_RECEIPT = "order_bill_ready";
export const EVENT_TYPE_ORDER_COLLECTED = "order_collected";
export const EVENT_TYPE_ORDER_READY_FOR_COLLECTION = "order_ready_for_collection";
export const EVENT_TYPE_ORDER_COMPLETED = "order_completed";
export const EVENT_TYPE_ORDER_CANCELLED = "order_cancelled";

export const EVENT_TYPE_CUSTOMER_REGISTER = "customer_register";
export const EVENT_TYPE_CREDIT_TOP_UP = "credit_top_up";
export const EVENT_TYPE_PAYMENT_FAILED = "payment_failed";
export const EVENT_TYPE_PAYMENT_SUCCEED = "payment_succeed";

export const EVENT_TYPE_JOB_READY_FOR_SCHEDULE = "logistic_delivery_schedule";
export const EVENT_TYPE_JOB_SCHEDULED = "logistic_delivery_scheduled";
export const EVENT_TYPE_JOB_FAILED = "logistic_delivery_failed";
export const EVENT_TYPE_JOB_COMPLETED = "logistic_deliveried";
export const EVENT_TYPE_NEW_INQUIRY = "new_inquiry";

export const EVENT_PLACEHOLDER_ORDER_NO = "ORDER_NO";
export const EVENT_PLACEHOLDER_PAYMENT_AMOUNT = "PAYMENT_AMOUNT";
export const EVENT_PLACEHOLDER_PAYMENT_OPTION = "PAYMENT_OPTION";
export const EVENT_PLACEHOLDER_EVENT_DATE_TIME = "EVENT_DATE_TIME";
export const EVENT_PLACEHOLDER_ORDER_PAYABLE = "ORDER_PAYABLE";
export const EVENT_PLACEHOLDER_BUSINESS_CODE = "BUSINESS_CODE";
export const EVENT_PLACEHOLDER_CUSTOMER_NAME = "CUSTOMER_NAME";
export const EVENT_PLACEHOLDER_CUSTOMER_ADDRESS = "CUSTOMER_ADDRESS";
export const EVENT_PLACEHOLDER_OPERATOR = "OPERATOR";
export const EVENT_PLACEHOLDER_NOTES = "NOTES";
export const EVENT_PLACEHOLDER_LOGISTIC_DATE_TIME = "LOGISTIC_DATE_TIME";
export const EVENT_PLACEHOLDER_REPORT_CATEGORY = "REPORT_CATEGORY";
export const EVENT_PLACEHOLDER_REPORT_ITEMS = "REPORT_ITEMS";
export const EVENT_PLACEHOLDER_REPORT_NOTE = "REPORT_NOTE";
export const EVENT_PLACEHOLDER_SERVICE_ID = "SERVICE_ID";
export const EVENT_PLACEHOLDER_RETURN_AMOUNT = "RETURN_AMOUNT";
export const EVENT_PLACEHOLDER_RETURN_DESCRIPTION = "RETURN_NOTE";
export const EVENT_PLACEHOLDER_ORDER_TOTAL_AMOUNT = "TOTAL_AMOUNT";
export const EVENT_PLACEHOLDER_COLLECTION_DATE = "COLLECTION_DATE";
export const EVENT_PLACEHOLDER_EVENT_DATE = "EVENT_DATE";
export const EVENT_PLACEHOLDER_EVENT_TIME = "EVENT_TIME";
export const EVENT_PLACEHOLDER_STORE_NAME = "STORE_NAME";
export const EVENT_PLACEHOLDER_STORE_ADDRESS = "STORE_ADDRESS";
export const EVENT_PLACEHOLDER_STORE_WORKING_HOUR = "STORE_WORKING_HOUR";
export const EVENT_PLACEHOLDER_APP_STORE_NAME = "APP_STORE_NAME";
export const EVENT_PLACEHOLDER_BUSINESS_NAME = "BUSINESS_NAME";
export const EVENT_PLACEHOLDER_JOB_TYPE = "JOB_TYPE";
export const EVENT_PLACEHOLDER_JOB_ADDRESS = "JOB_ADDRESS";
export const EVENT_PLACEHOLDER_JOB_SCHEDULED_DATE = "SCHEDULED_DATE";
export const EVENT_PLACEHOLDER_JOB_COMPLETED_DATE = "COMPLETED_DATE";
export const EVENT_PLACEHOLDER_JOB_WORKER = "JOB_WORKER_NAME";
// TODO: REMOVE
export const EVENT_PLACEHOLDER_BRAND_NAME = "BRAND_NAME";
export const EVENT_PLACEHOLDER_BUSINESS_ADDRESS = "BUSINESS_ADDRESS";
export const EVENT_PLACEHOLDER_BUSINESS_WORKING_HOURS = "BUSINESS_WORKING_HOURS";

export const EVENT_CHANNEL_EMAIL = "email";
export const EVENT_CHANNEL_NOTIFICATION = "notification";
export const EVENT_CHANNEL_INTERNAL_NOTIFICATION = "internal_notification";
export const EVENT_CHANNEL_SMS = "sms";

export const EVENT_GROUP_ORDER_NEW_CANCELLED = "order_new_cancelled";
export const EVENT_GROUP_ORDER_JOB_RESCHEDULE = "order_job_reschedule";
export const EVENT_GROUP_CUSTOMER_PAID = "customer_paid";
export const EVENT_GROUP_REFUND_RETURN = "refund_return";
export const EVENT_GROUP_JOB_ACCEPT_GIVE_UP = "job_accept_give_up";
export const EVENT_GROUP_JOB_COMPLETE_FAILED = "job_complete_failed";
export const EVENT_GROUP_POS_CLOCK_IN_OUT = "pos_clock_in_out";
export const EVENT_GROUP_POS_CASH_DRAWER = "pos_cash_drawer";
export const EVENT_GROUP_POS_CLOSE_OF_DAY = "pos_close_of_day";
