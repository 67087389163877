import { DateDisplay, NameDisplay } from "@easybiz/component";
import { useProductionMonitorData } from "@easybiz/hook";
import { useSupplierDateProductionStateDoc } from "@easybiz/web-firebase";
import { Checkbox, Collapse, Divider, Input, List, Progress, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LineItemStatusDrawer from "../LineItemStatusDrawer";
import OrderStatusButtons from "../OrderStatusButtons";
import Title from "../Title";

export default function ({ date, supplierId, businessCode }) {
  const document = useSupplierDateProductionStateDoc(supplierId, date);
  const [viewOrder, setViewOrder] = useState();
  const [select, setSelect] = useState();
  const [search, setSearch] = useState();
  const intl = useIntl();
  const { businesses, completeItem, totalItem, percent, orders, completeCount, completed, serviceTypes, products } =
    useProductionMonitorData(document, businessCode === supplierId ? null : businessCode, select?.key, search);

  useEffect(() => {
    if (select && !businesses?.find((business) => business.key === select.key)) {
      setSelect(null);
    }
  }, [select, document]);

  return (
    <div className="flex">
      <Space className={businesses ? null : "padding-bottom"}>
        <Progress type="circle" percent={percent} width={80} />
        <div className="flex">
          <Space>
            <Title>
              <NameDisplay nameKey={businessCode} />
            </Title>
            <Title>
              <DateDisplay date={date} />
            </Title>
          </Space>
          <Title type={completed ? "success" : `danger`}>
            {`${completeItem} / ${totalItem}`} <FormattedMessage defaultMessage={"items"} />
          </Title>
          <Input.Search
            placeholder={intl.formatMessage({ defaultMessage: "Search" })}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </Space>
      {businesses && (
        <Space wrap className="padding-vertical">
          {(businesses || []).map((business) => {
            const { key, total, complete, pending } = business;
            const selected = select?.key === key;

            return (
              <Space
                key={key}
                className="selectable"
                onClick={() => {
                  setSelect(selected ? null : business);
                }}
              >
                <Checkbox checked={selected} />
                <Title>
                  <NameDisplay nameKey={key} />
                </Title>
                <div className="flex">
                  <Progress style={{ width: 100 }} percent={total ? Math.floor((complete / total) * 100) : 0} />
                  {pending > 0 ? (
                    <Typography.Text type="warning" strong>
                      <FormattedMessage defaultMessage={"{count} pending"} values={{ count: pending }} />
                    </Typography.Text>
                  ) : (
                    <Typography.Text type="success" strong>
                      <FormattedMessage defaultMessage={"{count} completed"} values={{ count: complete }} />
                    </Typography.Text>
                  )}
                </div>
              </Space>
            );
          })}
        </Space>
      )}
      {select && (
        <Divider>
          <b>
            <NameDisplay nameKey={select.key} /> ({`${select.complete}/${select.total}`})
          </b>
        </Divider>
      )}
      {orders?.length === 0 ? (
        <List />
      ) : (
        <>
          <Collapse size="small">
            <Collapse.Panel
              key={"orders"}
              header={
                <Typography.Text>
                  <FormattedMessage defaultMessage={"Orders"} /> {`${completeCount}/${orders.length}`}
                  <Typography.Text strong type={completed ? "success" : "danger"}>
                    {" "}
                    (
                    {completed ? (
                      <FormattedMessage defaultMessage={"Completed"} />
                    ) : (
                      <FormattedMessage
                        defaultMessage={"{count} pending"}
                        values={{ count: orders.length - completeCount }}
                      />
                    )}
                    )
                  </Typography.Text>
                </Typography.Text>
              }
              extra={
                <Progress
                  style={{ width: 120 }}
                  percent={orders.length ? Math.floor((completeCount / orders.length) * 100) : 0}
                />
              }
            >
              <OrderStatusButtons
                date={date}
                filter={select?.key}
                orders={orders}
                complete={completeCount}
                onSelect={setViewOrder}
              />
            </Collapse.Panel>
            {serviceTypes.map(({ name, total, waiting, complete, orders }) => {
              return (
                <Collapse.Panel
                  key={`servicetype-${name}`}
                  header={
                    <Typography.Text>
                      {`${name} ${complete}/${total}`}
                      <Typography.Text strong type={waiting ? "danger" : "success"}>
                        {" "}
                        (
                        {waiting ? (
                          <FormattedMessage defaultMessage={"{count} pending"} values={{ count: waiting }} />
                        ) : (
                          <FormattedMessage defaultMessage={"Completed"} />
                        )}
                        )
                      </Typography.Text>
                    </Typography.Text>
                  }
                  extra={<Progress style={{ width: 120 }} percent={total ? Math.floor((complete / total) * 100) : 0} />}
                >
                  <OrderStatusButtons
                    date={date}
                    filter={select?.key}
                    orders={orders}
                    complete={complete}
                    onSelect={setViewOrder}
                  />
                </Collapse.Panel>
              );
            })}
          </Collapse>
          <Divider>
            <FormattedMessage defaultMessage={"By line items"} />
          </Divider>
          {products.length > 0 && (
            <Collapse>
              {products.map(({ name, total, waiting, complete, orders }) => {
                return (
                  <Collapse.Panel
                    key={`product-${name}`}
                    header={
                      <Typography.Text>
                        {`${name} ${complete}/${total}`}
                        <Typography.Text strong type={waiting ? "danger" : "success"}>
                          {" "}
                          (
                          {waiting ? (
                            <FormattedMessage defaultMessage={"{count} pending"} values={{ count: waiting }} />
                          ) : (
                            <FormattedMessage defaultMessage={"Completed"} />
                          )}
                          )
                        </Typography.Text>
                      </Typography.Text>
                    }
                    extra={
                      <Progress style={{ width: 120 }} percent={total ? Math.floor((complete / total) * 100) : 0} />
                    }
                  >
                    <OrderStatusButtons
                      date={date}
                      filter={select?.key}
                      orders={orders}
                      complete={complete}
                      onSelect={setViewOrder}
                    />
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          )}
        </>
      )}
      <LineItemStatusDrawer viewOrder={viewOrder} open={Boolean(viewOrder)} onClose={() => setViewOrder(null)} />
    </div>
  );
}
