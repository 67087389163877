import React from "react";
import { FormattedMessage } from "react-intl";
import CustomerAddressEditPanel from "../CustomerAddressEditPanel";
import Dialog from "../Dialog";

export default function CustomerAddressDialog({ customerId, editAddress, open, onComplete, onCancel }) {
  return (
    <Dialog
      title={
        editAddress ? (
          <FormattedMessage defaultMessage="Edit Address" />
        ) : (
          <FormattedMessage defaultMessage="New Address" />
        )
      }
      open={open}
      onCancel={onCancel}
      footer={false}
    >
      <CustomerAddressEditPanel customerId={customerId} editAddress={editAddress} onComplete={onComplete} />
    </Dialog>
  );
}
