import {
  EVENT_TYPE_ORDER_BILL_RECEIPT,
  EVENT_TYPE_ORDER_CANCELLED,
  EVENT_TYPE_ORDER_CASE_REPORT,
  EVENT_TYPE_ORDER_COLLECTED,
  EVENT_TYPE_ORDER_COMPLETED,
  EVENT_TYPE_ORDER_CREATED,
  EVENT_TYPE_ORDER_READY_FOR_COLLECTION,
  EVENT_TYPE_ORDER_RESCHEDULED,
  EVENT_TYPE_ORDER_SALES_RETURN,
} from "./constants/index.js";

export default (eventType) => {
  return (
    [
      EVENT_TYPE_ORDER_CREATED,
      EVENT_TYPE_ORDER_RESCHEDULED,
      EVENT_TYPE_ORDER_SALES_RETURN,
      EVENT_TYPE_ORDER_CASE_REPORT,
      EVENT_TYPE_ORDER_BILL_RECEIPT,
      EVENT_TYPE_ORDER_COLLECTED,
      EVENT_TYPE_ORDER_READY_FOR_COLLECTION,
      EVENT_TYPE_ORDER_COMPLETED,
      EVENT_TYPE_ORDER_CANCELLED,
    ].indexOf(eventType) >= 0
  );
};
