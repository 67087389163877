"use client";

import { useAuthUser, useClientType, useDeviceLocation, useInstallationId, usePushToken } from "@easybiz/context";
import { useAdminDeviceRegister } from "@easybiz/request";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function ({ multiStation, accountDoc, getDeviceInfo, onRegistered, onSignOut }) {
  const account = useAuthUser();
  const client = useClientType();
  const installId = useInstallationId();
  const pushToken = usePushToken();
  const location = useDeviceLocation();
  const { onSubmit, response, onClear } = useAdminDeviceRegister((succeed, response) => {
    if (succeed) {
      onRegistered(response);
    }
  });

  useEffect(() => {
    if (response?.errorCode) {
      let description, button;

      // switch (response?.errorCode) {
      //   case ERROR_CODE_SINGIN_OTHER_DEVICE:
      //     description = intl.formatMessage({
      //       defaultMessage:
      //         "Your account has been logged in another device, you can enable device whitelist to limit device access to your system.",
      //     });
      //     break;
      //   case ERROR_CODE_MULTI_STATION_WRONG_ADDRESS:
      //     description = intl.formatMessage({
      //       defaultMessage:
      //         "Multi-station sign out, your device address is not within the area of your store address, please check your store address setting or disable the Multi-station function if you don't need it.",
      //     });
      //     break;
      //   case ERROR_CODE_ACCOUNT_DELETED:
      //     description = intl.formatMessage({
      //       defaultMessage: "Your account has been deleted",
      //     });
      //     break;
      //   case ERROR_CODE_PASSWORD_CHANGED:
      //     description = intl.formatMessage({
      //       defaultMessage: "Your account password has been changed, please sign in again with your new password",
      //     });
      //     break;
      //   case ERROR_CODE_NOT_WHITELISTED:
      //     description = intl.formatMessage({
      //       defaultMessage:
      //         "Your device is not whitelisted to access the system, please contact your admin to whitelist your device.",
      //     });
      //     break;
      //   case ERROR_CODE_DEVICE_BLOCKED:
      //     description = intl.formatMessage({
      //       defaultMessage: "Your device has been blocked",
      //     });
      //     break;
      //   case ERROR_CODE_LOGGED_OUT:
      //     description = intl.formatMessage({
      //       defaultMessage: "Your account have been logged out",
      //     });
      //     break;
      //   case ERROR_CODE_LICENSE_EXPIRED:
      //     description = intl.formatMessage({
      //       defaultMessage: "Your license has expired",
      //     });
      //     break;
      //   case ERROR_CODE_USE_NATIVE_POS:
      //     description = intl.formatMessage({
      //       defaultMessage: "Please change to use our new POS Application for your customer screen",
      //     });
      //     button = response.actionButton;
      //     break;
      // }

      onSignOut("Login session expired", description, button);
      onClear();
    }
  }, [Boolean(response?.errorCode)]);

  useEffect(() => {
    if (installId && account?.uid) {
      // Init permission register
      onSubmit({ ...getDeviceInfo(), installId, pushToken, ...(location && { location }) });
    }
  }, [account?.uid, installId, pushToken, multiStation && location?.failed ? location.timestamp : null]);

  useEffect(() => {
    if (
      !multiStation &&
      client &&
      installId &&
      account?.authTime &&
      accountDoc?.get(client) &&
      accountDoc?.get(client).authTime > account?.authTime
    ) {
      if (accountDoc?.get(client).id !== installId) {
        // Sign out
        onSignOut(
          "Login session expired",
          `Your account has been logged in another device ID:${accountDoc?.get(client).id} at ${dayjs.unix(accountDoc?.get(client).authTime).format("HH:mm DD MMM YYYY")}, you can enable device whitelist to limit device access to your system.`
        );
      } else {
        onSubmit({ ...getDeviceInfo(), installId, pushToken });
      }
    }
  }, [
    Boolean(multiStation),
    client,
    installId,
    account?.authTime,
    accountDoc?.get(client)?.authTime,
    accountDoc?.get(client)?.id,
  ]);

  return null;
}
