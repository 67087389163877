"use client";

import { formatPrice } from "@easybiz/utils";
import { useMemo, useState } from "react";

export default (newOrder, topUpOption, fullAmount) => {
  const [payment, setPayment] = useState(undefined);
  const [pointsPayment, setPointsPayment] = useState(undefined);

  return useMemo(() => {
    let priceOverview;

    if (newOrder) {
      const { totalBalance = 0 } = newOrder;
      const totalPayable = pointsPayment ? formatPrice(totalBalance - pointsPayment.amount) : totalBalance;
      const totalOutstanding = payment?.amount ? formatPrice(totalPayable - payment?.amount) : totalPayable;

      priceOverview = {
        totalAmount: totalBalance,
        totalPayable,
        totalOutstanding,
      };
    } else if (topUpOption) {
      priceOverview = {
        totalPayable: topUpOption.amount,
        totalAmount: topUpOption.amount,
        totalOutstanding: formatPrice(topUpOption.amount - (payment?.amount || 0)),
      };
    } else {
      priceOverview = {
        totalPayable: fullAmount,
        totalAmount: fullAmount,
        totalOutstanding: formatPrice(fullAmount - (payment?.amount || 0)),
      };
    }

    return {
      ...priceOverview,
      payment,
      pointsPayment,
      setPayment,
      setPointsPayment,
    };
  }, [newOrder, topUpOption, fullAmount, payment, pointsPayment]);
};
