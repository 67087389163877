import { useToast } from "@easybiz/context";
import { OPERATION_GROUP_ORDER, ORDER_OPERATION_CANCEL } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const toast = useToast();
  const intl = useIntl();

  const onSubmit = useCallback(({ orderId, note }) => {
    if (!note) {
      return intl.formatMessage({ defaultMessage: "Please leave a note" });
    }

    return [
      OPERATION_GROUP_ORDER,
      ORDER_OPERATION_CANCEL,
      {
        note,
        orderId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, (request, response) =>
    toast.success(
      intl.formatMessage({ defaultMessage: "Order {orderNo} has been cancelled" }, { orderNo: response.orderNo })
    )
  );
};
