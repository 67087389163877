export default function (nameKey, context) {
  // Backward support;

  if (nameKey?.en) {
    return nameKey.en;
  }

  const { serviceTypes, businessCodes, accounts, serviceNames, stores, webapps, paymentMethods, procedureNames } =
    context || {};

  return (
    (businessCodes && businessCodes[nameKey]) ||
    (stores && stores[nameKey]) ||
    (webapps && webapps[nameKey]) ||
    (accounts && accounts[nameKey]) ||
    (serviceNames && serviceNames[nameKey]) ||
    (paymentMethods && paymentMethods[nameKey]) ||
    (serviceTypes && serviceTypes[nameKey]) ||
    (procedureNames && procedureNames[nameKey]) ||
    ""
  );
}
