export default function (content, limits) {
  let lines = [],
    lineIndex = 0;

  const words = `${content || ""}`.split(" ").filter((word) => word);
  for (const word of words) {
    let max;
    if (typeof limits === "number") {
      max = limits;
    } else if (limits instanceof Array) {
      max = limits[lines.length] || limits[limits.length - 1] || 20;
    } else {
      max = 20; // Default
    }

    if (lines[lineIndex] && lines[lineIndex].length + word.length >= max) {
      lines = [...lines, ``];
      lineIndex++;
    }

    lines[lineIndex] = `${
      lines[lineIndex] ? `${lines[lineIndex]} ` : ""
    }${word}`;
  }

  return lines;
}
