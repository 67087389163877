import { DateDisplay } from "@easybiz/component";
import { useJobHistoryQuery } from "@easybiz/web-firebase";
import { List } from "antd";
import React from "react";

export default function JobHistoryList({ style, taskId }) {
  const histories = useJobHistoryQuery(taskId);

  return (
    <List
      style={style}
      dataSource={histories || []}
      loading={!histories}
      renderItem={(doc) => {
        const { description, createDate, operator } = doc.data();
        return (
          <List.Item>
            <List.Item.Meta
              title={description}
              description={
                <span>
                  {operator?.name} <DateDisplay {...createDate} />
                </span>
              }
            />
          </List.Item>
        );
      }}
    />
  );
}
