import { EnvironmentOutlined, HistoryOutlined } from "@ant-design/icons";
import { useIsBusinessSlotReady, useToast } from "@easybiz/context";
import { JOB_TYPE_DELIVERY } from "@easybiz/utils";
import { Alert, Button, Space } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomerAddressQuickSelectPanel from "../CustomerAddressQuickSelectPanel";
import QuickSwitchCalendar from "../QuickSwitchCalendar";
import ScheduleOptionsList from "../ScheduleOptionsList";

export default function JobSchedulePanel({
  service,
  businessCode,
  customer,
  address,
  schedule,
  onUpdate,
  enableScheduleLater,
}) {
  const toast = useToast();
  const intl = useIntl();
  const isSlotReady = useIsBusinessSlotReady(service?.id);
  const [selectAddress, setSelectAddress] = useState(address);

  return (
    <Space direction="vertical" className="full-width">
      {customer ? (
        <CustomerAddressQuickSelectPanel customerId={customer.id} address={selectAddress} onSelect={setSelectAddress} />
      ) : (
        <Alert
          type="info"
          icon={<EnvironmentOutlined />}
          showIcon
          message={<FormattedMessage tagName={"b"} defaultMessage={"Please select customer account first"} />}
          description={
            <FormattedMessage
              defaultMessage={
                "Home delivery requiring add or select customer address, please search and select customer account on the right."
              }
            />
          }
        />
      )}
      {enableScheduleLater && (
        <div className="flex flex-row flex-end">
          <Button icon={<HistoryOutlined />} onClick={() => onUpdate({ collectionPlace: false })}>
            <FormattedMessage tagName={"span"} defaultMessage={"Schedule later"} />
          </Button>
        </div>
      )}
      {selectAddress &&
        (isSlotReady ? (
          <ScheduleOptionsList
            customerId={customer?.id}
            businessCode={businessCode}
            serviceId={service?.id}
            scheduleType={JOB_TYPE_DELIVERY}
            address={selectAddress}
            select={schedule?.date ? schedule : null}
            onSelect={(schedule, option) => {
              onUpdate({ schedule: { ...schedule, option: option || null }, address: selectAddress });
            }}
          />
        ) : (
          <QuickSwitchCalendar
            value={address && schedule?.date ? dayjs(schedule.date) : null}
            minDate={dayjs()}
            onSelect={(date) => {
              if (!selectAddress) {
                return toast.info(intl.formatMessage({ defaultMessage: "Please select customer address first" }));
              }

              onUpdate({ schedule: { date: date.format("YYYY-MM-DD") }, address: selectAddress });
            }}
          />
        ))}
    </Space>
  );
}
