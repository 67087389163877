import React from "react";
import { FormattedMessage } from "react-intl";
import {
  ACTION_ACK_PRICE,
  ACTION_AGREE_PRICE,
  ACTION_COLLECT,
  ACTION_CONFIRM_PRICE,
  ACTION_DELIVER,
  ACTION_PACK,
  ACTION_PAYMENT,
  ACTION_PICK_UP,
  ACTION_PLANT_IN,
  ACTION_PLANT_OUT,
  ACTION_SCHEDULE_DELIVER,
  ACTION_SCHEDULE_PICKUP,
  ACTION_SERVICE,
  ACTION_STORE_IN,
} from "@easybiz/utils";

export default function OrderProgressTitle({ action, ing }) {
  switch (action) {
    case ACTION_SCHEDULE_PICKUP:
      if (ing) {
        return <FormattedMessage defaultMessage="Pending pick up schedule" />;
      } else {
        return <FormattedMessage defaultMessage="Pick-up schedule" />;
      }
    case ACTION_PICK_UP:
      if (ing) {
        return <FormattedMessage defaultMessage="Pending pick up" />;
      } else {
        return <FormattedMessage defaultMessage="Pick up" />;
      }
    case ACTION_PLANT_IN:
      if (ing) {
        return <FormattedMessage defaultMessage="Pending service center check in" />;
      } else {
        return <FormattedMessage defaultMessage="Service center check in" />;
      }
    case ACTION_CONFIRM_PRICE:
      if (ing) {
        return <FormattedMessage defaultMessage="Preparing your itemised receipt" />;
      } else {
        return <FormattedMessage defaultMessage="Confirm order total" />;
      }
    case ACTION_AGREE_PRICE:
      if (ing) {
        return <FormattedMessage defaultMessage="Bill is ready, pending on your confirmation" />;
      } else {
        return <FormattedMessage defaultMessage="Your bill confirmation" />;
      }
    case ACTION_ACK_PRICE:
    case ACTION_SERVICE:
    case ACTION_PACK:
      if (ing) {
        return <FormattedMessage defaultMessage="Service in progress" />;
      } else {
        return <FormattedMessage defaultMessage="Servicing" />;
      }
    case ACTION_PAYMENT:
      if (ing) {
        return <FormattedMessage defaultMessage="Pending payment" />;
      } else {
        return <FormattedMessage defaultMessage="Payment" />;
      }
    case ACTION_SCHEDULE_DELIVER:
      if (ing) {
        return <FormattedMessage defaultMessage="Pending delivery schedule" />;
      } else {
        return <FormattedMessage defaultMessage="Delivery schedule" />;
      }
    case ACTION_PLANT_OUT:
      if (ing) {
        return <FormattedMessage defaultMessage="Delivery OTW" />;
      } else {
        return <FormattedMessage defaultMessage="Service center check out" />;
      }
    case ACTION_DELIVER:
      if (ing) {
        return <FormattedMessage defaultMessage="Pending delivery" />;
      } else {
        return <FormattedMessage defaultMessage="Delivery" />;
      }
    case ACTION_STORE_IN:
      if (ing) {
        return <FormattedMessage defaultMessage="Sending back to store" />;
      } else {
        return <FormattedMessage defaultMessage="Back to store" />;
      }
    case ACTION_COLLECT:
      if (ing) {
        return <FormattedMessage defaultMessage="Pending collection" />;
      } else {
        return <FormattedMessage defaultMessage="Collection" />;
      }
    default:
      return null;
  }
}
