import { useAuthUser, useMemberSettings, useOnlinePaymentMethods } from "@easybiz/context";
import {
  formatCard,
  formatPointsPayment,
  isDocumentExists,
  PAYMENT_ID_CREDIT,
  PAYMENT_ID_STRIPE,
} from "@easybiz/utils";
import { useMemo } from "react";

export default (amount, creditWallet, pointsWallet, creditCards, isRefund, enablePartial) => {
  const user = useAuthUser();
  const { pointToMoneyExRate } = useMemberSettings();
  const onlineMethods = useOnlinePaymentMethods();

  return useMemo(() => {
    const totalCredits = creditWallet?.get("credit") || 0;

    return [
      creditWallet &&
        (isDocumentExists(creditWallet) || isRefund) && {
          id: PAYMENT_ID_CREDIT,
          option: PAYMENT_ID_CREDIT,
          totalCredits,
          ...(!isRefund && { maximumPayable: totalCredits }),
          ...(!isRefund && typeof amount === "number" && amount > totalCredits && { amount: totalCredits }),
          disabled: isRefund ? false : totalCredits <= 0 || (!enablePartial && creditWallet?.get("credit") < amount),
        },
      pointsWallet && formatPointsPayment(pointToMoneyExRate, pointsWallet.get("point"), amount),
      ...(isRefund
        ? []
        : [].concat.apply(
            [],
            (onlineMethods || []).map(({ id }) => {
              if (id === PAYMENT_ID_STRIPE) {
                // Credit card
                return [
                  ...(creditCards || []).map(formatCard),
                  ...(user?.customerId || user?.businessCode
                    ? [
                        // Only customer and POS can pay with new card
                        {
                          id: PAYMENT_ID_STRIPE,
                          option: PAYMENT_ID_STRIPE,
                        },
                      ]
                    : []),
                ];
              } else {
                return [{ option: id, photoId: id, id }];
              }
            })
          )),
    ].filter((option) => option);
  }, [
    creditWallet,
    pointsWallet,
    creditCards,
    onlineMethods,
    Boolean(user?.customerId),
    Boolean(user?.businessCode),
    isRefund,
    enablePartial,
    pointToMoneyExRate,
  ]);
};
