"use client";

import { useHaveNetwork } from "@easybiz/context";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function useOfflineAlert() {
  const online = useHaveNetwork();

  if (online === false) {
    return {
      title: (
        <FormattedMessage
          defaultMessage="<b>No Internet Connection</b>, Try 1. Reconnecting to Wi-Fi. 2. Checking the network cables, modem and router"
          values={{ b: (t) => <b>{t}</b> }}
        />
      ),
      reloadBtn: <FormattedMessage defaultMessage="Reload App" />,
    };
  }
}
