import { TranslationOutlined } from "@ant-design/icons";
import { useLocale, useSetLocale } from "@easybiz/context";
import { SYSTEM_LANGUAGES } from "@easybiz/utils";
import { Button, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";

export default function ({ type, block }) {
  const [open, setVisible] = useState(false);
  const locale = useLocale();
  const setLocale = useSetLocale();

  return (
    <>
      <Button block={block} type={type} icon={<TranslationOutlined />} onClick={() => setVisible(true)}>
        <FormattedMessage tagName="span" defaultMessage="Language" />
      </Button>
      <Dialog
        open={open}
        onCancel={() => setVisible(false)}
        okText={<FormattedMessage defaultMessage={"Close"} />}
        okType="default"
        title={<FormattedMessage tagName="span" defaultMessage="System Language" />}
        onOk={() => setVisible(false)}
      >
        <Space direction="horizontal" wrap>
          {SYSTEM_LANGUAGES.map(({ code, title }) => {
            return (
              <Button key={code} type={locale === code ? "primary" : "default"} onClick={() => setLocale(code)}>
                {title}
              </Button>
            );
          })}
        </Space>
      </Dialog>
    </>
  );
}
