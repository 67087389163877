import React from "react";
import { FormattedMessage } from "react-intl";
import {
  UNIT_BAG,
  UNIT_BUNDLE,
  UNIT_FT,
  UNIT_HOUR,
  UNIT_INCH,
  UNIT_KG,
  UNIT_PAIR,
  UNIT_PCS,
  UNIT_SET,
  UNIT_SQFT,
} from "@easybiz/utils";

export default function UnitDisplay ({ unit }) {
  switch (unit) {
    case UNIT_PCS:
      return <FormattedMessage id='pcs' defaultMessage={"pcs"} />;
    case UNIT_BUNDLE:
      return <FormattedMessage id='bundle' defaultMessage={"bundle"} />;
    case UNIT_FT:
      return <FormattedMessage id='ft' defaultMessage={"ft"} />;
    case UNIT_INCH:
      return <FormattedMessage id='inch' defaultMessage={"inch"} />;
    case UNIT_KG:
      return <FormattedMessage id='kg' defaultMessage={"kg"} />;
    case UNIT_PAIR:
      return <FormattedMessage id="pair" defaultMessage={"pair"} />;
    case UNIT_SET:
      return <FormattedMessage id='set' defaultMessage={"set"} />;
    case UNIT_SQFT:
      return <FormattedMessage id='sqft' defaultMessage={"sqft"} />;
    case UNIT_HOUR:
      return <FormattedMessage id='hours' defaultMessage={"hours"} />;
    case UNIT_BAG:
      return <FormattedMessage id='bag' defaultMessage={"bag"} />;
    default:
      return unit || null;
  }
}
