import { AmountDisplay } from "@easybiz/component";
import { Button, Space } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import DialPad from "../DialPad";
import Title from "../Title";

export default function ({
  fontSize,
  buttonText,
  buttonType,
  prefix,
  suffix,
  type,
  removeable,
  value,
  onChange,
  onDone,
  loading,
  decimal,
  disableListener,
  stringFormat,
  listenValue,
  isCurrency,
}) {
  const [buttonFocused, setButtonFocused] = useState(false);
  const [changes, setChanges] = useState(null);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    setChanges(null);
    setInitial(true);
  }, [isCurrency, decimal, prefix, suffix]);

  const returnValue = useMemo(() => {
    if (changes === null) {
      return value;
    } else {
      if (changes && stringFormat) {
        return changes;
      } else if (changes && !isNaN(changes)) {
        return parseFloat(changes);
      } else {
        return null;
      }
    }
  }, [stringFormat, changes, value]);

  const removing = Boolean(removeable && value && !returnValue);

  const onComplete = useCallback(() => {
    if (stringFormat) {
      onDone(`${returnValue || ""}`);
    } else {
      onDone(typeof returnValue === "number" ? returnValue : null);
    }
  }, [returnValue, stringFormat, listenValue, onDone]);

  return (
    <Space direction="vertical" size={"middle"} className="full-width">
      <div className="text-center" style={{ minHeight: 41 }}>
        <Title type={type} fontSize={fontSize || 26}>
          {prefix ? <span>{prefix} </span> : null}
          {isCurrency ? <AmountDisplay value={changes ?? value ?? 0} /> : changes ?? value}
          {suffix ? (
            <Title fontSize={fontSize || 26} type="secondary">
              {suffix}
            </Title>
          ) : null}
        </Title>
      </div>
      <DialPad
        value={changes ?? value}
        initial={initial}
        onChange={(value) => {
          setInitial(false);

          if (onChange) {
            onChange(value);
          } else {
            setChanges(value);
          }
        }}
        onDone={onDone ? onComplete : null}
        decimal={decimal || isCurrency}
        disableListener={disableListener}
        disableSubmit={buttonFocused}
      />
      {onDone && (
        <Button
          block
          onFocus={() => setButtonFocused(true)}
          onBlur={() => setButtonFocused(false)}
          type={removing ? "primary" : buttonType || (value && value === returnValue ? "default" : "primary")}
          loading={loading}
          autoFocus
          danger={removing}
          onClick={onDone ? onComplete : null}
        >
          {removing ? (
            <FormattedMessage defaultMessage={"Remove"} />
          ) : (
            buttonText ||
            (value || !removeable ? (
              <FormattedMessage tagName="span" defaultMessage="Update" />
            ) : (
              <FormattedMessage tagName="span" defaultMessage="Add" />
            ))
          )}
        </Button>
      )}
    </Space>
  );
}
