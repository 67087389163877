import { ADMIN_OPERATION_INVOICE_SEND, isValidEmail, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  return useActionCallback(({ sendTo, pdfBase64, invoiceId }) => {
    if (!sendTo || !isValidEmail(sendTo))
      return intl.formatMessage({
        defaultMessage: "Please enter a valid email address",
      });
    if (!pdfBase64) return intl.formatMessage({ defaultMessage: "Invoice PDF not ready" });

    return [
      OPERATION_GROUP_ADMIN,
      ADMIN_OPERATION_INVOICE_SEND,
      {
        sendTo,
        invoiceId,
        pdfBase64,
      },
    ];
  }, callback);
};
