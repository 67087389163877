import { AmountDisplay } from "@easybiz/component";
import { Button, Form, Input, message, Modal, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";
import NumericUpdatePanel from "../NumericUpdatePanel";
import Title from "../Title";

export default ({ title, open, onClose, onAdd }) => {
  const [name, setName] = useState();
  const [surcharge, setSurcharge] = useState(0);
  const [surchargeVisible, setSurchargeVisible] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (open) {
      setName("");
      setSurcharge(0);
    }
  }, [open]);

  return (
    <Dialog
      title={title || <FormattedMessage defaultMessage="Other" />}
      disabled={surchargeVisible}
      value={`${name}${surcharge}`}
      open={open}
      onCancel={onClose}
      onOk={() => {
        if (!name) return message.info(intl.formatMessage({ defaultMessage: "Please describe your item" }));

        onAdd({
          id: name,
          code: name,
          ...(typeof surcharge === "number" && { surcharge }),
        });
      }}
    >
      <Form layout="vertical">
        <Form.Item label={<FormattedMessage defaultMessage="Item name" />}>
          <Input
            autoFocus
            placeholder={intl.formatMessage({
              defaultMessage: "Describe your item",
            })}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label={<FormattedMessage defaultMessage="Price" />}>
          <Space>
            <Title>
              <AmountDisplay value={surcharge} />
            </Title>
            <Button onClick={() => setSurchargeVisible(true)}>
              <FormattedMessage defaultMessage="Change" />
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <Modal
        title={<FormattedMessage tagName="span" defaultMessage="Surcharge" />}
        open={surchargeVisible}
        onCancel={() => setSurchargeVisible(false)}
        footer={false}
      >
        <NumericUpdatePanel
          value={surcharge}
          isCurrency
          open={surchargeVisible}
          onDone={(value) => {
            setSurcharge(value);
            setSurchargeVisible(false);
          }}
        />
      </Modal>
    </Dialog>
  );
};
