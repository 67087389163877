import {
  PLACEHOLDER_BUSINESS_CODE,
  PLACEHOLDER_CUSTOMER_CONTACT,
  PLACEHOLDER_CUSTOMER_NAME,
  PLACEHOLDER_ORDER_NO,
  PLACEHOLDER_ORDER_TOTAL_UNIT,
  PLACEHOLDER_PACKAGE_INDEX,
  PLACEHOLDER_TOTAL_PACKAGE,
} from "./constants/label.js";
import { toPackQR } from "./qrcode.js";
import renderLabelContent from "./renderLabelContent.js";

export default function (pack, context, settings) {
  const {
    packTitle = `@${PLACEHOLDER_BUSINESS_CODE}\r\n@${PLACEHOLDER_ORDER_NO}`,
    packContent = `@${PLACEHOLDER_CUSTOMER_NAME}\r\n@${PLACEHOLDER_CUSTOMER_CONTACT}`,
    packAppendix = `@${PLACEHOLDER_PACKAGE_INDEX}/@${PLACEHOLDER_TOTAL_PACKAGE}\r\n@${PLACEHOLDER_ORDER_TOTAL_UNIT}`,
  } = settings || {};

  return {
    qrcode: toPackQR(pack?.id),
    title: packTitle ? renderLabelContent(packTitle, pack, context) : ``,
    content: packContent ? renderLabelContent(packContent, pack, context) : ``,
    appendix: packAppendix ? renderLabelContent(packAppendix, pack, context) : ``,
    homeDelivery: pack?.homeDelivery,
  };
}
