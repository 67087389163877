import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { useImageHost, useOperator, useRealmId } from "@easybiz/context";
import { uploadStorage } from "@easybiz/web-firebase";
import { Button, Image, message, Progress, Space, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function Uploader({
  imageId,
  src,
  buttonText,
  height,
  width,
  resizeWidth,
  resizeHeight,
  resizeFormat,
  resizeMode,
  defaultImage,
  crop,
  disabled,
  png,
  onUploaded,
  photoId,
  fixed,
  minimum,
  expireDays,
  children,
  isVideo,
  fallback,
  alert,
  horizontal,
}) {
  const realmId = useRealmId();
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState();
  const hostAddress = useImageHost();
  const uploading = typeof progress === "number" && progress !== 100;
  const admin = useOperator();

  const srcUrl = useMemo(() => {
    if (photoId) {
      return `${hostAddress}/${photoId}${fixed ? `?${Date.now()}` : ""}`;
    } else {
      return src;
    }
  }, [hostAddress, realmId, photoId, src, fixed]);

  const onUpload = (file) => {
    setProgress(0);
    uploadStorage(
      realmId,
      file,
      {
        operator: admin.name || "",
        ...(Number.isInteger(resizeWidth) && { resizeWidth: `${resizeWidth}` }),
        ...(Number.isInteger(resizeHeight) && {
          resizeHeight: `${resizeHeight}`,
        }),
        ...(resizeFormat && { resizeFormat }),
        ...(resizeMode && { resizeMode }),
        ...(fixed && { perminent: `yes` }),
        ...(expireDays && { expireDays: `${expireDays}` }),
      },
      fixed && photoId,
      (uploadPhotoId, progress, error) => {
        setProgress(progress);

        if (error) {
          message.error(error.message);
        } else if (uploadPhotoId) {
          onUploaded && onUploaded(uploadPhotoId);
        }
      }
    );
  };

  const uploadButton = (
    <Upload
      listType={minimum || children ? null : "picture"}
      accept={
        isVideo
          ? ".mp4"
          : png
          ? "image/png, image/webp"
          : "image/jpeg, image/png, image/webp"
      }
      multiple={false}
      fileList={minimum || children ? null : fileList}
      beforeUpload={(file) => {
        setFileList([file]);
        onUpload(file);

        return false;
      }}
      progress={{
        percent: progress || 0,
        strokeWidth: 3,
        showInfo: true,
        status: progress === 100 ? "success" : "active",
        format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
      }}
      itemRender={
        minimum || children
          ? null
          : () => {
              return (
                <div className="flex flex-row flex-align-center">
                  <div className="flex-fill">
                    <Progress percent={progress} />
                  </div>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={() => {
                      setFileList(null);
                      onUploaded && onUploaded(null);
                    }}
                  />
                </div>
              );
            }
      }
    >
      {children || (
        <Button
          icon={<UploadOutlined />}
          loading={uploading}
          disabled={disabled}
          block
        >
          {uploading ? (
            <FormattedMessage tagName="span" defaultMessage="Uploading..." />
          ) : (
            buttonText || (
              <FormattedMessage tagName="span" defaultMessage="Upload" />
            )
          )}
        </Button>
      )}
    </Upload>
  );

  const uploader = crop ? (
    <ImgCrop quality={1} fillColor={png ? "#fff0" : "white"}>
      {uploadButton}
    </ImgCrop>
  ) : (
    uploadButton
  );

  if (minimum || children) {
    return uploader;
  } else {
    return (
      <Space direction={horizontal ? "horizontal" : "vertical"}>
        {alert && !srcUrl
          ? alert
          : (srcUrl || !isVideo) &&
            (isVideo ? (
              <video src={srcUrl} width={width || "100%"} controls />
            ) : imageId ? (
              <img
                id={imageId}
                src={
                  srcUrl ||
                  defaultImage ||
                  (fallback ? "/images/placeholder-icon.png" : null)
                }
                style={{ width: width || (srcUrl ? "100%" : null) }}
              />
            ) : (
              <Image
                src={
                  srcUrl ||
                  defaultImage ||
                  (fallback ? "/images/placeholder-icon.png" : null)
                }
                preview={srcUrl ? undefined : false}
                width={width || (srcUrl ? "100%" : null)}
                height={height || null}
                fallback={fallback ? "/images/placeholder-icon.png" : null}
              />
            ))}
        {uploader}
      </Space>
    );
  }
}
