import { Col, Row, Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import PrintReceiptButton from "../PrintReceiptButton";
import ReceiptView from "../ReceiptView";

export default function ({ lines, height, loading }) {
  return (
    <Row style={{ width: 436 }} gutter={[8, 8]}>
      <Col span={24}>
        <Space>
          <PrintReceiptButton
            text={<FormattedMessage tagName="span" defaultMessage="Print summary" />}
            lines={lines && lines.filter((line) => !line.breakdown)}
            block
            hideSetting
          />
          <PrintReceiptButton
            text={<FormattedMessage tagName="span" defaultMessage="Print full report" />}
            lines={lines}
            block
          />
        </Space>
      </Col>
      <Col span={24}>
        <div style={height ? { height, overflowY: "auto" } : null}>
          <ReceiptView lines={lines} loading={loading} />
        </div>
      </Col>
    </Row>
  );
}
