import { Card } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import ProductBadgeImage from "../ProductBadgeImage";

export default ({ onClick, photoId, title, count, width, other }) => {
  return (
    <Card className="selectable" onClick={onClick} bodyStyle={{ padding: 8, backgroundColor: "white" }}>
      {other ? (
        <div style={{ width: width, height: width }} className="flex flex-center flex-align-center">
          <img className="full-width" style={{ width: width / 2, height: width / 2 }} src={"/images/plus.png"} />
        </div>
      ) : (
        <ProductBadgeImage width={width} photoId={photoId} count={count} allProduct />
      )}
      <div
        style={{
          padding: "3px 0px 0px 0px",
          width: width,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
        }}
      >
        {other ? <FormattedMessage tagName="span" defaultMessage="Other" /> : title}
      </div>
    </Card>
  );
};
