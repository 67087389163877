import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { formatPrice } from "@easybiz/utils";
import { Button, message, Space } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import IconButton from "../IconButton";
import QtyInputPopover from "../QtyInputPopover";
import Title from "../Title";

export default function ({
  decimal,
  placement,
  disableInput,
  title,
  value,
  suffix,
  prefix,
  onChange,
  valueType,
  increment,
  maxValue,
  minValue,
}) {
  const [open, setVisible] = useState(false);

  const button = (
    <Title type={valueType || (value ? "success" : null)}>
      {prefix}
      {value || 0}
      {suffix ? <span> {suffix}</span> : null}
    </Title>
  );
  const intl = useIntl();

  return (
    <Space size={disableInput ? "large" : "small"}>
      <IconButton
        disabled={(value || 0) <= (minValue || 0)}
        icon={<MinusOutlined />}
        onClick={(e) =>
          onChange(
            formatPrice(
              Math.max(
                typeof minValue === "number" ? minValue : 0,
                (value || 0) - (typeof increment === "number" ? increment : 1)
              ),
              true
            ),
            e
          )
        }
      />
      {disableInput ? (
        button
      ) : (
        <QtyInputPopover
          removeable
          open={open}
          onOpenChange={setVisible}
          title={title}
          decimal={decimal}
          placement={placement || "left"}
          value={value}
          maxValue={maxValue}
          onDone={(update) => {
            if (!value && update === 0) {
              return message.info(intl.formatMessage({ defaultMessage: "Please enter quantity" }));
            }

            setVisible(false);
            onChange(update);
          }}
          suffix={suffix}
        >
          <Button style={{ padding: "0px 15px 0px 15px", minWidth: 64 }}>{button}</Button>
        </QtyInputPopover>
      )}
      <IconButton
        disabled={typeof maxValue === "number" && value >= maxValue}
        icon={<PlusOutlined />}
        onClick={(e) => onChange(formatPrice((value || 0) + (typeof increment === "number" ? increment : 1), true), e)}
      />
    </Space>
  );
}
