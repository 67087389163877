import { OPERATION_GROUP_POS, POS_OPERATION_PREVIEW_COLLECTION_OPTIONS } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const onClick = useCallback(({ serviceId, businessCode, orderId, serviceTypes, date }) => {
    return [
      OPERATION_GROUP_POS,
      POS_OPERATION_PREVIEW_COLLECTION_OPTIONS,
      {
        serviceId,
        businessCode,
        clientDate: date,
        ...(orderId && { orderId }),
        ...(Array.isArray(serviceTypes) && serviceTypes.length > 0 && { serviceTypes }),
      },
    ];
  }, []);

  return useActionCallback(onClick, callback);
};
