import { CarOutlined } from "@ant-design/icons";
import { JobTitle } from "@easybiz/component";
import { Button, Popover } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import JobSchedulePanel from "../JobSchedulePanel";
import ScheduleConfirmDialog from "../ScheduleConfirmDialog";
import Title from "../Title";

export default function CollectionScheduleButton({ children, disabled: initDisabled, order, scheduleType }) {
  const [open, setOpen] = useState(false);
  const [pendingSchedule, setPendingSchedule] = useState();
  const disabled = initDisabled || !order || order.get("voided") || order.get("completed");

  return (
    <>
      <Popover
        trigger={"click"}
        placement={"left"}
        title={
          <Title>
            <FormattedMessage
              defaultMessage={"{jobType} Schedule"}
              values={{
                jobType: <JobTitle type={scheduleType} />,
              }}
            />
          </Title>
        }
        content={
          <div style={{ width: 500, height: 500, overflowY: "auto" }}>
            <JobSchedulePanel
              service={order?.get("service")}
              businessCode={order?.get("business.id")}
              customer={order?.get("customer")}
              onUpdate={(schedule) => {
                setPendingSchedule({ ...schedule, scheduleType });
                setOpen(false);
              }}
            />
          </div>
        }
        open={open}
        onOpenChange={disabled ? null : setOpen}
        destroyTooltipOnHide
      >
        <Button icon={<CarOutlined />} block disabled={disabled}>
          {children}
        </Button>
      </Popover>
      <ScheduleConfirmDialog
        order={order}
        newSchedule={pendingSchedule}
        open={Boolean(pendingSchedule)}
        onCancel={() => setPendingSchedule(null)}
      />
    </>
  );
}
