import { Calendar, Collapse, Drawer, List } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DateDisplay } from "@easybiz/component";
import HandoverTags from "./HandoverTags";
import { ReceiptView } from "@easybiz/web-admin";
import { useHandoverHistoryQuery } from "@easybiz/web-firebase";

export default ({ isCheckOut, open, onClose }) => {
  const [date, setDate] = useState(dayjs());
  const [histories, loading] = useHandoverHistoryQuery(
    open && date.format("YYYY-MM-DD"),
    isCheckOut
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={<FormattedMessage tagName="span" defaultMessage="History" />}
      placement="bottom"
      height="100%"
      bodyStyle={{ padding: 0 }}
      destroyOnClose
    >
      <div
        className="flex flex-row"
        style={{ height: "calc(100vh - 64px)", overflow: "hidden" }}
      >
        <div
          style={{
            width: "50%",
            height: "100%",
            overflow: "auto",
            paddingRight: 16,
          }}
        >
          <Calendar
            value={date}
            onSelect={setDate}
            onChange={setDate}
            dateCellRender={(date) => (
              <HandoverTags isCheckOut={isCheckOut} date={date} />
            )}
          />
        </div>
        <div
          style={{
            width: "50%",
            height: "100%",
            padding: "16px 16px 0px 0px",
            overflow: "auto",
          }}
        >
          {!histories || !histories.length ? (
            <List loading={!!loading} />
          ) : (
            <Collapse ghost defaultActiveKey={[histories[0]?.id]}>
              {histories.map((history) => {
                const { operator, createDate, lines } = history.data();

                return (
                  <Collapse.Panel
                    key={history.id}
                    header={
                      <span>
                        {operator?.name} <DateDisplay {...createDate} />
                      </span>
                    }
                  >
                    <div className="flex flex-row flex-center">
                      <ReceiptView lines={lines} printing />
                    </div>
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          )}
          <br />
          <br />
          <br />
        </div>
      </div>
    </Drawer>
  );
};
