import { MailOutlined } from "@ant-design/icons";
import { useSetSendingEmailId } from "@easybiz/context";
import { useAdminInvoiceSend } from "@easybiz/request";
import { Button, Spin } from "antd";
import { Suspense, lazy } from "react";
import { FormattedMessage } from "react-intl";

const InvoicePDFBlobProvider = lazy(() => import(`@easybiz/web-component/src/InvoicePDF/InvoicePDFBlobProvider`));

export default function SendInvoiceButton({ sendTo, invoice, hideDetails }) {
  const setEmailId = useSetSendingEmailId();
  const { onSubmit, loading: submitting } = useAdminInvoiceSend((success, response) => {
    if (response?.emailId) {
      setEmailId(response.emailId);
    }
  });

  const onSend = async (pdfBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = function () {
      onSubmit({
        sendTo,
        invoiceId: invoice.id,
        pdfBase64: reader.result,
      });
    };
  };

  return (
    <Suspense fallback={<Spin />}>
      <InvoicePDFBlobProvider invoice={invoice} hideDetails={hideDetails}>
        {({ blob, loading }) => (
          <>
            <Button icon={<MailOutlined />} loading={loading || submitting} onClick={() => onSend(blob)} size="large">
              <FormattedMessage tagName="span" defaultMessage="Send email" />
            </Button>
          </>
        )}
      </InvoicePDFBlobProvider>
    </Suspense>
  );
}
