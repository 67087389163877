import { useOrderCollectionPanel } from "@easybiz/component";
import { JOB_TYPE_DELIVERY } from "@easybiz/utils";
import { Card, Col, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import CollectionScheduleButton from "../CollectionScheduleButton";
import JobScheduleButton from "../JobScheduleButton";
import OrderDischargePanel from "../OrderDischargePanel";

export default ({ order, placement, permissions }) => {
  const { title, subTitle } = useOrderCollectionPanel(order);

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Card>
          <Card.Meta title={title} description={subTitle} />
        </Card>
      </Col>
      <Col span={12}>
        <JobScheduleButton order={order} scheduleType={JOB_TYPE_DELIVERY} disabled={!permissions?.canDischarge}>
          <FormattedMessage tagName="span" defaultMessage="Home delivery" />
        </JobScheduleButton>
      </Col>
      <Col span={12}>
        <CollectionScheduleButton schedule={order?.get("delivery")} order={order} disabled={!permissions?.canDischarge}>
          <FormattedMessage tagName="span" defaultMessage="Reschedule" />
        </CollectionScheduleButton>
      </Col>
      <Col span={24}>
        <OrderDischargePanel disabled={!permissions?.canDischarge} order={order} placement={placement} enableRedo />
      </Col>
    </Row>
  );
};
