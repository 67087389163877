"use client";

import { useMemo } from "react";
import ProcedureDisplay from "./ProcedureDisplay";
import DateDisplay from "./DateDisplay";

export default function useLabelHistoryList(historyDocs) {
  return useMemo(() => {
    let dataSource;

    if (Array.isArray(historyDocs)) {
      dataSource = historyDocs.map((doc) => {
        const { procedure, note, operator, createDate } = doc.data();

        return {
          key: doc.id,
          title: <ProcedureDisplay procedure={procedure} />,
          description: (
            <>
              {operator?.name} | <DateDisplay {...createDate} />
              {note ? ` | ${note}` : ""}
            </>
          ),
        };
      });
    } else if (typeof historyDocs?.get === "function") {
      dataSource = (historyDocs.get("logs") || []).map((log) => {
        const { p, n, o, u } = log;
        return {
          key: `${u}`,
          title: <ProcedureDisplay procedure={p} />,
          description: (
            <>
              {o} | <DateDisplay timestamp={u} />
              {n ? ` | ${n}` : ""}
            </>
          ),
        };
      });
    }

    return { dataSource };
  }, [historyDocs]);
}
