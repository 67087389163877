import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@easybiz/web-admin";
import { Button, Space, Typography } from "antd";
import { FormattedMessage } from "react-intl";

export default function ({ title, extra, onClose, noPadding, iconButton }) {
  return (
    <div className={`flex flex-row flex-align-center flex-space-between${noPadding ? "" : " padding-half"}`}>
      <Typography.Title level={4} style={{ margin: 0, padding: "0px 8px" }}>
        {title}
      </Typography.Title>
      <Space>
        {extra}
        {iconButton ? (
          <IconButton icon={<CloseOutlined />} onClick={onClose} />
        ) : (
          <Button icon={<CloseOutlined />} onClick={onClose}>
            <FormattedMessage tagName={"span"} defaultMessage={"Close"} />
          </Button>
        )}
      </Space>
    </div>
  );
}
