import {
  JOB_STATUS_CANCELLED,
  JOB_STATUS_COMPLETE,
  JOB_STATUS_FAILED,
  JOB_STATUS_PENDING,
  JOB_STATUS_SCHEDULED,
} from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function LogisticStatusDisplay ({ status }) {
  switch (status) {
    case JOB_STATUS_PENDING:
      return <FormattedMessage defaultMessage="Pending" />;
    case JOB_STATUS_SCHEDULED:
      return <FormattedMessage defaultMessage="Scheduled" />;
    case JOB_STATUS_FAILED:
      return <FormattedMessage defaultMessage="Failed" />;
    case JOB_STATUS_COMPLETE:
      return <FormattedMessage defaultMessage="Completed" />;
    case JOB_STATUS_CANCELLED:
      return <FormattedMessage defaultMessage="Cancelled" />;
    default:
      return null;
  }
}
