import { CloseCircleOutlined, DollarOutlined, EditOutlined } from "@ant-design/icons";
import { useSetCheckoutEditOrder } from "@easybiz/checkout";
import { useOrderDetailTabPanel } from "@easybiz/component";
import { useIsDayClosed, useSetDisplayMode, useSetOpenOrderId } from "@easybiz/context";
import {
  ORDER_TAB_COLLECTION,
  ORDER_TAB_HISTORY,
  ORDER_TAB_LABEL,
  ORDER_TAB_LOGISTIC,
  ORDER_TAB_PAYMENT,
  ORDER_TAB_PHOTO,
  ORDER_TAB_PROGRESS,
  ORDER_TAB_RECEIPT,
} from "@easybiz/utils";
import { Alert, Button, Divider, Space, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import OrderCollectionPanel from "../OrderCollectionPanel";
import OrderDetailTabbar from "../OrderDetailTabbar";
import OrderHistoryPanel from "../OrderHistoryPanel";
import OrderLabelPanel from "../OrderLabelPanel";
import OrderLogisticPanel from "../OrderLogisticPanel";
import OrderPaymentPanel from "../OrderPaymentPanel";
import OrderProgressPanel from "../OrderProgressPanel";
import OrderReceiptPanel from "../OrderReceiptPanel";
import PhotoTakingPanel from "../PhotoTakingPanel";

export default function OrderDetailTabPanel({ cashDrawer, initTab, orderId, order, placement, height }) {
  const isDayClosed = useIsDayClosed();
  const { orderTabs, activeTab, setActiveTab, alertTitle, permissions } = useOrderDetailTabPanel(
    order,
    initTab,
    isDayClosed
  );
  const setEditOrder = useSetCheckoutEditOrder();
  const setDisplayMode = useSetDisplayMode();
  const setOpenOrderId = useSetOpenOrderId();

  return (
    <Space direction="vertical" className="full-width">
      {order?.get("voided") ? (
        <Alert
          type="error"
          icon={<CloseCircleOutlined />}
          showIcon
          message={<FormattedMessage defaultMessage="Order has been cancelled" />}
        />
      ) : (
        alertTitle && (
          <Alert
            type="error"
            message={alertTitle}
            showIcon
            icon={<DollarOutlined />}
            {...(permissions.canEdit && {
              action: (
                <Button
                  icon={<EditOutlined />}
                  size="middle"
                  onClick={() => {
                    setEditOrder(order);
                    setDisplayMode(null);
                    setOpenOrderId(null);
                  }}
                >
                  <FormattedMessage tagName={"span"} defaultMessage={"Start edit"} />
                </Button>
              ),
            })}
          />
        )
      )}
      <Tabs
        activeKey={activeTab}
        destroyInactiveTabPane
        onChange={setActiveTab}
        renderTabBar={({ activeKey }) => (
          <OrderDetailTabbar tabs={orderTabs} activeKey={activeKey} onChange={setActiveTab} />
        )}
        items={orderTabs.map(({ key, label }) => {
          let content;

          switch (key) {
            case ORDER_TAB_RECEIPT:
              content = <OrderReceiptPanel orderId={orderId} order={order} permissions={permissions} />;
              break;
            case ORDER_TAB_LABEL:
              content = <OrderLabelPanel order={order} placement={placement} permissions={permissions} />;
              break;
            case ORDER_TAB_PHOTO:
              content = <PhotoTakingPanel order={order} videoWidth={720} permissions={permissions} />;
              break;
            case ORDER_TAB_PAYMENT:
              content = (
                <OrderPaymentPanel
                  cashDrawer={cashDrawer}
                  placement={placement}
                  orderId={orderId}
                  order={order}
                  permissions={permissions}
                />
              );
              break;
            case ORDER_TAB_LOGISTIC:
              content = (
                <OrderLogisticPanel orderId={orderId} order={order} placement={placement} permissions={permissions} />
              );
              break;
            case ORDER_TAB_COLLECTION:
              content = <OrderCollectionPanel order={order} placement={placement} permissions={permissions} />;
              break;
            case ORDER_TAB_PROGRESS:
              content = <OrderProgressPanel orderId={orderId} order={order} permissions={permissions} />;
              break;
            case ORDER_TAB_HISTORY:
              content = <OrderHistoryPanel orderId={orderId} permissions={permissions} />;
              break;
            default:
          }

          return {
            key,
            children: height ? (
              <div
                style={{
                  height: alertTitle ? height - 58 : order?.get("voided") ? height - 48 : height,
                  overflowY: "auto",
                }}
              >
                <Divider>{label}</Divider>
                {content}
              </div>
            ) : (
              <>
                <Divider>{label}</Divider>
                {content}
              </>
            ),
          };
        })}
      />
    </Space>
  );
}
