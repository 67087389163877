export const SETTING_KEY_SYSTEM = "system";
export const SETTING_KEY_CUSTOMER_PROFILE = "customer_profile";
export const SETTING_KEY_HOME_DELIVER = "home_deliver";
export const SETTING_KEY_DISCHARGE = "discharge";
export const SETTING_KEY_ITEM_RECEIPT = "receipt";
export const SETTING_KEY_ITEM_CANCELLATION = "cancellation";
export const SETTING_KEY_ITEM_FAILED_REASONS = "failedreasons";
export const SETTING_KEY_ITEM_REWASH = "rewash";
export const SETTING_KEY_ITEM_REJECT = "reject";
export const SETTING_KEY_PICKUP_ITEM_TYPES = "pickup_item_types";
export const SETTING_KEY_CUSTOMER_SOURCES = "customer_sources";
export const SETTING_KEY_BIRTHDAY_PROMO = "birthday_promo";
export const SETTING_KEY_MINIMUM_ORDER = "minimum_order";
export const SETTING_KEY_SCHEDULE_COLOR = "schedule_color";
export const SETTING_KEY_INTEGRATION = "integration";
export const SETTING_KEY_TOP_UP_CREDIT = "topUpCredit";
export const SETTING_KEY_TOP_UP_RECEIPT = "topUpReceipt";
export const SETTING_KEY_COMMISSION_RATE = "commission_rate";
export const SETTING_KEY_LABEL = "label";
export const SETTING_KEY_TASK = "task";
export const SETTING_KEY_PRODUCTION = "production";
export const SETTING_KEY_ITEM_NOTES = "item_notes";
export const SETTING_KEY_SPEECH_PHOTO = "speechphoto";
export const SETTING_KEY_ABOUT_US = 'about_us';
export const STATUSES_KEY_COLLECTION = "collection";
export const STATUSES_KEY_SUPPLIER = 'supplier';
export const SETTING_KEY_FACTORY_COMMON_NOTES = "factory_notes";
export const SETTING_KEY_MEMBERSHIP = 'membership';
export const SETTING_KEY_ORDER_NO = 'order_no';

export const STATUSES_KEY_TOP_UPS = "top_ups";
export const STATUSES_KEY_SALES_STATISTIC = "sales_statistic";
export const STATUSES_KEY_COUNTER = "counter";
export const STATUSES_KEY_REGISTER = 'register';
export const STATUSES_KEY_JOB_RECORDS = 'LOGISTIC';

export const SETTING_FIELD_NONE = 0;
export const SETTING_FIELD_ASK = 1;
export const SETTING_FIELD_REQUIRE = 2;

export const SETTING_KEY_CUSTOMER_IDS = "customer_ids";
export const SETTING_KEY_REFERRAL = "referral";
export const SETTING_KEY_SMS = "sms";
export const SETTING_KEY_UNIT_LABELS = "unie_labels";
export const SETTING_KEY_FACTORY_DISPLAY_CATEGORY = "factory_display_category";
export const SETTING_KEY_FACTORY_IMPORTS = "factory_imports";
export const SETTING_KEY_OPERATORS = "oprators";
export const SETTING_KEY_STORAGES = 'storages';
export const SETTING_KEY_PRIVACY_POLICY = "privacy_policy";
export const SETTING_KEY_TERMS_AND_CONDITION = "T&C";
export const SETTING_KEY_APP_TERMS = "app_terms";
export const SETTING_KEY_FAQ = "faq";

export const STATUSES_KEY_POPULAR_TIMES = "popular_times";
export const STATUSES_KEY_ACTIVE_CSUTOMERS = "active_customers";
export const STATUSES_KEY_MEMBERS = "members";
export const STATUSES_KEY_POINTS = "points";
export const STATUSES_KEY_PAYMENTS = "payments";
export const STATUSES_KEY_REFUNDS = "refunds";
export const STATUSES_KEY_CONVERSATIONS = "conversations";
export const STATUSES_KEY_CONVERSATION_COUNTS = "conversation_counts";
export const STATUSES_KEY_CHAT_UNREAD = "chat_unread";
export const STATUSES_KEY_REFERRALS = "referrals";
export const STATUSES_KEY_SALES_RETURNS = "sales_returns";
export const STATUSES_KEY_CANCELLATIONS = "cancellations";
export const STATUSES_KEY_CANCELLATIONS_BREAKDOWNS = 'cancellation_line_items_breakdowns';
export const STATUSES_KEY_CUSTOMER_SPENDINGS = "customer_spendings";
export const STATUSES_KEY_ORDER_CONSOLIDATION = "order_consolidation";
export const STATUSES_KEY_ORDER_CONSOLIDATION_BREAKDOWNS = 'order_consolidation_breakdowns';
export const STATUSES_KEY_DIFF_DAY_CONSOLIDATION = "diffday_consolidation";
export const STATUSES_KEY_DIFF_DAY_CONSOLIDATION_BREAKDOWNS = 'diffday_consolidation_breakdowns';
export const STATUSES_KEY_PENDING_CONFIRMATION = "pending_confirmation";
export const STATUSES_KEY_SALES_BREAKDOWNS = "sales_breakdowns";
export const STATUSES_KEY_CHANNEL_STATISTIC = "channel_statistic";
export const STATUSES_KEY_STAFF_HOURS = "staff_hours";
export const STATUSES_KEY_NOTIFICATION = "notification";
export const STATUSES_KEY_TRIP_RECORDS = "LOGISTIC";
export const STATUSES_KEY_LOGISTIC = "logistic";

export const POPULAR_TIME_ORDER = "order";
export const POPULAR_TIME_PICKUP = "pickup";
export const POPULAR_TIME_DELIVER = "deliver";
export const POPULAR_TIME_COLLECT = "collect";

export const BACKGROUND_TASK_KEY = 'Background-tracking';

export const STORAGE_KEY_REALM_ID = 'realmId';
export const STORAGE_KEY_ADMIN_ID = 'driverId';