export * from "./admin.js";
export * from "./app.js";
export * from "./billing.js";
export * from "./booking.js";
export * from "./business.js";
export * from "./chat.js";
export * from "./cronjobs.js";
export * from "./customer.js";
export * from "./email.js";
export * from "./emoji.js";
export * from "./errorcodes.js";
export * from "./event.js";
export * from "./factory.js";
export * from "./ftp.js";
export * from "./label.js";
export * from "./logistic.js";
export * from "./member.js";
export * from "./notification.js";
export * from "./order.js";
export * from "./page.js";
export * from "./paths.js";
export * from "./payment.js";
export * from "./permission.js";
export * from "./platform.js";
export * from "./pos.js";
export * from "./preview.js";
export * from "./printing.js";
export * from "./procedure.js";
export * from "./recurring.js";
export * from "./routes.js";
export * from "./schedule.js";
export * from "./service.js";
export * from "./settings.js";
export * from "./transaction.js";
export * from "./unit.js";

export const COLOR_PENDING = "#FFE0B2";
export const COLOR_READY = "#B3E5FC";
export const COLOR_COMPLETE = "#C8E6C9";

export const BUSINESS_ID_FACTORY = "FACTORY";
export const BUSINESS_ID_MEMBER_CLUB = "MEMBER";
export const BUSINESS_ID_ADVANCE_CREDIT = "CREDIT";

export const OPERATION_STATUS_PENDING = "pending";
export const OPERATION_STATUS_FAILED = "failed";
export const OPERATION_STATUS_SUCCEED = "succeed";

export const VERIFY_TYPE_SMS = "sms";
export const VERIFY_TYPE_EMAIL = "email";

export const SMS_STATUS_SENT = "sent";
export const SMS_STATUS_ACCEPTED = "accepted";
export const SMS_STATUS_DELIVERED = "delivered";
export const SMS_STATUS_FAILED = "failed";

// Signature
export const SIGNATURE_TYPE_PLACEMENT = "placement";
export const SIGNATURE_TYPE_COLLECTION = "collection";

// Operation type
export const OPERATION_GROUP_PLATFORM = "platform";
export const OPERATION_GROUP_SUBSCRIPTION = "subscription";
export const OPERATION_GROUP_CUSTOMER = "customer";
export const OPERATION_GROUP_ORDER = "order";
export const OPERATION_GROUP_JOB = "job";
export const OPERATION_GROUP_ADMIN = "admin";
export const OPERATION_GROUP_POS = "pos";
export const OPERATION_GROUP_CHAT = "chat";

// Language
export const LANGUAGE_EN = "en";
export const LANGUAGE_ZH_CN = "zh-CN";

export const SYSTEM_LANGUAGES = [
  { code: LANGUAGE_EN, title: `English` },
  { code: LANGUAGE_ZH_CN, title: `简体中文` },
];

// Product
export const PRODUCT_DETAIL_TYPE_IMAGE = "image";
export const PRODUCT_DETAIL_TYPE_VIDEO = "video";
export const PRODUCT_DETAIL_TYPE_TITLE = "title";
export const PRODUCT_DETAIL_TYPE_PARAGRAPH = "paragraph";

// Report
export const REPORT_INTERVAL_DAILY = "daily";
export const REPORT_INTERVAL_WEEKLY = "weekly";
export const REPORT_INTERVAL_MONTHLY = "monthly";

// Receipt
export const RECEIPT_TYPE_CUSTOMER = "customer";
export const RECEIPT_TYPE_INTERNAL = "internal";
export const RECEIPT_TYPE_COLLECTION = "collection";
export const RECEIPT_TYPE_PICKUP = "pickup";
export const RECEIPT_TYPE_TOP_UP = "top_up";

export const ORDER_SOURCE_POS = "pos";
export const ORDER_SOURCE_WEBSITE = "website";
export const ORDER_SOURCE_WEBAPP = "webapp";
export const ORDER_SOURCE_IOS = "ios";
export const ORDER_SOURCE_FACTORY = "factory";
export const ORDER_SOURCE_ANDROID = "android";
export const ORDER_SOURCE_RECURRING = "recurring";
export const ORDER_SOURCE_ADMIN = "admin";

export const ACCOUNT_ADMIN = "REALM_ADMIN";
export const ACCOUNT_POS = "COUNTER_STAFF";

export const HANGER_RECORD_TYPE_RETURN = "RETURN";
export const HANGER_RECORD_TYPE_SEND = "SEND";

export const REPORT_TYPE_SALES_REPORT = "sales report";
export const REPORT_TYPE_TOP_UP_REPORT = "top up report";
export const REPORT_TYPE_OUTSTANDING_REPORT = "outstanding report";

// Guide
export const GUIDE_TYPE_OPEN_APP = "open_app";
export const GUIDE_TYPE_OPEN_POS = "open_pos";
export const GUIDE_TYPE_DOWNLOAD_EB = "download_eb";

// Case report
export const REPORT_STATUS_PENDING = "pending";
export const REPORT_STATUS_OPEN = "open";
export const REPORT_STATUS_CLOSED = "closed";

// Adjustment
export const ADJUSTMENT_MODE_SURCHARGE = "surcharge";
export const ADJUSTMENT_MODE_DISCOUNT = "discount";

// Promotion
export const PROMOTION_ID_BIRTHDAY = "birthday";
export const PROMOTION_ID_PUBLIC = "public";

export const PROMO_TYPE_PERCENT = "percentage";
export const PROMO_TYPE_FLAT = "flat";
export const PROMO_TYPE_FOC = "foc";
export const PROMO_TYPE_POINTS = "points";
export const PROMO_TYPE_CREDIT_BACK = "credits";

// Checkout
export const ITEM_STEP_SERVICE_TYPES = "service_types";
export const ITEM_STEP_COLLECTION_DATE = "collection_date";
export const ITEM_STEP_NOTE = "note";
export const ITEM_STEP_ACCESSORY = "accessory";
export const ITEM_STEP_PHOTOS = "photo";

// Pricing
export const PRICING_MODE_STANDARD = "standard";
export const PRICING_MODE_PACKAGE = "package";
export const PRICING_MODE_FLEXIBLE = "flexible";

// Article
export const ARTICLE_TYPE_IMAGE = "image";
export const ARTICLE_TYPE_VIDEO = "video";
export const ARTICLE_TYPE_TITLE = "title";
export const ARTICLE_TYPE_PARAGRAPH = "paragraph";

// Voucher
export const VOUCHER_STATUS_VALID = "valid";
export const VOUCHER_STATUS_USED = "used";
export const VOUCHER_STATUS_CANCELLED = "cancelled";

export const BATCH_VOUCHER_TYPE_SEQUENCE = "sequence";
export const BATCH_VOUCHER_TYPE_CODED = "coded";

// Cash Drawer
export const DRAWER_TRANSACTION_TYPE_OPENING = "opening";
export const DRAWER_TRANSACTION_TYPE_PAYMENT = "deposit";
export const DRAWER_TRANSACTION_TYPE_REFUND = "withdraw";
export const DRAWER_TRANSACTION_TYPE_CASH_IN = "cash_in";
export const DRAWER_TRANSACTION_TYPE_CASH_OUT = "cash_out";

// SMS
export const SMS_AGNET_VONAGE = "VONAGE";
export const SMS_AGNET_TWILLO = "TWILLO";
export const SMS_AGNET_MACROKIOSK = "MACROKIOSK";

export const SMS_AGENTS = [SMS_AGNET_VONAGE, SMS_AGNET_MACROKIOSK];
