import { BorderOutlined, CheckSquareOutlined, DollarOutlined, TagOutlined } from "@ant-design/icons";
import { UnitDisplay } from "@easybiz/component";
import { useOrderAvailableReturnItems } from "@easybiz/hook";
import { isDecimalUnit, processOrderPricing } from "@easybiz/utils";
import { Avatar, Button, Empty, List, message, Popover, Space, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../IconButton";
import OrderPhotoGallery from "../OrderPhotoGallery";
import PricingOverviewTypeItem from "../PricingOverviewTypeItem";
import QuantityUpdateBar from "../QuantityUpdateBar";
import Title from "../Title";

export default ({ order, selects, onCreditNote }) => {
  const availableItems = useOrderAvailableReturnItems(order, selects);
  const [items, setItems] = useState(availableItems);
  const intl = useIntl();

  const pricing = useMemo(() => {
    return processOrderPricing({
      ...order?.data(),
      items,
      creditNotes: null,
      payments: null,
      minimumOrder: null,
    });
  }, [order, items]);

  return (
    <Space direction="vertical" className="full-width" size={"large"}>
      <List
        itemLayout="vertical"
        header={
          <Title>
            <FormattedMessage defaultMessage={"Select return items"} />
          </Title>
        }
        dataSource={pricing?.items}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <FormattedMessage defaultMessage={"Order do not have line items available for sales return"} />
              }
            />
          ),
        }}
        loading={!order}
        renderItem={(item, index) => {
          const { qty, unit, totalQty, labelIndexes, returnLabels } = item;

          return (
            <PricingOverviewTypeItem
              item={item}
              type={qty ? "danger" : null}
              extra={
                <Typography.Text type={qty ? "danger" : null} style={{ fontSize: 16, whiteSpace: "nowrap" }}>
                  <FormattedMessage defaultMessage={"Return"} />
                </Typography.Text>
              }
              actions={[
                <Space>
                  <QuantityUpdateBar
                    title={<FormattedMessage defaultMessage={"Retrun Quantity"} />}
                    decimal={isDecimalUnit(unit)}
                    valueType={qty ? "danger" : null}
                    minValue={0}
                    maxValue={totalQty}
                    value={qty || 0}
                    suffix={
                      <span>
                        /{totalQty}
                        <UnitDisplay unit={unit} />
                      </span>
                    }
                    onChange={(qty) => {
                      setItems([...items.slice(0, index), { ...item, qty }, ...items.slice(index + 1)]);
                    }}
                  />
                  {Array.isArray(labelIndexes) ? (
                    <Popover
                      trigger={"click"}
                      placement="right"
                      title={
                        <Title>
                          <FormattedMessage defaultMessage={"Mark label as sales return"} />
                        </Title>
                      }
                      content={
                        <List
                          style={{
                            width: 300,
                            maxHeight: 400,
                            overflowY: "auto",
                          }}
                          dataSource={labelIndexes}
                          renderItem={(sortIndex) => {
                            const selectIndex = Array.isArray(returnLabels) ? returnLabels.indexOf(sortIndex) : -1;
                            const selected = selectIndex >= 0;

                            return (
                              <List.Item
                                extra={
                                  <IconButton
                                    type={selected ? "primary" : "text"}
                                    icon={selected ? <CheckSquareOutlined /> : <BorderOutlined />}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      if (selected) {
                                        setItems([
                                          ...items.slice(0, index),
                                          {
                                            ...item,
                                            returnLabels: [
                                              ...returnLabels.slice(0, selectIndex),
                                              ...returnLabels.slice(selectIndex + 1),
                                            ],
                                          },
                                          ...items.slice(index + 1),
                                        ]);
                                      } else {
                                        setItems([
                                          ...items.slice(0, index),
                                          {
                                            ...item,
                                            returnLabels: [...(returnLabels || []), sortIndex],
                                          },
                                          ...items.slice(index + 1),
                                        ]);
                                      }
                                    }}
                                  />
                                }
                              >
                                <List.Item.Meta
                                  avatar={<Avatar>{`#${sortIndex}`}</Avatar>}
                                  title={<OrderPhotoGallery itemIndex={sortIndex} order={order} limit={3} size={40} />}
                                />
                              </List.Item>
                            );
                          }}
                        />
                      }
                    >
                      <Button icon={<TagOutlined />} type={returnLabels?.length ? "link" : "text"}>
                        <FormattedMessage tagName={"span"} defaultMessage={"Mark labels"} />
                        {returnLabels?.length ? ` (${returnLabels.length})` : null}
                      </Button>
                    </Popover>
                  ) : null}
                </Space>,
              ]}
            />
          );
        }}
      />
      <Button
        block
        type="primary"
        icon={<DollarOutlined />}
        onClick={() => {
          if (!items?.find((item) => item.qty)) {
            return message.info(
              intl.formatMessage({
                defaultMessage: "Please select return items",
              })
            );
          }

          onCreditNote({
            lineTotal: pricing.netSales,
            pricing: processOrderPricing({
              ...order?.data(),
              items: items.filter((item) => item.qty > 0).map(({ qty, ...item }) => ({ ...item, qty, returnQty: qty })),
              creditNotes: null,
              payments: null,
              minimumOrder: null,
            }),
          });
        }}
      >
        <FormattedMessage tagName={"span"} defaultMessage="Preview return amount" />
      </Button>
    </Space>
  );
};
