import { CODE_DIVIDER, CODE_TYPE_PICKUP_BAG } from "./constants/index.js";

export default function (bagNo, settings) {
  const { offsetVertical, density } = settings || {};

  return `
    SIZE 60 mm, 40mm
    GAP 0 mm, 0 mm
    SPEED 3
    DENSITY ${density || 7}
    SHIFT ${typeof offsetVertical === "number" ? offsetVertical : 0}
    CLS
    QRCODE 10,80,L,9,M,0,1,1,"${CODE_TYPE_PICKUP_BAG}${CODE_DIVIDER}${`${bagNo}`.trim()}"
    TEXT 230,80,"4",0,1,1,"Bag"
    TEXT 230,130,"5",0,2,2,"${`${bagNo}`.trim()}"
    PRINT 1,1
    CLS
  `;
}
