"use client";

import { CATEGORY_OTHERS, formatPrice, translate, UNIT_SQFT } from "@easybiz/utils";
import { useMemo } from "react";

function convertOptions(options, serviceTypes, item, flatMode) {
  return (options || [])
    .map(({ id, code, title, prices }) => {
      const optionPrices = (prices || [])
        .filter((a, index) => prices.findIndex((b) => JSON.stringify(a) === JSON.stringify(b)) === index)
        .map(({ price, subType }) => {
          if (item.subTypeId ? item.subTypeId === subType?.id : flatMode ? !subType : true) {
            const { id: subTypeId, name: subTypeName, ...subTypeFields } = subType || {};
            const serviceType = serviceTypes?.find((serviceType) => serviceType.id === id);

            return {
              ...item,
              ...subTypeFields,
              unitPrice: price,
              optionId: id,
              option: translate(title),
              ...(typeof serviceType?.minimumOrder === "number" && { minimumOrder: serviceType?.minimumOrder }),
              ...(code && { optionCode: code }),
              ...(subTypeId && {
                subTypeId,
                subType: translate(subTypeName),
              }),
            };
          }
        })
        .filter((price) => price);

      if (optionPrices.length > 0) {
        return {
          key: id,
          title: translate(title),
          prices: optionPrices,
        };
      }
    })
    .filter((option) => option)
    .sort(
      (a, b) =>
        (serviceTypes || []).findIndex((data) => data.id === a.optionId) -
        (serviceTypes || []).findIndex((data) => data.id === b.optionId)
    );
}

function convertToStackItemFormat(products, serviceTypes, flatMode) {
  return [].concat.apply(
    [],
    (products || []).map(({ id, name, options, ...productFields }) => {
      let subTypes = [];

      if (flatMode) {
        options?.forEach(({ prices }) => {
          prices?.forEach(({ subType }) => {
            if (subType && !subTypes.find((added) => added.id === subType.id)) {
              subTypes.push(subType);
            }
          });
        });
      }

      const product = {
        ...productFields,
        productId: id,
        product: translate(name),
      };

      return [
        {
          key: id,
          title: translate(name),
          ...(productFields.photoId && { photoId: productFields.photoId }),
          options: convertOptions(options, serviceTypes, product, flatMode),
        },
        ...subTypes.map(({ id: subTypeId, name: subType, ...subTypeFields }) => {
          return {
            key: `${id}${subTypeId}`,
            title: `${translate(name)} - ${translate(subType)}`,
            ...(subTypeFields.photoId && { photoId: subTypeFields.photoId }),
            options: convertOptions(options, serviceTypes, {
              ...product,
              ...subTypeFields,
              subTypeId,
              subType: translate(subType),
            }),
          };
        }),
      ];
    })
  );
}

function isSpecialItem({ unit, note, addons, accessories }) {
  return (
    unit === UNIT_SQFT ||
    Boolean(note) ||
    (Array.isArray(addons) && addons.length > 0) ||
    (Array.isArray(accessories) && accessories.length > 0)
  );
}

function addSelectedItems(products, item, itemIndex, addAll, categoryId, photos) {
  let productIndex = products.findIndex(({ key }) => {
    return key === `${item.productId}${item.subTypeId || ""}`;
  });

  if (productIndex < 0) {
    productIndex = products.findIndex(({ key }) => {
      return key === item.productId;
    });
  }

  let itemPhotos;

  if (Array.isArray(photos) && Array.isArray(item.labelIndexes)) {
    let iPhotos = photos.filter((photo) => item.labelIndexes.indexOf(photo.l) >= 0);
    if (iPhotos.length > 0) {
      itemPhotos = iPhotos;
    }
  }

  if (productIndex >= 0) {
    const optionIndex = products[productIndex].options.findIndex(({ key }) => item.optionId === key);

    if (optionIndex >= 0) {
      const priceIndex = products[productIndex].options[optionIndex].prices.findIndex(
        ({ unitPrice, itemIndex, subTypeId }) =>
          (subTypeId || null) === (item.subTypeId || null) &&
          !Number.isInteger(itemIndex) &&
          item.unitPrice === unitPrice &&
          !isSpecialItem(item)
      );

      if (priceIndex >= 0) {
        // All matched, add select index to product index
        products[productIndex].options[optionIndex].prices[priceIndex] = {
          ...item,
          ...(itemPhotos && { photos: itemPhotos }),
          itemIndex,
        };
      } else {
        products[productIndex].options[optionIndex].prices.unshift({
          ...item,
          ...(itemPhotos && { photos: itemPhotos }),
          itemIndex,
        });
      }
    } else {
      products[productIndex].options.unshift({
        key: item.optionId,
        title: translate(item.option),
        prices: [{ ...item, ...(itemPhotos && { photos: itemPhotos }), itemIndex }],
      });
    }

    products[productIndex].count = formatPrice((products[productIndex].count || 0) + item.qty);

    return true;
  } else if (addAll || categoryId === item.categoryId) {
    // Add to top
    products.unshift({
      key: `${item.productId}${item.subTypeId || ""}`,
      count: item.qty,
      title: `${translate(item.product)}${item.subType ? ` - ${translate(item.subType)}` : ""}`,
      ...(item.other && { other: item.other }),
      ...(item.photoId && { photoId: item.photoId }),
      options: [
        {
          key: item.optionId,
          title: translate(item.option),
          prices: [
            {
              ...item,
              ...(item.option && { option: translate(item.option) }),
              ...(item.product && { product: translate(item.product) }),
              ...(itemPhotos && { photos: itemPhotos }),
              itemIndex,
            },
          ],
        },
      ],
    });

    return true;
  }
}

export default function useProductOptionsWithSelections(pricingData, items, flatMode, photos) {
  return useMemo(() => {
    // serviceTypes only for sorting
    let { products, categories, serviceTypes } = pricingData || {};

    if (Array.isArray(categories)) {
      let formattedCategories = [];

      categories.forEach((category) => {
        let formattedProducts = convertToStackItemFormat(category.products, serviceTypes, flatMode);

        formattedCategories.push({
          key: category.id,
          title: category.title,
          products: formattedProducts,
        });
      });

      if (Array.isArray(items) && items.length > 0) {
        let otherProducts = [];

        for (let index = 0; index < items.length; index++) {
          let added;

          for (const category of formattedCategories) {
            added = addSelectedItems(category.products, items[index], index, false, category.key, photos);
            if (added) {
              break;
            }
          }

          if (!added) {
            addSelectedItems(otherProducts, items[index], index, true, null, photos);
          }
        }

        if (otherProducts.length > 0) {
          formattedCategories.push({
            key: CATEGORY_OTHERS,
            products: otherProducts,
          });
        }
      }

      return {
        categories: formattedCategories,
        serviceTypes,
      };
    } else if (Array.isArray(products)) {
      let formattedProducts = convertToStackItemFormat(products, serviceTypes, flatMode);

      if (Array.isArray(items) && items.length > 0) {
        items.forEach((item, index) => {
          addSelectedItems(formattedProducts, item, index, true, null, photos);
        });
      }

      return {
        products: formattedProducts,
        serviceTypes,
      };
    } else {
      return {};
    }
  }, [pricingData, items, flatMode]);
}
