import { message, Popover, Space } from "antd";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import NumericUpdatePanel from "../NumericUpdatePanel";
import Title from "../Title";

export default ({
  placement,
  decimal,
  isCurrency,
  title,
  value,
  onDone,
  children,
  prefix,
  suffix,
  buttonText,
  buttonType,
  open,
  onOpenChange,
  extra,
  removeable,
  maxValue,
}) => {
  const intl = useIntl();
  useEffect(() => {}, []);

  return (
    <Popover
      destroyTooltipOnHide
      placement={placement}
      title={<Title>{title}</Title>}
      overlayStyle={{ width: 300 }}
      content={
        <Space direction="vertical" className="full-width">
          {extra}
          <NumericUpdatePanel
            buttonText={buttonText}
            buttonType={buttonType}
            decimal={decimal}
            isCurrency={isCurrency}
            value={value}
            prefix={prefix}
            suffix={suffix}
            onDone={(value) => {
              if (typeof maxValue === "number" && value > maxValue) {
                return message.info(intl.formatMessage({ defaultMessage: "Maximum {amount}" }, { amount: maxValue }));
              }

              onDone(typeof value === "number" ? value : 0);
            }}
            removeable={removeable}
          />
        </Space>
      }
      trigger="click"
      open={open}
      onOpenChange={onOpenChange}
    >
      {children}
    </Popover>
  );
};
