export const TRANSACTION_TYPE_TOP_UP = "top up";
export const TRANSACTION_TYPE_GIFT = "gift";
export const TRANSACTION_TYPE_CASH_BACK = 'cash_back';
export const TRANSACTION_TYPE_BONUS = 'bonus';
export const TRANSACTION_TYPE_REWARD = "reward";
export const TRANSACTION_TYPE_COMPENSATION = "compensation";
export const TRANSACTION_TYPE_SPEND = "spend";
export const TRANSACTION_TYPE_REFUND = "refund";
export const TRANSACTION_TYPE_VOID = "void";
export const TRANSACTION_TYPE_REDEEM = "redeem";

export const CREDIT_OPTION_CODE_GIFT = "GIFT";

export const TRANSACTION_TYPES = [
  TRANSACTION_TYPE_TOP_UP,
  TRANSACTION_TYPE_GIFT,
  TRANSACTION_TYPE_REWARD,
  TRANSACTION_TYPE_COMPENSATION,
  TRANSACTION_TYPE_SPEND,
];
