import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (realmId) => {
  const [realmDoc, setRealmDoc] = useState(null);

  // Realm doc
  useEffect(() => {
    setRealmDoc(null);
    if (realmId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}`), setRealmDoc);
    }
  }, [realmId]);

  return realmDoc;
};
