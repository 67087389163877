import {
  JOB_STATUS_CANCELLED,
  JOB_STATUS_COMPLETE,
  JOB_STATUS_FAILED,
  JOB_STATUS_PENDING,
  JOB_STATUS_SCHEDULED,
} from "./constants/index.js";

export default (status) => {
  switch (status) {
    case JOB_STATUS_PENDING:
      return `#03A9F4`;
    case JOB_STATUS_SCHEDULED:
      return `#FB8C00`;
    case JOB_STATUS_FAILED:
      return `#F44336`;
    case JOB_STATUS_COMPLETE:
      return `#4CAF50`;
    case JOB_STATUS_CANCELLED:
      return `#9E9E9E`;
    default:
      return null;
  }
};
