import { QrcodeOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { FormattedMessage } from "react-intl";
import PackageStorageTag from "../PackageStorageTag";

export default ({ order, tagOnly }) => {
  const { packs, stores } = order?.data() || {};
  const uniqueStores = Array.isArray(stores) ? stores.filter((store, index) => stores.indexOf(store) === index) : [];
  const storeTags = uniqueStores.map((store) => {
    const storePacks = stores.filter((packStore) => packStore === store);
    return <PackageStorageTag store={store} total={storePacks.length} />;
  });

  return Array.isArray(packs) ? (
    tagOnly ? (
      storeTags
    ) : (
      <Space>
        <QrcodeOutlined />
        <FormattedMessage
          tagName="b"
          defaultMessage="Total {total} packages, located at: {storages}"
          values={{
            total: packs.length,
            storages: (
              <Space size="small" wrap>
                {storeTags}
              </Space>
            ),
          }}
        />
      </Space>
    )
  ) : null;
};
