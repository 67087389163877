import { CLIENT_CONSOLE, CLIENT_MOBILE, CLIENT_POS } from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ client, short }) {
  switch (client) {
    case CLIENT_POS:
      if (short) {
        return <FormattedMessage tagName="span" defaultMessage="POS" />;
      } else {
        return <FormattedMessage tagName="span" defaultMessage="EasyBiz Cloud POS" />;
      }
    case CLIENT_MOBILE:
      return <FormattedMessage tagName="span" defaultMessage="EasyBiz App" />;
    case CLIENT_CONSOLE:
      return <FormattedMessage tagName="span" defaultMessage="EasyBiz Console" />;
    default:
      return null;
  }
}
