import { useToast } from "@easybiz/context";
import { CUSTOMER_OPERATION_ADDRESS_EDIT, OPERATION_GROUP_CUSTOMER } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();
  const toast = useToast();

  const onSubmit = useCallback(({ customerId, address, addressId, deleted, isDefault }, requireLine2) => {
    if (!address && !deleted)
      return intl.formatMessage({
        defaultMessage: `Please search and select address`,
      });
    if (requireLine2 && !address.unitNo)
      return intl.formatMessage({ defaultMessage: "Please enter your address line 2" });

    return [
      OPERATION_GROUP_CUSTOMER,
      CUSTOMER_OPERATION_ADDRESS_EDIT,
      {
        customerId,
        ...(addressId && { addressId }),
        ...(address && { address }),
        ...(deleted && { deleted: true }),
        ...(isDefault && { isDefault: true }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, (request) => {
    if (request.deleted) {
      toast.success(intl.formatMessage({ defaultMessage: "Address deleted" }));
    } else if (request.addressId) {
      toast.success(intl.formatMessage({ defaultMessage: "Address updated" }));
    } else {
      toast.success(intl.formatMessage({ defaultMessage: "Address added" }));
    }
  });
};
