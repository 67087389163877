import { AmountDisplay, DateDisplay, NameDisplay } from "@easybiz/component";
import { formatPrice } from "@easybiz/utils";
import { Collapse, Empty, List, Typography } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default ({ sales, date }) => {
  const [activeKey, setActiveKey] = useState(Array.isArray(sales) ? sales[0]?.svc : null);

  return (
    <div className="padding-top">
      {Array.isArray(sales) ? (
        <Collapse activeKey={activeKey} onChange={setActiveKey} size="middle">
          {(sales || []).map(({ svc, amt, returned, orders }) => {
            return (
              <Collapse.Panel
                key={svc}
                header={
                  <b>
                    <NameDisplay nameKey={svc} />: <AmountDisplay value={formatPrice(amt - (returned || 0), true)} />
                  </b>
                }
              >
                <List
                  size="small"
                  dataSource={orders || []}
                  renderItem={(order) => {
                    const { no, top, amt, time } = order;

                    return (
                      <List.Item
                        extra={
                          <Typography.Text type={amt < 0 ? "danger" : "success"}>
                            <AmountDisplay value={Math.abs(amt)} />
                          </Typography.Text>
                        }
                      >
                        <List.Item.Meta
                          title={top ? <FormattedMessage defaultMessage="Top up" /> : `#${no}`}
                          description={<DateDisplay date={order.date || date} time={time} />}
                        />
                      </List.Item>
                    );
                  }}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      ) : (
        <Empty
          description={
            <FormattedMessage
              defaultMessage={"No sales on {date}"}
              values={{
                date: <DateDisplay date={date} />,
              }}
            />
          }
        />
      )}
    </div>
  );
};
