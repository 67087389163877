import { useRealmId } from "@easybiz/context";
import { BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE, formatLineItemStep } from "@easybiz/utils";
import { collection, getFirestore, onSnapshot, orderBy, query } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (service, formatData) => {
  const realmId = useRealmId();
  const [options, setOptions] = useState(null);

  useEffect(() => {
    setOptions(null);

    if (realmId && service && [BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_ALTERATION].indexOf(service.sector) >= 0) {
      return onSnapshot(
        query(collection(getFirestore(), `realms/${realmId}/services/${service.id}/order_steps`), orderBy("sortIndex")),
        (result) => setOptions(formatData ? result?.docs.map(formatLineItemStep) : result?.docs)
      );
    }
  }, [realmId, service?.id, service?.sector, formatData]);

  return options;
};
