import { PROMO_TYPE_FLAT, PROMO_TYPE_PERCENT } from "@easybiz/utils";
import { Radio } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function FlatPercentRadioGroup({ value, onChange }) {
  return (
    <Radio.Group
      value={value || PROMO_TYPE_PERCENT}
      buttonStyle="solid"
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      <Radio.Button value={PROMO_TYPE_PERCENT}>
        <FormattedMessage defaultMessage="By percentage" />
      </Radio.Button>
      <Radio.Button value={PROMO_TYPE_FLAT}>
        <FormattedMessage defaultMessage="Flat amount" />
      </Radio.Button>
    </Radio.Group>
  );
}
