import { getDeviceInfo } from "@easybiz/web-component";
import { Alert } from "antd";
import { FormattedMessage } from "react-intl";

export default function () {
  return (
    <Alert
      banner
      type="info"
      message={<FormattedMessage defaultMessage="Printer driver installation required on Windows" />}
      description={
        <FormattedMessage
          defaultMessage="It looks like you are using <b>{windowsVersion}</b>, please follow <a>this guide</a> to install the printer driver first. <b>(Install driver is not required on Android/MacOS/ChromeOS)</b>"
          values={{
            windowsVersion: `${getDeviceInfo().deviceName}`,
            b: (t) => <b>{t}</b>,
            a: (t) => (
              <a
                href="https://intercom.help/easy-bus/en/articles/5641445-setup-gp1824tc-printer-label-printer"
                rel="noreferrer"
                target="_blank"
              >
                {t}
              </a>
            ),
          }}
        />
      }
    />
  );
}
