"use client";

import { createContext, useContext, useMemo, useState } from "react";

const EmailStateContext = createContext();

export function EmailStateProvider({ children }) {
  const [sendingEmailId, setSendingEmailId] = useState();

  const context = useMemo(() => {
    return {
      sendingEmailId,
      setSendingEmailId,
    };
  }, [sendingEmailId]);

  return <EmailStateContext.Provider value={context}>{children}</EmailStateContext.Provider>;
}

export const useSendingEmailId = () => useContext(EmailStateContext).sendingEmailId;
export const useSetSendingEmailId = () => useContext(EmailStateContext).setSendingEmailId;
