// For backward support only

export default function (value, locale) {
  if (!value) {
    return ``;
  } else if (typeof value === "string") {
    return value;
  } else if (typeof value === "object") {
    return `${value[locale] || value.en || ""}`;
  } else {
    return "";
  }
}
