import { LANGUAGE_EN, LANGUAGE_ZH_CN } from "@easybiz/utils";
import { useMemo, useState } from "react";
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';

export default (CHINESE) => {
  const [cacheLocale, setCacheLocale] = useState();

  return useMemo(() => {
    let locale = cacheLocale;

    if (!locale) {
      if (typeof window !== "undefined") {
        locale = window.localStorage?.getItem("language");
        if (!locale && navigator.language) {
          locale = navigator.language;
        }
      }
    }

    let messages, antdLocale;

    switch (locale) {
      case "zh":
      case "zh-CN":
        locale = LANGUAGE_ZH_CN;
        messages = CHINESE;
        antdLocale = zhCN;
        break;
      default:
        // Default to english
        locale = LANGUAGE_EN;
    }

    return {
      locale,
      antdLocale,
      messages,
      onUpdateLocale: (locale) => {
        window.localStorage.setItem("language", locale);
        setCacheLocale(locale);
      },
    };
  }, [cacheLocale]);
};
