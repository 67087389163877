import { JOB_OPERATION_UPDATE_DETAIL, OPERATION_GROUP_JOB } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ taskId, name, tels, note, photoId }) => {
    return [
      OPERATION_GROUP_JOB,
      JOB_OPERATION_UPDATE_DETAIL,
      {
        taskId,
        ...(name && { name }),
        ...(Array.isArray(tels) && { tels }),
        ...(typeof note === "string" && { note }),
        ...(photoId && { photoId }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Job updated" }));
};
