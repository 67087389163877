import {
  CONTENT_BOLD,
  isValidImageDataUrl,
  LINE_ALIGN_CENTER,
  LINE_TYPE_CUT,
  LINE_TYPE_IMAGE,
  LINE_TYPE_PARAGRAPH,
  LINE_TYPE_QRCODE,
  LINE_TYPE_TERMS,
  LINE_TYPE_TITLE,
  renderContent,
  renderParagraph,
} from "@easybiz/utils";
import * as escPos from "./escPos";

// Await only for image
export default async function (lines, settings, countryCode) {
  let { rowGap, lineCharacter, qrSize, codeTable } = settings || {};

  if (typeof rowGap !== "number") {
    rowGap = 40;
  }

  if (typeof lineCharacter !== "number") {
    lineCharacter = 48; // Default 48 ASIC character
  }

  let commands = [];

  escPos.reset(commands);
  if (Number.isInteger(codeTable)) {
    escPos.codeTable(commands, codeTable);
  }
  escPos.rowGap(commands, rowGap);

  for (const line of lines) {
    const { type, align, content, raw, bold, reverses, larges, reverse } = line;
    escPos.textAlign(commands, align);

    if (reverse) {
      escPos.reverse(commands, true);
    }

    switch (type) {
      case LINE_TYPE_IMAGE: {
        if (isValidImageDataUrl(content)) {
          if (line.header) {
            await escPos.image(commands, content, lineCharacter * 10);
          } else if (line.signature) {
            await escPos.image(commands, content, 200);
          } else {
            await escPos.image(
              commands,
              content,
              typeof line.width === "number" ? line.width : 500
            );
          }
          escPos.newLine(commands);
        }
        break;
      }
      case LINE_TYPE_TITLE: {
        escPos.fontSize(commands, 2);
        escPos.text(
          commands,
          raw
            ? renderContent(
                raw,
                parseInt(lineCharacter / 2 - lineCharacter / 16)
              )
            : content,
          countryCode
        );
        escPos.fontSize(commands, 1);
        escPos.newLine(commands);
        break;
      }
      case LINE_TYPE_PARAGRAPH: {
        escPos.fontSize(commands, 1);

        if (raw) {
          const contents = JSON.parse(raw);

          if (Array.isArray(contents)) {
            let bolded = false;

            for (const content of contents) {
              switch (content) {
                case CONTENT_BOLD:
                  escPos.bold(commands, true);
                  bolded = true;
                  continue;
                default:
                  escPos.text(
                    commands,
                    renderParagraph(
                      content,
                      lineCharacter,
                      align === LINE_ALIGN_CENTER
                    ),
                    countryCode
                  );
              }

              if (bolded) {
                escPos.bold(commands, false);
                bolded = false;
              }
            }
          }
        } else {
          if (content instanceof Array) {
            // TODO: REMOVE
            let index = 0;
            for (const path of content) {
              const lineReverse =
                reverses && reverses.indexOf(index) >= 0 ? true : false;
              const large = larges && larges.indexOf(index) >= 0 ? true : false;
              if (lineReverse) {
                escPos.reverse(commands, true);
              }
              if (large) {
                escPos.fontSize(commands, 2);
              }
              escPos.bold(
                commands,
                bold && bold.indexOf(index) >= 0 ? true : false
              );
              escPos.text(commands, path, countryCode);
              if (large) {
                escPos.fontSize(commands, 1);
              }
              if (lineReverse) {
                escPos.reverse(commands, false);
              }
              index++;
            }
            escPos.bold(commands, false);
          } else {
            if (reverse) {
              escPos.reverse(commands, true);
            }
            if (bold) {
              escPos.bold(commands, true);
            }
            escPos.text(commands, content, countryCode);
            if (bold) {
              escPos.bold(commands, false);
            }
            if (reverse) {
              escPos.reverse(commands, false);
            }
          }
        }

        escPos.newLine(commands);
        break;
      }
      case LINE_TYPE_QRCODE: {
        escPos.qrcode(commands, content, undefined, qrSize);
        escPos.newLine(commands);
        break;
      }
      case LINE_TYPE_CUT: {
        escPos.newLine(commands, 7);
        escPos.cut(commands);
        escPos.newLine(commands);
        break;
      }
      case LINE_TYPE_TERMS: {
        escPos.fontSmall(commands);

        if (raw) {
          const terms = JSON.parse(raw);
          if (Array.isArray(terms)) {
            for (const term of terms) {
              escPos.text(
                commands,
                renderContent(term, parseInt(lineCharacter * 1.33)),
                countryCode
              );
            }
          }
        } else {
          escPos.text(commands, content, countryCode);
        }

        escPos.newLine(commands);
        escPos.reset(commands);
        escPos.newLine(commands);
        break;
      }
    }

    if (reverse) {
      escPos.reverse(commands, false);
    }
  }

  escPos.newLine(commands, 7);
  escPos.cut(commands);

  return escPos.encode(commands);
}
