import {
  ACTION_DELIVER,
  ACTION_PICK_UP,
  ACTION_SCHEDULE_DELIVER,
  ACTION_SCHEDULE_PICKUP,
  ACTION_SERVICE,
} from "./constants/index.js";

export default (action) => {
  return (
    [
      ACTION_SCHEDULE_PICKUP,
      ACTION_SCHEDULE_DELIVER,
      ACTION_SERVICE,
      ACTION_PICK_UP,
      ACTION_DELIVER,
    ].indexOf(action) >= 0
  );
};
