import { useCurrency } from "@easybiz/context";
import {
  BENEFIT_TYPE_CASH_BACK,
  BENEFIT_TYPE_DISCOUNT,
  BENEFIT_TYPE_MONTHLY_FOC,
  BENEFIT_TYPE_POINTS,
} from "@easybiz/utils";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

export default function MemberBenefitDisplay({ promoMethod, value }) {
  const currency = useCurrency();

  switch (promoMethod) {
    case BENEFIT_TYPE_DISCOUNT:
      return <FormattedMessage defaultMessage={`{amount}% direct discount`} values={{ amount: value }} />;
    case BENEFIT_TYPE_CASH_BACK:
      return <FormattedMessage defaultMessage={`{amount}% cash back`} values={{ amount: value }} />;
    case BENEFIT_TYPE_POINTS:
      return <FormattedMessage defaultMessage={`{amount} times points`} values={{ amount: value }} />;
    case BENEFIT_TYPE_MONTHLY_FOC:
      return (
        <FormattedMessage
          defaultMessage={`{amount} monthly free of charge tier`}
          values={{
            amount: <FormattedNumber currency={currency} style="currency" value={value} />,
          }}
        />
      );
    default:
      return null;
  }
}
