import { EditOutlined, SubnodeOutlined, TagsOutlined } from "@ant-design/icons";
import { useLaundryLineItemEditor } from "@easybiz/checkout";
import { AmountDisplay } from "@easybiz/component";
import { Button, Collapse, List, Popover, Space, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import AccessorySelectPanel from "../AccessorySelectPanel";
import ServiceAddOnsPanel from "../ServiceAddOnsPanel";
import StepItemNotePanel from "../StepItemNotePanel";
import Title from "../Title";
import UnitPriceButton from "../UnitPriceButton";
import UnitQuantityUpdateButton from "../UnitQuantityUpdateButton";

export default function LaundryLineItemEditor({ item }) {
  const {
    added,
    selected,
    title,
    optionTile,
    descriptionHeader,
    unit,
    unitPrice,
    note,
    addonCount,
    accessoryCount,
    freeNote,
    commons,
    onEditItem,
  } = useLaundryLineItemEditor(item);

  return (
    <List.Item>
      <List.Item.Meta
        title={
          <div className={`flex flex-row flex-align-center flex-space-between`}>
            {
              added ? <Title type={selected ? "success" : null}>{title}</Title> : <span /> // Placeholder
            }
            <Space align="center">
              <Title>{optionTile}</Title>
              {added ? (
                <UnitPriceButton
                  unit={unit}
                  unitPrice={unitPrice}
                  onChange={(unitPrice) => onEditItem({ unitPrice })}
                />
              ) : (
                <Title>
                  <AmountDisplay value={unitPrice} />
                </Title>
              )}
              <UnitQuantityUpdateButton item={item} />
            </Space>
          </div>
        }
        description={
          added && (
            <Collapse destroyInactivePanel style={{ marginTop: 8 }} size="middle">
              <Collapse.Panel
                key={"details"}
                header={descriptionHeader}
                extra={
                  <span>
                    {note ? (
                      <Tag icon={<EditOutlined />} color="processing">
                        <FormattedMessage defaultMessage={"Note"} />
                      </Tag>
                    ) : null}
                    {addonCount > 0 && (
                      <Tag icon={<SubnodeOutlined />} color={"processing"}>
                        <b>{addonCount}</b>
                      </Tag>
                    )}
                    {accessoryCount > 0 && (
                      <Tag icon={<TagsOutlined />} color={"processing"}>
                        <b>{accessoryCount}</b>
                      </Tag>
                    )}
                  </span>
                }
              >
                <Space direction="vertical" className="full-width" size={"middle"}>
                  <Space>
                    <Popover
                      title={
                        <Title>
                          <FormattedMessage defaultMessage={"Add-on"} />
                        </Title>
                      }
                      content={
                        <ServiceAddOnsPanel
                          style={{
                            width: 300,
                            maxHeight: 300,
                            overflowY: "auto",
                          }}
                          selects={item?.addons}
                          onChange={(addons) =>
                            onEditItem({
                              addons: Array.isArray(addons) && addons.length > 0 ? addons : null,
                            })
                          }
                        />
                      }
                      placement="right"
                      trigger={"click"}
                    >
                      <Button
                        type={addonCount ? "primary" : "default"}
                        ghost={addonCount > 0}
                        icon={<SubnodeOutlined />}
                      >
                        <FormattedMessage tagName={"span"} defaultMessage={"Add-on"} />
                        {addonCount > 0 ? `(${addonCount})` : ""}
                      </Button>
                    </Popover>
                    <Popover
                      title={
                        <Title>
                          <FormattedMessage defaultMessage={"Accessory"} />
                        </Title>
                      }
                      trigger={"click"}
                      content={
                        <AccessorySelectPanel
                          style={{
                            width: 300,
                            maxHeight: 300,
                            overflowY: "auto",
                          }}
                          selects={item?.accessories}
                          onChange={(accessories) =>
                            onEditItem({
                              accessories: Array.isArray(accessories) && accessories.length > 0 ? accessories : null,
                            })
                          }
                        />
                      }
                      placement="right"
                    >
                      <Button
                        type={accessoryCount ? "primary" : "default"}
                        ghost={accessoryCount > 0}
                        icon={<TagsOutlined />}
                      >
                        <FormattedMessage tagName={"span"} defaultMessage={"Accessory"} />
                        {accessoryCount > 0 ? `(${accessoryCount})` : ""}
                      </Button>
                    </Popover>
                  </Space>
                  <StepItemNotePanel freeNote={freeNote} commons={commons} onChange={onEditItem} />
                </Space>
              </Collapse.Panel>
            </Collapse>
          )
        }
      />
    </List.Item>
  );
}
