import { ADMIN_OPERATION_BOOT_INTERCOM, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const onSubmit = useCallback(() => {
    return [OPERATION_GROUP_ADMIN, ADMIN_OPERATION_BOOT_INTERCOM];
  }, []);

  return useActionCallback(onSubmit, callback);
};
