import { DateDisplay } from "@easybiz/component";
import { useCollectionReceiptQuery } from "@easybiz/web-firebase";
import { Col, Drawer, Row } from "antd";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";
import ReceiptView from "../ReceiptView";

export default ({ open, onClose, order, placement }) => {
  const receipts = useCollectionReceiptQuery(open ? order?.id : null);

  return (
    <Drawer
      title={<FormattedMessage defaultMessage="Collection Receipts" />}
      placement={placement}
      open={open}
      onClose={onClose}
      width={470}
    >
      <Row gutter={[16, 16]}>
        {(receipts || []).map((receipt) => {
          const { createDate, operator } = receipt.data();

          return (
            <Col key={receipt.id}>
              <ReceiptView
                {...receipt.data()}
                title={
                  <span>
                    {operator ? operator.name : ""}{" "}
                    {createDate && createDate.toDate ? (
                      <DateDisplay timestamp={dayjs(createDate.toDate()).unix()} />
                    ) : (
                      ""
                    )}
                  </span>
                }
                email={{
                  customer: order.get("customer"),
                  orderId: order.id,
                  receiptId: receipt.id,
                }}
                printing
              />
            </Col>
          );
        })}
      </Row>
    </Drawer>
  );
};
