"use client";

import {
  useBleLabelPrinter,
  useBleReceiptPrinter,
  useBluetoothPrint,
  useCountryCode,
  useESCEncoder,
  useLabelSettings,
  useNamingContext,
  usePlatform,
  usePrinterConnect,
  useRegisteredDevice,
  useToast,
  useUsbPrint,
} from "@easybiz/context";
import {
  encodePackageLabel,
  encodePickUpBagLabel,
  encodeStorageLabel,
  encodeTaggingLabel,
  ERROR_CODE_PRINTING_ERROR,
  formatLabelContent,
  formatPackContent,
  PLATFORM_WEB,
  PRINTER_IO_BLUETOOTH,
  PRINTER_TYPE_LABEL,
  PRINTER_TYPE_RECEIPT,
  PRINTER_TYPE_STICKER,
} from "@easybiz/utils";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

export default () => {
  const countryCode = useCountryCode();
  const escpos = useESCEncoder();
  const registerDevice = useRegisteredDevice();
  const usbWrite = useUsbPrint();
  const bluetoothWrite = useBluetoothPrint();
  const onConnect = usePrinterConnect();
  const labelSettings = useLabelSettings();
  const context = useNamingContext();
  const toast = useToast();
  const intl = useIntl();
  const platform = usePlatform();
  const bleReceiptPrinter = useBleReceiptPrinter();
  const bleLabelPrinter = useBleLabelPrinter();
  const [printing, setPrinting] = useState(false);

  const print = useCallback(
    // Printer and previewSetting params is for test print function only
    async (args, printer, previewSetting) => {
      let commands = [],
        printerType;

      try {
        if (args.openDrawer) {
          commands.push(escpos.encodeOpenCashDrawer());
          printerType = PRINTER_TYPE_RECEIPT;
        } else if (Array.isArray(args.lines)) {
          const command = await escpos.encodeReceipt(
            args.lines,
            previewSetting || registerDevice?.get("receiptPrinter"),
            countryCode
          );
          commands.push(command);
          printerType = PRINTER_TYPE_RECEIPT;
        } else if (Array.isArray(args.mutiLines)) {
          for (const { lines } of args.mutiLines) {
            if (Array.isArray(lines)) {
              const command = await escpos.encodeReceipt(
                lines,
                previewSetting || registerDevice?.get("receiptPrinter"),
                countryCode
              );
              commands.push(command);
            }
          }
          printerType = PRINTER_TYPE_RECEIPT;
        } else if (args.label) {
          commands = [encodeTaggingLabel(args.label, previewSetting || registerDevice?.get("labelPrinter"))];
          printerType = PRINTER_TYPE_LABEL;
        } else if (args.labelOrder) {
          // Print order labels
          const labels =
            (typeof args.labelOrder.get === "function" ? args.labelOrder.get("labels") : args.labelOrder.labels) || [];

          for (const labelId of labels) {
            const label = formatLabelContent(
              args.labelOrder,
              labelId,
              context,
              null,
              args.labelSettings || labelSettings
            );
            commands.push(encodeTaggingLabel(label, previewSetting || registerDevice?.get("labelPrinter")));

            if (previewSetting) {
              // Only print one tag for testing printing
              break;
            }

            const { accessories } = label;
            if (Array.isArray(accessories)) {
              for (const accessory of accessories) {
                for (let index = 0; index < accessory.qty; index++) {
                  const label = formatLabelContent(
                    args.labelOrder,
                    labelId,
                    context,
                    {
                      ...accessory,
                      index: index + 1,
                    },
                    args.labelSettings || labelSettings
                  );
                  commands.push(encodeTaggingLabel(label, previewSetting || registerDevice?.get("labelPrinter")));
                }
              }
            }
          }

          printerType = PRINTER_TYPE_LABEL;
        } else if (args.pack) {
          commands = [
            encodePackageLabel(
              formatPackContent(args.pack, context, args.labelSettings || labelSettings),
              previewSetting || registerDevice?.get("stickerPrinter")
            ),
          ];
          printerType = PRINTER_TYPE_STICKER;
        } else if (Array.isArray(args.packs)) {
          for (const pack of args.packs) {
            commands.push(
              encodePackageLabel(
                formatPackContent(pack, context, args.labelSettings || labelSettings),
                previewSetting || registerDevice?.get("stickerPrinter")
              )
            );

            if (previewSetting) {
              // Only print one tag for testing printing
              break;
            }
          }

          printerType = PRINTER_TYPE_STICKER;
        } else if (typeof args.qrBag === "string") {
          if (!args.qrBag) {
            toast.error(intl.formatMessage({ defaultMessage: "Bag no can not be empty" }));
            return;
          }

          commands = [encodePickUpBagLabel(args.qrBag, previewSetting || registerDevice?.get("labelPrinter"))];
          printerType = PRINTER_TYPE_LABEL;
        } else if (typeof args.storage === "string") {
          if (!args.storage) {
            toast.error(intl.formatMessage({ defaultMessage: "Storage code can not be empty" }));
            return;
          }

          commands = [encodeStorageLabel(args.storage, previewSetting || registerDevice?.get("labelPrinter"))];
          printerType = PRINTER_TYPE_LABEL;
        } else {
          throw new Error(`Nothing to print`);
        }

        if (!printer) {
          printer = registerDevice?.get(`${printerType}Printer`);
        }

        // Web bluetooth must use the in moment device object
        if (platform === PLATFORM_WEB && printer?.io === PRINTER_IO_BLUETOOTH) {
          if (printerType === PRINTER_TYPE_RECEIPT) {
            printer = bleReceiptPrinter;
          } else if (printerType === PRINTER_TYPE_LABEL) {
            printer = bleLabelPrinter;
          }
        }

        if (!printer) {
          toast.error(intl.formatMessage({ defaultMessage: "Printer not paired" }), printerType, args);
          onConnect({ commands, printerType });
          return;
        }

        setPrinting(true);
        if (printer.address || printer.writeValue) {
          // Bluetooth
          await bluetoothWrite(printer, commands, printerType);
        } else {
          // USB
          await usbWrite(printer, commands);
        }

        return true;
      } catch (error) {
        toast.error(error.message, ERROR_CODE_PRINTING_ERROR);
        return error;
      } finally {
        setPrinting(false);
      }
    },
    [registerDevice, context, labelSettings, platform, bleReceiptPrinter, bleLabelPrinter, toast]
  );

  const openDrawer = useCallback(
    async (auto) => {
      // Skip if no printer connected
      if (registerDevice?.get("receiptPrinter") || !auto) {
        await print({ openDrawer: true });
      }
    },
    [print, registerDevice]
  );

  return {
    print,
    printing,
    openDrawer,
  };
};
