"use client";

import { createContext, useContext, useMemo } from "react";

const AuthenticationContext = createContext();

export function AuthenticationProvider({ realmId, account, avatarUrl, onSignOut, accountName, children }) {
  const context = useMemo(() => {
    return {
      account,
      uid: account?.uid,
      realmId: realmId || account?.realmId,
      businessCode: account?.businessCode,
      requireSignIn: account === false,
      signInEmail: account?.email,
      emailVerified: account?.emailVerified,
      realmMaster: account?.realmMaster,
      platformMode: account?.platformMode,
      pendingSetup: account?.pendingSetup,
      registeredDeviceId: account?.installId,
      connectDeviceId: account?.connectDeviceId,
      checkInStaff: account?.checkInStaff,
      onSignOut,
      avatarUrl: avatarUrl || account?.avatarUrl,
      operator: account
        ? {
            id: account.customerId || account.businessCode || account.uid,
            name: account.checkInStaff ? account.checkInStaff.name : accountName || account.name || "",
          }
        : null,
    };
  }, [realmId, account, avatarUrl, onSignOut, accountName]);

  return <AuthenticationContext.Provider value={context}>{children}</AuthenticationContext.Provider>;
}

export const useRealmId = () => useContext(AuthenticationContext).realmId;
export const useOperator = () => useContext(AuthenticationContext).operator;
export const useSignInAvatarUrl = () => useContext(AuthenticationContext).avatarUrl;
export const useAuthUser = () => useContext(AuthenticationContext).account;
export const useSignInUID = () => useContext(AuthenticationContext).uid;
export const useBusinessCode = () => useContext(AuthenticationContext).businessCode;
export const useSignInEmail = () => useContext(AuthenticationContext).signInEmail;
export const useIsSignInEmailVerified = () => useContext(AuthenticationContext).emailVerified;
export const useIsMasterAdmin = () => useContext(AuthenticationContext).realmMaster;
export const useRegisteredDeviceId = () => useContext(AuthenticationContext).registeredDeviceId;
export const useIsRequireSignIn = () => useContext(AuthenticationContext).requireSignIn;
export const useIsPlatformMode = () => useContext(AuthenticationContext).platformMode;
export const useInPendingSetup = () => useContext(AuthenticationContext).pendingSetup;
export const useConnectedDeviceId = () => useContext(AuthenticationContext).connectDeviceId;
export const useCheckInStaff = () => useContext(AuthenticationContext).checkInStaff;
export const useAuthSignOut = () => useContext(AuthenticationContext).onSignOut;
