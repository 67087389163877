import { PlusOutlined, PrinterOutlined, ReloadOutlined } from "@ant-design/icons";
import { useConnectedPrinter, useToast } from "@easybiz/context";
import { useAdminPrinterRegister } from "@easybiz/request";
import { findPrinter, PRINTER_IO_USB } from "@easybiz/utils";
import { useUsbIO } from "@easybiz/web-component";
import { Avatar, Button, List, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import USBPrinterConnectButton from "../USBPrinterConnectButton";

export default function ({ printerType, commands }) {
  const [devices, setDevices] = useState();
  const select = useConnectedPrinter(printerType);

  const toast = useToast();
  const usbIO = useUsbIO();
  const { onSubmit, loading } = useAdminPrinterRegister();

  const onRequest = async () => {
    try {
      const printer = await navigator.usb.requestDevice({ filters: [] });

      let error;

      if (commands) {
        // Try continue printing before set printer
        error = await usbIO(printer, commands, printerType);
      }

      if (error) {
        toast.error(error.message);
      } else {
        onSubmit({
          printer,
          printerType,
          io: PRINTER_IO_USB,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (navigator.usb) {
      navigator.usb.getDevices().then(setDevices);
    }
  }, []);

  return (
    <List
      header={<FormattedMessage defaultMessage="USB Devices" tagName="b" />}
      dataSource={devices || []}
      loading={loading}
      renderItem={(device) => {
        const selected = Boolean(select && findPrinter(device, select));

        return (
          <List.Item
            actions={
              selected
                ? [
                    <Tag color={"success"}>
                      <FormattedMessage defaultMessage={"Connected"} />
                    </Tag>,
                  ]
                : [<USBPrinterConnectButton device={device} printerType={printerType} commands={commands} />]
            }
          >
            <List.Item.Meta
              avatar={<Avatar icon={<PrinterOutlined />} />}
              title={
                <span>
                  {device.productName} {device.serialNumber ? <Tag>{device.serialNumber}</Tag> : null}
                </span>
              }
              description={device.manufacturerName}
            />
          </List.Item>
        );
      }}
      footer={
        <Space>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => {
              navigator.usb.getDevices().then(setDevices);
            }}
          >
            <FormattedMessage tagName="span" defaultMessage="Refresh devices" />
          </Button>
          <Button icon={<PlusOutlined />} onClick={onRequest}>
            <FormattedMessage tagName="span" defaultMessage="Add device" />
          </Button>
        </Space>
      }
    />
  );
}
