import {
  CalendarOutlined,
  DollarCircleOutlined,
  GiftOutlined,
  QrcodeOutlined,
  TabletOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  useCheckoutCustomer,
  useCheckoutOutstanding,
  useCheckoutPricing,
  useCheckoutService,
  useSetCheckoutCustomer,
} from "@easybiz/checkout";
import { useCustomerScreen } from "@easybiz/context";
import {
  CUSTOMER_SCREEN_DATE_TIME_OPTIONS,
  CUSTOMER_SCREEN_ORDER,
  CUSTOMER_SCREEN_PROFILE_FILL_IN,
  CUSTOMER_SCREEN_TOP_UP_OPTIONS,
} from "@easybiz/utils";
import { IconButton } from "@easybiz/web-admin";
import { setDoc, updateDoc } from "@firebase/firestore";
import { Badge, Button, Popover, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import CustomerScreenOptionsMenu from "./CustomerScreenOptionsMenu";
import DevicePairingDialog from "./DevicePairingDialog";

export default function CustomerScreenButton() {
  const customerScreen = useCustomerScreen();
  const customer = useCheckoutCustomer();
  const setCustomer = useSetCheckoutCustomer();
  const service = useCheckoutService();
  const pricing = useCheckoutPricing();
  const totalPayable = useCheckoutOutstanding();
  const [linkDialogVisible, setLinkDialogVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const { mode, linking, updateCustomer } = customerScreen?.data() || {};

  useEffect(() => {
    if (
      customerScreen?.get("mode") === CUSTOMER_SCREEN_ORDER &&
      pricing &&
      customer &&
      service &&
      typeof totalPayable === "number"
    ) {
      updateDoc(customerScreen.ref, {
        customerId: customer.id,
        customer,
        serviceId: service.id,
        pricing,
        totalPayable,
      });
    }
  }, [customer, service?.id, pricing, customerScreen?.get("mode"), totalPayable]);

  useEffect(() => {
    if (updateCustomer && mode === CUSTOMER_SCREEN_PROFILE_FILL_IN) {
      // Customer fill in completed
      setCustomer(updateCustomer);
      setDoc(customerScreen.ref, { linking: customerScreen.get("linking") || null });
    }
  }, [mode, Boolean(updateCustomer)]);

  const icon = useMemo(() => {
    switch (mode) {
      case CUSTOMER_SCREEN_PROFILE_FILL_IN:
        return <UserOutlined style={{ color: linking ? "green" : "red" }} />;
      case CUSTOMER_SCREEN_ORDER:
        return <DollarCircleOutlined style={{ color: linking ? "green" : "red" }} />;
      case CUSTOMER_SCREEN_TOP_UP_OPTIONS:
        return <GiftOutlined style={{ color: linking ? "green" : "red" }} />;
      case CUSTOMER_SCREEN_DATE_TIME_OPTIONS:
        return <CalendarOutlined style={{ color: linking ? "green" : "red" }} />;
      default:
        return null;
    }
  }, [mode, linking]);

  return (
    <>
      <Popover
        open={menuVisible}
        onOpenChange={(open) => {
          if (!open) {
            setMenuVisible(false);
          }
        }}
        trigger={"click"}
        title={<FormattedMessage defaultMessage={"Customer Screen Controller"} />}
        content={
          <Space direction="vertical" size={"large"}>
            <CustomerScreenOptionsMenu onClose={() => setMenuVisible(false)} />
            <Button
              disabled={!customerScreen}
              icon={<QrcodeOutlined />}
              onClick={() => {
                setLinkDialogVisible(true);
                setMenuVisible(false);
              }}
            >
              <FormattedMessage tagName={"span"} defaultMessage={"Device pairing"} />
            </Button>
          </Space>
        }
        placement="top"
      >
        <Badge
          size="small"
          status={customerScreen?.get("linking") ? "success" : "error"}
          offset={[-10, 10]}
          count={icon}
          dot={!icon}
        >
          <IconButton
            disabled={!customerScreen}
            size="large"
            block
            onClick={() => {
              if (customerScreen?.get("linking")) {
                setMenuVisible(true);
              } else {
                setLinkDialogVisible(true);
              }
            }}
            icon={<TabletOutlined />}
          />
        </Badge>
      </Popover>
      <DevicePairingDialog open={linkDialogVisible} onCancel={() => setLinkDialogVisible(false)} />
    </>
  );
}
