import { Button, Space } from "antd";
import React from "react";

export default ({ disabled, indexes, filter, onSelect }) => {
  return (
    <Space wrap>
      {indexes.map((index) => {
        return (
          <Button
            key={index}
            disabled={disabled}
            type={filter === index ? 'primary' : "link"}
            onClick={() => onSelect(filter === index ? null : index)}
          >
            {index}
          </Button>
        );
      })}
    </Space>
  );
};
