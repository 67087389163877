import { AppUpdateButton, DialPad, Title } from "@easybiz/web-admin";
import { useClockInDialog } from "@easybiz/pos";
import { Modal, Space, Spin } from "antd";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import React from "react";
import PasscodeDisplay from "./PasscodeDisplay";

export default ({ open, onCancel }) => {
  const { title, header, passcode, onChange, loading, loadingTip, onSubmit } = useClockInDialog((token) => {
    if (token) {
      signInWithCustomToken(getAuth(), token);
    }
    onCancel();
  });

  return (
    <Modal title={title} footer={false} open={open} closable={false} destroyOnClose>
      <Space direction="vertical" className="full-width" size={"large"}>
        <Title fontSize={30}>{header}</Title>
        <PasscodeDisplay passcode={passcode} hidden />
        <div />
        <Spin spinning={loading} tip={loadingTip}>
          <DialPad value={passcode} onChange={onChange} onDone={onSubmit} />
        </Spin>
        <AppUpdateButton type={"text"} showVersion />
      </Space>
    </Modal>
  );
};
