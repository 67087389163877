import { usePOSClockIn } from "@easybiz/request";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export default (onSucceed) => {
  const [passcode, setPasscode] = useState("");
  const { loading, onSubmit } = usePOSClockIn((succeed, response) => {
    setPasscode("");

    if (succeed) {
      onSucceed(response.token);
    }
  });

  useEffect(() => {
    if (passcode && `${passcode}`.length >= 4) {
      onSubmit({ passcode });
    }
  }, [passcode]);

  return {
    passcode,
    onChange: setPasscode,
    title: <FormattedMessage defaultMessage="Clock In" />,
    header: <FormattedMessage defaultMessage="Passcode" />,
    loadingTip: <FormattedMessage defaultMessage={"Clocking in..."} />,
    loading,
    onSubmit,
  };
};
