import { CloseOutlined, DollarOutlined } from "@ant-design/icons";
import {
  useCheckoutEditItem,
  useCheckoutPricingVersion,
  useGridSelectionPanel,
  useSetCheckoutEditItem,
} from "@easybiz/checkout";
import { Button, Menu, Popover } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import IndexesBar from "../IndexesBar";
import OtherProductPanel from "../OtherProductPanel";
import ProductSelectPanel from "../ProductSelectPanel";
import ProductsGrid from "../ProductsGrid";

export default function GridSelectionPanel({ search, onSearch }) {
  const setEditItem = useSetCheckoutEditItem();
  const item = useCheckoutEditItem();
  const pricingVersion = useCheckoutPricingVersion();
  const [addOther, setAddOther] = useState();
  const { activeKey, filter, indexes, menus, products, setActiveKey, setFilter } = useGridSelectionPanel(
    search,
    onSearch
  );

  return addOther ? (
    <div className="flex flex-fill self-scroll padding-horizontal-half">
      <OtherProductPanel initProduct={addOther} onClose={() => setAddOther(null)} />
    </div>
  ) : (
    <div className="flex flex-fill scroll-container">
      <div className={`flex flex-fill flex-row scroll-container`}>
        {activeKey && (
          <div className="flex scroll-body">
            {pricingVersion && (
              <Popover
                title={
                  <FormattedMessage
                    defaultMessage={"Current published price list version: {version}"}
                    values={{ version: pricingVersion }}
                  />
                }
                placement="right"
                trigger="click"
              >
                <div className="padding-horizontal-half">
                  <Button icon={<DollarOutlined />} type="text" size="small">
                    {pricingVersion}
                  </Button>
                </div>
              </Popover>
            )}
            {menus && (
              <Menu
                selectedKeys={[activeKey]}
                style={{ width: 130 }}
                mode="inline"
                items={menus}
                onClick={({ key }) => {
                  setActiveKey(key);
                  if (item) {
                    setEditItem(null);
                  }
                }}
              />
            )}
            {filter && (
              <div className="padding-half">
                <Button
                  block
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setFilter(null);
                    if (search) {
                      onSearch(null);
                    }
                  }}
                >
                  <FormattedMessage tagName={"span"} defaultMessage="Close" />
                </Button>
              </div>
            )}
          </div>
        )}
        <div className="flex flex-fill padding-half">
          {item ? (
            <ProductSelectPanel item={item} onAddOther={setAddOther} />
          ) : (
            <ProductsGrid products={products} onAddOther={setAddOther} />
          )}
        </div>
      </div>
      {Array.isArray(indexes) && (
        <div className="padding-half">
          <IndexesBar filter={filter} indexes={indexes} optionKey="product" onSelect={setFilter} />
        </div>
      )}
    </div>
  );
}
