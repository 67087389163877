import {
  CalendarOutlined,
  CameraOutlined,
  CarOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  HistoryOutlined,
  HourglassOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { useClientType } from "@easybiz/context";
import {
  CLIENT_POS,
  ORDER_TAB_COLLECTION,
  ORDER_TAB_HISTORY,
  ORDER_TAB_LABEL,
  ORDER_TAB_LOGISTIC,
  ORDER_TAB_PAYMENT,
  ORDER_TAB_PHOTO,
  ORDER_TAB_PROGRESS,
  ORDER_TAB_RECEIPT,
} from "@easybiz/utils";
import { Badge, Segmented } from "antd";

export default function ({ tabs, activeKey, onChange }) {
  const client = useClientType();

  return (
    <Segmented
      block
      value={activeKey}
      size={client === CLIENT_POS ? "large" : undefined}
      options={tabs.map(({ key, badge }) => {
        let icon;

        switch (key) {
          case ORDER_TAB_RECEIPT:
            icon = <FileTextOutlined />;
            break;
          case ORDER_TAB_LABEL:
            icon = <TagOutlined />;
            break;
          case ORDER_TAB_PHOTO:
            icon = <CameraOutlined />;
            break;
          case ORDER_TAB_PAYMENT:
            icon = <CreditCardOutlined />;
            break;
          case ORDER_TAB_LOGISTIC:
            icon = <CarOutlined />;
            break;
          case ORDER_TAB_COLLECTION:
            icon = <CalendarOutlined />;
            break;
          case ORDER_TAB_PROGRESS:
            icon = <HourglassOutlined />;
            break;
          case ORDER_TAB_HISTORY:
            icon = <HistoryOutlined />;
            break;
          default:
            return null;
        }

        return {
          value: key,
          icon: badge ? <Badge dot={Boolean(badge)}>{icon}</Badge> : icon,
        };
      })}
      onChange={onChange}
    />
  );
}
