// Admin
export const ADMIN_OPERATION_INVOICE_PREVIEW = "preview_invoice";
export const ADMIN_OPERATION_INVOICE_SUBMIT = "submit_invoice";
export const ADMIN_OPERATION_INVOICE_SEND = "send_invoice";
export const ADMIN_OPERATION_SMS_OTP_SETTING = "sms_otp_setting";

export const ADMIN_OPERATION_TOGGLE_STAFF_ACCOUNT = "toggle_staff_account";
export const ADMIN_OPERATION_POS_REQUEST_SIGN_IN_TOKEN = "pos_sign_in_token";
export const ADMIN_OPERATION_POS_SCAN_SIGN_IN = "pos_scan_sign_in";
export const ADMIN_OPERATION_MERGE_CUSTOMER_ACCOUNT = "merge_account";
export const ADMIN_OPERATION_MOVE_ORDER = "move_order";
export const ADMIN_OPERATION_UPDATE_APP_SLOGAN = "update_app_slogan";
export const ADMIN_OPERATION_UPDATE_APP_HOME_INSTALL = "update_app_home_install";
export const ADMIN_OPERATION_UPDATE_APP_BANNER = "update_app_banner";
export const ADMIN_OPERATION_UPDATE_WEB_APP = "update_web_app";
export const ADMIN_OPERATION_UPDATE_LIVE_CHAT = "update_live_chat";
export const ADMIN_OPERATION_UPLOAD_IMAGE = "upload_image";
export const ADMIN_OPERATION_UPDATE_SERVICE = "update_service";
export const ADMIN_OPERATION_UPDATE_CUT_OFF_TIME = "update_cut_off_time";
export const ADMIN_OPERATION_DELETE_SERVICE = "delete_service";
export const ADMIN_OPERATION_PRODUCT_EDIT = "product_edit";
export const ADMIN_OPERATION_PRODUCT_CATEGORY_EDIT = "product_category_edit";
export const ADMIN_OPERATION_MOVE_CATEGORY = "move_category";
export const ADMIN_OPERATION_HIDDEN_PRODUCT = "hidden_product";
export const ADMIN_OPERATION_HIDDEN_SERVICE_TYPE = "hidden_service_type";
export const ADMIN_OPERATION_PUBLISH_PRICING = "publish_pricing";
export const ADMIN_OPERATION_DELETE_PRODUCT = "delete_product";
export const ADMIN_OPERATION_UPDATE_PRODUCT_DETAIL = "update_product_detail";
export const ADMIN_OPERATION_BATCH_VOUCHER_EDIT = "batch_voucher_edit";
export const ADMIN_OPERATION_BATCH_PAYMENTS = "batch_payments";
export const ADMIN_OPERATION_PHOTO_DELETE = "photo_delete";
export const ADMIN_OPERATION_UPDATE_ONLINE_BRAND = "update_online_brand";
export const ADMIN_OPERATION_REGISTER_DOMAIN = "register_domain";
export const ADMIN_OPERATION_UPDATE_PROFILE_SETTINGS = "update_profile_settings";
export const ADMIN_OPERATION_UPDATE_ADMIN_PROFILE = "update_admin_profile";
export const ADMIN_OPERATION_UPDATE_COMPANY = "update_shop_brand";
export const ADMIN_OPERATION_DELETE_COMPANY = "delete_shop_brand";
export const ADMIN_OPERATION_UPDATE_MARKETING_CHANNEL = "update_marketing_channel";
export const ADMIN_OPERATION_DELETE_MARKETING_CHANNEL = "delete_marketing_channel";
export const ADMIN_OPERATION_EXPORT_CUSTOMERS = "export_customers";
export const ADMIN_OPERATION_DELETE_CUSTOMER_ACCOUNT = "delete_customer_account";
export const ADMIN_OPERATION_CREDITS_UPDATE = "update_credits";
export const ADMIN_OPERATION_POINTS_UPDATE = "update_points";
export const ADMIN_OPERATION_CANCEL_CREDIT_TRANSACTION = "cancel_credit_transaction";
export const ADMIN_OPERATION_UPDATE_TOP_UP_OPTION = "update_top_up_option";
export const ADMIN_OPERATION_UPDATE_TOP_UP_SETTINGS = "update_top_up_settings";
export const ADMIN_OPERATION_POINTS_SETTINGS = "update_points_settings";
export const ADMIN_OPERATION_UPDATE_RECYCLE_ITEM = "update_recycle_item";
export const ADMIN_OPERATION_UPDATE_MEMBER_OPTION = "update_member_option";
export const ADMIN_OPERATION_UPDATE_CUSTOMER_MEMBER_STATE = "update_customer_member_state";
export const ADMIN_OPERATION_NEW_BUSINESS = "create_new_business";
export const ADMIN_OPERATION_SERVICE_TYPE_EDIT = "service_type_edit";
export const ADMIN_OPERATION_SORT_PRICING_PLAN = "sort_pricing_plan";
export const ADMIN_OPERATION_BUSINESS_ADD_ON_EDIT = "add_on_edit";
export const ADMIN_OPERATION_TOGGLE_BUSINESS_PRICE_LIST = "toggle_business_price_list";
export const ADMIN_OPERATION_UPDATE_SHOP_PROFILE = "update_shop_profile";
export const ADMIN_OPERATION_UPDATE_SHOP_OPENING_HOURS = "update_shop_opening_hours";
export const ADMIN_OPERATION_ENABLE_DISABLE_BUSINESS = "enable_disable_business";
export const ADMIN_OPERATION_BATCH_UPDATE_JOBS = "batch_job_updates";
export const ADMIN_OPERATION_UPDATE_BUSINESS_ACTIVE_STATE = "update_business_active_state";
export const ADMIN_OPERATION_SORT_BUSINESSES = "sort_businesses";
export const ADMIN_OPERATION_EDIT_REFERRAL_SETTINGS = "edit_referral_settings";
export const ADMIN_OPERATION_EDIT_ARTICLE = "edit_article";
export const ADMIN_OPERATION_EDIT_PROMOTION = "edit_promotion";
export const ADMIN_OPERATION_EDIT_PROMO_CODE = "edit_promo_code";
export const ADMIN_OPERATION_EDIT_BIRTHDAY_PROMO = "edit_birthday_promo";
export const ADMIN_OPERATION_REQUEST_ORDER_PAYMENT = "request_order_payment";
export const ADMIN_OPERATION_UNREGISTER_DOMAIN = "unregister_domain";
export const ADMIN_OPERATION_EDIT_EMAIL_REPORT = "edit_email_report";
export const ADMIN_OPERATION_SEND_EMAIL_REPORT = "send_email_report";
export const ADMIN_OPERATION_EXPIRE_MOCK_SIGN_IN = "expire_mock_sign_in";
export const ADMIN_OPERATION_EDIT_RUNNING_NO = "edit_running_no";
export const ADMIN_OPERATION_EDIT_POS_SETTING = "edit_pos_setting";
export const ADMIN_OPERATION_REFRESH_SALES_REPORT = "refresh_sales_report";
export const ADMIN_OPERATION_REQUEST_SALES_REPORT = "request_sales_report";
export const ADMIN_OPERATION_CHANGE_ACCOUNTING_RULE = "change_accounting_rule";
export const ADMIN_OPERATION_POS_FTP_UPLOAD = "pos_ftp_upload";
export const ADMIN_OPERATION_POS_FTP_EDIT = "pos_ftp_edit";
export const ADMIN_OPERATION_EDIT_CASH_UP_SETTING = "edit_cash_up";
export const ADMIN_OPERATION_BOOT_INTERCOM = "boot_intercom";
export const ADMIN_OPERATION_POS_TEAMMATE_EDIT = "edit_teammate";
export const ADMIN_OPERATION_PASSCODE_GENERATE = "passcode_generate";
export const ADMIN_OPERATION_EDIT_ROLE = "edit_role";
export const ADMIN_OPERATION_COLLECTION_POINT_EDIT = "collection_point_edit";
export const ADMIN_OPERATION_WORKFLOW_ACCOUNT_EDIT = "workflow_account_edit";
export const ADMIN_OPERATION_RESET_PASSWORD = "reset_password";
export const ADMIN_OPERATION_REQUEST_HELP = "request_help";
export const ADMIN_OPERATION_RECEIPT_TEMPLATE_PREVIEW = "preview_receipt";
export const ADMIN_OPERATION_UPDATE_REALM_SETTINGS = "update_realm_settings";
export const ADMIN_OPERATION_EDIT_DELIVERY_OPTIONS = "edit_delivery_options_2";
export const ADMIN_OPERATION_RECEIPT_TEMPLATE_EDIT = "receipt_template_edit";
export const ADMIN_OPERATION_UPDATE_MINIMUM_ORDER = "update_minimum_order";
export const ADMIN_OPERATION_EDIT_ORDER_QUESTION = "edit_order_question";
export const ADMIN_OPERATION_DELETE_STAFF_ACCOUNT = "delete_staff_account"; //TODO: REMOVE
export const ADMIN_OPERATION_PHOTO_UPLOAD = "photo_upload"; // TODO: REMOVE
export const ADMIN_OPERATION_EDIT_DEVICE_WHITE_LIST = "edit_device_white_list";
export const ADMIN_OPERATION_SEND_POS_LINK = "send_pos_link";
export const ADMIN_OPERATION_EVENT_TEMPLATE_EDIT = "edit_event_template";
export const ADMIN_OPERATION_EVENT_TEMPLATE_SEND = "event_template_send";
export const ADMIN_OPERATION_OPERATION_SETTING = "operation_setting";
export const ADMIN_OPERATION_EXPORT_ORDERS = "export_orders";
export const ADMIN_OPERATION_REGISTER_DEVICE = "register_device";
export const ADMIN_OPERATION_DELETE_DEVICE = "delete_device";
export const ADMIN_OPERATION_PLANT_NEW_INVOICE = "plant_new_invoice";
export const ADMIN_OPERATION_PLANT_UPDATE_INVOICE = "plant_update_invoice";
export const ADMIN_OPERATION_PLANT_EDIT_AGENT = "plant_edit_agent";
export const ADMIN_OPERATION_PLANT_EDIT_ORDER_FLOW = "plant_edit_order_flow";
export const ADMIN_OPERATION_PLANT_EDIT_PRICE_OPTION = "plant_edit_price_option";
export const ADMIN_OPERATION_PLANT_EDIT_SKU = "plant_edit_sku";
export const ADMIN_OPERATION_PLANT_EDIT_PRICE_LIST = "plant_edit_price_list_2";
export const ADMIN_OPERATION_UPDATE_DEVICE_STATUS = "update_device_status";
export const ADMIN_OPERATION_DEVICE_SIGN_OUT = "device_sign_out";
export const ADMIN_OPERATION_NOTIFICATION_MARK_ALL_READ = "mark_all_read";
export const ADMIN_OPERATION_EDIT_BREAKDOWN_TEMPLATE = "edit_breakdown_template";
export const ADMIN_OPERATION_PRINTER_REGISTER = "printer_register";
export const ADMIN_OPERATION_EDIT_SURCHARGE_DISCOUNT = "edit_surcharge_discount";
export const ADMIN_OPERATION_EDIT_GUIDE_PRICE = "edit_guide_price";
export const ADMIN_OPERATION_EDIT_ZONE = "edit_zone";
export const ADMIN_OPERATION_EDIT_BUSINESS_DATE_TIME = "edit_business_date_time";
export const ADMIN_OPERATION_EDIT_BUSINESS_AREA = "edit_business_area";
export const ADMIN_OPERATION_CUSTOMER_SCREEN_POSTER_EDIT = "edit_customer_screen_poster";
export const ADMIN_OPERATION_EDIT_BOOKING_STEPS = "edit_booking_steps";
export const ADMIN_OPERATION_EDIT_CUSTOM_BOOKING_STEP = "edit_custom_booking_step";
export const ADMIN_OPERATION_EDIT_SERVICE_TYPE_PRICING = "edit_service_type_pricing";
export const ADMIN_OPERATION_EDIT_POS_PAYMENT_METHODS = "edit_pos_payment_methods";
export const ADMIN_OPERATION_MANUAL_PAYMENT_METHOD_EDIT = "manual_payment_method_edit";
export const ADMIN_OPERATION_PUBLISH_APP = "publish_app";
export const ADMIN_OPERATION_EDIT_ADDRESS_INSTRUCTIONS = "edit_address_instructions";
export const ADMIN_OPERATION_UPDATE_ONLINE_BOOKING = "update_online_booking";
export const ADMIN_OPERATION_EDIT_BUSINESS_RECEIPT_TERMS = "edit_business_receipt_terms";
export const ADMIN_OPERATION_UPDATE_ONLINE_TERMS = "update_online_terms";
export const ADMIN_OPERATION_GET_IMAGE_DETAILS = "get_image_data_url";
export const ADMIN_OPERATION_REQUEST_CUSTOMER_SIGN_IN = "request_customer_sign_in";
export const ADMIN_OPERATION_EDIT_OFF_DAY = "edit_off_day";
export const ADMIN_OPERATION_EDIT_REPORT_CASE = "edit_report_case";
export const ADMIN_OPERATION_EDIT_ACCESSORY = "edit_accessory";
export const ADMIN_OPERATION_PREVIEW_TIME_SLOTS = "preview_time_slots";
export const ADMIN_OPERATION_UPDATE_BUSINESS_AGENT = "update_business_agent";
export const ADMIN_OPERATION_CLOSE_REPORT_CASE = "close_report_case";
export const ADMIN_OPERATION_STORAGE = "storage";
export const ADMIN_OPERATION_STORAGE_EDIT = "storage_edit";
export const ADMIN_OPERATION_ORDER_HANDOVER = "order_handover";
export const ADMIN_OPERATION_HANDOVER_SIGNATURE = "handover_signature";
export const ADMIN_OPERATION_HOME_DELIVERY_LOADING = "home_delivery_loading";
export const ADMIN_OPERATION_AVATAR_UPLOAD = 'avatar_upload';
export const ADMIN_OPERATION_STRIPE_CONNECT = 'stripe_connect';
export const ADMIN_OPERATION_SETTING_UPDATE_POS = 'setting_update_pos';
export const ADMIN_OPERATION_JOB_SETTING_EDIT = "job_setting_edit";
export const ADMIN_OPERATION_EVENT_EMAIL_SEND = "event_email_send";

// Billing
export const ADMIN_OPERATION_FREE_TRIAL = "free_trial";
export const ADMIN_OPERATION_BILLING_REQUEST_ADD_CARD = "billing_request_add_card";
export const ADMIN_OPERATION_BILLING_REMOVE_CARD = "billing_remove_card";
export const ADMIN_OPERATION_BILLING_SET_DEFAULT_CARD = "billing_set_default_card";
export const ADMIN_OPERATION_BILLING_UPDATE_BILLING_DETAIL = "billing_setup_billing_detail";
export const ADMIN_OPERATION_BILLING_PAY_INVOICE = "billing_pay_invoice";
export const ADMIN_OPERATION_COMPLETE_ACCOUNT_SETUP = "complete_account_setup";
