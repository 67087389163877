import { useOrderNoteUpdate } from "@easybiz/request";
import { Button, Drawer, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ServiceNotesPanel from "../ServiceNotesPanel";

export default ({ placement, order, noteLabel, open, onCancel, allItems }) => {
  const [selects, setSelects] = useState([]);
  const [note, setNote] = useState("");
  const intl = useIntl();

  const { onSubmit, loading } = useOrderNoteUpdate((succeed) => {
    if (succeed) {
      onCancel();
    }
  });

  useEffect(() => {
    if (open) {
      setNote(noteLabel?.note || "");
      setSelects([]);
    }
  }, [open]);

  return (
    <Drawer
      placement={placement}
      title={
        noteLabel ? (
          <FormattedMessage
            defaultMessage="Update {index} Label Note"
            values={{
              index: `#${noteLabel.unitIndex}${
                noteLabel.unitTotalPcs ? ` (${noteLabel.unitPcsIndex}/${noteLabel.unitTotalPcs})` : ""
              }`,
            }}
          />
        ) : allItems ? (
          <FormattedMessage tagName="span" defaultMessage="Update Note For All Labels" />
        ) : (
          <FormattedMessage tagName="span" defaultMessage="Update Order Note" />
        )
      }
      open={open}
      onClose={onCancel}
      width={470}
      extra={
        <Button
          loading={loading}
          type={"primary"}
          onClick={() => {
            onSubmit({
              note,
              selects,
              sortIndex: noteLabel?.sortIndex,
              orderId: order.id,
              allItems,
            });
          }}
        >
          <FormattedMessage defaultMessage={"Update"} />
        </Button>
      }
    >
      <Space direction="vertical" className="full-width" size={"middle"}>
        <Input.TextArea
          disabled={loading}
          value={note}
          placeholder={intl.formatMessage({ defaultMessage: "Note" })}
          onChange={(e) => setNote(e.target.value)}
        />
        <ServiceNotesPanel selects={selects} disabled={loading} onChange={setSelects} />
      </Space>
    </Drawer>
  );
};
