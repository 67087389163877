import { OPERATION_GROUP_POS, POS_OPERATION_CLOSE_OF_DAY_SUBMIT } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ preview, signature, note }) => {
    if (!preview)
      return intl.formatMessage({
        defaultMessage: "Preparing close of day report, try again later",
      });

    return [
      OPERATION_GROUP_POS,
      POS_OPERATION_CLOSE_OF_DAY_SUBMIT,
      {
        ...(signature && { signature }),
        ...(note && { note }),
        preview,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Report submitted" }));
};
