export default function (address) {
  const { formatted, geo, postalCode, route, unitNo, sortIndex } =
    typeof address.data === "function" ? address.data() || {} : address || {};

  return {
    id: address.id,
    formatted: formatted || "",
    geo,
    ...(postalCode && { postalCode }),
    ...(route && { route }),
    ...(unitNo && { unitNo }),
    ...(sortIndex === 1 && { isDefault: true }),
  };
}
