"use client";

import { createContext, useContext, useMemo } from "react";

const ThemingContext = createContext();

export function ThemingProvider({ theme, children }) {
  const context = useMemo(() => {
    return theme || {};
  }, [theme?.primary]);

  return <ThemingContext.Provider value={context}>{children}</ThemingContext.Provider>;
}

// Theming
export const useDisabledColor = () => "#B9BDC1";
export const usePrimaryColor = () => useContext(ThemingContext).primary;
export const usePrimaryContainerColor = () => useContext(ThemingContext).primaryContainer;
export const useSecondaryColor = () => useContext(ThemingContext).secondary;
export const useSecondaryContainerColor = () => useContext(ThemingContext).secondaryContainer;
export const useTertiaryColor = () => useContext(ThemingContext).tertiary;
export const useTertiaryContainerColor = () => useContext(ThemingContext).tertiaryContainer;
export const useErrorColor = () => useContext(ThemingContext).error;
export const useErrorContainerColor = () => useContext(ThemingContext).errorContainer;
export const useSurfaceColor = () => useContext(ThemingContext).surface;
export const useBackgroundColor = () => useContext(ThemingContext).background;
export const useSurfaceTextColor = () => useContext(ThemingContext).onSurface;
export const useSurfaceCardColor = () => useContext(ThemingContext).surfaceVariant;
export const useSurfaceCardTextColor = () => useContext(ThemingContext).onSurfaceVariant;
export const useSelectedTextColor = () => useContext(ThemingContext).onSecondaryContainer;
export const useBackgroundTextColor = () => useContext(ThemingContext).onBackground;
export const useInfoTextColor = () => useContext(ThemingContext).onTertiaryContainer;
