import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { UnitDisplay } from "@easybiz/component";
import { Button, Space, Typography } from "antd";
import React from "react";

export default ({ value, unit, onChange, min, max, offset }) => {
  return (
    <Space>
      <Button icon={<MinusOutlined />} onClick={() => onChange(Math.max(min, (value || 0) - (offset || 1)))} />
      <Typography.Text strong ellipsis={{ rows: 1 }}>
        {" "}
        {value || 0}
        {unit ? (
          <span>
            /<UnitDisplay unit={unit} />
          </span>
        ) : (
          ""
        )}
      </Typography.Text>
      <Button icon={<PlusOutlined />} onClick={() => onChange(Math.min(max, (value || 0) + (offset || 1)))} />
    </Space>
  );
};
