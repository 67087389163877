import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { useApplicationLauncher } from "@easybiz/component";
import { Button, Result, Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import AppUpdateButton from "../AppUpdateButton";

export default function () {
  const { title, showReload } = useApplicationLauncher();

  return (
    <div className="flex full-screen flex-center flex-align-center padding">
      <Space direction="vertical" align="center" size={"large"}>
        <img src="/images/banner.png" style={{ width: 400 }} />
        <Result
          icon={<LoadingOutlined />}
          title={title}
          extra={
            showReload && (
              <Button icon={<ReloadOutlined />} onClick={() => window.location.reload(true)}>
                <FormattedMessage tagName={"span"} defaultMessage={"Reload"} />
              </Button>
            )
          }
        />
        <AppUpdateButton showVersion type="text" />
      </Space>
      <br />
      <br />
      <br />
    </div>
  );
}
