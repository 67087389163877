import { DateDisplay, ScheduleDisableDisplay } from "@easybiz/component";
import { Checkbox, Empty, List, Space, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import TimeSlot from "../TimeSlot";

export default ({ option, dates, loading, select, onSelect }) => {
  return (
    <List
      loading={loading}
      size="small"
      dataSource={(dates || []).filter((data) => data.enabled)}
      renderItem={(item) => {
        const { date, slots, disabledType, enabled } = item;
        const haveSlot = Array.isArray(slots);

        return (
          <List.Item
            className={enabled ? (haveSlot ? null : `selectable`) : `gray-background`}
            actions={haveSlot ? null : [<Checkbox checked={select?.date === date} />]}
            onClick={
              haveSlot
                ? null
                : () => {
                    onSelect({ date }, option);
                  }
            }
          >
            <List.Item.Meta
              title={
                enabled ? (
                  <Typography.Title level={5}>
                    <DateDisplay date={date} weekday="long" />
                  </Typography.Title>
                ) : null
              }
              description={
                disabledType ? (
                  <span>
                    <DateDisplay date={date} weekday="long" /> <ScheduleDisableDisplay {...item} />
                  </span>
                ) : (
                  <Space wrap size="small">
                    {(slots || []).map((slot) => {
                      const selected = Boolean(
                        select &&
                          select.date === date &&
                          (select.start || null) === (slot.start || null) &&
                          (select.end || null) === (slot.end || null)
                      );

                      return (
                        <TimeSlot
                          key={`${slot.start}${slot.end}`}
                          {...slot}
                          selected={selected}
                          onSelect={(slot) => {
                            if (onSelect) {
                              onSelect({ ...slot, date }, option);
                            }
                          }}
                        />
                      );
                    })}
                  </Space>
                )
              }
            />
          </List.Item>
        );
      }}
      locale={{
        emptyText: <Empty description={<FormattedMessage defaultMessage="Booking not available" />} />,
      }}
    />
  );
};
