import { OPERATION_GROUP_POS, POS_OPERATION_CLOSE_OF_DAY_PREVIEW } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const onSubmit = useCallback(({ date }) => {
    return [
      OPERATION_GROUP_POS,
      POS_OPERATION_CLOSE_OF_DAY_PREVIEW,
      {
        date
      }
    ];
  }, []);

  return useActionCallback(onSubmit, callback);
};
