import translate from "./translate.js";

export default function (addon) {
  return {
    id: addon.id,
    title: translate(addon.get("title")),
    ...(addon.get("code") && { code: addon.get("code") }),
    ...(addon.get("surcharge") && { surcharge: addon.get("surcharge") }),
  };
}
