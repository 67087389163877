"use client";

import { useGoogleMapApiKey } from "@easybiz/context";
import { useJsApiLoader } from "@react-google-maps/api";

const LIBRARIES = ["places"];

export function GoogleMapJsLoader({ children }) {
  const googleMapsApiKey = useGoogleMapApiKey();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: LIBRARIES,
    googleMapsApiKey,
  });

  return isLoaded ? children : null;
}
