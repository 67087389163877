import { useToast } from "@easybiz/context";
import { ADMIN_OPERATION_INVOICE_SUBMIT, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const toast = useToast();
  const intl = useIntl();

  return useActionCallback(
    ({ hideDetails, invoice }) => {
      if (!invoice) {
        return intl.formatMessage({ defaultMessage: "Invoice preview not ready" });
      }

      return [
        OPERATION_GROUP_ADMIN,
        ADMIN_OPERATION_INVOICE_SUBMIT,
        {
          ...(hideDetails && { hideDetails }),
          invoice,
        },
      ];
    },
    callback,
    (request, response) =>
      toast.success(
        intl.formatMessage(
          { defaultMessage: "Invoice {invoiceNo} created" },
          { invoiceNo: `#${response.invoice?.invoiceNo}` }
        )
      )
  );
};
