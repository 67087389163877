import { OPERATION_GROUP_ORDER, ORDER_OPERATION_TWO_STEP_PRICE_CONTINUE } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ orderId, isAck }) => {
    return [
      OPERATION_GROUP_ORDER,
      ORDER_OPERATION_TWO_STEP_PRICE_CONTINUE,
      {
        orderId,
        ...(isAck && { isAck }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Order continue processed" }));
};
