import { LanguageSettingButton, OfflineAlert } from "@easybiz/web-admin";
import { Space, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import QRSignInPanel from "./QRSignInPanel";

export default function SignIn() {
  return (
    <div className="flex full-screen">
      <OfflineAlert />
      <div className="flex flex-fill flex-center flex-align-center padding">
        <div style={{ maxWidth: 600 }}>
          <div className="flex flex-row flex-align-center">
            <div className="flex-fill">
              <Space direction="horizontal" align="center">
                <img alt="EasyBiz Cloud" src="/images/logo.png" style={{ width: 50, height: 50 }} />
                <Typography style={{ fontSize: 20 }}>
                  <FormattedMessage defaultMessage={"EasyBiz Point of Sale"} />
                </Typography>
              </Space>
            </div>
            <LanguageSettingButton type={"text"} />
          </div>
          <br />
          <br />
          <QRSignInPanel />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
