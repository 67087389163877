import { LoadingOutlined } from "@ant-design/icons";
import { useHandoverHistoryQuery } from "@easybiz/web-firebase";
import { Tag } from "antd";
import React from "react";

export default ({ isCheckOut, date }) => {
  const [histories] = useHandoverHistoryQuery(
    date.format("YYYY-MM-DD"),
    isCheckOut
  );

  if (histories) {
    return histories.map((doc) => {
      return <Tag key={doc.id}>{doc.get("operator.name")}</Tag>;
    });
  } else {
    return <Tag icon={<LoadingOutlined />} />;
  }
};
