import { PROMO_TYPE_FLAT, PROMO_TYPE_PERCENT } from "@easybiz/utils";
import AmountDisplay from "../AmountDisplay";

export default function ({ surcharge, discount }) {
  const { type, value } = surcharge || discount || {};

  if (type === PROMO_TYPE_PERCENT) {
    return `${discount ? `-` : `+`}${value}%`;
  } else if (type === PROMO_TYPE_FLAT) {
    return (
      <>
        {discount ? `-` : `+`}
        <AmountDisplay value={value} />
      </>
    );
  } else {
    return null;
  }
}
