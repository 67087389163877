import { CloudDownloadOutlined } from "@ant-design/icons";
import { PlatformClientTitle } from "@easybiz/component";
import { useAppVersion, useClientType, useOTAUpdate } from "@easybiz/context";
import { Button, message } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function ({ style, showVersion, block, type }) {
  const update = useOTAUpdate();
  const appVersion = useAppVersion();
  const client = useClientType();
  const [progressing, setProgressing] = useState(false);
  const intl = useIntl();

  if (typeof update === "function") {
    return (
      <Button
        icon={<CloudDownloadOutlined />}
        block={block}
        loading={progressing}
        type="primary"
        style={style}
        onClick={() => {
          setProgressing(true);
          update();
        }}
      >
        <FormattedMessage tagName="span" defaultMessage="Update" />
      </Button>
    );
  } else if (showVersion) {
    return (
      <Button
        block={block}
        type={type}
        onClick={() => {
          message.info(
            intl.formatMessage({
              defaultMessage: "Your Cloud POS is up to date",
            })
          );
        }}
      >
        <span>
          <PlatformClientTitle client={client} short />
          {` v${appVersion}`}
        </span>
      </Button>
    );
  } else {
    return null;
  }
}
