import { NumericUpdatePanel, ReceiptView } from "@easybiz/web-admin";
import { useRecyclableItemQuery } from "@easybiz/web-firebase";
import { translate } from "@easybiz/utils";
import { Button, Card, Drawer, Form, message, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PointsRedeemSubmitDialog from "./PointsRedeemSubmitDialog";

export default ({ customer, open, onClose }) => {
  const items = useRecyclableItemQuery(open);
  const [returnCount, setReturnCount] = useState(null);
  const [selectItem, setSelectItem] = useState(null);
  const [receipt, setReceipt] = useState();
  const intl = useIntl();

  return (
    <Drawer
      width={470}
      title={<FormattedMessage tagName="span" defaultMessage="Recycle" />}
      open={open}
      onClose={onClose}
      destroyOnClose
      extra={
        receipt ? (
          <Button
            size="large"
            onClick={() => {
              setReceipt(null);
              onClose();
            }}
          >
            <FormattedMessage defaultMessage={"Close"} />
          </Button>
        ) : (
          <Button
            type="primary"
            size="large"
            onClick={() => {
              if (!selectItem)
                return message.info(
                  intl.formatMessage({
                    defaultMessage: "Please select recycle item",
                  })
                );
              if (!returnCount || isNaN(returnCount))
                return message.info(
                  intl.formatMessage({
                    defaultMessage: "Please enter received quantity",
                  })
                );
              if (parseInt(returnCount) <= 0)
                return message.info(
                  intl.formatMessage({
                    defaultMessage: "Quantity must be greater than 0",
                  })
                );

              setReturnCount(parseInt(returnCount));
            }}
          >
            <FormattedMessage defaultMessage={"Preview"} />
          </Button>
        )
      }
    >
      {receipt ? (
        <ReceiptView {...receipt} printing />
      ) : (
        <>
          <Form layout="vertical">
            <Form.Item label={<FormattedMessage tagName={"b"} defaultMessage={"Recycle Items"} />}>
              {items ? (
                <Space direction="horizontal" wrap>
                  {items.map((item) => (
                    <Button
                      key={item.id}
                      type={selectItem && selectItem.id === item.id ? "primary" : "default"}
                      shape="round"
                      onClick={() => setSelectItem(item)}
                    >
                      {translate(item.get("title"))}
                    </Button>
                  ))}
                </Space>
              ) : (
                <Card loading bordered={false} />
              )}
            </Form.Item>
            <Form.Item label={<FormattedMessage tagName={"b"} defaultMessage={"Received quantity"} />}>
              <NumericUpdatePanel
                value={returnCount}
                suffix={selectItem && translate(selectItem.get("title"))}
                onChange={setReturnCount}
              />
            </Form.Item>
          </Form>
        </>
      )}
      <PointsRedeemSubmitDialog
        customer={customer}
        recycleItem={selectItem}
        quantity={returnCount}
        open={Number.isInteger(returnCount)}
        onClose={() => setReturnCount(null)}
        onSucceed={(response) => {
          setReturnCount(null);
          setReceipt(response);
        }}
      />
    </Drawer>
  );
};
