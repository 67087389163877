import { OPERATION_GROUP_POS, POS_OPERATION_TOP_UP_REPORT } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const onSubmit = useCallback(({ startDate, endDate }) => {
    return [
      OPERATION_GROUP_POS,
      POS_OPERATION_TOP_UP_REPORT,
      {
        startDate,
        endDate,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback);
};
