"use client";

import { formatPrice } from "@easybiz/utils";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import CustomerDisplay from "./CustomerDisplay";
import AmountDisplay from "./AmountDisplay";

export default (customer, wallet, topUpOption) => {
  const currentBalance = wallet?.get("credit") || 0;
  const giftAmount = topUpOption?.oneTimeGiftAmount || topUpOption?.giftAmount || 0;
  const newBalance =
    wallet && topUpOption ? formatPrice((currentBalance || 0) + topUpOption.amount + giftAmount) : null;

  return useMemo(
    () => [
      {
        description: <FormattedMessage defaultMessage="Top up customer" />,
        title: <CustomerDisplay customer={customer} />,
      },
      {
        title: <FormattedMessage defaultMessage="Current balance" />,
        content: <AmountDisplay value={currentBalance} />,
        currentBalance: true,
      },
      {
        title: <FormattedMessage defaultMessage="Top up amount" />,
        content: <AmountDisplay value={topUpOption?.amount || 0} />,
        topUpAmount: true,
      },
      ...(giftAmount
        ? [
            {
              title: <FormattedMessage defaultMessage="Gift amount" />,
              content: <AmountDisplay value={giftAmount} />,
              giftAmount: true,
            },
          ]
        : []),
      {
        title: <FormattedMessage defaultMessage="New balance" />,
        content: <AmountDisplay value={newBalance} />,
        newBalance: true,
      },
    ],
    [customer, wallet, topUpOption?.amount, giftAmount]
  );
};
