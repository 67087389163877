import { useRealmId } from "@easybiz/context";
import {
  JOB_STATUS_COMPLETE,
  JOB_STATUS_FAILED,
  JOB_STATUS_PENDING,
  JOB_STATUS_SCHEDULED,
  sortOrderJobs,
} from "@easybiz/utils";
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (orderId, pendingOnly) => {
  const realmId = useRealmId();
  const [tasks, setTasks] = useState();

  useEffect(() => {
    setTasks(null);
    if (realmId && orderId) {
      return onSnapshot(
        query(
          collection(getFirestore(), `realms/${realmId}/tasks`),
          where("status", "in", [
            ...(pendingOnly ? [] : [JOB_STATUS_COMPLETE]),
            JOB_STATUS_FAILED,
            JOB_STATUS_PENDING,
            JOB_STATUS_SCHEDULED,
          ]),
          where("orderId", "==", orderId),
          orderBy("schedule.date")
        ),
        (result) => setTasks(sortOrderJobs(result?.docs))
      );
    }
  }, [realmId, orderId, pendingOnly]);

  return tasks;
};
