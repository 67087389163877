import { AmountDisplay, DateDisplay, PaymentMethodDisplay } from "@easybiz/component";
import { Collapse, Empty, List, Typography } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default ({ incomes, date }) => {
  const [activeKey, setActiveKey] = useState(Array.isArray(incomes) ? incomes[0]?.option : null);

  return (
    <div className="padding-top">
      {Array.isArray(incomes) ? (
        <Collapse activeKey={activeKey} onChange={setActiveKey} size="middle">
          {(incomes || []).map(({ option, amt, payments }) => {
            return (
              <Collapse.Panel
                key={option}
                header={
                  <b>
                    <PaymentMethodDisplay option={option} />: <AmountDisplay value={amt} />
                  </b>
                }
              >
                <List
                  size="small"
                  dataSource={payments || []}
                  renderItem={(payment) => {
                    const { no, top, amt, time } = payment;

                    return (
                      <List.Item
                        extra={
                          <Typography.Text type={amt < 0 ? "danger" : "success"}>
                            <AmountDisplay value={Math.abs(amt)} />
                          </Typography.Text>
                        }
                      >
                        <List.Item.Meta
                          title={top ? <FormattedMessage defaultMessage="Top up" /> : `#${no}`}
                          description={<DateDisplay date={payment.date || date} time={time} />}
                        />
                      </List.Item>
                    );
                  }}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      ) : (
        <Empty
          description={
            <FormattedMessage
              defaultMessage={"No payment made on {date}"}
              values={{
                date: <DateDisplay date={date} />,
              }}
            />
          }
        />
      )}
    </div>
  );
};
