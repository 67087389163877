export const PERMISSION_DATA_CANCEL_ORDER = "data_cancel_order";
export const PERMISSION_DATA_REFUND = "data_refund";
export const PERMISSION_DATA_EDIT_CUSTOMER = "data_customer";
export const PERMISSION_DATA_EDIT_CUSTOMER_LOYALITY = "data_customer_loyalty";
export const PERMISSION_DATA_DELETE_CUSTOMER = "data_delete";
export const PERMISSION_DATA_ORDERS = "data_orders";
export const PERMISSION_DATA_SEARCH_ORDERS = "data_search_orders";
export const PERMISSION_DATA_EXPORT_ORDERS = "data_export_orders";
export const PERMISSION_DATA_CUSTOMERS = "data_customrs";
export const PERMISSION_DATA_SEARCH_CUSTOMERS = "data_search_customrs";
export const PERMISSION_DATA_EXPORT_CUSTOMERS = "data_export_customrs";
export const PERMISSION_DATA_REPORT = "data_analysis";
export const PERMISSION_DATA_SALES = "data_sales";
export const PERMISSION_DATA_EXPORT_REPORT = "data_export_exports";

export const PERMISSION_SETTING_BUSINESS = "setting_business";
export const PERMISSION_SETTING_LOYALTY_PROGRAMS = "loyalty_programs";
export const PERMISSION_SETTING_MARKETING = "marketing";
export const PERMISSION_SETTING_NOTIFICATION_PRINTING = "setting_notification_center";
export const PERMISSION_SETTING_SECURITY_PAYMENT_METHOD = "setting_system";
export const PERMISSION_SETTING_WORKFLOW_ACCOUNT = "setting_teammate";
export const PERMISSION_SETTING_BILLING = "setting_billing";

export const PERMISSION_WORKFLOW_NEW_ONLINE_ORDER = "new_online_order";
export const PERMISSION_WORKFLOW_NEW_PLANT_ORDER = "new_plant_order";
export const PERMISSION_WORKFLOW_JOB_SCHEDULE = "worklow_job_schedule";
export const PERMISSION_WORKFLOW_JOB_FULFILL = "worklow_job_fulfill";
export const PERMISSION_WORKFLOW_PRODUCTION = "worklow_production";
export const PERMISSION_WORKFLOW_CHAT_SUPPORT = "worklow_live_chat";
export const PERMISSION_WORKFLOW_PREPARE_INVOICE = "worklow_plant_billing";
export const PERMISSION_WORKFLOW_CONFIRM_ORDER = "data_edit_general";
export const PERMISSION_WORKFLOW_PAYMENT = "data_edit_monetary";
export const PERMISSION_WORKFLOW_CASE_REPORTS = "worklow_case_reports"; // TODO: DELETE

export const DEFAULT_PERMISSION_ADMIN = [
  PERMISSION_WORKFLOW_NEW_ONLINE_ORDER,
  PERMISSION_WORKFLOW_NEW_PLANT_ORDER,
  PERMISSION_DATA_CANCEL_ORDER,
  PERMISSION_DATA_REFUND,
  PERMISSION_DATA_EDIT_CUSTOMER,
  PERMISSION_DATA_EDIT_CUSTOMER_LOYALITY,
  PERMISSION_DATA_DELETE_CUSTOMER,
  PERMISSION_DATA_SALES,
  PERMISSION_DATA_ORDERS,
  PERMISSION_DATA_CUSTOMERS,
  PERMISSION_DATA_REPORT,
  PERMISSION_DATA_EXPORT_CUSTOMERS,
  PERMISSION_DATA_EXPORT_REPORT,
  PERMISSION_SETTING_BUSINESS,
  PERMISSION_SETTING_SECURITY_PAYMENT_METHOD,
  PERMISSION_SETTING_NOTIFICATION_PRINTING,
  PERMISSION_SETTING_WORKFLOW_ACCOUNT,
  PERMISSION_SETTING_BILLING,
  PERMISSION_WORKFLOW_PRODUCTION,
  PERMISSION_WORKFLOW_JOB_SCHEDULE,
  PERMISSION_WORKFLOW_CHAT_SUPPORT,
  PERMISSION_WORKFLOW_PREPARE_INVOICE,
  PERMISSION_WORKFLOW_CASE_REPORTS,
  PERMISSION_WORKFLOW_CONFIRM_ORDER,
  PERMISSION_WORKFLOW_PAYMENT,
  PERMISSION_SETTING_LOYALTY_PROGRAMS,
  PERMISSION_SETTING_MARKETING,
];

export const DEFAULT_PERMISSION_CUSTOMER_SERVICE = [
  PERMISSION_DATA_ORDERS,
  PERMISSION_DATA_CUSTOMERS,
  PERMISSION_DATA_REPORT,
  PERMISSION_WORKFLOW_CONFIRM_ORDER,
  PERMISSION_WORKFLOW_PAYMENT,
  PERMISSION_SETTING_BUSINESS,
  PERMISSION_SETTING_NOTIFICATION_PRINTING,
  PERMISSION_WORKFLOW_PRODUCTION,
  PERMISSION_WORKFLOW_JOB_SCHEDULE,
  PERMISSION_WORKFLOW_CHAT_SUPPORT,
  PERMISSION_WORKFLOW_CASE_REPORTS,
];

export const DEFAULT_PERMISSION_DRIVER = [PERMISSION_WORKFLOW_JOB_FULFILL];
