import { useEffect, useMemo } from "react";
import { usePOSCollectionOptions } from "./posOperations";

export default function useAsyncCollectionOptions({ serviceId, businessCode, orderId, serviceTypes, date, combine }) {
  const { onSubmit, loading, response } = usePOSCollectionOptions();
  const serviceTypeIds = (serviceTypes || [])
    .map((type) => type.id)
    .sort()
    .join(",");

  useEffect(() => {
    if (onSubmit && serviceId && businessCode && date) {
      onSubmit({
        serviceId,
        businessCode,
        orderId,
        serviceTypes,
        date,
      });
    }
  }, [Boolean(onSubmit), serviceId, businessCode, orderId, serviceTypeIds, date]);

  return useMemo(() => {
    return {
      loading,
      ...(Array.isArray(response?.options) && {
        options: combine
          ? [].concat.apply(
              [],
              (response?.options || []).map(({ dates, option }) => {
                return (dates || []).map((data) => ({
                  ...data,
                  ...(option && { option }),
                }));
              })
            )
          : response?.options,
      }),
    };
  }, [loading, response, combine]);
}
