import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";
import SignaturePad from "../SignaturePad";

export default ({ open, onCancel, onSigned }) => {
  const [signature, setSignature] = useState();

  useEffect(() => {
    if (open) {
      setSignature(null);
    }
  }, [open]);

  return (
    <Dialog
      title={<FormattedMessage defaultMessage="Signature" />}
      value={signature}
      open={open}
      onCancel={onCancel}
      onOk={() => {
        onSigned(signature || null);
      }}
    >
      <SignaturePad onChange={setSignature} />
    </Dialog>
  );
};
