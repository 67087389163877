"use client";

import { useCountryCode } from "@easybiz/context";
import usePlacesAutocomplete from "use-places-autocomplete";

export default (defaultValue) => {
  const countryCode = useCountryCode();

  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue,
    initOnMount: true,
    requestOptions: {
      locationBias: "IP_BIAS",
      ...(countryCode && {
        componentRestrictions: { country: countryCode },
      }),
    },
    debounce: 300,
  });

  return {
    value,
    setValue,
    clear: clearSuggestions,
    places: data,
  };
};
