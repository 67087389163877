import { ReloadOutlined } from "@ant-design/icons";
import { useOrderReceiptRefresh } from "@easybiz/request";
import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ orderId }) {
  const { onSubmit, loading } = useOrderReceiptRefresh();

  return (
    <Button loading={loading} icon={<ReloadOutlined />} type="text" onClick={() => onSubmit({ orderId })}>
      <FormattedMessage tagName="span" defaultMessage="Reload receipt" />
    </Button>
  );
}
