export default function ({ place_id, address_components, geometry, formatted_address }) {
  const country = address_components && address_components.find(({ types }) => types.indexOf("country") >= 0);
  const locality = address_components && address_components.find(({ types }) => types.indexOf("locality") >= 0);
  const neighborhood = address_components && address_components.find(({ types }) => types.indexOf("neighborhood") >= 0);
  const route = address_components && address_components.find(({ types }) => types.indexOf("route") >= 0);
  const premise = address_components && address_components.find(({ types }) => types.indexOf("premise") >= 0);
  const streetNumber =
    address_components && address_components.find(({ types }) => types.indexOf("street_number") >= 0);
  const postalCode = address_components && address_components.find(({ types }) => types.indexOf("postal_code") >= 0);

  return {
    id: place_id,
    formatted: formatted_address,
    geo: {
      lat: typeof geometry.location.lat === "function" ? geometry.location.lat() : geometry.location.lat,
      lng: typeof geometry.location.lng === "function" ? geometry.location.lng() : geometry.location.lng,
    },
    ...(country && { country: country.long_name }),
    ...(locality && { locality: locality.long_name }),
    ...(neighborhood && { neighborhood: neighborhood.long_name }),
    ...(route && { route: route.long_name }),
    ...(premise && { premise: premise.long_name }),
    ...(streetNumber && { streetNumber: streetNumber.long_name }),
    ...(postalCode && { postalCode: postalCode.long_name }),
  };
}
