export default (documents) => {
  return (documents || [])
    .map((address) => {
      const { road, geo, postalCode, formatted } = address.data();

      if (geo?.lat && geo?.lng) {
        return {
          ...(road && { route: road }),
          ...(postalCode && { postalCode }),
          formatted: `${formatted}${postalCode ? ` ${postalCode}` : ""}`,
          geo,
          id: address.id,
        };
      }
    })
    .filter((data) => data);
};
