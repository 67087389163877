"use client";

import { useMemo } from "react";

export default () => {
  return useMemo(() => {
    return {
      primary: `#006c49`,
      primaryContainer: `#8af7c5`,
      secondary: `#4d6357`,
      secondaryContainer: `#cfe9d9`,
      surface: `#dbe5dd`,
      background: `#fbfdf8`,
      errorContainer: `#F9DEDC`,
      tertiaryContainer: `#c1e9fb`,
      error: `#B3261E`,
      tertiary: `#3d6373`,
    };
  }, []);
};
