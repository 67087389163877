export default function ({ customer }) {
  const { name, tels, contacts, index, contact } = customer || {};

  let phoneDisplay;

  if (Array.isArray(tels)) {
    phoneDisplay = tels.map(({ number, dialCode, intl }) => intl || `+${dialCode} ${number}`).join(", ");
  } else if (Array.isArray(contacts) && contacts.length > 0) {
    phoneDisplay = contacts.join(", ");
  } else if (typeof contact === 'string') {
    phoneDisplay = contact;
  }

  return `${Number.isInteger(index) ? `[${index}] ` : ""}${name || ""} ${phoneDisplay || ""}`;
}
