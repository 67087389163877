"use client";

import { createContext, useContext, useMemo, useState } from "react";

const ServerAPIContext = createContext();

export function ServerAPIProvider({ httpsFunction, children }) {
  const [blockingRequest, setBlockingRequest] = useState(null);

  const context = useMemo(() => {
    return {
      httpsFunction,
      blockingRequest,
      setBlockingRequest,
    };
  }, [httpsFunction, blockingRequest]);

  return <ServerAPIContext.Provider value={context}>{children}</ServerAPIContext.Provider>;
}

export const useHttpsFunction = () => useContext(ServerAPIContext).httpsFunction;
export const useBlockingRequest = () => useContext(ServerAPIContext).blockingRequest;
export const useSetBlockingRequest = () => useContext(ServerAPIContext).setBlockingRequest;
