import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { CustomerDisplay, CustomerEditDrawer, DelaySearchInput, IconButton, Title } from "@easybiz/web-admin";
import { Form, Space } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import QuickSearchButton from "./QuickSearchButton";

export default function CustomerSelectBanner({ customer, search, onSelect, onSearch }) {
  const intl = useIntl();
  const [showEditor, setShowEditor] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <div>
      <div className="flex flex-row flex-align-center padding-bottom-half">
        {!customer && (
          <QuickSearchButton customer={customer} search={search} inputFocused={inputFocused} onSearch={onSearch} />
        )}
        <div className="flex-fill padding-horizontal-half" style={{ overflow: "hidden" }}>
          {customer ? (
            <Title ellipsis>
              <CustomerDisplay customer={customer} />
            </Title>
          ) : (
            <Form autoComplete="off">
              <DelaySearchInput
                value={search}
                onFocus={() => {
                  onSearch(typeof search === "string" ? search : "");
                  setInputFocused(true);
                }}
                onBlur={() => {
                  setInputFocused(false);
                }}
                placeholder={intl.formatMessage({
                  defaultMessage: `Search customer, order`,
                })}
                onChange={onSearch}
              />
            </Form>
          )}
        </div>
        {customer ? (
          <Space direction="horizontal">
            <IconButton size="large" onClick={() => setShowEditor(true)} icon={<EditOutlined />} />
            <IconButton size="large" danger onClick={() => onSelect(null)} block icon={<CloseOutlined />} />
          </Space>
        ) : (
          <IconButton
            id="new-customer"
            size="large"
            onClick={() => setShowEditor(true)}
            block
            icon={<PlusOutlined />}
          />
        )}
      </div>
      <CustomerEditDrawer
        open={showEditor}
        customerId={customer?.id}
        onSaved={(customer) => {
          onSelect(customer);
          onSearch("");
          setShowEditor(false);
        }}
        onClose={() => setShowEditor(false)}
      />
    </div>
  );
}
