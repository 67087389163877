import { PrinterOutlined } from "@ant-design/icons";
import { useBusinessCode, useRealmId } from "@easybiz/context";
import { usePrinter } from "@easybiz/hook";
import {
  BUSINESS_TYPE_SHOP,
  LINE_ALIGN_CENTER,
  LINE_TYPE_TITLE,
  PRINTER_TYPE_LABEL,
  PRINTER_TYPE_RECEIPT,
} from "@easybiz/utils";
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where } from "@firebase/firestore";
import { Button, message } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function TestPrintButton({ printerType, device, printArgs, settings }) {
  const realmId = useRealmId();
  const [printing, setPrinting] = useState(false);
  const printer = usePrinter();
  const businessCode = useBusinessCode();

  const onTestPrint = async () => {
    try {
      setPrinting(true);

      let args = printArgs;

      if (!args) {
        let queryObj = query(
          collection(getFirestore(), `realms/${realmId}/orders`),
          orderBy("timestamp", "desc"),
          limit(1)
        );

        if (businessCode) {
          queryObj = query(queryObj, where("type", "==", BUSINESS_TYPE_SHOP), where("business.id", "==", businessCode));
        }

        const latestOrder = await getDocs(queryObj);

        // Default testing prinit content
        switch (printerType) {
          case PRINTER_TYPE_RECEIPT:
            if (latestOrder.empty) {
              args = {
                lines: [
                  {
                    type: LINE_TYPE_TITLE,
                    align: LINE_ALIGN_CENTER,
                    raw: "Test title",
                  },
                ],
              };
            } else {
              const receipt = await getDoc(doc(getFirestore(), `realms/${realmId}/receipts/${latestOrder.docs[0].id}`));
              args = receipt.data();
            }
            break;
          case PRINTER_TYPE_LABEL:
            if (latestOrder.empty) {
              args = {
                label: {
                  title: `Test title`,
                  description: "Test content",
                },
              };
            } else {
              args = {
                labelOrder: latestOrder.docs[0],
              };
            }
            break;
        }
      }

      await printer.print(args || {}, device, settings);
    } catch (err) {
      message.error(err.message);
    } finally {
      setPrinting(false);
    }
  };

  return (
    <Button disabled={!printer || printing} loading={printing} icon={<PrinterOutlined />} onClick={onTestPrint}>
      <FormattedMessage tagName="span" defaultMessage="Test print" />
    </Button>
  );
}
