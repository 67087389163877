"use client";

import { createContext, useContext, useMemo } from "react";
import { useClientDate } from "./LiveStateContext";

const SettingContext = createContext();

export function SettingProvider({ profileConfig, businessSetting, children }) {
  const context = useMemo(() => {
    return {
      businessSetting: businessSetting || {},
      profileConfig: profileConfig || {},
    };
  }, [businessSetting, profileConfig]);

  return <SettingContext.Provider value={context}>{children}</SettingContext.Provider>;
}

export const useBusinessSettings = () => useContext(SettingContext).businessSetting;
export const useIsDayClosed = () => {
  const date = useClientDate();
  const { closedDate } = useBusinessSettings(date);
  return closedDate === date;
};
export const useProfileConfig = () => useContext(SettingContext)?.profileConfig || {};
export const useIsNameEditDisabled = () => useContext(SettingContext)?.profileConfig?.nameEditable === false;
