import { useProfileConfig, useToast } from "@easybiz/context";
import { CUSTOMER_OPERATION_PROFILE_SAVE, OPERATION_GROUP_CUSTOMER, isValidEmail } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const profileConfig = useProfileConfig();
  const { askName = true, askTel, askEmail } = profileConfig;
  const toast = useToast();
  const intl = useIntl();

  const onSubmit = useCallback(
    ({ name, email, phoneNumber, avatar, customerId, editOrderId, remarks, address, birthday, tels, businessCode }) => {
      const formattedName = name && `${name}`.trim();
      const formattedEmail = email && `${email}`.trim().toLowerCase();

      if (!customerId) {
        if (askName && !formattedName) {
          return intl.formatMessage({ defaultMessage: "Name can not be empty" });
        }

        if (askTel && !phoneNumber && (!Array.isArray(tels) || !tels?.find((tel) => tel?.number))) {
          return intl.formatMessage({ defaultMessage: "Phone number can not be empty" });
        }

        if (askEmail && !formattedEmail)
          return intl.formatMessage({ defaultMessage: "Please leave your email address" });

        if (!businessCode) {
          return intl.formatMessage({ defaultMessage: "Please select customer register place" });
        }
      }

      if (phoneNumber?.valid === false || tels?.find((phoneNumber) => phoneNumber.valid === false)) {
        return intl.formatMessage({ defaultMessage: "Please enter a valid phone number" });
      }

      if (formattedEmail && !isValidEmail(formattedEmail))
        return intl.formatMessage({ defaultMessage: "Please enter a valid email address" });

      return [
        OPERATION_GROUP_CUSTOMER,
        CUSTOMER_OPERATION_PROFILE_SAVE,
        {
          businessCode,
          ...(customerId && { customerId }),
          ...(formattedName && { name: formattedName }),
          ...(phoneNumber && { phoneNumber }),
          ...(tels && { tels }),
          ...(birthday && { birthday }),
          ...(typeof remarks === "string" && { remarks }),
          ...(typeof address === "string" && { address }),
          ...(avatar && { avatar }),
          ...(typeof email === "string" && { email }),
          ...(editOrderId && { editOrderId }),
        },
      ];
    },
    [askName, askTel, askEmail]
  );

  return useActionCallback(onSubmit, callback, (request, response) => {
    const { name, phoneNumber, email, avatar } = request;
    const updates = (name ? 1 : 0) + (phoneNumber ? 1 : 0) + (email ? 1 : 0) + (avatar ? 1 : 0);

    toast.success(
      response.newCustomer
        ? intl.formatMessage({ defaultMessage: "New account created" })
        : updates > 1
        ? intl.formatMessage({ defaultMessage: "Profile updated" })
        : phoneNumber
        ? intl.formatMessage({ defaultMessage: "Phone number updated" })
        : email
        ? intl.formatMessage({ defaultMessage: "Email updated" })
        : intl.formatMessage({ defaultMessage: "Profile updated" })
    );
  });
};
