import React from "react";
import OperationMonitorTag from "../OperationMonitorTag";
import QuickSwitchCalendar from "../QuickSwitchCalendar";

export default function ({ title, supplierId, bySupplier, value, selects, onSelect }) {
  return (
    <QuickSwitchCalendar
      title={title}
      value={value}
      dateCellRender={(selectDate, sameMonth) => {
        if (selectDate && sameMonth) {
          return (
            <OperationMonitorTag
              supplierId={supplierId}
              bySupplier={bySupplier}
              date={selectDate.format("YYYY-MM-DD")}
              selects={selects}
            />
          );
        }
      }}
      onSelect={onSelect}
    />
  );
}
