import translate from "./translate.js";

export default function (doc) {
  return {
    id: doc.id,
    type: doc.get("type"),
    title: translate(doc?.get("title")),
    ...(doc?.get("code") && { code: doc?.get("code") }),
    ...(doc?.get("enableOthers") && { enableOthers: doc?.get("enableOthers") }),
    ...(doc?.get("options") && { options: doc?.get("options") }),
    ...(doc?.get("printOnLabel") && { printOnLabel: doc?.get("printOnLabel") }),
    ...(doc?.get("mandatory") && { mandatory: doc?.get("mandatory") }),
  };
}
