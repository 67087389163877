import { HistoryOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import Title from "../Title";
import JobHistoryList from "./JobHistoryList";

export default function ({ taskId, placement }) {
  return (
    <Popover
      trigger={"click"}
      destroyTooltipOnHide
      title={
        <Title>
          <FormattedMessage defaultMessage={"Job history"} />
        </Title>
      }
      content={<JobHistoryList taskId={taskId} style={{ width: 400 }} />}
      placement={placement || "left"}
    >
      <Button style={{ minWidth: 64 }} icon={<HistoryOutlined />} />
    </Popover>
  );
}
