import { useLabelHistoryList } from "@easybiz/component";
import { useItemHistoryQuery } from "@easybiz/web-firebase";
import { List } from "antd";
import React from "react";

export default function LabelHistoryList({ labelId }) {
  const operations = useItemHistoryQuery(labelId);
  const { dataSource } = useLabelHistoryList(operations);

  return (
    <List
      size="small"
      style={{
        minWidth: 400,
        maxHeight: 600,
        overflowY: "auto",
      }}
      dataSource={dataSource || []}
      loading={!dataSource}
      renderItem={({ title, description }) => {
        return (
          <List.Item>
            <List.Item.Meta title={title} description={description} />
          </List.Item>
        );
      }}
    />
  );
}
