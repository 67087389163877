import { ShopFilled, ShopOutlined } from "@ant-design/icons";
import { DateDisplay, NameDisplay } from "@easybiz/component";
import { useBusinessCode, useBusinessSettings, useSetDisplayMode, useStoreLicenses } from "@easybiz/context";
import { BUSINESS_TYPE_SHOP } from "@easybiz/utils";
import { useCloseOfDayReportDoc } from "@easybiz/web-firebase";
import { Button, Menu } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import CloseOfDayDialog from "./CloseOfDayDialog";
import OrderSalesPanel from "./OrderSalesPanel";
import PanelHeader from "./PanelHeader";

export default ({ date, selectOrderId, onClose }) => {
  const storeCode = useBusinessCode();
  const [businessCode, setBusinessCode] = useState(storeCode);
  const [endOfDayVisible, setEndOfDayVisible] = useState(false);
  const { clients } = useBusinessSettings();
  const storeLicenses = useStoreLicenses();
  const validClients =
    Array.isArray(clients) &&
    Array.isArray(storeLicenses) &&
    clients.filter((client) => storeLicenses.indexOf(client) >= 0);
  const dateKey = date?.format("YYYY-MM-DD");
  const today = dayjs().format("YYYY-MM-DD");
  const report = useCloseOfDayReportDoc(dateKey, businessCode);
  const orderListHeight = window.innerHeight - 140;
  const setDisplayMode = useSetDisplayMode();

  const onSelectOrder = useCallback(
    (order) => {
      setDisplayMode((displayMode) => ({
        ...displayMode,
        orderId: displayMode.orderId === order.id ? null : order.id,
      }));
    },
    [setDisplayMode]
  );

  return (
    <>
      <PanelHeader
        title={
          <span>
            <FormattedMessage defaultMessage={"Daily Sales"} />
            {` `}(<DateDisplay date={dateKey} />)
          </span>
        }
        onClose={onClose}
        extra={
          report?.exists() === false && (
            <Button
              loading={!report}
              type={report?.exists() === false && today === dateKey ? "primary" : "default"}
              disabled={dateKey > today}
              onClick={() => setEndOfDayVisible(true)}
            >
              <FormattedMessage tagName={"span"} defaultMessage={"Close of day"} />
            </Button>
          )
        }
      />
      <div className="flex flex-row flex-fill scroll-container">
        {Array.isArray(validClients) && validClients.length > 0 && (
          <div className="scroll-body padding-horizontal-half">
            <Menu
              selectedKeys={[businessCode]}
              onSelect={({ key }) => setBusinessCode(key)}
              items={[
                {
                  key: storeCode,
                  icon: <ShopFilled />,
                  label: <NameDisplay nameKey={storeCode} />,
                },
                {
                  type: "group",
                  label: <FormattedMessage defaultMessage={"Client stores"} />,
                  children: validClients.map((businessCode) => ({
                    key: businessCode,
                    icon: <ShopOutlined />,
                    label: <NameDisplay nameKey={businessCode} />,
                  })),
                },
              ]}
            />
          </div>
        )}
        <div className="flex flex-fill scroll-body">
          <OrderSalesPanel
            height={orderListHeight}
            businessCode={businessCode}
            orderType={BUSINESS_TYPE_SHOP}
            selectOrderId={selectOrderId}
            onSelect={onSelectOrder}
            date={date}
            reportDoc={report}
          />
          <br />
          <br />
          <br />
        </div>
      </div>
      <CloseOfDayDialog
        dateKey={dateKey}
        open={endOfDayVisible}
        onCancel={() => setEndOfDayVisible(false)}
        onComplete={() => setEndOfDayVisible(false)}
      />
    </>
  );
};
