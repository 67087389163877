import {
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  BUSINESS_SECTOR_LAUNDRY,
} from "./constants/index.js";

export default function (businessSector) {
  return (
    [
      BUSINESS_SECTOR_LAUNDRY,
      BUSINESS_SECTOR_ALTERATION,
      BUSINESS_SECTOR_BAG_SERVICE,
    ].indexOf(businessSector || BUSINESS_SECTOR_LAUNDRY) >= 0
  );
}
