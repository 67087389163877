import {
  BorderlessTableOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileDoneOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { AmountDisplay } from "@easybiz/component";
import { SalesReport } from "@easybiz/web-admin";
import { useAppDaySales } from "@easybiz/web-firebase";
import { Button, Segmented, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import CloseOfDayWithdrawDialog from "./CloseOfDayWithdrawDialog";
import DailyIncomeList from "./DailyIncomeList";
import DailyOrderList from "./DailyOrderList";
import DailySalesList from "./DailySalesList";

const TAB_REPORT = "report";
const TAB_ORDER = "order";
const TAB_SALES = "sales";
const TAB_PAYMENT = "payment";

export default ({ reportDoc, businessCode, orderType, date, selectOrderId, onSelect }) => {
  const report = useAppDaySales(date.format("YYYY-MM-DD"), businessCode);
  const [activeTab, setActiveTab] = useState();
  const [withdrawVisible, setWithdrawVisible] = useState(false);

  useEffect(() => {
    if (reportDoc?.exists()) {
      setActiveTab(TAB_REPORT);
    } else if (activeTab === TAB_REPORT) {
      setActiveTab(TAB_ORDER);
    }
  }, [reportDoc?.exists()]);

  const tabs = useMemo(() => {
    return [
      ...(reportDoc?.exists()
        ? [
            {
              icon: <FileDoneOutlined />,
              label: <FormattedMessage defaultMessage={"Close of day"} />,
              value: TAB_REPORT,
              key: TAB_REPORT,
              children: (
                <div className="flex flex-align-center padding-top-half">
                  <SalesReport lines={reportDoc.get("lines")} />
                  <br />
                  <Button type="text" danger icon={<RollbackOutlined />} onClick={() => setWithdrawVisible(true)}>
                    <FormattedMessage tagName={"span"} defaultMessage={"Withdraw report"} />
                  </Button>
                </div>
              ),
            },
          ]
        : []),
      {
        icon: <BorderlessTableOutlined />,
        label: <FormattedMessage defaultMessage={"{count} orders"} values={{ count: report?.order || 0 }} />,
        value: TAB_ORDER,
        key: TAB_ORDER,
        children: (
          <DailyOrderList
            businessCode={businessCode}
            date={date}
            orderType={orderType}
            onSelect={onSelect}
            selectOrderId={selectOrderId}
          />
        ),
      },
      {
        icon: <DollarOutlined />,
        label: (
          <FormattedMessage
            defaultMessage={"{amount} sales"}
            values={{ amount: <AmountDisplay value={report?.net || 0} /> }}
          />
        ),
        value: TAB_SALES,
        key: TAB_SALES,
        children: <DailySalesList sales={report?.sales} date={date.format("YYYY-MM-DD")} />,
      },
      {
        icon: <CreditCardOutlined />,
        label: (
          <FormattedMessage
            defaultMessage={"{amount} paid"}
            values={{ amount: <AmountDisplay value={report?.paid || 0} /> }}
          />
        ),
        value: TAB_PAYMENT,
        key: TAB_PAYMENT,
        children: <DailyIncomeList incomes={report?.incomes} date={date.format("YYYY-MM-DD")} />,
      },
    ];
  }, [reportDoc, report, businessCode, date, selectOrderId, onSelect]);

  return (
    <div className="padding-horizontal-half">
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        renderTabBar={({ activeKey }) => (
          <Segmented block size="large" value={activeKey} onChange={setActiveTab} options={tabs} />
        )}
        items={tabs}
      />
      <CloseOfDayWithdrawDialog
        date={date.format("YYYY-MM-DD")}
        open={withdrawVisible}
        onCancel={() => setWithdrawVisible(false)}
      />
    </div>
  );
};
