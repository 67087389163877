import { LoadMoreButton, OrderList } from "@easybiz/web-admin";
import { useOrderStateQuery } from "@easybiz/web-firebase";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function OrderLoadMoreQueryList({ queryParams, onSelect }) {
  const [limit, setLimit] = useState(5);
  const [orders, loading] = useOrderStateQuery(queryParams ? { limit, ...queryParams } : null);

  return (
    <>
      <OrderList
        fullOrderNo={!queryParams?.businessCode}
        orders={orders}
        onSelect={onSelect}
        search={queryParams?.search}
        loading={loading}
        emptyText={
          queryParams?.search ? (
            <FormattedMessage
              defaultMessage={"No order found by searching {search}"}
              values={{ search: <b>{queryParams?.search}</b> }}
            />
          ) : queryParams?.customerId ? null : (
            <FormattedMessage
              defaultMessage={"Type to search order by order number, customer name, tel, email or order line items"}
            />
          )
        }
      />
      <LoadMoreButton loading={loading} dataSource={orders} onClick={() => setLimit(limit + 5)} />
    </>
  );
}
