import { JOB_OPERATION_REVERSE, OPERATION_GROUP_JOB } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ taskId }) => {
    return [
      OPERATION_GROUP_JOB,
      JOB_OPERATION_REVERSE,
      {
        taskId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Job status reversed" }));
};
