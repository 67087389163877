import dayjs from "dayjs";
import {
  LABEL_PLACEHOLDERS,
  PLACEHOLDER_AGENT_NAME,
  PLACEHOLDER_BALANCE_AMOUNT,
  PLACEHOLDER_BUSINESS_CODE,
  PLACEHOLDER_COLLECTION_PLACE,
  PLACEHOLDER_CUSTOMER_CONTACT,
  PLACEHOLDER_CUSTOMER_NAME,
  PLACEHOLDER_DELIVERY_DATE,
  PLACEHOLDER_DELIVERY_WEEK_DAY,
  PLACEHOLDER_DEPARTMENT,
  PLACEHOLDER_ITEM_DETAILS,
  PLACEHOLDER_ITEM_NAME,
  PLACEHOLDER_ITEM_REMARK,
  PLACEHOLDER_ORDER_NO,
  PLACEHOLDER_ORDER_TOTAL_UNIT,
  PLACEHOLDER_ORDER_UNIT_INDEX,
  PLACEHOLDER_PACKAGE_INDEX,
  PLACEHOLDER_PACKING_DATE,
  PLACEHOLDER_PACKING_PCS,
  PLACEHOLDER_RECEVING_DATE,
  PLACEHOLDER_RECEVING_WEEK_DAY,
  PLACEHOLDER_SERVICE_OPTION,
  PLACEHOLDER_SET_PCS_INDEX,
  PLACEHOLDER_SET_TOTAL_PCS,
  PLACEHOLDER_TOTAL_PACKAGE,
} from "./constants/label.js";
import translate from "./translate.js";

export default function (
  template,
  {
    businessCode,
    option,
    optionId,
    optionCode,
    subOptions,
    selectSteps,
    date,
    note,
    subType,
    product,
    orderNo,
    customer,
    unitIndex,
    totalUnit,
    unitTotalPcs,
    unitPcsIndex,
    totalBalance,
    timestamp,
    homeDelivery,
    collectionPlace,
    delivery,
    department,
    agent,
    packingPcs,
    index,
    total,
  },
  context
) {
  const { businessCodes, stores, webapps, serviceTypes } = context || {};

  let placeholder,
    protection = 100;

  do {
    protection--;
    placeholder = LABEL_PLACEHOLDERS.find((item) => template.indexOf(`@${item}`) >= 0);

    if (placeholder) {
      let replacement = "";
      switch (placeholder) {
        case PLACEHOLDER_BUSINESS_CODE:
          replacement = `${
            (businessCodes && businessCodes[businessCode]) ||
            (stores && stores[businessCode]) ||
            (webapps && webapps[businessCode]) ||
            ""
          }`;
          break;
        case PLACEHOLDER_ORDER_NO:
          replacement = `${orderNo || ""}`;
          break;
        case PLACEHOLDER_ORDER_TOTAL_UNIT:
          replacement = `${totalUnit || ""}`;
          break;
        case PLACEHOLDER_ORDER_UNIT_INDEX:
          replacement = `${unitIndex || ""}`;
          break;
        case PLACEHOLDER_SET_TOTAL_PCS:
          replacement = `${unitTotalPcs || ""}`;
          break;
        case PLACEHOLDER_SET_PCS_INDEX: {
          replacement = `${unitPcsIndex || ""}`;
          break;
        }
        case PLACEHOLDER_CUSTOMER_NAME:
          replacement = `${customer?.name || ""}`;
          break;
        case PLACEHOLDER_CUSTOMER_CONTACT:
          if (Array.isArray(customer?.tels)) {
            replacement = customer.tels.map(({ number, intl }) => intl || number).join(", ");
          }
          break;
        case PLACEHOLDER_ITEM_NAME: {
          replacement = `${(product && translate(product)) || ""}${subType ? `-${translate(subType)}` : ""}`;
          break;
        }
        case PLACEHOLDER_SERVICE_OPTION: {
          let options = [];

          if (optionCode) {
            options.push(optionCode);
          } else if (serviceTypes && serviceTypes[optionId]) {
            options.push(serviceTypes[optionId]);
          } else if (translate(option)) {
            options.push(translate(option));
          }

          if (subOptions) {
            subOptions.forEach(({ code }) => {
              if (code) {
                options.push(code);
              }
            });
          }

          replacement = options.join(" | ");
          break;
        }
        case PLACEHOLDER_RECEVING_DATE: {
          const receive = dayjs.unix(timestamp);
          replacement = receive.format("DD/MM");
          break;
        }
        case PLACEHOLDER_RECEVING_WEEK_DAY: {
          const receive = dayjs.unix(timestamp);
          replacement = receive.format("dd");
          break;
        }
        case PLACEHOLDER_DELIVERY_DATE: {
          if (delivery?.date) {
            const receive = dayjs(delivery?.date, "YYYY-MM-DD");
            replacement = receive.format("DD/MM");
          }
          break;
        }
        case PLACEHOLDER_DELIVERY_WEEK_DAY: {
          if (delivery?.date) {
            const receive = dayjs(delivery?.date, "YYYY-MM-DD");
            replacement = receive.format("dd");
          }
          break;
        }
        case PLACEHOLDER_BALANCE_AMOUNT: {
          replacement = totalBalance ? `BAL: ${totalBalance}` : "";
          break;
        }
        case PLACEHOLDER_ITEM_REMARK: {
          replacement = note ? `* ${note}` : "";
          break;
        }
        case PLACEHOLDER_ITEM_DETAILS: {
          replacement = (selectSteps || [])
            .filter((step) => step.printOnLabel)
            .map(({ value }) => {
              let content = ``;
              if (typeof value === "string") {
                content = value;
              } else if (Array.isArray(value)) {
                content = value
                  .map(({ code }) => code)
                  .filter((code) => code)
                  .join(", ");
              }
              return content;
            })
            .join(" | ");
          break;
        }
        case PLACEHOLDER_COLLECTION_PLACE: {
          replacement = homeDelivery
            ? `Home Delivery`
            : (businessCodes && businessCodes[collectionPlace]) ||
              (stores && stores[collectionPlace]) ||
              (webapps && webapps[collectionPlace]) ||
              "";
          break;
        }
        case PLACEHOLDER_DEPARTMENT: {
          replacement = `${department || ""}`;
          break;
        }
        case PLACEHOLDER_PACKING_PCS: {
          replacement = `${packingPcs || ""}`;
          break;
        }
        case PLACEHOLDER_AGENT_NAME: {
          replacement = agent?.name || ``;
          break;
        }
        case PLACEHOLDER_PACKAGE_INDEX: {
          replacement = index || ``;
          break;
        }
        case PLACEHOLDER_TOTAL_PACKAGE: {
          replacement = total || ``;
          break;
        }
        case PLACEHOLDER_PACKING_DATE: {
          if (date) {
            const receive = dayjs(date, "YYYY-MM-DD");
            replacement = receive.format("DD/MM");
          }
          break;
        }
      }

      const params = template.match(new RegExp(`@${placeholder}=\{(.*?)\}`, "i"));
      let regHolder;
      if (params) {
        regHolder = params[0];
        if (params[1] !== replacement) {
          replacement = "";
        }
      } else {
        regHolder = `@${placeholder}`;
      }

      template = template.replace(new RegExp(regHolder, "g"), replacement || "");
    }
  } while (placeholder && protection > 0);

  template = template.trim();
  template = template.replace(/ {1,}/g, " ");

  return template;
}
