"use client";

import { createContext, useContext, useMemo, useState } from "react";

const PushNotificationContext = createContext();

export function PushNotificationProvider({ children }) {
  const [pushToken, setPushToken] = useState();

  const context = useMemo(() => {
    return {
      pushToken,
      setPushToken,
    };
  }, [pushToken]);

  return <PushNotificationContext.Provider value={context}>{children}</PushNotificationContext.Provider>;
}

export const usePushToken = () => useContext(PushNotificationContext).pushToken;
export const useSetPushToken = () => useContext(PushNotificationContext).setPushToken;
