import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { escapeRegExp } from "@easybiz/utils";
import { Button, Input, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import IconButton from "../IconButton";

export default ({
  dataKey,
  clearTrigger,
  disabled,
  options,
  optionKey,
  secondOptionKey,
  onFiltered,
  onSearch,
  extra,
}) => {
  const [search, setSearch] = useState();
  const [searching, setSearching] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (search) {
      setSearch(null);
    }
  }, [dataKey]);

  useEffect(() => {
    if (clearTrigger && search) {
      setSearching(false);
      setSearch(null);
    }
  }, [clearTrigger]);

  useEffect(() => {
    if (onSearch) {
      onSearch(search);
    }
  }, [search]);

  const { indexedOptions, indexes } = useMemo(() => {
    const indexedOptions = (options || []).map((option) => {
      let indexes = [];

      const key = option && (option[optionKey] || option[secondOptionKey]);
      if (key) {
        indexes = [].concat
          .apply(
            [],
            `${key}`
              .split(" ")
              .map((content) =>
                `${content || ""}`.split("/").map((data) => `${data || ""}`.replace(/\W+/g, " ").trim())
              )
          )
          .filter((data) => data && `${data}`.length > 2)
          .map((data) => `${data}`.trim().slice(0, 1).toUpperCase());
        indexes = indexes.filter((data, index) => indexes.indexOf(data) === index);
      }

      return {
        option,
        indexes,
      };
    });

    let allIndexes = [].concat.apply(
      [],
      indexedOptions.map((data) => data.indexes)
    );
    allIndexes = allIndexes.filter((data, index) => allIndexes.indexOf(data) === index).sort();

    if (indexedOptions.find(({ indexes }) => indexes.length === 0)) {
      allIndexes.push("?");
    }

    return {
      indexedOptions,
      indexes: allIndexes,
    };
  }, [options, optionKey, secondOptionKey]);

  useEffect(() => {
    if (search && Array.isArray(indexedOptions)) {
      if (searching) {
        const regX = new RegExp(escapeRegExp(search), "i");
        onFiltered((options || []).filter((option) => regX.test(option[optionKey] || option[secondOptionKey])));
      } else {
        onFiltered(
          indexedOptions
            .filter(({ indexes }) => (search === "?" ? indexes.length === 0 : indexes.indexOf(search) >= 0))
            .map(({ option }) => option)
        );
      }
    } else {
      onFiltered(null);
    }
  }, [indexedOptions, optionKey, secondOptionKey, search, searching]);

  if (searching) {
    return (
      <Space className="padding-horizontal-half" wrap>
        <Input.Search
          placeholder={intl.formatMessage({ defaultMessage: "Search" })}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autoFocus
        />
        <IconButton icon={<CloseOutlined />} onClick={() => setSearching(false)} />
      </Space>
    );
  } else {
    return (
      <Space wrap>
        <Button disabled={disabled} type="link" icon={<SearchOutlined />} onClick={() => setSearching(true)} />
        {indexes.map((index) => {
          return (
            <Button
              key={index}
              disabled={disabled}
              type={search === index ? "primary" : "link"}
              onClick={() => setSearch(search === index ? null : index)}
            >
              {index}
            </Button>
          );
        })}
        {extra}
      </Space>
    );
  }
};
