import { ApiOutlined, CreditCardOutlined, MobileOutlined, ScanOutlined, TabletOutlined } from "@ant-design/icons";
import { DevicePairingModeDisplay } from "@easybiz/component";
import { usePOSDownloadLink } from "@easybiz/context";
import {
  PAIRING_MODE_CUSTOMER_SCREEN,
  PAIRING_MODE_MOBILE_OPERATION,
  PAIRING_MODE_PAYMENT_TERMINAL,
} from "@easybiz/utils";
import { Dialog } from "@easybiz/web-admin";
import { Alert, Button, Divider, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import DevicePairingQRCode from "./DevicePairingQRCode";

export default ({ open, onCancel }) => {
  const posLink = usePOSDownloadLink();
  const [pairingMode, setPairingMode] = useState(null);

  useEffect(() => {
    setPairingMode(null);
  }, [open]);

  return (
    <Dialog
      title={<FormattedMessage defaultMessage="Device Pairing" />}
      open={open}
      onCancel={onCancel}
      onOk={onCancel}
      okType="default"
      okText={<FormattedMessage defaultMessage={"Close"} />}
    >
      {!pairingMode && (
        <Alert
          icon={<ApiOutlined />}
          showIcon
          type="info"
          message={
            <FormattedMessage defaultMessage={"Please select the type of device you want to pair with your POS"} />
          }
        />
      )}
      {!pairingMode && <br />}
      <Space direction="vertical" align="center" size={"large"} className="full-width">
        <Space wrap>
          {[
            {
              key: PAIRING_MODE_CUSTOMER_SCREEN,
              icon: <TabletOutlined />,
            },
            {
              key: PAIRING_MODE_MOBILE_OPERATION,
              icon: <MobileOutlined />,
            },
            {
              key: PAIRING_MODE_PAYMENT_TERMINAL,
              icon: <CreditCardOutlined />,
              disabled: true,
            },
          ].map(({ key, icon, disabled }) => (
            <Button
              icon={icon}
              type={pairingMode === key ? "primary" : "default"}
              onClick={() => setPairingMode(key)}
              disabled={disabled}
            >
              <span>
                <DevicePairingModeDisplay pairingMode={key} />
              </span>
            </Button>
          ))}
        </Space>
        {pairingMode && (
          <Divider style={{ margin: 0 }}>
            <DevicePairingModeDisplay pairingMode={pairingMode} />
          </Divider>
        )}
        {pairingMode && <DevicePairingQRCode pairingMode={pairingMode} />}
        {pairingMode && (
          <Alert
            icon={<ScanOutlined />}
            showIcon
            type="info"
            message={
              <FormattedMessage
                defaultMessage={"Scan QR code using <a>EasyBiz POS - Point of Sale</a> mobile App"}
                values={{
                  a: (t) => (
                    <a href={posLink} target="_blank" rel="noreferrer">
                      {t}
                    </a>
                  ),
                }}
              />
            }
          />
        )}
      </Space>
    </Dialog>
  );
};
