import { useScheduleConfirmDialog } from "@easybiz/component";
import { Descriptions, Input, Space } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import Dialog from "../Dialog";
import SurchargeEditButton from "../SurchargeEditButton";
import Title from "../Title";

export default function ({ open, onCancel, order, newSchedule: initSchedule }) {
  const intl = useIntl();
  const { title, okText, onOk, loading, newSchedule, onScheduleChange, note, onNoteChange, descriptions } =
    useScheduleConfirmDialog(open ? order : null, initSchedule, onCancel);

  return (
    <Dialog title={title} open={open} onCancel={onCancel} confirmLoading={loading} okText={okText} onOk={onOk}>
      <Space direction="vertical" size={"middle"} className="full-width">
        <Descriptions bordered column={1}>
          {descriptions.map(({ label, content, isSurcharge }, index) => (
            <Descriptions.Item key={`${index}`} label={label}>
              <Title>{content}</Title>
              {isSurcharge ? ` ` : null}
              {isSurcharge && (
                <SurchargeEditButton
                  disabled={loading}
                  surcharge={newSchedule?.option?.surcharge}
                  onChange={(surcharge) => {
                    onScheduleChange({
                      ...newSchedule,
                      option: { ...newSchedule.option, surcharge },
                    });
                  }}
                />
              )}
            </Descriptions.Item>
          ))}
        </Descriptions>
        {order && (
          <Input.TextArea
            placeholder={intl.formatMessage({ defaultMessage: "Note" })}
            value={note}
            disabled={loading}
            onChange={(e) => onNoteChange(e.target.value)}
          />
        )}
      </Space>
    </Dialog>
  );
}
