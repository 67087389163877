"use client";

import { formatOnlinePaymentMethods, PAYMENT_ID_STRIPE } from "@easybiz/utils";
import { createContext, useContext, useMemo } from "react";

const PaymentMethodContext = createContext();

export function PaymentMethodProvider({ client, realmDoc, businessDoc, offlinePaymentMethods, children }) {
  const context = useMemo(() => {
    return {
      onlineMethods: formatOnlinePaymentMethods(client, realmDoc, businessDoc),
      offlinePaymentMethods,
    };
  }, [client, realmDoc, businessDoc, offlinePaymentMethods]);

  return <PaymentMethodContext.Provider value={context}>{children}</PaymentMethodContext.Provider>;
}

export const useOnlinePaymentMethods = () => useContext(PaymentMethodContext).onlineMethods;
export const useIsCreditCardEnabled = () =>
  Boolean(useContext(PaymentMethodContext).onlineMethods?.find((payment) => payment.id === PAYMENT_ID_STRIPE));
export const useOfflinePaymentMethods = (refund) => {
  const { offlinePaymentMethods } = useContext(PaymentMethodContext);
  return useMemo(
    () =>
      (offlinePaymentMethods || [])
        .map(({ refundable, id, ...others }) => {
          if (refundable || !refund) {
            return {
              ...others,
              option: id,
              id,
            };
          }
        })
        .filter((data) => data),
    [offlinePaymentMethods, refund]
  );
};
