import { EditOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function MenuEditButton({ icon, title, disabled, onClick, onSelect, items, loading, onSubmit }) {
  const intl = useIntl();

  return (
    <Dropdown.Button
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      overlay={
        <Menu
          onClick={(e) => {
            const result = onSelect(e.key);

            if (typeof result?.onOk === "function") {
              Modal.confirm({
                title: result.title,
                content: result.content,
                ...(e.key === "delete" && {
                  okText: intl.formatMessage({ defaultMessage: "Delete" }),
                  okType: "danger",
                }),
                ...(result.okText && { okText: result.okText }),
                onOk: () => {
                  onSubmit(result.onOk());
                },
              });
            } else if (result) {
              onSubmit(result);
            }
          }}
          items={items || []}
        />
      }
    >
      {icon || <EditOutlined />} {title || <FormattedMessage tagName="span" defaultMessage="Edit" />}
    </Dropdown.Button>
  );
}
