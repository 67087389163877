import { OrderList } from "@easybiz/web-admin";
import { useBusinessCode } from "@easybiz/context";
import { useOrderStateQuery } from "@easybiz/web-firebase";
import React, { useState } from "react";

export default function ({ awaitAction, selects, onSelect, mutipleSelect }) {
  const [limit, setLimit] = useState(10);
  const businessCode = useBusinessCode();
  const [orders, loading] = useOrderStateQuery({
    awaitAction,
    limit,
    supplierId: businessCode,
  });

  return (
    <div style={{ height: window.innerHeight - 170, overflowY: "auto" }}>
      <OrderList
        selects={selects}
        orders={orders}
        loading={loading}
        onLoadMore={() => setLimit(limit + 10)}
        onSelect={onSelect}
        mutipleSelect={mutipleSelect}
      />
    </div>
  );
}
