"use client";

import { useErrorContainerColor } from "@easybiz/context";
import { calculateUnitIndex } from "@easybiz/utils";
import { useIntl } from "react-intl";
import ProcedureDisplay from "./ProcedureDisplay";

export default (order, sortIndex, checkProcedure) => {
  const { procedures, itemsStatus, items } = order?.data() || {};
  const currentIndex = (itemsStatus && itemsStatus[sortIndex]) || 0;
  const errorColor = useErrorContainerColor();
  const { unitIndex, unitTotalPcs, unitPcsIndex, salesReturn, redo } = calculateUnitIndex(items, sortIndex);
  const checkIndex = Array.isArray(procedures) && checkProcedure ? procedures.indexOf(checkProcedure) : -1;
  const completed = checkProcedure ? currentIndex >= checkIndex : false;
  const intl = useIntl();

  return {
    backgroundColor: salesReturn || redo ? errorColor : completed ? "#4cae4f" : `#eeeeee`,
    title: (
      <>
        {`#${unitIndex}${unitTotalPcs ? ` (${unitPcsIndex}/${unitTotalPcs})` : ""} `}
        <ProcedureDisplay procedure={procedures && procedures[currentIndex]} />
        {salesReturn ? ` [${intl.formatMessage({ defaultMessage: "Sales return" })}]` : ""}
        {redo ? ` [${intl.formatMessage({ defaultMessage: "Redo" })}]` : ""}
      </>
    ),
  };
};
