import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { AmountDisplay, PaymentMethodDisplay } from "@easybiz/component";
import { useCurrency, useErrorContainerColor, useSecondaryContainerColor } from "@easybiz/context";
import { formatNumber, PAYMENT_ID_CREDIT } from "@easybiz/utils";
import { Button, List, message, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../IconButton";
import PaymentReferenceInput from "../PaymentReferenceInput";
import QtyInputPopover from "../QtyInputPopover";
import Title from "../Title";

export default function ({
  header,
  pointsOption,
  totalAmount,
  totalPayable,
  totalOutstanding,
  pricingTBD,
  pointsPayment,
  payment,
  onPaymentChange,
  onPointsPaymentChange,
  enablePartial,
  totalPayableAction,
  onFocusChange,
  disabled,
}) {
  const currency = useCurrency();
  const intl = useIntl();
  const [amountVisible, setAmountVisible] = useState(false);
  const haveOutstanding = totalOutstanding > 0;
  const errorBackground = useErrorContainerColor();
  const successBackground = useSecondaryContainerColor();

  return (
    <>
      <List
        size="small"
        header={header}
        dataSource={[
          {
            title: pricingTBD ? (
              <FormattedMessage defaultMessage="Estimated total payable" />
            ) : (
              <FormattedMessage defaultMessage="Total payable" />
            ),
            amount: <AmountDisplay value={totalAmount} />,
            action: totalPayableAction,
          },
          ...(pointsPayment
            ? [
                {
                  title: (
                    <FormattedMessage
                      defaultMessage="Use points ({amount})"
                      values={{ amount: pointsPayment.points }}
                    />
                  ),
                  type: "success",
                  amount: (
                    <span>
                      <AmountDisplay value={-pointsPayment.amount} accounting />
                    </span>
                  ),
                  action: (
                    <IconButton
                      danger
                      icon={<CloseOutlined />}
                      disabled={disabled}
                      onClick={() => {
                        onPointsPaymentChange(null);
                        onPaymentChange(undefined);
                      }}
                    />
                  ),
                },
              ]
            : pointsOption
            ? [
                {
                  description: (
                    <span>
                      <FormattedMessage
                        tagName="span"
                        defaultMessage="Customer points {amount}"
                        values={{ amount: pointsOption.totalPoints }}
                      />{" "}
                      <b>
                        <AmountDisplay value={-pointsOption.amount} accounting />
                      </b>
                    </span>
                  ),
                  action: (
                    <Button
                      onClick={() => {
                        onPointsPaymentChange(pointsOption);
                        onPaymentChange(undefined);
                      }}
                      disabled={disabled}
                    >
                      <FormattedMessage tagName="span" defaultMessage="Use points" />
                    </Button>
                  ),
                },
              ]
            : []),
          ...(totalPayable === 0
            ? []
            : payment === undefined
            ? [
                {
                  title: <FormattedMessage defaultMessage="Pay by" />,
                  type: "danger",
                },
              ]
            : [
                {
                  title: <FormattedMessage defaultMessage="Pay by" />,
                  description: payment ? (
                    <PaymentReferenceInput
                      payment={payment}
                      onChange={onPaymentChange}
                      onFocusChange={onFocusChange}
                      disabled={disabled}
                    />
                  ) : null,
                  amount: (
                    <b>
                      {payment ? (
                        <PaymentMethodDisplay option={payment.option} />
                      ) : (
                        <Title type={"danger"} fontSize={22}>
                          <FormattedMessage defaultMessage={"Unpaid"} />
                        </Title>
                      )}
                    </b>
                  ),
                  amountStyle: { maxWidth: 150 },
                  type: payment ? null : "danger",
                  action: (
                    <IconButton
                      icon={<EditOutlined />}
                      disabled={disabled}
                      onClick={() => onPaymentChange(undefined)}
                    />
                  ),
                },
                ...(payment
                  ? [
                      {
                        title: <FormattedMessage defaultMessage="Amount paid" />,
                        type: "success",
                        amount: <AmountDisplay value={payment.amount} />,
                        action: enablePartial ? (
                          <QtyInputPopover
                            title={<FormattedMessage defaultMessage="Partial Payment" />}
                            isCurrency
                            placement="left"
                            open={amountVisible}
                            onOpenChange={setAmountVisible}
                            onDone={(amount) => {
                              if (typeof amount !== "number") {
                                amount = totalPayable;
                              }

                              if (amount === 0)
                                return message.info(
                                  intl.formatMessage({
                                    defaultMessage: "Payment amount can not be 0",
                                  })
                                );
                              if (
                                payment?.option === PAYMENT_ID_CREDIT &&
                                typeof payment.balance === "number" &&
                                amount > payment.balance
                              ) {
                                return message.info(
                                  intl.formatMessage(
                                    {
                                      defaultMessage:
                                        "Payment amount can not larger than current credit balance of {amount}",
                                    },
                                    {
                                      amount: formatNumber(totalPayable, currency),
                                    }
                                  )
                                );
                              }
                              if (amount > totalPayable)
                                return message.info(
                                  intl.formatMessage(
                                    {
                                      defaultMessage: "Payment amount can not greater than total payable of {amount}",
                                    },
                                    {
                                      amount: formatNumber(totalPayable, currency),
                                    }
                                  )
                                );

                              onPaymentChange({ ...payment, amount });
                              setAmountVisible(false);
                            }}
                          >
                            <IconButton
                              icon={<EditOutlined />}
                              disabled={disabled}
                              onClick={() => setAmountVisible(true)}
                            />
                          </QtyInputPopover>
                        ) : null,
                      },
                    ]
                  : []),
              ]),
          ...(payment || pointsPayment || payment === null || totalPayable === 0
            ? [
                {
                  title: <FormattedMessage defaultMessage="Outstanding" />,
                  type: haveOutstanding ? "danger" : null,
                  backgroundColor: haveOutstanding ? errorBackground : successBackground,
                  amount:
                    totalOutstanding === 0 ? (
                      <Title type="success" fontSize={22}>
                        <FormattedMessage defaultMessage={"Paid"} />
                      </Title>
                    ) : (
                      <AmountDisplay value={totalOutstanding} />
                    ),
                },
              ]
            : []),
        ]}
        renderItem={({ title, description, amountStyle, amount, action, type, backgroundColor }) => {
          return (
            <List.Item
              style={{ backgroundColor }}
              actions={[
                <Space>
                  {action}
                  {amount ? (
                    <Title type={type} style={amountStyle}>
                      {amount}
                    </Title>
                  ) : null}
                </Space>,
              ]}
            >
              <List.Item.Meta title={<Title>{title}</Title>} description={description} />
            </List.Item>
          );
        }}
      />
    </>
  );
}
