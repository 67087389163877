import { FileTextOutlined, HighlightOutlined } from "@ant-design/icons";
import {
  useCheckoutDate,
  useCheckoutPricing,
  useCheckoutEditOrder,
  useIsCheckoutDirty,
  useSetCheckoutEditOrder,
} from "@easybiz/checkout";
import { AmountDisplay, OrderNoDisplay } from "@easybiz/component";
import { useBlockingRequest, useClientDate, useIsDayClosed, useSetOpenOrderId } from "@easybiz/context";
import { ACTION_CONFIRM_PRICE } from "@easybiz/utils";
import { IconButton, OrderEditButton, OrderPricingPanel, Title } from "@easybiz/web-admin";
import { Button, Card, Col, Popover, Row, Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import EditOrderCloseButton from "./EditOrderCloseButton";

export default function EditOrderPanel() {
  const confirmOrder = useCheckoutEditOrder();
  const setConfirmOrder = useSetCheckoutEditOrder();
  const pricing = useCheckoutPricing();
  const dayClosed = useIsDayClosed();
  const setOpenOrderId = useSetOpenOrderId();
  const blocking = useBlockingRequest();
  const isDirty = useIsCheckoutDirty();
  const isPendingConfirm = Boolean(confirmOrder?.get("awaits")?.find((action) => action === ACTION_CONFIRM_PRICE));

  const onConfirmed = (response, request) => {
    setConfirmOrder(null);
    setOpenOrderId(request.orderId);
  };

  return (
    <div className="flex flex-fill scroll-container">
      <div className="flex flex-row flex-space-between flex-align-center padding-bottom-half">
        <Title className={"padding-left-half"}>
          <FormattedMessage
            defaultMessage={"Edit Order {orderNo}"}
            values={{
              orderNo: (
                <OrderNoDisplay orderNo={confirmOrder.get("orderNo")} businessCode={confirmOrder.get("business.id")} />
              ),
            }}
          />
        </Title>
        <Space>
          <IconButton icon={<FileTextOutlined />} onClick={() => setOpenOrderId(confirmOrder.id)} />
          <EditOrderCloseButton />
        </Space>
      </div>
      <Card
        className="flex flex-fill scroll-container"
        bodyStyle={{ display: "flex", flexDirection: "column", padding: 0, height: "100%" }}
      >
        <div className="flex-fill scroll-body">
          <OrderPricingPanel
            disabled={Boolean(blocking)}
            header={
              <span className="flex flex-row flex-space-between flex-align-center padding-horizontal">
                <FormattedMessage tagName="b" defaultMessage="Line items" />
                {isDirty && (
                  <Popover
                    trigger={"click"}
                    title={
                      <Title>
                        <FormattedMessage
                          defaultMessage={"Order {orderNo} have unsaved changes"}
                          values={{ orderNo: `#${confirmOrder?.get("orderNo")}` }}
                        />
                      </Title>
                    }
                  >
                    <Button icon={<HighlightOutlined />} size="small" type="link">
                      <FormattedMessage tagName={"span"} defaultMessage={"Draft"} />
                    </Button>
                  </Popover>
                )}
              </span>
            }
          />
        </div>
        <Row gutter={[8, 8]} className="padding-half">
          <Col span={24} className="flex flex-row flex-space-between flex-align-center">
            <span />
            <Title fontSize={22}>
              <AmountDisplay value={pricing?.netSales || 0} />
            </Title>
          </Col>
          <Col span={12}>
            {isPendingConfirm && (
              <OrderEditButton
                order={confirmOrder}
                disabled={dayClosed || Boolean(blocking)}
                onSubmitted={onConfirmed}
              />
            )}
          </Col>
          <Col span={12}>
            <OrderEditButton
              order={confirmOrder}
              disabled={dayClosed || Boolean(blocking)}
              onSubmitted={onConfirmed}
              confirmed
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}
