import {
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  ITEM_STEP_SERVICE_TYPES,
} from "./constants/index.js";

export default function (sector, lineItemsSteps, items) {
  if (Array.isArray(lineItemsSteps) && Array.isArray(items)) {
    for (const step of lineItemsSteps) {
      if (step.mandatory) {
        const itemIndex = items.findIndex(
          ({ selectSteps }) =>
            !Array.isArray(selectSteps) ||
            !selectSteps.find((select) => select.id === step.id)
        );
        if (itemIndex >= 0) {
          const item = items[itemIndex];
          return { ...item, itemIndex, missingStepId: step.id };
        }
      }
    }
  }

  if (
    Array.isArray(items) &&
    [BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE].indexOf(sector) >=
      0
  ) {
    let itemIndex = items.findIndex(
      ({ subOptions }) => !Array.isArray(subOptions) || subOptions.length === 0
    );

    if (itemIndex >= 0) {
      const item = items[itemIndex];
      return { ...item, itemIndex, missingStepId: ITEM_STEP_SERVICE_TYPES };
    }
  }
}
