import { UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { CustomerEditDrawer } from "../CustomerProfileEditor";

export default function OrderEditCustomerButton({ order, maxWidth }) {
  const [editCustomerVisible, setEditCustomerVisible] = useState(false);

  return (
    <>
      <Button
        icon={<UserOutlined />}
        disabled={!order}
        onClick={() => setEditCustomerVisible(true)}
        style={{ maxWidth: maxWidth || 200, overflow: "hidden" }}
      >
        {order?.get("customer.name")}
      </Button>
      <CustomerEditDrawer
        customerId={order?.get("customer.id")}
        open={editCustomerVisible}
        onClose={() => setEditCustomerVisible(false)}
      />
    </>
  );
}
