"use client";

import React, { createContext, useContext, useEffect, useMemo, useRef } from "react";

const ApplicationStateContext = createContext();

function addListener(ref, listener) {
  if (Array.isArray(ref.current)) {
    ref.current.push(listener);
  } else {
    ref.current = [listener];
  }
}

function removeListener(ref, listener) {
  const index = Array.isArray(ref.current) ? ref.current.findIndex((data) => data === listener) : -1;
  if (index >= 0) {
    ref.current.splice(index, 1);
  }
}

export function WebKeyboardListenerProvider({
  children,
}) {
  const dialPadListenerRef = useRef();
  const returnListenerRef = useRef();

  useEffect(() => {
    const onInputListener = ({ key }) => {
      if (!key) return;

      // Invoke latest listener
      const dialPadListener = Array.isArray(dialPadListenerRef.current)
        ? dialPadListenerRef.current[dialPadListenerRef.current.length - 1]
        : null;
      const returnListener = Array.isArray(returnListenerRef.current)
        ? returnListenerRef.current[returnListenerRef.current.length - 1]
        : null;

      if (key === "Enter") {
        if (returnListener) {
          returnListener();
        }
      } else if (dialPadListener) {
        if ((key.length === 1 && !isNaN(key)) || key === "Backspace" || key === ".") {
          dialPadListener(key);
        }
      }
    };

    window.addEventListener("keydown", onInputListener);
    return () => window.removeEventListener("keydown", onInputListener);
  }, []);

  const context = useMemo(() => {
    return {
      addDialPadListener: (listener) => {
        addListener(dialPadListenerRef, listener);
        // console.log("dialpad listener", dialPadListenerRef.current);
      },
      removeDialPadListener: (listener) => {
        removeListener(dialPadListenerRef, listener);
        // console.log("dialpad listener", dialPadListenerRef.current);
      },
      addReturnListener: (listener) => {
        addListener(returnListenerRef, listener);
        // console.log("return listener", returnListenerRef.current);
      },
      removeReturnListener: (listener) => {
        removeListener(returnListenerRef, listener);
        // console.log("return listener", returnListenerRef.current);
      },
    };
  }, []);

  return <ApplicationStateContext.Provider value={context}>{children}</ApplicationStateContext.Provider>;
}

export const useAddDialPadListener = () => useContext(ApplicationStateContext).addDialPadListener;
export const useRemoveDialPadListener = () => useContext(ApplicationStateContext).removeDialPadListener;
export const useAddReturnListener = () => useContext(ApplicationStateContext).addReturnListener;
export const useRemoveReturnListener = () => useContext(ApplicationStateContext).removeReturnListener;
