import { useToast } from "@easybiz/context";
import { OPERATION_GROUP_ORDER, ORDER_OPERATION_SALES_RETURN } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const toast = useToast();
  const intl = useIntl();

  return useActionCallback(
    ({ orderId, reportId, photos, creditNote }) => {
      if (!creditNote?.lineTotal) {
        return intl.formatMessage({ defaultMessage: "Please enter sales return amount" });
      }

      return [
        OPERATION_GROUP_ORDER,
        ORDER_OPERATION_SALES_RETURN,
        {
          orderId,
          ...(photos && { photos }),
          ...(reportId && { reportId }),
          ...(creditNote && { creditNote }),
        },
      ];
    },
    callback,
    (request, response) =>
      toast.success(
        response.pendingApproval
          ? intl.formatMessage({
              defaultMessage: "Sales return submitted, pending admin approval",
            })
          : intl.formatMessage({ defaultMessage: "Sales return success" })
      )
  );
};
