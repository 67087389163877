import { MobileOutlined, ShopOutlined } from "@ant-design/icons";
import { NameDisplay } from "@easybiz/component";
import { useAppLicenses, useStoreLicenses } from "@easybiz/context";
import { Button, Space } from "antd";
import React from "react";

export default ({ select, onSelect }) => {
  const appLicenses = useAppLicenses();
  const storeLicenses = useStoreLicenses();

  return (
    <Space>
      {appLicenses?.map((businessCode) => (
        <Button
          shape="round"
          icon={<MobileOutlined />}
          type={select === businessCode ? "primary" : null}
          onClick={() => onSelect(businessCode)}
        >
          <NameDisplay nameKey={businessCode} />
        </Button>
      ))}
      {storeLicenses?.map((businessCode) => (
        <Button
          shape="round"
          icon={<ShopOutlined />}
          type={select === businessCode ? "primary" : null}
          onClick={() => onSelect(businessCode)}
        >
          <NameDisplay nameKey={businessCode} />
        </Button>
      ))}
    </Space>
  );
};
