import { PrinterOutlined } from "@ant-design/icons";
import { Dialog } from "@easybiz/web-admin";
import { usePrinter } from "@easybiz/hook";
import { Button, Input, message, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default ({ open, onCancel }) => {
  const printer = usePrinter();

  const [bagNo, sestBagNo] = useState();
  const [printing, setPrinting] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    sestBagNo("");
  }, [open]);

  const onPrintQRBag = async () => {
    if (!bagNo) return message.error(intl.formatMessage({ defaultMessage: "Please enter a bag No/Name" }));

    try {
      setPrinting(true);

      await printer.print({ qrBag: bagNo });
    } catch (err) {
      message.error(err.message);
    } finally {
      setPrinting(false);
    }
  };

  const onPrintStorage = async () => {
    if (!bagNo)
      return message.error(
        intl.formatMessage({
          defaultMessage: `Please enter a storage name/code`,
        })
      );

    try {
      setPrinting(true);

      await printer.print({ storage: bagNo });
    } catch (err) {
      message.error(err.message);
    } finally {
      setPrinting(false);
    }
  };

  return (
    <Dialog
      title={<FormattedMessage tagName="span" defaultMessage={`Print`} />}
      onCancel={onCancel}
      open={open}
      okText={<FormattedMessage tagName={"span"} defaultMessage="Close" />}
      value={bagNo}
      okType="default"
      confirmLoading={printing}
      onOk={onCancel}
    >
      <Space direction="vertical" className="full-width" size={"large"}>
        <Input
          autoFocus
          value={bagNo}
          placeholder={intl.formatMessage({
            defaultMessage: "Enter bag number or storage code",
          })}
          onChange={(e) => sestBagNo(e.target.value.toUpperCase())}
        />
        <Space>
          <Button icon={<PrinterOutlined />} onClick={onPrintQRBag}>
            <FormattedMessage tagName={"span"} defaultMessage={"Print QR bag label"} />
          </Button>
          <Button icon={<PrinterOutlined />} onClick={onPrintStorage}>
            <FormattedMessage tagName={"span"} defaultMessage={"Print storage label"} />
          </Button>
        </Space>
      </Space>
    </Dialog>
  );
};
