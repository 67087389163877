import { UnitDisplay } from "@easybiz/component";
import { useBusinessUnits } from "@easybiz/hook";
import { Radio, Select } from "antd";
import { useEffect } from "react";

export default function ({ sector, value, onChange, radio, autoSelect }) {
  const units = useBusinessUnits(sector);

  useEffect(() => {
    if (autoSelect && !value && units && units.length > 0) {
      onChange(units[0]);
    }
  }, [autoSelect, value, units]);

  const onValueChange = (value) => {
    const unit = units?.find(({ unit }) => unit === (typeof value === "string" ? value : value?.target?.value));
    if (unit) {
      onChange(unit);
    }
  };

  return radio ? (
    <Radio.Group
      options={units.map(({ unit }) => ({ label: <UnitDisplay unit={unit} />, value: unit }))}
      onChange={onValueChange}
      value={value?.unit}
      optionType="button"
      buttonStyle="solid"
    />
  ) : (
    <Select className="full-width" value={value} onChange={onValueChange} allowClear={false}>
      {units.map(({ unit }) => (
        <Select.Option value={unit} key={unit}>
          <UnitDisplay unit={unit} />
        </Select.Option>
      ))}
    </Select>
  );
}
