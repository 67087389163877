import { useToast } from "@easybiz/context";
import { BUSINESS_ID_FACTORY, OPERATION_GROUP_ORDER, ORDER_OPERATION_CREATE, PAYMENT_ID_CASH } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const toast = useToast();
  const intl = useIntl();

  const onSubmit = ({ orderId, serviceId, businessCode, source, checkoutParams, pricing, customer }) => {
    const { payment, pricingTBD } = checkoutParams || {};
    if (!customer) {
      return businessCode === BUSINESS_ID_FACTORY
        ? intl.formatMessage({ defaultMessage: "Please select agent" })
        : intl.formatMessage({ defaultMessage: "Please select customer" });
    }
    if (!businessCode) return intl.formatMessage({ defaultMessage: "Please select App" });
    if (!serviceId) return intl.formatMessage({ defaultMessage: "Please select business" });
    if (!pricingTBD && pricing?.totalPayable > 0 && payment === undefined) {
      return intl.formatMessage({
        defaultMessage: "Please select payment method",
      });
    }

    if (payment?.noteRequired && !payment.note) {
      return intl.formatMessage({
        defaultMessage: "Please enter payment reference",
      });
    }

    if (payment?.option === PAYMENT_ID_CASH && payment?.cashAmount && payment?.cashAmount < payment.amount) {
      return intl.formatMessage({
        defaultMessage: "Cash received must be greater than the payment amount",
      });
    }

    return [
      OPERATION_GROUP_ORDER,
      ORDER_OPERATION_CREATE,
      {
        orderId,
        serviceId,
        businessCode,
        ...(businessCode === BUSINESS_ID_FACTORY ? { agentId: customer.id } : { customerId: customer.id }),
        checkoutParams,
        source,
      },
    ];
  };

  return useActionCallback(onSubmit, callback, (request, response) => {
    if (response.newOrder) {
      toast.success(
        intl.formatMessage({ defaultMessage: "Order {orderNo} created" }, { orderNo: `#${response.newOrder?.orderNo}` })
      );
    }
  });
};
