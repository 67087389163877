export const EMAIL_TEMPLATE_GENERAL = `general`;
export const EMAIL_TEMPLATE_RECEIPT = `receipt`;
export const EMAIL_TEMPLATE_OTP = `otp`;
export const EMAIL_TEMPLATE_PLATFORM_WELCOME = `verification`;
export const EMAIL_TEMPLATE_FORGOT_PASSWORD = `forgot_password`;
export const EMAIL_TEMPLATE_SIGN_IN_ALERT = `sign_in_alert`;
export const EMAIL_TEMPLATE_REALM_DELETED = `realm_deleted`;

export const EMAIL_STATUS_ERROR = "error";
export const EMAIL_STATUS_PROCESSED = "processed";
export const EMAIL_STATUS_DROPPED = "dropped";
export const EMAIL_STATUS_DEFERRED = "deferred";
export const EMAIL_STATUS_BOUNCE = "bounce";
export const EMAIL_STATUS_SPAM = "spam report";
export const EMAIL_STATUS_DELIVERED = "delivered";
export const EMAIL_STATUS_OPEN = "open";
export const EMAIL_STATUS_CLICK = "click";
export const EMAIL_STATUS_SENT = "sent";
