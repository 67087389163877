export const SERVICE_CATEGORY_ITEM_SERVICING = "item servicing";
export const SERVICE_CATEGORY_SHOP_SERVICE = "shop service";

export const SERVICE_PRICING_TYPE_DIRECT = "direct";
export const SERVICE_PRICING_TYPE_STEPS = "steps";

export const INPUT_MODE_SINGLE = "single";
export const INPUT_MODE_MULTIPLE = "multiple";
export const INPUT_MODE_FREE_INPUT = "freeInput";
export const INPUT_MODE_PHOTO = "photo";

export const INPUT_TYPE_TEXT = "text";
export const INPUT_TYPE_NUMBER = "number";

export const DISPLAY_MODE_LIST = "list";
export const DISPLAY_MODE_CHIP = "chip";

export const ASSIGN_WORKER_TYPE_AFTER_ORDER = "afterOrderCreated";
export const ASSIGN_WORKER_TYPE_STAND_ALONE = "assignSeparately";

export const OPTIONAL = "optional";
export const REQUIRED = "required";

export const STEP_TYPE_SINGLE_SELECT = "single_select";
export const STEP_TYPE_MULTI_SELECT = "multi_select";
export const STEP_TYPE_PHOTO = "photo";
export const STEP_TYPE_FREE_INPUT = "free_input";

export const STEP_PLACEMENT_PRODUCT = "product";
export const STEP_PLACEMENT_ORDER = "order";

export const SERVICE_TYPE_PUD_SERVICE = "pud_service";

export const CATEGORY_OTHERS = "__other";
