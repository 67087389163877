import { JOB_OPERATION_FAILED, OPERATION_GROUP_JOB } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ taskId, note }) => {
    return [
      OPERATION_GROUP_JOB,
      JOB_OPERATION_FAILED,
      {
        taskId,
        ...(note && { note }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Job failed" }));
};
