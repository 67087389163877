import { Typography } from "antd";
import React from "react";

export default function ({ children, type, style, color, lineThrough, fontSize, ellipsis, className }) {
  return (
    <Typography.Text
      className={className}
      strong
      type={type}
      delete={lineThrough}
      style={{ fontSize: fontSize || 18, ...style, ...(color && { color }) }}
      ellipsis={ellipsis}
    >
      {children}
    </Typography.Text>
  );
}
