import { useIntl } from "react-intl";

export default function ({ date, time, start, end, timestamp, weekday, monthFormat, hideDay, hideTime }) {
  const intl = useIntl();

  return `${intl.formatDate(timestamp ? parseInt(`${timestamp}000`) : date, {
    month: monthFormat || "long",
    year: "numeric",
    ...(!hideDay && {
      day: "2-digit",
      weekday,
    }),
  })}${
    start && end
      ? ` ${start} - ${end}`
      : (time || timestamp) && !hideTime
      ? ` ${intl.formatTime(timestamp ? parseInt(`${timestamp}000`) : `${date}T${time}`)}`
      : ""
  }`;
}
