import { message, Space, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";
import DialPad from "../DialPad";

export default ({ birthday, open, onCancel, onSave }) => {
  const [date, setDate] = useState("");
  const intl = useIntl();
  const momentDate = dayjs(date, "YYYYMMDD");

  useEffect(() => {
    if (open) {
      if (birthday && dayjs(birthday, "YYYY-MM-DD").isValid()) {
        setDate(dayjs(birthday, "YYYY-MM-DD").format("YYYYMMDD"));
      } else {
        setDate("");
      }
    }
  }, [open]);

  const yearMonthDay = useMemo(() => {
    const year = `${date}`.slice(0, 4);
    const month = `${date}`.slice(4, 6);
    const day = `${date}`.slice(6, 8);

    return [
      {
        title: <FormattedMessage defaultMessage={"Year"} />,
        value: `${year}${Array(Math.max(0, 4 - `${year}`.length))
          .fill("_")
          .join("")}`.split(""),
        type: `${year}`.length === 4 ? (dayjs(year).isValid() ? "success" : "danger") : undefined,
      },
      {
        title: <FormattedMessage defaultMessage={"Month"} />,
        value: `${month}${Array(Math.max(0, 2 - `${month}`.length))
          .fill("_")
          .join("")}`.split(""),
        type:
          `${month}`.length === 2
            ? !isNaN(month) && parseInt(month) >= 1 && parseInt(month) <= 12
              ? "success"
              : "danger"
            : undefined,
      },
      {
        title: <FormattedMessage defaultMessage={"Day"} />,
        value: `${day}${Array(Math.max(0, 2 - `${day}`.length))
          .fill("_")
          .join("")}`.split(""),
        type: `${day}`.length === 2 ? (dayjs(date).isValid() ? "success" : "danger") : undefined,
      },
    ];
  }, [date]);

  return (
    <Dialog
      title={<FormattedMessage defaultMessage="Birthday" />}
      open={open}
      onCancel={onCancel}
      onOk={() => {
        if (`${date}`.length !== 8 || !momentDate.isValid()) {
          return message.info(
            intl.formatMessage({
              defaultMessage: "Please enter a valid birthday",
            })
          );
        }

        onSave(momentDate.format("YYYY-MM-DD"));
      }}
      enableReturn
    >
      <div className="flex flex-align-center">
        <Space size="large">
          {yearMonthDay.map(({ title, value, type }, index) => (
            <div key={`${index}`} className="flex flex-align-center">
              <Typography.Title level={3} style={{ margin: 0 }}>
                {title}
              </Typography.Title>
              <Space>
                {value.map((char, index) => (
                  <Typography.Title type={type} key={`${index}`} level={3} style={{ margin: 0 }}>
                    {char}
                  </Typography.Title>
                ))}
              </Space>
            </div>
          ))}
        </Space>
      </div>
      <br />
      <br />
      <DialPad value={date} onChange={(date) => setDate(`${date || ""}`.slice(0, 8))} />
    </Dialog>
  );
};
