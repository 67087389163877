import { PlusOutlined } from "@ant-design/icons";
import { useCustomerAddressQuery } from "@easybiz/web-firebase";
import { toRecordAddress } from "@easybiz/utils";
import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import MenuEditButton from "../MenuEditButton";

export default ({ customerId, address, onSelect }) => {
  const addresses = useCustomerAddressQuery(customerId);

  if (address) {
    return (
      <MenuEditButton
        onClick={() => onSelect(address, true)}
        onSelect={(key) => {
          if (key === "add") {
            onSelect(null);
          } else {
            const address = addresses?.find((address) => address.id === key);
            if (address) {
              onSelect(toRecordAddress(address));
            }
          }
        }}
        items={[
          {
            type: "group",
            label: <FormattedMessage defaultMessage="New" />,
            children: [
              {
                key: "add",
                icon: <PlusOutlined />,
                label: <FormattedMessage defaultMessage="Add new address" />,
              },
            ],
          },
          {
            type: "group",
            label: <FormattedMessage defaultMessage="Switch address" />,
            children: (addresses || [])
              .filter((doc) => doc.id !== address.id)
              .map((addressDoc) => {
                return {
                  key: addressDoc.id,
                  label: addressDoc.get("formatted"),
                };
              }),
          },
        ]}
      />
    );
  } else {
    return (
      <Button icon={<PlusOutlined />} onClick={() => onSelect(null)}>
        <FormattedMessage tagName={"span"} defaultMessage={"Add address"} />
      </Button>
    );
  }
};
