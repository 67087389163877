import { LINE_ALIGN_CENTER, LINE_ALIGN_LEFT, LINE_ALIGN_RIGHT } from "@easybiz/utils";

const iconv = require("iconv-lite");

const ESC = 0x1b;
const RESET = 0x40;
const CMD = 0x1d;
const ALIGN = 0x61;
const FONT_SIZE = 0x21;
// const BARC0DE = 0x6b;
// const HEIGHT = 0x68;
// const WDITH = 0x77;
const CODETABLE = 0x74;
const CUT = 0x56;
const ALL = 0x00;
const HALF = 0x01;
// const HRI = 0x48;
const BEEP = 0x42;
// const BELOW = 0x02;
// const CODE_128 = 0x49;
// const CODE_39 = 0x04;
// const CODE_128_SET_C = [0x7b, 0x43];
const NEW_LINE = 0x0a;
const BOLD = 0x45;
const YES = 0x01;
const NO = 0x00;

async function loadImage(dataURL) {
  return new Promise((resolve) => {
    if (dataURL == null) return resolve();

    let image = new Image();
    image.addEventListener("load", function () {
      resolve(image);
    });
    image.src = dataURL;
  });
}

export function reset(commands) {
  commands.push(ESC);
  commands.push(RESET);
}

export function text(commands, value, countryCode) {
  let bytes = iconv.encode(value,  countryCode === "TH" ? "tis620" : "GB18030");

  commands.push(bytes);
}

export async function image(commands, dataURL, targetWidth = 120) {
  const img = await loadImage(dataURL);
  var width = targetWidth;
  width = width - (width % 8);
  var height = parseInt((img.height / img.width) * width);
  height = height - (height % 8);

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  let context = canvas.getContext("2d");
  context.drawImage(img, 0, 0, width, height);
  let image = context.getImageData(0, 0, width, height);

  const background = [0xff, 0xff, 0xff];

  // Flatten (canvas-flatten.flatten)
  for (let i = 0; i < image.data.length; i += 4) {
    const alpha = image.data[i + 3];
    const invAlpha = 255 - alpha;

    image.data[i] = (alpha * image.data[i] + invAlpha * background[0]) / 255;
    image.data[i + 1] = (alpha * image.data[i + 1] + invAlpha * background[1]) / 255;
    image.data[i + 2] = (alpha * image.data[i + 2] + invAlpha * background[2]) / 255;
    image.data[i + 3] = 0xff;
  }

  // Atkinson (canvas-dither.atkinson)
  const luminance = new Uint8ClampedArray(image.width * image.height);

  for (let l = 0, i = 0; i < image.data.length; l++, i += 4) {
    luminance[l] = image.data[i] * 0.299 + image.data[i + 1] * 0.587 + image.data[i + 2] * 0.114;
  }

  for (let l = 0, i = 0; i < image.data.length; l++, i += 4) {
    const value = luminance[l] < 129 ? 0 : 255;
    const error = Math.floor((luminance[l] - value) / 8);
    image.data.fill(value, i, i + 3);

    luminance[l + 1] += error;
    luminance[l + 2] += error;
    luminance[l + width - 1] += error;
    luminance[l + width] += error;
    luminance[l + width + 1] += error;
    luminance[l + 2 * width] += error;
  }

  let getPixel = (x, y) => (image.data[(width * y + x) * 4] > 0 ? 0 : 1);

  let bytes = new Uint8Array((width * height) >> 3);

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x = x + 8) {
      let i = y * (width >> 3) + (x >> 3);
      bytes[i] =
        (getPixel(x + 0, y) << 7) |
        (getPixel(x + 1, y) << 6) |
        (getPixel(x + 2, y) << 5) |
        (getPixel(x + 3, y) << 4) |
        (getPixel(x + 4, y) << 3) |
        (getPixel(x + 5, y) << 2) |
        (getPixel(x + 6, y) << 1) |
        getPixel(x + 7, y);
    }
  }

  commands.push(0x1d);
  commands.push(0x76);
  commands.push(0x30);
  commands.push(0x00);
  commands.push((width >> 3) & 0xff);
  commands.push(((width >> 3) >> 8) & 0xff);
  commands.push(height & 0xff);
  commands.push((height >> 8) & 0xff);
  commands.push(bytes);
}

export function bold(commands, bold) {
  commands.push(ESC);
  commands.push(BOLD);
  commands.push(bold ? YES : NO);
}

export function fontSmall(commands) {
  commands.push(ESC);
  commands.push(0x4d);
  commands.push(YES);
}

export function charset(commands, chinese) {
  commands.push(YES);
  commands.push(0x52);
  commands.push(chinese ? 15 : 0);
}

export function fontSize(commands, n = 1) {
  let times;
  switch (n) {
    case 1:
      times = 0x00;
      break;
    case 2:
      times = 0x11;
      break;
    case 3:
      times = 0x22;
      break;
    case 4:
      times = 0x33;
      break;
    case 5:
      times = 0x44;
      break;
    case 6:
      times = 0x55;
      break;
    case 7:
      times = 0x66;
      break;
    case 8:
      times = 0x77;
      break;
  }

  commands.push(CMD);
  commands.push(FONT_SIZE);
  commands.push(times);
}

export function textAlign(commands, alignType) {
  commands.push(ESC);
  commands.push(ALIGN);

  switch (alignType) {
    case LINE_ALIGN_LEFT:
      return commands.push(0x00);
    case LINE_ALIGN_CENTER:
      return commands.push(0x01);
    case LINE_ALIGN_RIGHT:
      return commands.push(0x02);
    default:
      commands.push(0x00);
  }
}

export function rowGap(commands, value) {
  if (value) {
    commands.push(ESC);
    commands.push(0x33);
    commands.push(value || 0);
  } else {
    commands.push(ESC);
    commands.push(0x32);
  }
}

export function newLine(commands, num = 1) {
  if (typeof num === "number") {
    for (let index = 0; index < num; index++) {
      commands.push(NEW_LINE);
    }
  }
}

export function reverse(commands, isReverse) {
  commands.push(CMD);
  commands.push(0x42);
  commands.push(isReverse ? 0x01 : 0x00);
}

export function qrcode(commands, value, model, size, errorlevel) {
  /* Force printing the print buffer and moving to a new line */
  let results = [];
  results = [...results, 0x0a];

  /* Model */

  const models = {
    1: 0x31,
    2: 0x32,
  };

  if (typeof model === "undefined") {
    model = 2;
  }

  if (model in models) {
    results = [...results, 0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, models[model], 0x00];
  } else {
    throw new Error("Model must be 1 or 2");
  }

  /* Size */

  if (typeof size === "undefined") {
    size = 6;
  }

  if (typeof size !== "number") {
    throw new Error("Size must be a number");
  }

  if (size < 1 || size > 8) {
    throw new Error("Size must be between 1 and 8");
  }

  results = [...results, 0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, size];

  /* Error level */

  const errorlevels = {
    l: 0x30,
    m: 0x31,
    q: 0x32,
    h: 0x33,
  };

  if (typeof errorlevel === "undefined") {
    errorlevel = "m";
  }

  if (errorlevel in errorlevels) {
    results = [...results, 0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, errorlevels[errorlevel]];
  } else {
    throw new Error("Error level must be l, m, q or h");
  }

  /* Data */

  let bytes = iconv.encode(value, "iso88591");
  let length = bytes.length + 3;

  results = [...results, 0x1d, 0x28, 0x6b, length % 0xff, length / 0xff, 0x31, 0x50, 0x30, bytes];

  /* Print QR code */

  results = [...results, 0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x51, 0x30];

  results.forEach((code) => commands.push(code));
}

export function cut(commands, all) {
  commands.push(CMD);
  commands.push(CUT);
  commands.push(all ? ALL : HALF);
}

export function codeTable(commands, code) {
  commands.push(ESC);
  commands.push(CODETABLE);
  commands.push(code);
}

export function beep(commands, n = 1, t = 1) {
  commands.push(CMD);
  commands.push(BEEP);
  commands.push(n);
  commands.push(t);
}

export function divider(commands, length = 32) {
  return text(
    `${Array.apply(null, Array(length))
      .map(() => "-")
      .join("")}\n`,
    commands
  );
}

export function openCashDrawer(commands) {
  commands.push(0x10);
  commands.push(0x14);
  commands.push(0x01);
  commands.push(0x00);
  commands.push(0x01);
}

export function encode(_buffer) {
  let length = 0;

  _buffer.forEach((item) => {
    if (typeof item === "number") {
      length++;
    } else {
      length += item.length;
    }
  });

  let result = new Uint8Array(length);

  let index = 0;

  _buffer.forEach((item) => {
    if (typeof item === "number") {
      result[index] = item;
      index++;
    } else {
      result.set(item, index);
      index += item.length;
    }
  });

  return result;
}
