"use client";

import { useAuthUser } from "@easybiz/context";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function () {
  const [showReload, setShowReload] = useState(false);
  const user = useAuthUser();

  useEffect(() => {
    if (user) {
      const handler = setTimeout(() => {
        setShowReload(true);
      }, 10000);

      return () => clearTimeout(handler);
    }
  }, [user]);

  return {
    title: user ? (
      <FormattedMessage defaultMessage={"Getting everything ready..."} />
    ) : (
      <FormattedMessage defaultMessage={"Logging in..."} />
    ),
    showReload
  }
}
