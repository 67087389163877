import { useProductServiceTypes } from "@easybiz/checkout";
import { useServiceTypeOptionsWithSelects } from "@easybiz/hook";
import { formatPrice, PROMO_TYPE_PERCENT, translate } from "@easybiz/utils";
import { Checkbox, Input, message } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";
import IndexSearchbar from "../IndexSearchbar";
import OtherPriceDialog from "../OtherPriceDialog";
import PriceEditDialog from "../PriceEditDialog";
import ServiceTypeOptionsList from "../ServiceTypeOptionsList";

export default ({ productId, subOptions, onChange }) => {
  const serviceTypes = useProductServiceTypes(productId);
  const [addedOnly, setAddOnly] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState();
  const [priceEditIndex, setPriceEditIndex] = useState();
  const [otherVisible, setOtherVisible] = useState(false);
  const [noteOption, setNoteOption] = useState();
  const intl = useIntl();
  const options = useServiceTypeOptionsWithSelects(serviceTypes, subOptions);

  const onOptionChange = (index, updates, reset) => {
    if (index >= 0) {
      // Update
      onChange([
        ...subOptions.slice(0, index),
        ...(updates ? [reset ? updates : { ...subOptions[index], ...updates }] : []),
        ...subOptions.slice(index + 1),
      ]);
    } else if (updates) {
      // Add
      onChange([...(subOptions || []), updates]);
    }
  };

  return (
    <>
      <IndexSearchbar
        options={options}
        optionKey="title"
        secondOptionKey="code"
        onFiltered={setFilteredOptions}
        disabled={addedOnly}
        extra={
          options?.length >= 5 && (
            <Checkbox checked={addedOnly} onChange={(e) => setAddOnly(e.target.checked)}>
              <FormattedMessage defaultMessage={"Only show added"} />
            </Checkbox>
          )
        }
      />
      <ServiceTypeOptionsList
        options={addedOnly ? subOptions : filteredOptions || options}
        selects={subOptions}
        onChange={onOptionChange}
        onEditPrice={setPriceEditIndex}
        onEditNote={setNoteOption}
        onOther={() => setOtherVisible(true)}
      />
      <PriceEditDialog
        enableDiscount
        title={
          <FormattedMessage
            defaultMessage={"{option} Price"}
            values={{
              option: translate(subOptions && subOptions[priceEditIndex]?.title),
            }}
          />
        }
        open={typeof priceEditIndex === "number"}
        initPrice={subOptions && subOptions[priceEditIndex]?.price}
        initDiscount={subOptions && subOptions[priceEditIndex]?.discount}
        onCancel={() => setPriceEditIndex(null)}
        onChange={(price, discount) => {
          const {
            discount: oldDiscount,
            discountAmount: oldDiscountAmount,
            ...otherFields
          } = subOptions[priceEditIndex];

          onOptionChange(
            priceEditIndex,
            {
              ...otherFields,
              price: parseFloat(price),
              ...(discount && {
                discount,
                discountAmount:
                  discount.type === PROMO_TYPE_PERCENT ? formatPrice((price * discount.value) / 100) : discount.value,
              }),
            },
            true
          );
          setPriceEditIndex(null);
        }}
      />
      <OtherPriceDialog
        title={<FormattedMessage defaultMessage={"Other service"} />}
        open={otherVisible}
        onClose={() => setOtherVisible(false)}
        onAdd={({ code, surcharge }) => {
          const exist = options.find((option) => option.title === code);
          if (exist) {
            return message.info(
              intl.formatMessage(
                {
                  defaultMessage: "Service option {name} already listed above, please select option",
                },
                { name: code }
              )
            );
          }

          onChange([
            ...(subOptions || []),
            {
              title: code,
              code,
              ...(typeof surcharge === "number" && { price: surcharge }),
            },
          ]);
          setOtherVisible(false);
        }}
      />
      <Dialog
        title={translate(noteOption?.title)}
        open={Boolean(noteOption)}
        onCancel={() => setNoteOption(null)}
        onOk={() => {
          setNoteOption(null);
          onOptionChange(noteOption.selectIndex, {
            note: noteOption.note || null,
          });
        }}
        value={noteOption?.note}
      >
        <Input
          value={noteOption?.note}
          autoFocus
          placeholder={intl.formatMessage({ defaultMessage: "Note" })}
          onChange={(e) => setNoteOption({ ...noteOption, note: e.target.value })}
        />
      </Dialog>
    </>
  );
};
