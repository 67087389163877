import { ContainerOutlined } from "@ant-design/icons";
import { useCashDrawerEndAlert } from "@easybiz/pos";
import { Alert } from "antd";

export default function CashDrawerEndAlert() {
  const endAlert = useCashDrawerEndAlert();

  if (endAlert) {
    return (
      <Alert
        type="info"
        icon={<ContainerOutlined />}
        showIcon
        message={<b>{endAlert.title}</b>}
        description={endAlert.description}
      />
    );
  } else {
    return null;
  }
}
