import { OPERATION_GROUP_ORDER, ORDER_OPERATION_REFUND } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  return useActionCallback(
    ({ orderId, payment, amount, option, note }) => {
      if (!payment) {
        if (typeof amount !== "number" || amount <= 0) {
          return intl.formatMessage({ defaultMessage: `Please enter refund amount` });
        }
        if (!option) {
          return intl.formatMessage({ defaultMessage: `Please select refund option` });
        }
      } else if (!note) {
        return intl.formatMessage({ defaultMessage: "Please leave a refund note" });
      }

      return [
        OPERATION_GROUP_ORDER,
        ORDER_OPERATION_REFUND,
        {
          orderId,
          ...(payment ? { payment } : { amount, option }),
          ...(note && { note }),
        },
      ];
    },
    callback,
    intl.formatMessage({ defaultMessage: `Refund succeed` })
  );
};
