import { LogoutOutlined } from "@ant-design/icons";
import { useCheckInStaff } from "@easybiz/context";
import { usePOSClockOut } from "@easybiz/request";
import { Button } from "antd";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ block, iconOnly }) => {
  const checkInStaff = useCheckInStaff();
  const { loading, onSubmit } = usePOSClockOut((success, response) => {
    if (success && response.token) {
      signInWithCustomToken(getAuth(), response.token);
    }
  });

  return (
    <Button
      size="large"
      disabled={!checkInStaff}
      icon={<LogoutOutlined />}
      block={block}
      danger
      loading={loading}
      onClick={onSubmit}
    >
      {iconOnly ? null : <FormattedMessage tagName="span" defaultMessage="Clock out" />}
    </Button>
  );
};
