import { ReturnListener } from "@easybiz/web-component";
import { Button } from "antd";
import { useState } from "react";

export default function ({ loading, disabled, listenerDisabled, children, onClick, ...others }) {
  const [focused, setFocused] = useState(false);

  return (
    <Button
      {...others}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      {children}
      <ReturnListener disabled={loading || disabled || listenerDisabled || focused} callback={onClick} />
    </Button>
  );
}
