"use client";

import React, { createContext, useContext, useMemo } from "react";

const InstallationContext = createContext();

export function InstallationProvider({
  children,
  installed,
  installEvent,
  isMobile,
  onOTAUpdate,
  onOTACheck,
  otaNewVersion
}) {
  const context = useMemo(() => {
    return {
      installed,
      installEvent,
      isMobile,
      onOTAUpdate,
      onOTACheck,
      otaNewVersion
    };
  }, [
    installed,
    installEvent,
    isMobile,
    onOTAUpdate,
    onOTACheck,
    otaNewVersion
  ]);

  return <InstallationContext.Provider value={context}>{children}</InstallationContext.Provider>;
}

// Status
export const useOTAUpdate = () => useContext(InstallationContext).onOTAUpdate;
export const useOTACheck = () => useContext(InstallationContext).onOTACheck;
export const useOTANewVersion = () => useContext(InstallationContext).otaNewVersion;
export const useIsOnMobile = () => useContext(InstallationContext).isMobile;
export const useIsAppInstalled = () => useContext(InstallationContext).installed;
export const useInstallationEvent = () => useContext(InstallationContext).installEvent;