import {
  ADJUSTMENT_MODE_DISCOUNT,
  BENEFIT_TYPE_MONTHLY_FOC,
  BUSINESS_SECTOR_AIRCON_SERVICE,
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  BUSINESS_SECTOR_LAUNDRY,
  BUSINESS_TYPE_ONLINE,
  PROMO_TYPE_FLAT,
  PROMO_TYPE_FOC,
  PROMO_TYPE_PERCENT,
  UNIT_HOUR,
  UNIT_PCS,
} from "./constants/index.js";
import formatPrice from "./formatPrice.js";
import isDecimalUnit from "./isDecimalUnit.js";
import rounding from "./rounding.js";
import translate from "./translate.js";

export default (checkoutParams) => {
  let {
    type,
    delivery,
    minimumOrder,
    gstRate,
    service, // Order pricing effected fields
    fixedAmount, // B2B
    fixedQty,
    items,
    serviceTypes,
    promotions,
    adjustments, // Main items
    creditNotes,
    payments, // Waived & Payments
    customerPromotions,
    campaignPromotions,
    promoCodePromotion,
    voucherPromotion,
    discounts, // TODO: REMOVE
  } = checkoutParams || {};

  const isOldFormat = Array.isArray(discounts);

  // Order level min order base on grand total
  let minimumSurchargeAmount = 0;

  if (!Array.isArray(promotions)) {
    promotions = [
      ...(customerPromotions || []).map((promotion) => ({ ...promotion, member: true })),
      ...(campaignPromotions || []).map((campaign) => ({ ...campaign, campaign: true })),
      ...(promoCodePromotion ? [{ ...promoCodePromotion, promocode: true }] : []),
      ...(voucherPromotion ? [{ ...voucherPromotion, voucher: true }] : []),
    ];
  }

  // =====================================================
  // #1 Calculate Subtotal (items + subTypes)
  let subTotalAmount = 0;

  if (
    typeof fixedAmount === "number" ||
    (fixedAmount && !isNaN(fixedAmount)) ||
    typeof fixedQty === "number" ||
    (fixedQty && !isNaN(fixedQty))
  ) {
    // Plant direct total amount input case
    subTotalAmount = fixedAmount && !isNaN(fixedAmount) ? parseFloat(fixedAmount) : 0;
    items = [
      {
        cartIndex: 0,
        // Product fields
        product: `Total service`,
        productId: "totalQty",
        unit: UNIT_PCS,
        // Option fields
        optionId: "totalQty",
        // Pricing
        unitPrice: 0,
        qty: fixedQty && !isNaN(fixedQty) ? parseInt(fixedQty) : 0,
        lineTotal: subTotalAmount,
      },
    ];
  } else {
    // Caclculate line item total
    items = (items || []).map((item) => {
      const { addons, unitPrice, qty, unit } = item;

      let addonCharge = 0;

      if (Array.isArray(addons)) {
        addons.forEach(({ surcharge }) => {
          if (typeof surcharge === "number" && surcharge > 0) {
            if (isDecimalUnit(unit) || !Number.isInteger(qty)) {
              addonCharge = formatPrice(addonCharge + surcharge);
            } else {
              addonCharge = formatPrice(addonCharge + surcharge * qty);
            }
          }
        });
      }

      return {
        ...item,
        ...(addonCharge && { addonCharge }),
        lineTotal: formatPrice((unitPrice || 0) * (qty || 0) + addonCharge),
      };
    });

    // For none empty order, service types recalculated base on items
    if (items.length > 0) {
      serviceTypes = items.map((item) => {
        const { product, option, optionId, optionPhoto, minimumOrder, minOrder, unit, qty, subOptions } = item;
        const serviceType = Array.isArray(serviceTypes)
          ? serviceTypes.find((serviceType) => serviceType.id === optionId)
          : null;

        let serviceTypeTitle, units;

        switch (service?.sector) {
          case BUSINESS_SECTOR_AIRCON_SERVICE: {
            units = Array.isArray(subOptions) ? subOptions[0]?.qty : null;
            serviceTypeTitle = translate(product);
            break;
          }
          default:
            serviceTypeTitle = translate(option);
        }

        return (
          serviceType || {
            id: optionId,
            title: serviceTypeTitle,
            ...(optionPhoto && { photoId: optionPhoto }),
            ...((minimumOrder || minOrder) && { minimumOrder: minimumOrder || minOrder }),
            ...(unit === UNIT_HOUR && { hours: qty }),
            ...(Number.isInteger(units) && { units }),
          }
        );
      });

      serviceTypes = serviceTypes
        .filter((type, index) => serviceTypes.findIndex((other) => other.id === type.id) === index)
        .map(({ minCharge, ...serviceType }) => {
          // Drop existing minCharge
          const typeItems = (items || []).filter(({ optionId }) => optionId === serviceType.id);

          return {
            ...serviceType,
            typeTotal: formatPrice(typeItems.map((item) => item.lineTotal).reduce((a, b) => a + b)),
          };
        });
    } else {
      // Drop existing typeTotal and minCharge
      serviceTypes = (serviceTypes || []).map(({ typeTotal, minCharge, ...serviceType }) => serviceType);
    }

    // Caclculate service type minimum
    serviceTypes = (serviceTypes || []).map((serviceType) => {
      let minCharge = 0;

      // Item level minimum charge only for online order
      if (typeof serviceType.minimumOrder === "number" && type === BUSINESS_TYPE_ONLINE) {
        minCharge = formatPrice(serviceType.minimumOrder - (serviceType.typeTotal || 0));
      }

      return {
        ...serviceType,
        ...(minCharge && { minCharge }),
        lineTotal: formatPrice((serviceType.typeTotal || 0) + minCharge),
      };
    });

    // Subtotl = item line total + min charge (if any)
    if (serviceTypes.length > 0) {
      subTotalAmount = formatPrice(serviceTypes.map(({ lineTotal }) => lineTotal).reduce((a, b) => a + b));
    }

    if (!isOldFormat && typeof minimumOrder === "number" && minimumOrder > subTotalAmount) {
      minimumSurchargeAmount = formatPrice(minimumOrder - subTotalAmount);
      subTotalAmount = minimumOrder;
    }
  }

  // =====================================================
  // #2 Calculate Grand total (+ promotions / express / adjustments)
  // TODO: REMOVE
  let grossSales = subTotalAmount;
  let deliveryAmount = 0,
    promotionAmount = 0;

  if (delivery && typeof delivery.amount === "number" && delivery.amount > 0) {
    deliveryAmount = delivery.amount;
    grossSales = formatPrice(grossSales + deliveryAmount);
  }

  // Only process promotions for none B2B orders
  if (Array.isArray(promotions) && promotions.length > 0) {
    // Fist display percenrage promnotion then flat promo and put FOC to last
    const SORTING = [PROMO_TYPE_PERCENT, PROMO_TYPE_FLAT, PROMO_TYPE_FOC];
    promotions = promotions
      .map(({ applied, expired, lineTotal, shortAmount, minWaivedAmount, duplicate, minCap, ...promotion }) =>
        expired ? null : promotion
      )
      .filter((promotion) => promotion)
      .sort((a, b) => SORTING.indexOf(a.type) - SORTING.indexOf(b.type))
      .map((promotion) => {
        const { type, value, cap } = promotion;

        let shortAmount;

        if (typeof promotion.minimumOrder === "number" && promotion.minimumOrder > subTotalAmount) {
          // Not meet minimum order
          shortAmount = formatPrice(promotion.minimumOrder - subTotalAmount);
        }

        if (type === PROMO_TYPE_PERCENT) {
          let limitServiceTypes = promotion.limitServiceTypes;

          if (!Array.isArray(limitServiceTypes) && Array.isArray(promotion.limitServices)) {
            // BACKWARD SUPPORT: TODO: REMOVE
            limitServiceTypes = promotion.limitServices
              .map((id) => {
                const data = `${id || ""}`.split("-");
                return data[0] === (service && service.id) ? data[1] : null;
              })
              .filter((id) => id);
          }

          let isTypeLimited = Array.isArray(limitServiceTypes) && limitServiceTypes.length > 0;
          let lineTotal;

          // TODO: REMOVE
          if (isOldFormat) {
            // Backward support, for existing order with percentage discounts fallbacl calculate for each line items
            lineTotal = formatPrice(
              [
                0,
                ...(items || []).map(({ unitPrice, addonCharge, qty, optionId, labels }) => {
                  if (
                    Array.isArray(promotion.excludeItemLabels) &&
                    promotion.excludeItemLabels.length > 0 &&
                    promotion.excludeItemLabels.find((label) => (labels || []).indexOf(label) >= 0)
                  ) {
                    return 0;
                  } else if (
                    Array.isArray(promotion.includeItemLabels) &&
                    promotion.includeItemLabels.length > 0 &&
                    !(labels || []).find((label) => promotion.includeItemLabels.indexOf(label) >= 0)
                  ) {
                    return 0;
                  }

                  if (!isTypeLimited || limitServiceTypes.indexOf(optionId) >= 0) {
                    const discountUnitPrice = formatPrice((unitPrice * value) / 100);
                    return formatPrice(discountUnitPrice * qty + (addonCharge ? (addonCharge * value) / 100 : 0));
                  } else {
                    return 0;
                  }
                }),
              ].reduce((a, b) => a + b)
            );
          } else if (isTypeLimited) {
            // Promotion is limited on service type level, calculateService type total
            const serviceTypeTotal = formatPrice(
              [
                0,
                ...(serviceTypes || []).map(({ id, lineTotal }) => {
                  if (limitServiceTypes.indexOf(id) >= 0) {
                    return lineTotal;
                  } else {
                    return 0;
                  }
                }),
              ].reduce((a, b) => a + b)
            );

            lineTotal = formatPrice((serviceTypeTotal * value) / 100);
          } else {
            // On order level, dierctly apply on subtotal amount
            lineTotal = formatPrice((subTotalAmount * value) / 100);
          }

          return {
            ...promotion,
            lineTotal: typeof cap === "number" ? Math.min(cap, lineTotal) : lineTotal,
            ...(typeof shortAmount === "number" && { shortAmount }),
          };
        } else if (type === PROMO_TYPE_FLAT) {
          return {
            ...promotion,
            lineTotal: value,
            ...(typeof shortAmount === "number" && { shortAmount }),
          };
        } else {
          return promotion;
        }
      });

    // Backward supoport, only apply percentage promition first (TODO: combine percentage and flat promotion together)
    promotions.forEach((promotion, index) => {
      if (promotion.type === PROMO_TYPE_PERCENT) {
        if (promotion.disabled || typeof promotion.shortAmount === "number") {
          // Ignore
        } else {
          const duplicate = promotions.find(
            (another, otherIndex) =>
              !another.disabled &&
              typeof another.shortAmount !== "number" &&
              another.id !== promotion.id &&
              another.type === PROMO_TYPE_PERCENT &&
              (another.lineTotal > promotion.lineTotal ||
                (another.lineTotal === promotion.lineTotal && otherIndex < index))
          );

          if (duplicate) {
            // Have other percentage promotion is heigher or is the same but have lower index (show on top)
            promotion.duplicate = true;
          } else {
            const maxPromoAmount =
              typeof minimumOrder === "number" ? formatPrice(grossSales - minimumOrder) : promotion.lineTotal;
            const linePromoAmount = maxPromoAmount < promotion.lineTotal ? maxPromoAmount : promotion.lineTotal;

            if (linePromoAmount > 0) {
              if (linePromoAmount !== promotion.lineTotal) {
                promotion.minCap = minimumOrder;
              }

              promotion.lineTotal = linePromoAmount;
              promotion.applied = true;
              promotionAmount = formatPrice(promotionAmount + linePromoAmount);
              grossSales = formatPrice(grossSales - linePromoAmount);
            } else {
              if (maxPromoAmount < promotion.lineTotal) {
                promotion.minWaivedAmount = formatPrice(promotion.lineTotal - maxPromoAmount);
              }

              promotion.lineTotal = 0;
            }
          }
        }
      }
    });
  }

  let expressAmount = 0;

  if (delivery && delivery.option && delivery.option.surcharge && typeof delivery.option.surcharge.value === "number") {
    if (delivery.option.surcharge.type === PROMO_TYPE_PERCENT) {
      expressAmount = formatPrice(
        ((isOldFormat ? grossSales : subTotalAmount) * delivery.option.surcharge.value) / 100
      );
    } else if (delivery.option.surcharge.type === PROMO_TYPE_FLAT) {
      expressAmount = delivery.option.surcharge.value;
    }

    grossSales = formatPrice(grossSales + expressAmount);
  }

  // Backward supoport, continue apply flat promition (TODO: combine percentage and flat promotion together)
  promotions.forEach((promotion) => {
    if (promotion.type === PROMO_TYPE_FLAT) {
      if (promotion.disabled) {
        promotion.applied = false;
      } else if (
        typeof promotion.minimumOrder === "number" &&
        promotion.minimumOrder > (isOldFormat ? grossSales : subTotalAmount)
      ) {
        // Not meet minimum order
        promotion.applied = false;
      } else {
        const linePromoAmount =
          typeof minimumOrder === "number"
            ? Math.min(grossSales - minimumOrder, promotion.lineTotal)
            : promotion.lineTotal;

        if (linePromoAmount > 0) {
          if (linePromoAmount !== promotion.lineTotal) {
            promotion.minCap = minimumOrder;
          }

          promotion.lineTotal = linePromoAmount;
          promotion.applied = true;
          promotionAmount = formatPrice(promotionAmount + promotion.lineTotal);
          grossSales = formatPrice(grossSales - promotion.lineTotal);
        } else {
          promotion.lineTotal = 0;
        }
      }
    }
  });

  if (isOldFormat) {
    if (typeof minimumOrder === "number" && grossSales < minimumOrder) {
      minimumSurchargeAmount = formatPrice(minimumOrder - grossSales);
      grossSales = formatPrice(grossSales + minimumSurchargeAmount);
    }
  }

  // Adjustments
  if (Array.isArray(adjustments) && adjustments.length > 0) {
    const beforeAdjustment = grossSales;

    adjustments.forEach((adjustment) => {
      const isDiscount = adjustment.mode === ADJUSTMENT_MODE_DISCOUNT;

      if (adjustment.type === PROMO_TYPE_FLAT && typeof adjustment.value === "number") {
        let amount = Math.abs(adjustment.value);

        if (isDiscount) {
          amount = Math.min(grossSales, amount);
          amount = -amount;
        }

        adjustment.lineTotal = amount;
        grossSales = formatPrice(grossSales + amount);
      } else if (adjustment.type === PROMO_TYPE_PERCENT && typeof adjustment.value === "number") {
        let amount = formatPrice((beforeAdjustment * Math.abs(adjustment.value)) / 100);

        if (isDiscount) {
          amount = Math.min(grossSales, amount);
          amount = -amount;
        }

        adjustment.lineTotal = amount;
        grossSales = formatPrice(grossSales + amount);
      }
    });
  }

  promotions.forEach((promotion) => {
    if (
      !promotion.disabled &&
      (promotion.type === PROMO_TYPE_FOC || promotion.promoMethod === BENEFIT_TYPE_MONTHLY_FOC)
    ) {
      // Value is customer current FOC balance
      const lineTotal = Math.min(grossSales, promotion.value);
      promotion.lineTotal = lineTotal;
      promotion.applied = promotion.value > 0;

      if (lineTotal > 0) {
        grossSales = formatPrice(grossSales - lineTotal);
      }
    }
  });

  // =====================================================
  // #4 GST
  let gstAmount = 0;

  if (typeof gstRate === "number") {
    gstAmount = formatPrice((grossSales * gstRate) / 100);
  }

  // =====================================================
  // #5 Rounding & final amount
  const beforeRounding = formatPrice(grossSales + gstAmount);
  const roundingAmount = rounding(null, beforeRounding);
  const netSales = formatPrice(beforeRounding + roundingAmount);

  // =====================================================
  // #6 Process outstanding
  let totalWaived = 0,
    totalPaid = 0;

  if (Array.isArray(creditNotes) && creditNotes.length > 0) {
    totalWaived = formatPrice(creditNotes.map(({ v, lineTotal }) => v || lineTotal || 0).reduce((a, b) => a + b)); // TODO: REMOVE lineTotal
  }

  if (Array.isArray(payments) && payments.length > 0) {
    totalPaid = formatPrice(payments.map((payment) => payment?.v || 0).reduce((a, b) => a + b));
  }

  const totalBalance = Math.max(0, formatPrice(netSales - totalWaived - totalPaid));

  return {
    items: items || [],
    serviceTypes: serviceTypes || [],
    deliveryAmount,
    expressAmount,
    minimumSurchargeAmount,
    ...(typeof minimumOrder === "number" && { minimumOrder }),
    ...(delivery && { delivery }),
    promotionAmount,
    promotions: promotions || [],
    adjustments: adjustments || [],
    subTotalAmount,
    grossSales,
    gstAmount,
    ...(typeof gstRate === "number" && { gstRate }),
    roundingAmount,
    netSales,
    totalPaid,
    totalWaived,
    totalBalance,
    ...([BUSINESS_SECTOR_LAUNDRY, BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE].indexOf(service?.sector) >=
      0 && {
      totalUnit:
        Array.isArray(items) && items.length > 0
          ? items
              .map(({ qty, unit }) => {
                if (Number.isInteger(qty) && !isDecimalUnit(unit)) {
                  return qty;
                } else {
                  return 1;
                }
              })
              .reduce((a, b) => a + b)
          : 0,
    }),
  };
};
