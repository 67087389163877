export default (a, b) => {
  if (a?.address || b?.address) {
    // Bluetooth
    return a?.address === b?.address;
  } else {
    // USB
    return (
      (a?.serialNumber || "") === (b?.serialNumber || "") &&
      (a?.vendorId || null) === (b?.vendorId || null) &&
      (a?.productId || null) === (b?.productId || null)
    );
  }
};
