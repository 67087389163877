import { CUSTOMER_OPERATION_PAYMENT_INTENT_CANCEL, OPERATION_GROUP_CUSTOMER } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  return useActionCallback(
    ({ paymentIntent }) => {
      return [
        OPERATION_GROUP_CUSTOMER,
        CUSTOMER_OPERATION_PAYMENT_INTENT_CANCEL,
        {
          customerId: paymentIntent.metadata.customer_id,
          paymentIntentId: paymentIntent.id,
          stripeAccount: paymentIntent.account,
        },
      ];
    },
    callback,
    intl.formatMessage({ defaultMessage: "Payment cancelled" })
  );
};
