"use client";

import { useBusinessCode, usePOSSettings } from "@easybiz/context";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function useOrderDischargePanel(order, corporate) {
  const [selects, setSelects] = useState([]);
  const businessCode = useBusinessCode();
  const { enforceScanToDischarge, paidOnlyDischarge } = usePOSSettings();

  return useMemo(() => {
    const { itemsStatus, items, procedures, totalBalance } = order?.data() || {};

    let pending = [],
      complete = [];

    items?.forEach(({ labelIndexes, ...item }, itemIndex) => {
      const completeIndexes = labelIndexes?.filter(
        (sortIndex) => (itemsStatus && itemsStatus[sortIndex]) === procedures?.length - 1
      );
      const pendingIndexes = labelIndexes?.filter(
        (sortIndex) => (itemsStatus && itemsStatus[sortIndex]) !== procedures?.length - 1
      );

      if (pendingIndexes?.length > 0) {
        pending.push({ ...item, labelIndexes: pendingIndexes, itemIndex });
      }

      if (completeIndexes?.length > 0) {
        complete.push({ ...item, labelIndexes: completeIndexes, itemIndex });
      }
    });

    let pendingIndexes, completeIndexes;

    if (pending.length > 0) {
      pendingIndexes = [].concat.apply(
        [],
        pending.map(({ labelIndexes }) => labelIndexes || [])
      );
    }

    if (complete.length > 0) {
      completeIndexes = [].concat.apply(
        [],
        complete.map(({ labelIndexes }) => labelIndexes || [])
      );
    }

    return {
      selects,
      setSelects,
      pending: pending.length > 0 && {
        items: pending,
        title: <FormattedMessage defaultMessage="Pending ({count})" values={{ count: pendingIndexes.length }} />,
        dischargeDisabled:
          (totalBalance > 0 &&
            paidOnlyDischarge &&
            !corporate &&
            (!Array.isArray(selects) ||
              selects.length === 0 ||
              selects.some(
                (value) => !items?.find((item) => Array.isArray(item.returnLabels) && item.returnLabels.includes(value))
              ))) ||
          Boolean(
            businessCode && businessCode !== order?.get("business.id") && businessCode !== order?.get("collectionPlace")
          ),
        dischargeBtn: (
          <FormattedMessage
            defaultMessage="Discharge ({selected}/{total})"
            values={{
              selected: selects?.length || 0,
              total: pendingIndexes.length,
            }}
          />
        ),
        ...((!enforceScanToDischarge || corporate) && {
          selectAll: {
            title: <FormattedMessage defaultMessage="Select all" />,
            checked:
              Array.isArray(selects) &&
              selects.length > 0 &&
              Array.isArray(pendingIndexes) &&
              selects?.length === pendingIndexes?.length,
            indeterminate:
              Array.isArray(selects) &&
              selects.length > 0 &&
              Array.isArray(pendingIndexes) &&
              selects?.length < pendingIndexes?.length,
            onChange: (checked) => setSelects(checked ? pendingIndexes : []),
          },
        }),
        indexes: pendingIndexes,
      },
      complete: complete.length > 0 && {
        items: complete,
        indexes: completeIndexes,
        title: <FormattedMessage defaultMessage="Discharged ({count})" values={{ count: completeIndexes.length }} />,
      },
    };
  }, [order, selects, enforceScanToDischarge, paidOnlyDischarge, corporate]);
}
