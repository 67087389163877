import { useBusinessCode, useRealmId } from "@easybiz/context";
import { formatNextOrderNumber } from "@easybiz/utils";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (serviceId) => {
  const realmId = useRealmId();
  const businessCode = useBusinessCode();
  const [nextOrderNo, setNextOrderNo] = useState();

  useEffect(() => {
    setNextOrderNo(null);
    if (realmId && businessCode && serviceId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/businesses/${businessCode}/settings/order_no`),
        (counter) => setNextOrderNo(formatNextOrderNumber(counter, serviceId))
      );
    }
  }, [realmId, businessCode, serviceId]);

  return nextOrderNo;
};
