import { useToast } from "@easybiz/context";
import { OPERATION_GROUP_ORDER, ORDER_OPERATION_REDO } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const toast = useToast();
  const intl = useIntl();

  return useActionCallback(
    ({ source, orderId, redoOrderId, checkoutParams }) => {
      if (!checkoutParams?.returnSchedule) {
        return intl.formatMessage({ defaultMessage: "Please select return schedule" });
      }

      return [
        OPERATION_GROUP_ORDER,
        ORDER_OPERATION_REDO,
        {
          orderId,
          redoOrderId,
          source,
          checkoutParams,
        },
      ];
    },
    callback,
    (request, response) =>
      toast.success(
        intl.formatMessage(
          { defaultMessage: "Redo order {orderNo} created" },
          { orderNo: `#${response.newOrder?.orderNo}` }
        )
      )
  );
};
