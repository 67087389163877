import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DateDisplay } from "@easybiz/component";
import { Button, Calendar, message, Space } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import Title from "../Title";

export default function ({ title, minDate, value, onSelect, dateCellRender }) {
  const [videoDate, setViewDate] = useState(null);
  const intl = useIntl();
  const select = videoDate || value;

  return (
    <div className="flex">
      <div className="flex flex-row flex-space-between flex-align-center padding-bottom-half">
        <Space>
          <span />
          {title}
          <Title>
            <DateDisplay date={(select || dayjs()).format("MMMM YYYY")} hideDay hideTime />
          </Title>
        </Space>
        <Space>
          <Button
            style={{ minWidth: 64 }}
            icon={<LeftOutlined />}
            onClick={() => {
              setViewDate((select || dayjs()).subtract(1, "month"), true);
            }}
          ></Button>
          <Button
            style={{ minWidth: 64 }}
            icon={<RightOutlined />}
            onClick={() => {
              setViewDate((select || dayjs()).add(1, "month"), true);
            }}
          ></Button>
        </Space>
      </div>
      <Calendar
        value={select || null}
        onSelect={(date) => {
          if (minDate && date.format("YYYY-MM-DD") < minDate.format("YYYY-MM-DD")) {
            message.info(
              intl.formatMessage(
                {
                  defaultMessage: "You selected a past date {date}, please select again",
                },
                { date: intl.formatDate(date.format("YYYY-MM-DD")) }
              )
            );
          } else {
            onSelect(date);
          }
        }}
        className="hideHeader"
        disabledDate={(date) => {
          if (minDate) {
            return date.format("YYYY-MM-DD") < minDate.format("YYYY-MM-DD");
          } else {
            return false;
          }
        }}
        dateCellRender={(date) => {
          return dateCellRender && dateCellRender(date, date.isSame(select || dayjs(), "month"));
        }}
      />
    </div>
  );
}
