import isDecimalUnit from "./isDecimalUnit.js";

export default (items, sortIndex) => {
  const itemIndex = (items || []).findIndex(({ labelIndexes }) => (labelIndexes || []).indexOf(sortIndex) >= 0);
  const { unit, pcs, pcsPerUnit, labelIndexes, returnLabels, redoLabels } = (items && items[itemIndex]) || {};
  const decimal = isDecimalUnit(unit);
  const labelIndex = (labelIndexes || []).indexOf(sortIndex);

  const unitIndex =
    [
      0,
      ...items.slice(0, Math.max(0, itemIndex)).map(({ qty, unit }) => (isDecimalUnit(unit) ? 1 : Math.floor(qty))),
    ].reduce((a, b) => a + b) +
    (decimal ? 1 : Math.floor(labelIndex / (Number.isInteger(pcsPerUnit) ? pcsPerUnit : 1)) + 1);

  let unitTotalPcs = pcs || pcsPerUnit;

  return {
    unitIndex,
    ...(Array.isArray(returnLabels) &&
      returnLabels.indexOf(sortIndex) >= 0 && {
        salesReturn: true,
      }),
    ...(Array.isArray(redoLabels) &&
      redoLabels.indexOf(sortIndex) >= 0 && {
        redo: true,
      }),
    ...(labelIndex >= 0 &&
      Number.isInteger(unitTotalPcs) &&
      unitTotalPcs > 1 && {
        unitTotalPcs,
        unitPcsIndex: (labelIndex % unitTotalPcs) + 1,
      }),
  };
};
