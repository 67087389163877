import { Dialog } from "@easybiz/web-admin";
import { useOrderTwoStepPriceContinue } from "@easybiz/request";
import { ACTION_AGREE_PRICE } from "@easybiz/utils";
import { Alert } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ order, open, onCancel }) => {
  const awaits = order?.get("awaits");
  const { onSubmit, loading } = useOrderTwoStepPriceContinue((succeed) => {
    if (succeed) {
      onCancel();
    }
  });
  const confirmForCustomer = Boolean(Array.isArray(awaits) && awaits.find((action) => action === ACTION_AGREE_PRICE));

  return (
    <Dialog
      title={
        order && (
          <FormattedMessage
            defaultMessage={`Continue order {orderNo}`}
            values={{ orderNo: `#${order.get("orderNo")}` }}
          />
        )
      }
      open={open}
      onCancel={onCancel}
      onOk={() => onSubmit({ orderId: order.id, isAck: !confirmForCustomer })}
      confirmLoading={loading}
    >
      {confirmForCustomer ? (
        <Alert
          type="warning"
          banner
          message={<FormattedMessage tagName="b" defaultMessage="Agree on price on behalf of customer?" />}
        />
      ) : (
        <Alert
          type="info"
          banner
          message={<FormattedMessage defaultMessage="Acknowlogied and continue process order" />}
        />
      )}
    </Dialog>
  );
};
