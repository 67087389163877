import { LoadingOutlined, PrinterOutlined, SettingOutlined } from "@ant-design/icons";
import { useIsPlatformMode, usePrinterConnect, useRegisteredDevice } from "@easybiz/context";
import { usePrinter } from "@easybiz/hook";
import { PRINTER_TYPE_LABEL } from "@easybiz/utils";
import { Button, Space } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function PrintLabelButton({ type, loading, order }) {
  const printer = usePrinter();
  const registerDevice = useRegisteredDevice();
  const platformMode = useIsPlatformMode();
  const connectPrinter = usePrinterConnect();
  const [printing, setPrinting] = useState(false);

  const onPrint = async () => {
    try {
      setPrinting(true);
      await printer.print({ labelOrder: order });
    } finally {
      setPrinting(false);
    }
  };

  return (
    <Space>
      <Button
        type={type}
        disabled={!order}
        loading={Boolean(printing || loading)}
        onClick={onPrint}
        icon={registerDevice || platformMode ? <PrinterOutlined /> : <LoadingOutlined />}
      >
        <FormattedMessage
          tagName="span"
          defaultMessage="Print labels ({count})"
          values={{
            count: order?.get("labels")?.length,
          }}
        />
      </Button>
      <Button
        icon={<SettingOutlined />}
        type="text"
        onClick={() => connectPrinter({ printerType: PRINTER_TYPE_LABEL, labelOrder: order })}
      />
    </Space>
  );
}
