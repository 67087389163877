import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
import {
  useIsWebcameraEnabled,
  useOperator,
  useRealmId,
  useRegisteredDevice
} from "@easybiz/context";
import { arrayUnion, collection, doc, getFirestore, updateDoc } from "@firebase/firestore";
import { getStorage, ref, uploadBytes, uploadString } from "@firebase/storage";
import { Alert, Button, Col, Row, Switch, Upload, message } from "antd";
import dayjs from "dayjs";
import React, { lazy, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import AdminSuspense from "../AdminSuspense";
import OrderPhotoGallery from "../OrderPhotoGallery";

const Webcamera = lazy(() => import("../Webcamera"));

export default ({ order, videoWidth, sortIndex, permissions }) => {
  const realmId = useRealmId();
  const operator = useOperator();
  const cameraEnabled = useIsWebcameraEnabled();
  const deviceDoc = useRegisteredDevice();
  const [loading, setLoading] = useState(false);
  const cameraRef = useRef();

  const onCapture = async (content) => {
    const uploadPhotoId = doc(collection(getFirestore(), `photos`)).id;
    const photoRef = ref(getStorage(), `${uploadPhotoId}`);
    const metadata = {
      resizeWidth: 800,
      resiztHeight: 800,
      realmId,
      photoId: uploadPhotoId,
      newUpload: "yes",
    };

    try {
      setLoading(true);

      if (typeof content === "string") {
        await uploadString(photoRef, content, "data_url", { metadata });
      } else {
        await uploadBytes(photoRef, content, metadata);
      }

      await updateDoc(order.ref, {
        photos: arrayUnion({
          i: uploadPhotoId,
          o: operator?.name || null,
          t: dayjs().unix(),
          ...(typeof sortIndex === "number" && { l: sortIndex }),
        }),
      });
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onTakephoto = () => {
    cameraRef.current && onCapture(cameraRef.current.getScreenshot());
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Alert
            type={cameraEnabled ? "success" : "info"}
            icon={<CameraOutlined />}
            showIcon
            message={
              cameraEnabled ? (
                <FormattedMessage
                  defaultMessage={"Web camera is enabled, <b>touch/click on camera below to capture image</b>"}
                  values={{ b: (t) => <b>{t}</b> }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage={
                    "Enable web camera for photo capture <b>You will be ask for camera permission by enabling</b>"
                  }
                  values={{ b: (t) => <b>{t}</b> }}
                />
              )
            }
            action={
              <Switch
                checkedChildren={<FormattedMessage defaultMessage={"Enabled"} />}
                unCheckedChildren={<FormattedMessage defaultMessage={"Disabled"} />}
                checked={cameraEnabled}
                onChange={(checked) => {
                  if (deviceDoc?.exists()) {
                    updateDoc(deviceDoc.ref, { webCamera: checked });
                  }
                }}
              />
            }
          />
        </Col>
        <Col span={24}>
          {cameraEnabled && (
            <AdminSuspense>
              <Webcamera camRef={cameraRef} videoWidth={videoWidth} onTakephoto={onTakephoto} />
            </AdminSuspense>
          )}
        </Col>
        <Col span={24}>
          <OrderPhotoGallery
            extra={
              <Upload
                disabled={!order || !permissions?.canNote}
                accept="image/*"
                fileList={[]}
                multiple
                action={onCapture}
                style={{ width: "100%" }}
              >
                <Button icon={<UploadOutlined />} loading={loading} block disabled={!order || !permissions?.canNote}>
                  <FormattedMessage tagName="span" defaultMessage="Upload" />
                </Button>
              </Upload>
            }
            order={order}
            itemIndex={sortIndex}
            monitoring
            wrap
          />
        </Col>
      </Row>
    </>
  );
};
