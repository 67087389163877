import { OPERATION_GROUP_POS, POS_OPERATION_DEVICE_PAIRING_TOKEN } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const onSubmit = useCallback(({ pairingMode }) => {
    return [OPERATION_GROUP_POS, POS_OPERATION_DEVICE_PAIRING_TOKEN, { pairingMode }];
  }, []);

  return useActionCallback(onSubmit, callback);
};
