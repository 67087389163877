import { useClientType, usePlatform } from "@easybiz/context";
import { ADMIN_OPERATION_REGISTER_DEVICE, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const platform = usePlatform();
  const client = useClientType();

  const onSubmit = useCallback(
    (deviceInfo) => {
      return [OPERATION_GROUP_ADMIN, ADMIN_OPERATION_REGISTER_DEVICE, { ...deviceInfo, platform, client }];
    },
    [platform, client]
  );

  return useActionCallback(onSubmit, callback);
};
