"use client";

import {
  browserName,
  browserVersion,
  isWindows,
  osName,
  osVersion,
  mobileVendor,
  mobileModel
} from "react-device-detect";

export default () => {
  return {
    deviceName: osName || null,
    os: `${browserName || ""} ${browserVersion || ""}`,
    ...(isWindows && { isWindows }),
    ...(osName && { osName }),
    ...(osVersion && { osVersion }),
    ...(mobileVendor && { vendor: mobileVendor }),
    ...(mobileModel && { model: mobileModel }),
    ...(browserName && { browser: browserName })
  };
};