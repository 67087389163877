import { OPERATION_GROUP_POS, POS_OPERATION_DEVICE_REGISTER } from "@easybiz/utils";
import { useCallback } from "react";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const onSubmit = useCallback((deviceInfo) => {
    return [OPERATION_GROUP_POS, POS_OPERATION_DEVICE_REGISTER, deviceInfo];
  }, []);

  return useActionCallback(onSubmit, callback, null, true);
};
