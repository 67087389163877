import { ADMIN_OPERATION_BATCH_PAYMENTS, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  return useActionCallback(({ customer, payment, invoiceId, orderIds, signature }) => {
    if (!invoiceId && !customer) {
      return intl.formatMessage({
        defaultMessage: "Please select customer",
      });
    }

    if (!payment) {
      return intl.formatMessage({
        defaultMessage: "Please select payment method",
      });
    }

    if (payment.noteRequired && !payment.note) {
      return intl.formatMessage({
        defaultMessage: "Please leave a payment note",
      });
    }

    if (!invoiceId && (!Array.isArray(orderIds) || orderIds.length === 0)) {
      return intl.formatMessage({
        defaultMessage: "Please select outstanding orders",
      });
    }

    return [
      OPERATION_GROUP_ADMIN,
      ADMIN_OPERATION_BATCH_PAYMENTS,
      {
        customer,
        payment,
        invoiceId,
        orderIds,
        signature,
      },
    ];
  }, callback);
};
