import { useToast } from "@easybiz/context";
import { OPERATION_GROUP_ORDER, ORDER_OPERATION_LINE_ITEM_EDIT } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const toast = useToast();
  const intl = useIntl();

  const onSubmit = useCallback(({ newPricing, orderId, confirmed }) => {
    return [
      OPERATION_GROUP_ORDER,
      ORDER_OPERATION_LINE_ITEM_EDIT,
      {
        newPricing,
        orderId,
        ...(confirmed && { confirmed }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, (request, response) =>
    toast.success(
      response.isConfirm
        ? intl.formatMessage({ defaultMessage: "Order confirmed" })
        : intl.formatMessage({ defaultMessage: "Order line item updated" })
    )
  );
};
