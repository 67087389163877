"use client";

import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

export default function ({ photos, viewIndex, onClose, onRenderCaption }) {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    if (Array.isArray(photos)) {
      if (typeof viewIndex === "number" && viewIndex >= photos.length) {
        setCurrentImage(viewIndex - 1);
      } else {
        setCurrentImage(viewIndex || 0);
      }
    }
  }, [photos, viewIndex]);

  const gotoPrevLightboxImage = () => {
    setCurrentImage((currentImage + photos.length - 1) % photos.length);
  };

  const gotoNextLightboxImage = () => {
    setCurrentImage((currentImage + 1) % photos.length);
  };

  return Array.isArray(photos) ? (
    <Lightbox
      mainSrc={photos[currentImage]?.url}
      imageTitle={`${currentImage + 1}/${photos.length}${
        photos[currentImage]?.index ? ` (#${photos[currentImage]?.index})` : ""
      }`}
      imageCaption={onRenderCaption && onRenderCaption(currentImage)}
      nextSrc={photos[(currentImage + 1) % photos.length]?.url}
      prevSrc={photos[(currentImage + photos.length - 1) % photos.length]?.url}
      onCloseRequest={onClose}
      onMovePrevRequest={gotoPrevLightboxImage}
      onMoveNextRequest={gotoNextLightboxImage}
    />
  ) : null;
}
