import {
  CONTENT_BOLD,
  CONTENT_BREAK,
  CONTENT_DIVIDER,
  CONTENT_FENCE,
  CONTENT_SIGN_HERE,
} from "./constants/index.js";

const REGEX_CHINESE =
  /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

function countCharacter(content) {
  const datas = `${content || ""}`.split("");
  return [0, ...datas.map((data) => (REGEX_CHINESE.test(data) ? 2 : 1))].reduce(
    (a, b) => a + b
  );
}

function addPadding(length) {
  if (length < 0) return "";

  return Array(Math.max(0, length)).fill(" ").join("");
}

function singleLine(content) {
  return `${content || ""}`.replace(/[\n\r]+/g, "").trim();
}

function wrapWords(content, limit) {
  if (typeof limit !== "number" || !Number.isInteger(limit) || limit < 2) {
    return [content];
  }

  let words = singleLine(content).split(" ");

  let lines = [];
  let line = [];

  for (let index = 0; index < words.length; index++) {
    let word = words[index];

    const lineAfter = countCharacter([...line, word].join(" "));

    if (lineAfter <= limit) {
      // Enough space
      line.push(word);
    } else {
      // Not enough space
      let isAscii = countCharacter(word) === word.length;
      if (isAscii && word.length <= limit) {
        // Break line
        lines.push(line.join(" "));
        line = [word];
      } else {
        // Break word
        let lineSpace = limit - countCharacter(line.join(" ")) - 1;
        let characters = word.split("");
        let partA = "",
          partB = "";

        characters.forEach((character) => {
          const characterLength = countCharacter(character);
          if (
            partA !== null &&
            countCharacter(partA) + characterLength <= lineSpace
          ) {
            partA = `${partA}${character}`;
          } else {
            if (partA) {
              line.push(partA);
              lines.push(line.join(" "));
              line = [];
            }

            partA = null;

            if (countCharacter(partB) + characterLength <= limit) {
              partB = `${partB}${character}`;
            } else {
              lines.push(partB);
              partB = character;
            }
          }
        });

        if (partB) {
          lines.push(partB);
        }
      }
    }
  }

  if (line.length > 0) {
    lines.push(line.join(" "));
  }

  return lines;
}

export function renderContent(content, maxLength) {
  const lineContent = `${singleLine(content)}`;
  const lines = wrapWords(lineContent, maxLength);

  return `${lines.join("\n")}\n`;
}

export function renderParagraph(content, limit, center) {
  switch (content) {
    case CONTENT_DIVIDER:
      return `${Array(limit).fill("-").join("")}\n`;
    case CONTENT_BREAK:
      return `\r\n`;
    case CONTENT_BOLD:
      // Nothing to render
      return null;
    case CONTENT_FENCE:
      return `|${Array(limit - 2)
        .fill(" ")
        .join("")}|\n`;
    case CONTENT_SIGN_HERE:
      return `${Array(parseInt(limit / 2))
        .fill("_")
        .join("")}\n`;
    default:
      if (Array.isArray(content)) {
        const contentLimit = Math.max(0, limit - 2); // Leave 2 space for center gutter
        const mutiColum = content.length > 2;
        const left = `${content[0] || ""}`;
        const right = content.slice(1).join(" ");
        const oneThird = parseInt(contentLimit / 3);

        let leftLimit, rightLimit;

        if (center) {
          // Fixed left width
          leftLimit = oneThird;
          rightLimit = contentLimit - leftLimit;
        } else if (mutiColum) {
          // Keep right on same line
          rightLimit = Math.min(oneThird * 2, countCharacter(right));
          leftLimit = contentLimit - rightLimit;
        } else {
          const leftLength = countCharacter(left);
          const rightLength = countCharacter(right);

          if (leftLength < rightLength) {
            leftLimit = Math.min(leftLength, oneThird);
            rightLimit = contentLimit - leftLimit;
          } else {
            rightLimit = Math.min(rightLength, oneThird);
            leftLimit = contentLimit - rightLimit;
          }
        }

        let leftLines = wrapWords(left, leftLimit);
        let rightLines = wrapWords(right, rightLimit);
        let lineCount = Math.max(leftLines.length, rightLines.length);

        let lines = [];

        for (let lineIndex = 0; lineIndex < lineCount; lineIndex++) {
          const segLeft = leftLines[lineIndex] || "";
          const segRight = rightLines[lineIndex] || "";
          const leftLength = countCharacter(segLeft);
          const rightLength = countCharacter(segRight);

          if (center) {
            lines.push(
              `${addPadding(leftLimit - leftLength)}${segLeft}${
                segLeft.trim() ? `:` : ` `
              } ${segRight}${addPadding(rightLimit - rightLength)}`
            );
          } else {
            lines.push(
              `${segLeft}${addPadding(leftLimit - leftLength)}  ${addPadding(
                rightLimit - rightLength
              )}${segRight}`
            );
          }
        }

        return `${lines.join("\n")}\n`;
      } else {
        return renderContent(content, limit);
      }
  }
}
