import { useOrderPaymentIntentUpdate } from "@easybiz/request";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";
import PaymentMethodSelecter from "../PaymentMethodSelecter";

export default ({ order, open, onCancel }) => {
  const [paymentIntent, setPaymentIntent] = useState(order?.get("paymentIntent"));
  const { onSubmit, loading } = useOrderPaymentIntentUpdate((success) => success && onCancel());

  useEffect(() => {
    if (order) {
      setPaymentIntent(order.get("paymentIntent"));
    }
  }, [order]);

  return (
    <Dialog
      title={<FormattedMessage defaultMessage="Intent Payment Method" />}
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() => onSubmit({ orderId: order.id, paymentIntent })}
    >
      <PaymentMethodSelecter
        customerId={order?.get("customer.id")}
        payment={paymentIntent}
        onSelect={setPaymentIntent}
      />
    </Dialog>
  );
};
