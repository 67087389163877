import { CrownOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ members }) {
  if (Array.isArray(members) && members.length > 0) {
    return members.map(({ id, title, expireDate }) => {
      const active = expireDate >= dayjs().format("YYYY-MM-DD");
      return (
        <Tag
          key={id}
          icon={<CrownOutlined />}
          color={active ? "success" : "error"}
        >
          {active ? null : (
            <span>
              [<FormattedMessage tagName="span" defaultMessage="Expired" />]{" "}
            </span>
          )}
          {title}
        </Tag>
      );
    });
  } else {
    return null;
  }
}
