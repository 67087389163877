import { useSupplierDateProductionStateDoc } from "@easybiz/web-firebase";
import { useDateProductionStatus } from "@easybiz/hook";
import { Spin, Tag } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

export default ({ date, supplierId, bySupplier, selects, onDataLoaded }) => {
  const result = useSupplierDateProductionStateDoc(supplierId, date);
  const data = useDateProductionStatus(result, selects, supplierId, bySupplier);

  useEffect(() => {
    if (onDataLoaded) {
      onDataLoaded(result);
    }
  }, [result, onDataLoaded]);

  if (result) {
    if (data?.total) {
      const { completed, complete, total, options } = data;

      return (
        <div>
          <Tag color={completed ? "success" : "processing"}>
            {`${complete}/${total}`} <FormattedMessage defaultMessage={"Items"} />
          </Tag>
          {options.map((option) => {
            const text = `${option.complete}/${option.total} ${option.id}`;
            return (
              <Tag color={option.completed ? "success" : "processing"}>{option.strong ? <b>{text}</b> : text}</Tag>
            );
          })}
        </div>
      );
    }
  } else {
    return <Spin spinning={Boolean(supplierId)} />;
  }
};
