import { OPERATION_GROUP_ORDER, ORDER_OPERATION_DISCHARGE } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ orderId, labelIds, signature }) => {
    return [
      OPERATION_GROUP_ORDER,
      ORDER_OPERATION_DISCHARGE,
      {
        orderId,
        ...(Array.isArray(labelIds) && labelIds.length > 0 && { labelIds }),
        ...(signature && { signature }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Order discharged" }));
};
