import translate from "./translate.js";

export default function (doc) {
  return {
    id: doc.id,
    code: doc.get("code"),
    mode: doc.get("mode"),
    title: translate(doc.get("title")),
    type: doc.get("type"),
    value: doc.get("value"),
    ...(doc.get("editable") && { editable: doc.get("editable") }),
  };
}
