export default (value, currency, accounting) => {
  if (typeof value !== "number") return "";

  return new Intl.NumberFormat(
    "en-US-POSIX",
    currency
      ? {
          style: "currency",
          currency,
          currencyDisplay: "narrowSymbol",
          ...(accounting && { currencySign: "accounting" }),
        }
      : { minimumFractionDigits: 2 }
  ).format(value).replace(/\u00A0/g, ' ');
};
