"use client";

import { createContext, useContext, useMemo } from "react";

const PrintingContext = createContext();

export function PrintingProvider({ ESCEncoder, usbIO, bluetoothIO, connectPrinter, children }) {
  const context = useMemo(() => {
    return {
      ESCEncoder,
      usbIO,
      bluetoothIO,
      connectPrinter,
    };
  }, [ESCEncoder, usbIO, bluetoothIO, connectPrinter]);

  return <PrintingContext.Provider value={context}>{children}</PrintingContext.Provider>;
}

export const useESCEncoder = () => useContext(PrintingContext).ESCEncoder;
export const useUsbPrint = () => useContext(PrintingContext).usbIO;
export const useBluetoothPrint = () => useContext(PrintingContext).bluetoothIO;
export const usePrinterConnect = () => useContext(PrintingContext).connectPrinter;
