import { useCheckoutEditOrder, useCheckoutService, useCheckoutServiceTypes } from "@easybiz/checkout";
import { useBusinessCode, useClientDate, useSetCollectionOptions } from "@easybiz/context";
import { useAsyncCollectionOptions } from "@easybiz/request";
import { useEffect } from "react";

export default function () {
  const service = useCheckoutService();
  const businessCode = useBusinessCode();
  const editOrder = useCheckoutEditOrder();
  const date = useClientDate();
  const serviceTypes = useCheckoutServiceTypes();
  const setCollectionOptions = useSetCollectionOptions();
  const { options } = useAsyncCollectionOptions({
    serviceId: service?.id,
    businessCode,
    date,
    orderId: editOrder?.id,
    serviceTypes,
    combine: true,
  });

  useEffect(() => {
    setCollectionOptions(options);
  }, [options]);
}
