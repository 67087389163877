import { AmountDisplay } from "@easybiz/component";
import { formatPrice, PAYMENT_ID_CASH } from "@easybiz/utils";
import { Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import DialPad from "../DialPad";
import Title from "../Title";

export default function ({ disabled, payment, onChange, disableListener }) {
  const [enterAmount, setEnterAmount] = useState();

  useEffect(() => {
    if (onChange && typeof enterAmount === "string" && payment?.option === PAYMENT_ID_CASH) {
      onChange({ ...payment, cashAmount: enterAmount });
    }
  }, [enterAmount]);

  if (payment?.option === PAYMENT_ID_CASH) {
    return (
      <div>
        <Divider orientation="left">
          <FormattedMessage tagName="span" defaultMessage="Change Calculator" />
        </Divider>
        <Row gutter={32} className="padding-horizontal-half">
          <Col span={10} className="flex flex-align-end">
            <Title ellipsis>
              <AmountDisplay value={enterAmount || 0} />
            </Title>
            <Title type={"success"} ellipsis>
              <AmountDisplay value={payment.amount || 0} />
            </Title>
            <Divider style={{ margin: "8px 0px" }} />
            <Title type={"warning"} ellipsis>
              <AmountDisplay
                value={
                  enterAmount && !isNaN(enterAmount) ? formatPrice(parseFloat(enterAmount) - (payment.amount || 0)) : 0
                }
              />
            </Title>
          </Col>
          <Col span={14}>
            <DialPad
              value={enterAmount}
              onChange={setEnterAmount}
              disabled={disabled}
              decimal
              disableSubmit
              disableListener={disableListener}
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return null;
  }
}
