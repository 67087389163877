import {
  CODE_DIVIDER,
  CODE_TYPE_BAG,
  CODE_TYPE_ITEM,
  CODE_TYPE_ORDER,
  CODE_TYPE_PACK,
} from "./constants/index.js";

export function toItemQR(id) {
  return `${CODE_TYPE_ITEM}${CODE_DIVIDER}${id || ""}`;
}

export function toBagQR(bagNo) {
  return `${CODE_TYPE_BAG}${CODE_DIVIDER}${bagNo || ""}`;
}

export function toOrderQR(content) {
  return `${CODE_TYPE_ORDER}${CODE_DIVIDER}${content || ""}`;
}

export function toPackQR(id) {
  return `${CODE_TYPE_PACK}${CODE_DIVIDER}${id || ""}`;
}
