import { JOB_OPERATION_COMPLETE, OPERATION_GROUP_JOB } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ taskId, note, bags, signature, checkItems }) => {
    return [
      OPERATION_GROUP_JOB,
      JOB_OPERATION_COMPLETE,
      {
        ...(note && { note }),
        ...(Array.isArray(bags) && bags.length > 0 && { bags }),
        ...(signature && { signature }),
        ...(Array.isArray(checkItems) && checkItems.length > 0 && { checkItems }),
        taskId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Job completed" }));
};
