import { PlusOutlined } from "@ant-design/icons";
import { AddressDisplay, NA } from "@easybiz/component";
import { toRecordAddress } from "@easybiz/utils";
import { useCustomerDoc } from "@easybiz/web-firebase";
import { Button, Card, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import CustomerAddressEditPanel from "../CustomerAddressEditPanel";
import CustomerAddressList from "../CustomerAddressList";

export default function ({ customerId, address, onSelect }) {
  const [newVisible, setNewVisible] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [editAddress, setEditAddress] = useState();
  const customerDoc = useCustomerDoc(address ? null : customerId);

  useEffect(() => {
    if (customerDoc && !address) {
      if (customerDoc?.get("defaultAddress")) {
        onSelect(customerDoc?.get("defaultAddress"));
      } else {
        setActiveKey("address");
      }
    }
  }, [Boolean(address), customerDoc]);

  return (
    <>
      {customerDoc || address ? (
        <Collapse activeKey={activeKey} onChange={setActiveKey} size="middle">
          <Collapse.Panel
            key={"address"}
            header={
              <FormattedMessage
                defaultMessage={"Address: {address}"}
                values={{ address: address ? <AddressDisplay address={address} /> : <NA /> }}
              />
            }
          >
            {editAddress || newVisible ? (
              <CustomerAddressEditPanel
                customerId={customerId}
                editAddress={editAddress}
                onCancel={(e) => {
                  e.stopPropagation();
                  setEditAddress(null);
                  setNewVisible(false);
                }}
                onComplete={(address) => {
                  onSelect(address);
                  setEditAddress(null);
                  setNewVisible(false);
                  setActiveKey(null);
                }}
              />
            ) : (
              <CustomerAddressList
                customerId={customerId}
                selectId={address?.id}
                header={
                  <Button
                    icon={<PlusOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setNewVisible(true);
                    }}
                  >
                    <FormattedMessage tagName={"span"} defaultMessage={"Add address"} />
                  </Button>
                }
                onEdit={(addressDoc) => setEditAddress(toRecordAddress(addressDoc))}
                onSelect={(addressDoc) => {
                  onSelect(toRecordAddress(addressDoc));
                  setActiveKey(null);
                }}
              />
            )}
          </Collapse.Panel>
        </Collapse>
      ) : (
        <Card loading />
      )}
    </>
  );
}
