"use client";

import { useBusinesses } from "@easybiz/context";
import { useState } from "react";

export default function useCheckoutState(propBusinessCode, propService, propCustomer, propEditOrder) {
  const businesses = useBusinesses();
  const [stateBusinessCode, setBusinessCode] = useState();
  const defaultService = businesses && businesses[0];
  const [stateService, setService] = useState();
  const [stateCustomer, setCustomer] = useState();
  const [stateEditOrder, setEditOrder] = useState();
  const editOrder = propEditOrder || stateEditOrder;
  const businessCode = editOrder ? editOrder.get("business.id") : propBusinessCode || stateBusinessCode;
  const service = editOrder ? editOrder.get("service") : propService || stateService || defaultService;
  const customer = editOrder ? editOrder.get("customer") : propCustomer || stateCustomer;

  return {
    businessCode,
    service,
    customer,
    editOrder,
    setBusinessCode,
    setService,
    setCustomer,
    setEditOrder,
  };
}
