import {
  JOB_TYPE_DELIVERY,
  JOB_TYPE_PICKUP,
  JOB_TYPE_SERVICE,
} from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ type }) {
  switch (type) {
    case JOB_TYPE_PICKUP:
      return <FormattedMessage defaultMessage="Pick-up" />;
    case JOB_TYPE_SERVICE:
      return <FormattedMessage defaultMessage="Service" />;
    case JOB_TYPE_DELIVERY:
      return <FormattedMessage defaultMessage="Delivery" />;
    default:
      return null;
  }
}
