import {
  PERMISSION_DATA_SEARCH_CUSTOMERS,
  PERMISSION_DATA_SEARCH_ORDERS,
} from "./constants/index.js";

// Permission helpers
export default function (
  permisison,
  context
) {
  const { realmMaster, permissions } = context || {};
  // Skip limitation permission for master account
  if (
    [PERMISSION_DATA_SEARCH_ORDERS, PERMISSION_DATA_SEARCH_CUSTOMERS].indexOf(
      permisison
    ) >= 0
  ) {
    if (realmMaster) return false; // Realm master have full permission
  }

  if (realmMaster) return true; // Realm master have full permission

  if (!Array.isArray(permissions)) return false;

  return permissions.indexOf(permisison) >= 0;
}
