import { PrinterTypeDisplay } from "@easybiz/component";
import { useConnectedPrinter, useRegisteredDevice } from "@easybiz/context";
import { useAdminPrinterRegister } from "@easybiz/request";
import { PRINTER_TYPE_LABEL, PRINTER_TYPE_RECEIPT } from "@easybiz/utils";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";
import LabelPrinterSettingPanel from "../LabelPrinterSettingPanel";
import ReceiptPrinterSettingPanel from "../ReceiptPrinterSettingPanel";
import TestPrintButton from "../TestPrintButton";

export default ({ printerType, open, onCancel }) => {
  const printer = useConnectedPrinter(printerType);
  const registerDevice = useRegisteredDevice();
  const [settings, setSettings] = useState();
  const { onSubmit, loading } = useAdminPrinterRegister((success) => success && onCancel());

  useEffect(() => {
    if (open) {
      setSettings(registerDevice?.get(`${printerType}Printer`));
    }
  }, [open]);

  let settingPanel;

  switch (printerType) {
    case PRINTER_TYPE_RECEIPT:
      settingPanel = <ReceiptPrinterSettingPanel settings={settings} onChange={setSettings} />;
      break;
    case PRINTER_TYPE_LABEL:
      settingPanel = <LabelPrinterSettingPanel settings={settings} onChange={setSettings} />;
      break;
  }

  return (
    <Dialog
      zIndex={1100}
      title={
        <FormattedMessage
          defaultMessage="{printerType} settings"
          values={{
            printerType: <PrinterTypeDisplay printerType={printerType} />,
          }}
        />
      }
      confirmLoading={loading}
      open={open}
      onCancel={onCancel}
      onOk={() =>
        onSubmit({
          settings,
          deviceId: registerDevice.id,
          printerType,
          printer,
        })
      }
    >
      {settingPanel}
      <br />
      <br />
      <TestPrintButton printerType={printerType} device={printer} settings={settings} />
    </Dialog>
  );
};
