import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";
import { useRealmId } from "@easybiz/context";

export default (customerId) => {
  const realmId = useRealmId();
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    setCustomer(null);
    if (realmId && customerId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/customers/${customerId}`),
        setCustomer
      );
    }
  }, [realmId, customerId]);

  return customer;
};
