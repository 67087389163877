import { EditOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { AddressDisplay } from "@easybiz/component";
import { useCustomerAddressQuery } from "@easybiz/web-firebase";
import { Avatar, Checkbox, Empty, List, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import IconButton from "../IconButton";

export default function ({ header, selectId, customerId, onEdit, onSelect }) {
  const addresses = useCustomerAddressQuery(customerId);

  return (
    <List
      header={header}
      dataSource={addresses || []}
      loading={!addresses}
      locale={{
        emptyText: <Empty description={<FormattedMessage defaultMessage={"Customer account has no address"} />} />,
      }}
      renderItem={(address) => {
        return (
          <List.Item
            className={onSelect ? "selectable" : null}
            onClick={onSelect ? () => onSelect(address) : null}
            extra={
              onEdit ? (
                <IconButton
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(address);
                  }}
                />
              ) : null
            }
          >
            <List.Item.Meta
              avatar={
                onSelect ? <Checkbox checked={selectId === address.id} /> : <Avatar icon={<EnvironmentOutlined />} />
              }
              title={<AddressDisplay address={address.data()} />}
              description={
                address.get("isDefault") ? (
                  <Tag color="success">
                    <FormattedMessage defaultMessage={"Default address"} />
                  </Tag>
                ) : null
              }
            />
          </List.Item>
        );
      }}
    />
  );
}
