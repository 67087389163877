"use client";

import { useErrorColor, usePrimaryColor, useSecondaryColor } from "@easybiz/context";
import { BUSINESS_ID_FACTORY, BUSINESS_TYPE_SHOP } from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";
import OrderNoDisplay from "./OrderNoDisplay";
import CustomerDisplay from "./CustomerDisplay";
import DateDisplay from "./DateDisplay";

export default function useOrderListItem(order, shortOrderNumber, heighLightCustomer) {
  const {
    service,
    business,
    orderNo,
    customer,
    agent,
    salesRemark,
    timestamp,
    creator,
    homeDelivery,
    type,
    pricingTBD,
    paid,
    completed,
    redoOrderNo,
    voided,
    voidedBy,
    voidNote,
    voidedDate,
    delivery,
    netSales,
    completes,
    awaits,
  } = order?.data() || {};
  const isPlant = business?.id === BUSINESS_ID_FACTORY;
  const primary = usePrimaryColor();
  const errorColor = useErrorColor();
  const secondary = useSecondaryColor();
  const completeCount = Array.isArray(completes) ? completes.length : 0;
  const total = completeCount + (Array.isArray(awaits) ? awaits.length : 0);
  const percent = total > 0 ? parseFloat(completeCount / total) : 0;

  return {
    avatarId: isPlant ? null : service?.id,
    title: (
      <>
        {shortOrderNumber ? `#${orderNo}` : <OrderNoDisplay business={business} orderNo={orderNo} agent={agent} />}{" "}
        {customer && heighLightCustomer && <CustomerDisplay customer={customer} />}
      </>
    ),
    description: (
      <>
        {customer && !heighLightCustomer ? <CustomerDisplay customer={customer} /> : null}
        {customer && !heighLightCustomer ? `\n` : null}
        <DateDisplay timestamp={timestamp} />
        {` ${creator?.name || ""}`}
      </>
    ),
    progress: order &&
      !voided && {
        percent,
        color: completed ? primary : secondary,
      },
    salesRemark,
    completed,
    priceAmount: netSales,
    priceColor: voided ? "gray" : paid ? primary : errorColor,
    homeDelivery: homeDelivery && type === BUSINESS_TYPE_SHOP && <FormattedMessage defaultMessage={"Home delivery"} />,
    deliveryDate: delivery && <DateDisplay date={delivery?.date} />,
    voidedDescription: voided && (
      <>
        {voidedBy?.name} {voidedDate?.date && <DateDisplay date={voidedDate.date} />} {voidNote ? ` (${voidNote})` : ""}
      </>
    ),
    statusChip: voided
      ? {
          isVoid: true,
          title: <FormattedMessage defaultMessage={"Cancelled"} />,
        }
      : redoOrderNo
      ? {
          isRedo: true,
          title: <FormattedMessage defaultMessage={"Redo {orderNo}"} values={{ orderNo: `#${redoOrderNo}` }} />,
        }
      : pricingTBD
      ? {
          isPending: true,
          title: <FormattedMessage defaultMessage={"Pending"} />,
        }
      : paid
      ? {
          isPaid: true,
          title: <FormattedMessage defaultMessage={"Paid"} />,
        }
      : null,
  };
}
