import { DateDisplay } from "@easybiz/component";
import { useCashDrawer } from "@easybiz/context";
import { FormattedMessage } from "react-intl";

export default function CashDrawerEndAlert() {
  const cashDrawer = useCashDrawer();

  if (cashDrawer?.get('startTime')) {
    return {
      title: <FormattedMessage defaultMessage={"Have you forgot to end your cash drawer?"} />,
      description: (
        <FormattedMessage
          defaultMessage={"Current cash drawer started at {time} by {operatorName}"}
          values={{ time: <DateDisplay timestamp={cashDrawer?.get('startTime')} />, operatorName: cashDrawer?.get('startBy.name') }}
        />
      ),
    };
  }
}
