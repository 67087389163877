import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, message, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";
import IndexSearchbar from "../IndexSearchbar";

export default ({ step, selects, multiple, onChange }) => {
  const [filteredOptions, setFilteredOptions] = useState(null);
  const [otherVisible, setOtherVisible] = useState(false);
  const [others, setOthers] = useState("");
  const intl = useIntl();

  useEffect(() => {
    if (otherVisible) {
      setOthers("");
    }
  }, [otherVisible]);

  const renderOptionButton = (option, index) => {
    const selectIndex = selects ? selects.findIndex((select) => select.code === option.code) : -1;

    return (
      <Button
        key={`${index}`}
        type={selectIndex >= 0 ? "primary" : 'default'}
        shape="round"
        onClick={() => {
          if (selectIndex >= 0) {
            const values = [...selects.slice(0, selectIndex), ...selects.slice(selectIndex + 1)];
            onChange(values.length > 0 ? values : null);
          } else {
            if (multiple) {
              onChange([...(selects || []), option]);
            } else {
              onChange([option]);
            }
          }
        }}
      >
        <span
          style={{
            maxWidth: 300,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {option.code}
        </span>
      </Button>
    );
  };

  const options = filteredOptions || step.options;

  return (
    <>
      <Space direction="vertical" className="full-width padding-left-half">
        <IndexSearchbar dataKey={step.key} options={step.options} optionKey="code" onFiltered={setFilteredOptions} />
        <Space direction="horizontal" wrap>
          {options && options.map(renderOptionButton)}
          {selects &&
            selects
              .filter((select) => !options || !options.find((option) => option.code === select.code))
              .map(renderOptionButton)}
          {step.enableOthers && (
            <Button
              type="dashed"
              shape="round"
              onClick={() => {
                setOtherVisible(true);
              }}
              icon={<PlusOutlined />}
            >
              <FormattedMessage tagName={"span"} defaultMessage={"Other"} />
            </Button>
          )}
        </Space>
      </Space>
      <Dialog
        title={<FormattedMessage defaultMessage="Other option" />}
        open={otherVisible}
        onCancel={() => setOtherVisible(false)}
        value={others}
        onOk={() => {
          if (!others)
            return message.info(
              intl.formatMessage({
                defaultMessage: "Please describe your option",
              })
            );

          const index = selects ? selects.findIndex((option) => option.code === others) : -1;
          if (index >= 0) {
            message.info(intl.formatMessage({ defaultMessage: "{item} already selected" }, { item: others }));
          } else {
            setOthers("");
            setOtherVisible(false);
            onChange([...(selects || []), { code: others }]);
          }
        }}
      >
        <Input
          autoFocus
          placeholder={intl.formatMessage({
            defaultMessage: "Describe your option",
          })}
          value={others}
          onChange={(e) => setOthers(e.target.value)}
        />
      </Dialog>
    </>
  );
};
