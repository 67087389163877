import { formatCheckoutPromotions, PROMO_TYPE_FOC } from "@easybiz/utils";
import { useMemo } from "react";
import useCustomerDoc from "./useCustomerDoc";
import useCustomerMonthFOCDoc from "./useCustomerMonthFOCDoc";
import useAutoPromotionQuery from "./useAutoPromotionQuery";

export default (businessCode, serviceId, customerId, date) => {
  const customerDoc = useCustomerDoc(customerId);
  const promotions = useAutoPromotionQuery(date, customerId);
  const focPromotion = promotions?.find((promotion) => promotion.get("type") === PROMO_TYPE_FOC);
  const focStatusDoc = useCustomerMonthFOCDoc(focPromotion?.get("memberId"), customerId, date);

  return useMemo(() => {
    if (Array.isArray(promotions)) {
      return formatCheckoutPromotions(promotions, {
        customerId,
        businessCode,
        serviceId,
        date,
        customerDoc,
        focStatusDoc,
      });
    }
  }, [promotions, businessCode, serviceId, customerId, date, customerDoc, focStatusDoc]);
};
