"use client";

import {
  CODE_DIVIDER,
  CODE_TYPE_BAG,
  CODE_TYPE_PICKUP_BAG,
  CODE_TYPE_MEMBER_CARD,
  CODE_TYPE_STORAGE,
} from "@easybiz/utils";
import { useEffect, useRef } from "react";

export default ({ enabled, filter, onDetected }) => {
  const timer = useRef(false);
  const input = useRef(false);

  useEffect(() => {
    if (enabled) {
      const onInputListener = ({ key }) => {
        if (!key) return;

        if (key.length === 1 || key === "Shift") {
          if (key.length === 1) {
            input.current = `${input.current || ""}${key}`;
            if (input.current && input.current.split(CODE_DIVIDER)[1]) {
              const datas = input.current.split(CODE_DIVIDER);

              if (datas[1].length === 20) {
                timer.current && clearTimeout(timer.current);

                if (!filter || filter == datas[0]) {
                  onDetected({
                    type: datas[0],
                    data: datas[1],
                  });
                }
                input.current = ``;
              }
            }
          }

          if (timer.current) {
            clearTimeout(timer.current);
          }

          timer.current = setTimeout(() => {
            if (input.current && input.current.split(CODE_DIVIDER)[1]) {
              const datas = input.current.split(CODE_DIVIDER);
              if (
                datas[0] === CODE_TYPE_BAG ||
                datas[0] === CODE_TYPE_PICKUP_BAG ||
                datas[0] === CODE_TYPE_STORAGE
              ) {
                if (!filter || filter == datas[0]) {
                  onDetected({
                    type: datas[0],
                    data: datas[1],
                  });
                }
              }
            } else if (
              input.current &&
              input.current.length === 18 &&
              !isNaN(input.current)
            ) {
              // Memnbercard
              onDetected({
                type: CODE_TYPE_MEMBER_CARD,
                data: input.current,
              });
            }
            input.current = ``;
          }, 200);
        } else if (key === "Enter") {
          timer.current && clearTimeout(timer.current);
          if (input.current) {
            const datas = input.current.split(CODE_DIVIDER);
            if (datas.length === 2) {
              if (!filter || filter == datas[0]) {
                onDetected({
                  type: datas[0],
                  data: datas[1],
                });
              }
            } else if (input.current.length === 18 && !isNaN(input.current)) {
              // Memnbercard
              onDetected({
                type: CODE_TYPE_MEMBER_CARD,
                data: input.current,
              });
            }
          }
          input.current = ``;
        } else {
          input.current = ``;
        }
      };

      window.addEventListener("keydown", onInputListener);
      return () => window.removeEventListener("keydown", onInputListener);
    }
  }, [enabled, filter, onDetected]);

  return null;
};
