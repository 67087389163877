import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { AmountDisplay } from "@easybiz/component";
import { useCurrency } from "@easybiz/context";
import { translate } from "@easybiz/utils";
import { Badge, Button, List, Space } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../IconButton";
import Title from "../Title";

export default ({ options, loading, selects, onEditPrice, onEditNote, onChange, onOther }) => {
  const intl = useIntl();
  const currency = useCurrency();

  return (
    <List
      size="small"
      dataSource={options}
      loading={loading}
      renderItem={(option) => {
        const { title, code, startPrice, endPrice } = option;
        const selectIndex = selects ? selects.findIndex((select) => translate(select.title) === translate(title)) : -1;
        const selected = selectIndex >= 0;
        const select = selects && selects[selectIndex];

        return (
          <List.Item
            actions={[
              select ? (
                <Space direction="vertical" align="end">
                  <Badge
                    count={
                      select.discountAmount
                        ? intl.formatNumber(-select.discountAmount, {
                            style: "currency",
                            currency,
                            currencyDisplay: "narrowSymbol",
                            currencySign: "accounting",
                          })
                        : null
                    }
                    offset={[-72]}
                  >
                    <Button
                      style={{ minWidth: 73 }}
                      type="primary"
                      ghost
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditPrice(selectIndex);
                      }}
                    >
                      <b>
                        <AmountDisplay value={select.price} />
                      </b>
                    </Button>
                  </Badge>
                  <Button
                    icon={<EditOutlined />}
                    type={select.note ? "link" : "text"}
                    onClick={() => onEditNote({ selectIndex, note: select.note, title })}
                  >
                    <FormattedMessage tagName={"span"} defaultMessage="Note" />
                    <Badge dot={Boolean(select.note)} />
                  </Button>
                </Space>
              ) : (
                <IconButton
                  icon={<PlusOutlined />}
                  onClick={() => onChange(-1, { ...option, price: startPrice || 0 })}
                />
              ),
            ]}
          >
            <List.Item.Meta
              title={<Title type={selected ? "success" : null}>{translate(title)}</Title>}
              description={
                <Space>
                  {code ? <b>[{code}]</b> : null}
                  {selected ? (
                    <IconButton
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => onChange(selectIndex, null)}
                    />
                  ) : startPrice || endPrice ? (
                    <span>
                      {startPrice ? <AmountDisplay value={startPrice} /> : null} ~{" "}
                      {endPrice ? <AmountDisplay value={endPrice} /> : null}
                    </span>
                  ) : null}
                </Space>
              }
            />
          </List.Item>
        );
      }}
      footer={
        <Button icon={<PlusOutlined />} onClick={onOther}>
          <FormattedMessage tagName={"span"} defaultMessage={"Other service"} />
        </Button>
      }
    />
  );
};
