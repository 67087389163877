import encodeOpenCashDrawer from "./encodeOpenCashDrawer";
import encodeReceipt from "./encodeReceipt";

export { default as useBluetoothIO } from './useBluetoothIO';
export { default as useUsbIO } from './useUsbIO';

export const ESCEncoder = {
  encodeOpenCashDrawer,
  encodeReceipt
};
