import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useOrderPaymentStatusList } from "@easybiz/component";
import { Button, List, Space, Tag, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ order, canCancelPayment, onRefund, onEditPaymentIntent }) => {
  const { dataSource } = useOrderPaymentStatusList(order);

  return (
    <List
      size="small"
      dataSource={dataSource || []}
      loading={!dataSource}
      renderItem={({
        title,
        amount,
        strong,
        description,
        infos,
        alerts,
        color,
        backgroundColor,
        tag,
        refundablePayment,
        paymentIntent,
      }) => {
        return (
          <List.Item
            style={{ backgroundColor }}
            extra={
              <Space direction="horizontal" align="center">
                {refundablePayment && (
                  <Button disabled={!canCancelPayment} type="text" danger onClick={() => onRefund(refundablePayment)}>
                    <FormattedMessage defaultMessage={"Refund"} />
                  </Button>
                )}
                <Space direction="vertical" align="end">
                  <Typography.Text style={{ color, fontSize: strong ? 18 : 16 }} strong={strong}>
                    {amount}
                  </Typography.Text>
                  {tag && <Tag>{tag}</Tag>}
                </Space>
              </Space>
            }
          >
            <List.Item.Meta
              title={
                <Typography.Text style={{ fontSize: strong ? 18 : 16 }} strong={strong}>
                  {title}
                </Typography.Text>
              }
              description={
                infos || alerts ? (
                  <div className="flex">
                    {description && <Typography.Text>{description}</Typography.Text>}
                    {Array.isArray(infos) &&
                      infos.map((content, index) => (
                        <Typography.Text key={`info-${index}`} type="warning">
                          <InfoCircleOutlined />
                          {` `}
                          {content}
                        </Typography.Text>
                      ))}
                    {Array.isArray(alerts) &&
                      alerts.map((content, index) => (
                        <Typography.Text key={`alert-${index}`} type="danger">
                          <CloseOutlined />
                          {` `}
                          {content}
                        </Typography.Text>
                      ))}
                  </div>
                ) : (
                  description
                )
              }
            />
          </List.Item>
        );
      }}
    />
  );
};
