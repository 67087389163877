import { NameDisplay } from "@easybiz/component";
import { useBusinessCode, useDisplayMode, useSetDisplayMode, useSuppliers } from "@easybiz/context";
import { ProductionMonitorPanel } from "@easybiz/web-admin";
import { Segmented } from "antd";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PanelHeader from "./PanelHeader";

export default function ({ date, onClose }) {
  const businessCode = useBusinessCode();
  const suppliers = useSuppliers();
  const displayMode = useDisplayMode();
  const setDisplayMode = useSetDisplayMode();
  const supplierId = displayMode?.supplierId;

  useEffect(() => {
    if (suppliers && suppliers[0] && (!supplierId || suppliers.indexOf(supplierId) < 0)) {
      setDisplayMode((displayMode) => ({ ...displayMode, supplierId: suppliers[0] }));
    }
  }, [supplierId, suppliers]);

  return (
    <>
      <PanelHeader
        title={
          <span>
            <FormattedMessage defaultMessage={"Production Monitor"} />
            {suppliers?.length === 1 ? (
              <b>
                {" "}
                (<NameDisplay nameKey={supplierId} />)
              </b>
            ) : null}
          </span>
        }
        onClose={onClose}
      />
      {suppliers?.length > 1 && (
        <div className="padding-half">
          <Segmented
            block
            value={supplierId}
            onChange={(supplierId) => setDisplayMode((displayMode) => ({ ...displayMode, supplierId }))}
            options={suppliers.map((supplierId) => ({
              label: <NameDisplay nameKey={supplierId} />,
              value: supplierId,
            }))}
          />
        </div>
      )}
      <div className="flex flex-fill self-scroll padding-horizontal-half">
        <ProductionMonitorPanel date={date} supplierId={supplierId} businessCode={businessCode} />
      </div>
    </>
  );
}
