"use client";

import { useIsPlatformMode } from "@easybiz/context";
import { useAdminIntercomBoot } from "@easybiz/request";
import { useEffect } from "react";

export default function ({ onLaunched }) {
  const platformMode = useIsPlatformMode();

  const { onSubmit } = useAdminIntercomBoot((succeed, response) => {
    if (succeed) {
      onLaunched(response.intercom);
    }
  });

  useEffect(() => {
    if (onSubmit && !platformMode) {
      onSubmit();
    }
  }, [platformMode, Boolean(onSubmit)]);

  return null;
}
