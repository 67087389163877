import {
  TRANSACTION_TYPE_BONUS,
  TRANSACTION_TYPE_CASH_BACK,
  TRANSACTION_TYPE_COMPENSATION,
  TRANSACTION_TYPE_GIFT,
  TRANSACTION_TYPE_REDEEM,
  TRANSACTION_TYPE_REFUND,
  TRANSACTION_TYPE_REWARD,
  TRANSACTION_TYPE_SPEND,
  TRANSACTION_TYPE_TOP_UP,
  TRANSACTION_TYPE_VOID,
} from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ type }) {
  switch (type) {
    case TRANSACTION_TYPE_TOP_UP:
      return <FormattedMessage defaultMessage="Top Up" />;
    case TRANSACTION_TYPE_GIFT:
      return <FormattedMessage defaultMessage="Gift" />;
    case TRANSACTION_TYPE_REWARD:
      return <FormattedMessage defaultMessage="Reward" />;
    case TRANSACTION_TYPE_COMPENSATION:
      return <FormattedMessage defaultMessage="Compensation" />;
    case TRANSACTION_TYPE_SPEND:
      return <FormattedMessage defaultMessage="Payment" />;
    case TRANSACTION_TYPE_VOID:
      return <FormattedMessage defaultMessage="Void" />;
    case TRANSACTION_TYPE_CASH_BACK:
      return <FormattedMessage defaultMessage="Cash back" />;
    case TRANSACTION_TYPE_BONUS:
      return <FormattedMessage defaultMessage="Bonus" />;
    case TRANSACTION_TYPE_REFUND:
      return <FormattedMessage defaultMessage="Refund" />;
    case TRANSACTION_TYPE_REDEEM:
      return <FormattedMessage defaultMessage="Redeem" />;
    default:
      return type || null;
  }
}
