export default (option, topUpRecord) => {
  const { amount, giftAmount, oneTimeGiftAmount } = option.data();

  return {
    id: option.id,
    amount,
    ...(giftAmount > 0 && { giftAmount }),
    ...(oneTimeGiftAmount > 0 &&
      (!topUpRecord || !topUpRecord?.get(option.id)) && {
        oneTimeGiftAmount,
      }),
  };
};
