import { CameraOutlined, EditOutlined, HistoryOutlined, PrinterOutlined } from "@ant-design/icons";
import { usePrinter } from "@easybiz/hook";
import { List, Popover, Space, Tag, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import IconButton from "../IconButton";
import LabelHistoryList from "../LabelHistoryList";
import LabelStatusTag from "../LabelStatusTag";
import LabelView from "../LabelView";
import OrderPhotoGallery from "../OrderPhotoGallery";
import Title from "../Title";

export default ({ item, order, onNote, onPhoto, disablePrint, disabled, onClick }) => {
  const { labelId, label, accessory, sortIndex, note } = item;
  const printer = usePrinter();

  const onPrint = () => {
    printer.print({ label });
  };

  return (
    <List.Item>
      <List.Item.Meta
        title={
          <Space direction="vertical" className="full-width">
            <Space>
              {accessory ? <Tag>{accessory}</Tag> : null}
              <LabelStatusTag order={order} sortIndex={sortIndex} />
            </Space>
            <LabelView onClick={onClick} label={label} disabled={disablePrint} half />
          </Space>
        }
        description={
          <Space direction="vertical">
            {note ? <Typography.Text type="warning" strong>{`* ${note}`}</Typography.Text> : null}
            <OrderPhotoGallery order={order} itemIndex={sortIndex} wrap />
            {!accessory && (
              <Space>
                {onNote ? (
                  <IconButton onClick={() => onNote(label)} icon={<EditOutlined />} disabled={disabled} />
                ) : null}
                {onPhoto ? (
                  <IconButton onClick={() => onPhoto(sortIndex)} icon={<CameraOutlined />} disabled={disabled} />
                ) : null}
                {disablePrint ? null : <IconButton onClick={onPrint} icon={<PrinterOutlined />} />}
                <Popover
                  trigger="click"
                  placement="left"
                  title={
                    <Title>
                      <FormattedMessage defaultMessage={"Label History"} />
                    </Title>
                  }
                  content={<LabelHistoryList labelId={labelId} />}
                >
                  <IconButton icon={<HistoryOutlined />} />
                </Popover>
              </Space>
            )}
          </Space>
        }
      />
    </List.Item>
  );
};
