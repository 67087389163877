import { ADMIN_OPERATION_PHOTO_DELETE, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  return useActionCallback(
    ({ photoId, orderId, localIndex }) => {
      return [
        OPERATION_GROUP_ADMIN,
        ADMIN_OPERATION_PHOTO_DELETE,
        {
          orderId,
          photoId,
          ...(Number.isInteger(localIndex) && { localIndex }),
        },
      ];
    },
    callback,
    intl.formatMessage({ defaultMessage: "Photo deleted" })
  );
};
