import { OPERATION_GROUP_ORDER, ORDER_OPERATION_REFRESH_RECEIPT } from "@easybiz/utils";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  return useActionCallback(
    ({ orderId }) => {
      return [
        OPERATION_GROUP_ORDER,
        ORDER_OPERATION_REFRESH_RECEIPT,
        {
          orderId,
        },
      ];
    },
    callback,
    intl.formatMessage({ defaultMessage: "Receipt refreshed" })
  );
};
