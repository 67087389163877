"use client";

import { createContext, useContext } from "react";

const CustomerScreenContext = createContext();

export function CustomerScreenProvider({ customerScreenDoc, children }) {
  return <CustomerScreenContext.Provider value={customerScreenDoc}>{children}</CustomerScreenContext.Provider>;
}

export const useCustomerScreen = () => useContext(CustomerScreenContext);
