import { useErrorColor, useLabelSettings, useSecondaryContainerColor } from "@easybiz/context";
import { usePrinter } from "@easybiz/hook";
import { LABEL_AREA_LEFT, LABEL_AREA_RIGHT, LABEL_AREA_SIDE } from "@easybiz/utils";
import { Card } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React from "react";

export default ({ previewSettings, heighLightArea, label, backgroundColor, onClick, disabled, half }) => {
  const settings = useLabelSettings();
  const printer = usePrinter();
  const { fixedHeight } = previewSettings || settings || {};
  const isNarrow = fixedHeight <= 10;
  const height = typeof fixedHeight === "number" ? fixedHeight * 5 : 50;
  const singleMark = label.leftIndex && `${label.leftIndex}`.length === 1;
  const errorColor = useErrorColor();
  const bgColor = useSecondaryContainerColor();

  return (
    <Card
      className="selectable"
      style={{
        backgroundColor: backgroundColor || "white",
        height,
        overflow: "hidden",
      }}
      bodyStyle={{ padding: 0 }}
      onClick={() => {
        if (onClick) {
          onClick(label);
        } else if (!disabled) {
          printer.print({ label });
        }
      }}
    >
      <div style={{ position: "relative", fontFamily: "monospace" }}>
        {heighLightArea === LABEL_AREA_SIDE && (
          <div
            style={{
              position: "absolute",
              left: 0,
              width: isNarrow ? 135 : 115,
              height,
              backgroundColor: bgColor,
            }}
          />
        )}
        {heighLightArea === LABEL_AREA_SIDE && (
          <div
            style={{
              position: "absolute",
              right: 0,
              width: isNarrow ? 145 : 125,
              height,
              backgroundColor: bgColor,
            }}
          />
        )}
        {heighLightArea === LABEL_AREA_RIGHT && (
          <div
            style={{
              position: "absolute",
              left: 325,
              width: 95,
              height,
              backgroundColor: bgColor,
            }}
          />
        )}
        {heighLightArea === LABEL_AREA_LEFT && (
          <div
            style={{
              position: "absolute",
              left: isNarrow ? 135 : 115,
              width: isNarrow ? 270 : 210,
              height,
              backgroundColor: bgColor,
            }}
          />
        )}
        {isNarrow ? (
          <b
            className="font-clip"
            style={{
              fontSize: 28,
              position: "absolute",
              left: 15,
              top: 0,
              width: 95,
            }}
          >
            {label.leftIndex}
          </b>
        ) : singleMark ? (
          <b
            className="font-clip"
            style={{
              fontSize: 60,
              position: "absolute",
              left: 5,
              top: -10,
              width: 28,
              overflow: "hidden",
            }}
          >
            {label.leftIndex}
          </b>
        ) : (
          <div
            className="font-clip"
            style={{
              transform: "rotate(90deg)",
              transformOrigin: "top left",
              fontSize: 36,
              overflow: "hidden",
              position: "absolute",
              left: 50,
              top: 4,
            }}
          >
            {label.leftIndex}
          </div>
        )}
        {label.qrcode && (
          <div
            className="flex flex-align-center"
            style={{
              position: "absolute",
              left: isNarrow ? 105 : 45,
              top: 6,
            }}
          >
            <QRCodeSVG value={label.qrcode} size={isNarrow ? 36 : 64} bordered={false} />
            {label.appendix}
          </div>
        )}
        <b
          className="font-clip"
          style={{
            position: "absolute",
            left: isNarrow ? (label.qrcode ? 145 : 85) : 115,
            width: isNarrow ? 245 : 210,
            top: 0,
            fontSize: 22,
            lineHeight: 1,
            whiteSpace: "nowrap",
            backgroundColor: label.reverseTitle ? "black" : "transparent",
            color: label.creditNote ? errorColor : label.reverseTitle ? "white" : "black",
          }}
        >
          {label.title}
        </b>
        <div
          className={isNarrow ? "font-clip flex flex-row flex-align-center" : ""}
          style={{
            position: "absolute",
            lineHeight: "14px",
            left: isNarrow ? (label.qrcode ? 145 : 85) : 115,
            width: isNarrow ? 245 : 220,
            top: 26,
            fontSize: 14,
            whiteSpace: "pre-wrap",
          }}
        >
          {isNarrow && label.icons &&
            label.icons.map((code) => (
              <div
                style={{
                  margin: 2,
                  padding: "0px 2px",
                  border: "2px solid",
                }}
              >
                {code}
              </div>
            ))
          }
          {label.description}
        </div>
        <div style={{ position: "absolute", left: 330, width: 95, top: 4 }}>
          <div
            style={{
              whiteSpace: "pre-wrap",
              lineHeight: "14px",
              fontSize: 14,
            }}
          >
            {label.info}
          </div>
          {!isNarrow &&
            <div className="flex flex-row">
              {label.icons &&
                label.icons.map((code) => (
                  <div
                    style={{
                      margin: 2,
                      padding: "0px 2px",
                      border: "2px solid",
                    }}
                  >
                    {code}
                  </div>
                ))}
            </div>
          }
        </div>
        {!half && (
          <>
            {label.qrcode && (
              <div className="flex flex-align-center" style={{ position: "absolute", left: 415, top: 6 }}>
                <QRCodeSVG value={label.qrcode} size={isNarrow ? 36 : 64} bordered={false} />
                {label.appendix}
              </div>
            )}
            {isNarrow ? (
              <b
                className="font-clip"
                style={{
                  fontSize: 28,
                  position: "absolute",
                  left: 470,
                  top: 0,
                  overflow: "hidden",
                }}
              >
                {label.rightIndex}
              </b>
            ) : singleMark ? (
              <b
                className="font-clip"
                style={{
                  fontSize: 60,
                  position: "absolute",
                  left: 500,
                  top: -10,
                  overflow: "hidden",
                }}
              >
                {label.rightIndex}
              </b>
            ) : (
              <div
                className="font-clip"
                style={{
                  transform: "rotate(90deg)",
                  transformOrigin: "top left",
                  fontSize: 36,
                  position: "absolute",
                  left: 550,
                  top: 4,
                  overflow: "hidden",
                }}
              >
                {label.rightIndex}
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};
