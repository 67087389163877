"use client";

import {
  useAppVersion,
  useAuthUser,
  useClientType,
  useHttpsFunction,
  useInstallationId,
  useSetBlockingRequest,
  useToast,
} from "@easybiz/context";
import { useCallback, useState } from "react";

export default (onClick, onComplete, succeed, noneAuth) => {
  const toast = useToast();
  const user = useAuthUser();
  const client = useClientType();
  const clientVersion = useAppVersion();
  const installId = useInstallationId();
  const httpsFunction = useHttpsFunction();
  const [request, setRequest] = useState();
  const setBlockingRequest = useSetBlockingRequest();
  const [response, setResponse] = useState();

  const onSubmit = useCallback(
    async (params, blocking) => {
      try {
        setResponse(null);
        const args = onClick(params);

        if (typeof args === "string") {
          toast.info(args);
        } else if (blocking === null) {
          // Params check
          return true;
        } else if (Array.isArray(args)) {
          const [group, type, data] = args;

          setRequest(data || {});

          if (blocking) {
            setBlockingRequest(data || {});
          }

          httpsFunction({
            group,
            type,
            data: data || {},
            client,
            clientVersion,
            installId
          })
            .then((response) => {
              setResponse(response.data || {});

              if (typeof succeed === "string") {
                toast.success(succeed);
              } else if (typeof succeed === "function") {
                succeed(data, response.data);
              }

              if (onComplete) {
                onComplete(true, response.data, { ...data, type });
              }
            })
            .catch((error) => {
              console.info(`%c[${type} failed]`, `color: red`, error.message, JSON.stringify(error.details));
              toast.error(error.message, error.details);

              if (onComplete) {
                onComplete(false, { ...error.details, message: error.message }, data);
              }
            })
            .finally(() => {
              setRequest(null);

              if (blocking) {
                setBlockingRequest(null);
              }
            });
        }
      } catch (error) {
        toast.error(error.message, error.code);
      }
    },
    [client, installId, clientVersion, onClick, onComplete]
  );

  const onClear = useCallback(() => setResponse(null), []);

  return {
    request,
    response,
    loading: Boolean(request),
    succeed: Boolean(response),
    onSubmit: (user || noneAuth) && onClick ? onSubmit : null,
    onClear,
  };
};
