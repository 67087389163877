import { useImageHost } from "@easybiz/context";
import { LazyImage } from "@easybiz/web-component";
import { Col, List, Row, Space, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";

export default ({ notice, open, onCancel }) => {
  const imageHost = useImageHost();

  return (
    <Dialog
      title={notice?.title || <FormattedMessage defaultMessage="System notice" />}
      width={800}
      open={open}
      onCancel={onCancel}
      cancelButtonProps={{ hidden: true }}
      onOk={onCancel}
    >
      <Row gutter={[16, 48]}>
        {(notice?.contents || []).map(({ emoji, header, photoId, alert, paragraphs, items }, index) => (
          <Col key={`${index}`} span={24}>
            <Row gutter={[16, 16]}>
              {header && (
                <Col span={24}>
                  <Space>
                    {emoji ? <Typography.Title level={4}>{emoji}</Typography.Title> : null}
                    <Typography.Title level={4}>{header}</Typography.Title>
                  </Space>
                </Col>
              )}
              {alert && (
                <Col span={24}>
                  <Typography.Text type="warning" strong>
                    {alert}
                  </Typography.Text>
                </Col>
              )}
              {photoId && (
                <Col span={24}>
                  <LazyImage src={`${imageHost}/${photoId}`} width="100%" />
                </Col>
              )}
              {Array.isArray(paragraphs || items) && (
                <Col span={24} key={`${index}`}>
                  <List
                    size="small"
                    dataSource={paragraphs || items}
                    renderItem={(item) => {
                      const { prefix, title, alert } = typeof item === "string" ? { title: item } : item || {};

                      return (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <span>
                                {prefix ? <b>[{prefix}] </b> : null}
                                {title}
                              </span>
                            }
                            description={
                              alert && (
                                <Typography.Text type="warning" strong>
                                  * {alert}
                                </Typography.Text>
                              )
                            }
                          />
                        </List.Item>
                      );
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </Dialog>
  );
};
