export default (roundingRule, netSales) => {
  let roundingAmount = 0;

  switch (roundingRule) {
    default: {
      const priceSplit = `${netSales}`.split(".");
      if (priceSplit.length === 2 && priceSplit[1].length === 2) {
        const lastDigit = parseInt(priceSplit[1][1]);
        if ([1, 2].indexOf(lastDigit) >= 0) {
          // Floor
          roundingAmount = -parseFloat(`0.0${lastDigit}`);
        } else if ([3, 4, 6, 7].indexOf(lastDigit) >= 0) {
          // Floor
          roundingAmount = parseFloat(
            `${5 - lastDigit < 0 ? "-" : ""}0.0${Math.abs(5 - lastDigit)}`
          );
        } else if ([8, 9].indexOf(lastDigit) >= 0) {
          // Floor
          roundingAmount = parseFloat(
            `${10 - lastDigit < 0 ? "-" : ""}0.0${Math.abs(10 - lastDigit)}`
          );
        }
      }
    }
  }

  return roundingAmount;
};
