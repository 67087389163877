"use client";

import { PLATFORM_WEB, PRINTER_IO_BLUETOOTH, PRINTER_TYPE_LABEL, PRINTER_TYPE_RECEIPT } from "@easybiz/utils";
import { createContext, useContext, useMemo, useState } from "react";
import { usePlatform } from "./ConfigurationContext";
import { useBleLabelPrinter, useBleReceiptPrinter } from "./LiveStateContext";

const DeviceRegistrationContext = createContext();

export function DeviceRegistrationProvider({ deviceDoc, children }) {
  const [location, setLocation] = useState();

  const context = useMemo(() => {
    return {
      location,
      setLocation,
      deviceDoc,
      whitelisted: deviceDoc?.get("listed") === true,
    };
  }, [deviceDoc, location]);

  return <DeviceRegistrationContext.Provider value={context}>{children}</DeviceRegistrationContext.Provider>;
}

export const useDeviceLocation = () => useContext(DeviceRegistrationContext).location;
export const useDeviceLocationSetter = () => useContext(DeviceRegistrationContext).setLocation;
export const useRegisteredDevice = () => useContext(DeviceRegistrationContext).deviceDoc;
export const useIsDeviceWhitelisted = () => useContext(DeviceRegistrationContext).whitelisted;
export const useIsWebcameraEnabled = () => useContext(DeviceRegistrationContext).deviceDoc?.get("webCamera");
export const useConnectedPrinter = (printerType) => {
  const platform = usePlatform();
  const bleReceiptPrinter = useBleReceiptPrinter();
  const bleLabelPrinter = useBleLabelPrinter();
  const { deviceDoc } = useContext(DeviceRegistrationContext);
  const printer = deviceDoc?.get(`${printerType}Printer`);

  if (platform === PLATFORM_WEB && printer?.io === PRINTER_IO_BLUETOOTH) {
    if (printerType === PRINTER_TYPE_RECEIPT && !bleReceiptPrinter) {
      return null;
    } else if (printerType === PRINTER_TYPE_LABEL && !bleLabelPrinter) {
      return null;
    }
  }

  return printer;
};
