import { OrderNoDisplay } from "@easybiz/component";
import { useCashDrawer } from "@easybiz/context";
import { OrderDetailTabPanel, OrderEditCustomerButton } from "@easybiz/web-admin";
import { useOrderDoc } from "@easybiz/web-firebase";
import { Card } from "antd";
import PanelHeader from "./PanelHeader";

export default function ({ orderId, onClose }) {
  const orderDoc = useOrderDoc(orderId, true);
  const cashDrawer = useCashDrawer();

  return (
    <div className="flex flex-fill scroll-container">
      <PanelHeader
        title={
          orderDoc && <OrderNoDisplay orderNo={orderDoc.get("orderNo")} businessCode={orderDoc.get("business.id")} />
        }
        onClose={onClose}
        noPadding
        iconButton
        extra={<OrderEditCustomerButton order={orderDoc} maxWidth={150} />}
      />
      <div className="flex-fill scroll-container padding-top-half">
        <Card bodyStyle={{ padding: 8 }}>
          <OrderDetailTabPanel
            cashDrawer={cashDrawer}
            orderId={orderId}
            order={orderDoc}
            height={window.innerHeight - 120}
          />
        </Card>
      </div>
    </div>
  );
}
