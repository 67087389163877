import {
  CLIENT_CONSOLE,
  CLIENT_CUSTOMER_APP,
  CLIENT_MOBILE,
  CLIENT_POS,
  PAYMENT_ID_ALIPAY,
  PAYMENT_ID_GRABPAY,
  PAYMENT_ID_PAYNOW_STRIPE,
  PAYMENT_ID_STRIPE,
  PAYMENT_ID_WECHATPAY,
} from "./constants/index.js";

export default (client, realmDoc, businessDoc) => {
  let paymentMethods;

  switch (client) {
    case CLIENT_POS:
      paymentMethods = realmDoc?.get("posStripeMethods");
      break;
    case CLIENT_CUSTOMER_APP:
      paymentMethods = businessDoc?.get("paymentMethods");
      break;
    case CLIENT_CONSOLE:
    case CLIENT_MOBILE:
      paymentMethods = [
        PAYMENT_ID_STRIPE,
        ...(realmDoc?.get("currency") === "SGD" ? [PAYMENT_ID_PAYNOW_STRIPE] : []),
        PAYMENT_ID_GRABPAY,
        PAYMENT_ID_WECHATPAY,
        PAYMENT_ID_ALIPAY,
      ];
      break;
    default:
      paymentMethods = [];
  }

  return (paymentMethods || []).map((id) => {
    let title = "";

    switch (id) {
      case PAYMENT_ID_ALIPAY:
        title = "Alipay";
        break;
      case PAYMENT_ID_GRABPAY:
        title = "GrabPay";
        break;
      case PAYMENT_ID_PAYNOW_STRIPE:
        title = "PAYNOW";
        break;
      case PAYMENT_ID_STRIPE:
        title = "Credit card";
        break;
      case PAYMENT_ID_WECHATPAY:
        title = "Wechat Pay";
        break;
    }

    return {
      id,
      title,
    };
  });
};
