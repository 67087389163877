import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { UnitDisplay } from "@easybiz/component";
import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import QtyInputPopover from "../QtyInputPopover";
import Title from "../Title";

export default function ({ value, unit, pcs, onChange }) {
  const [open, setVisible] = useState(false);
  const [pendingValue, setPendingValue] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    setPendingValue(null);
  }, [open]);

  return (
    <QtyInputPopover
      removeable={!pendingValue}
      open={open}
      onOpenChange={setVisible}
      placement={"left"}
      title={
        pendingValue ? (
          <FormattedMessage
            defaultMessage={"Total <b>{quantity}{unit}</b>, how many pcs?"}
            values={{
              b: (t) => <Title type={"success"}>{t}</Title>,
              quantity: pendingValue,
              unit: <UnitDisplay unit={unit} />,
            }}
          />
        ) : (
          <FormattedMessage defaultMessage="How Many {unit}?" values={{ unit: <UnitDisplay unit={unit} /> }} />
        )
      }
      decimal={!pendingValue}
      value={pendingValue ? pcs : value}
      suffix={pendingValue ? <FormattedMessage defaultMessage={"pcs"} /> : <UnitDisplay unit={unit} />}
      onDone={(value) => {
        if (pendingValue) {
          if (typeof value !== "number" || value === 0) {
            return message.info(
              intl.formatMessage({
                defaultMessage: "Please enter how many pcs",
              })
            );
          }

          onChange({ qty: pendingValue, pcs: value });
          setVisible(false);
        } else {
          if (typeof value === "number" && value > 0) {
            setPendingValue(value);
          } else {
            onChange({ qty: 0 });
            setVisible(false);
          }
        }
      }}
      buttonText={
        pendingValue ? (
          value ? (
            <FormattedMessage defaultMessage={"Update"} />
          ) : (
            <FormattedMessage defaultMessage={"Add"} />
          )
        ) : (
          <FormattedMessage defaultMessage={"Next"} />
        )
      }
      buttonType={pendingValue ? "primary" : "default"}
    >
      <Button icon={value ? <EditOutlined /> : <PlusOutlined />}>
        <Title type={value ? "success" : null}>
          {value ? (
            <span>
              {value}
              {unit ? (
                <span>
                  {" "}
                  <UnitDisplay unit={unit} />
                </span>
              ) : null}
              {pcs ? (
                <span>
                  {" "}
                  (
                  <FormattedMessage defaultMessage={"{count} pcs"} values={{ count: pcs }} />)
                </span>
              ) : null}
            </span>
          ) : (
            <FormattedMessage tagName={"span"} defaultMessage={"Add"} />
          )}
        </Title>
      </Button>
    </QtyInputPopover>
  );
}
