export const BOOKING_SLOT_BADGE_FULL = "full";
export const BOOKING_SLOT_BADGE_PAST = "past";
export const BOOKING_SLOT_BADGE_ECO = "eco";
export const BOOKING_SLOT_BADGE_RESERVED = "reserved";
export const BOOKING_SLOT_BADGE_CURRENT = 'current';

export const BOOKING_STEP_SUB_SERVICE = "sub_service";
export const BOOKING_STEP_SERVICE_TYPES = "service";
export const BOOKING_STEP_UPLOAD = "photo";
export const BOOKING_STEP_BRAND = 'brand';
export const BOOKING_STEP_UNIT = 'unit';
export const BOOKING_STEP_FREQUENCY = "frequency";
export const BOOKING_STEP_DURATION = "duration";
export const BOOKING_STEP_NO_OF_UNIT = "no_of_unit";
export const BOOKING_STEP_BRANDS = "brands";
export const BOOKING_STEP_ADDRESS = "address";
export const BOOKING_STEP_SELECT_PRODUCT = "product";
export const BOOKING_STEP_SCHEDULE_PUD = "pud";
export const BOOKING_STEP_RESCHEDULE_PUD = "reschedule_pud";
export const BOOKING_STEP_RECURRING = 'recurring';
export const BOOKING_STEP_SCHEDULE_DELIVERY = 'schedule_delivery';
export const BOOKING_STEP_SCHEDULE_SERVICE = 'schedule_service';
export const BOOKING_STEP_PAYMENT = "payment";
export const BOOKING_STEP_CONTACT = "contact";
export const BOOKING_STEP_REVIEW = "review";

export const TOP_UP_STEP_VERIFY = 'verify';
export const TOP_UP_STEP_OPTIONS = 'top_up_options';