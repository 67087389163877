import { ACTION_SCHEDULE_DELIVER, ACTION_SCHEDULE_PICKUP, JOB_TYPE_DELIVERY, JOB_TYPE_PICKUP } from "@easybiz/utils";
import { useOrderJobQuery } from "@easybiz/web-firebase";
import { Alert, Col, Empty, List, Row, Space } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import CollectionScheduleButton from "../CollectionScheduleButton";
import JobScheduleButton from "../JobScheduleButton";
import JobStatusCard from "../JobStatusCard";
import PackStorageInfo from "../PackStorageInfo";
import SkipPickupButton from "./SkipPickupButton";

export default ({ orderId, order, placement }) => {
  const tasks = useOrderJobQuery(orderId);
  const awaits = order?.get("awaits");
  const pendingPickupSchedule = Boolean(awaits?.find((action) => action === ACTION_SCHEDULE_PICKUP));
  const pendingDeliverySchedule = Boolean(awaits?.find((action) => action === ACTION_SCHEDULE_DELIVER));

  return (
    <Row gutter={[16, 16]}>
      {Array.isArray(order?.get("packs")) && (
        <Col span={24}>
          <PackStorageInfo order={order} />
        </Col>
      )}
      {(pendingPickupSchedule || pendingDeliverySchedule) && (
        <Col span={24}>
          <Alert
            banner
            type="info"
            message={
              pendingPickupSchedule ? (
                <FormattedMessage defaultMessage={"Pending pick up schedule"} />
              ) : (
                <FormattedMessage defaultMessage={"Pending delivery schedule"} />
              )
            }
            action={
              <Space>
                {pendingPickupSchedule && <SkipPickupButton order={order} />}
                <JobScheduleButton
                  orderId={order?.id}
                  customerId={order?.get("customer.id")}
                  businessCode={order?.get("business.id")}
                  serviceId={order?.get("service.id")}
                  scheduleType={pendingDeliverySchedule ? JOB_TYPE_PICKUP : JOB_TYPE_DELIVERY}
                  placement={placement}
                  title={<FormattedMessage tagName="span" defaultMessage="Schedule" />}
                />
              </Space>
            }
          />
        </Col>
      )}
      {tasks?.length ? (
        tasks.map((task) => {
          return (
            <Col key={task.id} span={24}>
              <JobStatusCard task={task} />
            </Col>
          );
        })
      ) : (
        <Col span={24}>
          <List
            loading={!tasks}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<FormattedMessage defaultMessage={"No job scheduled"} />}
                />
              ),
            }}
          />
        </Col>
      )}
      <Col span={24}>
        <CollectionScheduleButton order={order}>
          <FormattedMessage tagName="span" defaultMessage="Change to store collection" />
        </CollectionScheduleButton>
      </Col>
      <Col span={24}>
        <JobScheduleButton
          block
          order={order}
          scheduleType={JOB_TYPE_DELIVERY}
          disabled={Boolean(pendingPickupSchedule || pendingDeliverySchedule)}
        >
          <FormattedMessage tagName="span" defaultMessage="Add new delivery" />
        </JobScheduleButton>
      </Col>
    </Row>
  );
};
