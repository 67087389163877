import { Button, Card } from "antd";
import React, { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import SignaturePad from "react-signature-pad-wrapper";

export default function ({ disabled, onChange }) {
  const padRef = useRef();

  useEffect(() => {
    if (padRef.current) {
      padRef.current.instance.addEventListener("endStroke", () => {
        if (padRef.current.isEmpty()) {
          onChange(null);
        } else {
          onChange(padRef.current.toDataURL("image/jpeg"));
        }
      });
    }
  }, [Boolean(padRef.current)]);

  return (
    <Card style={{ height: 154, width: 306 }} bodyStyle={{ padding: 2, position: "relative", height: 154, width: 306 }}>
      <SignaturePad
        ref={padRef}
        height={150}
        width={300}
        disabled={disabled}
        options={{
          backgroundColor: "#ffffff",
        }}
      />
      <Button
        danger
        disabled={disabled}
        onClick={() => {
          padRef.current && padRef.current.clear();
          onChange(null);
        }}
        style={{ position: "absolute", bottom: 2, left: 2 }}
      >
        <FormattedMessage tagName="span" defaultMessage="Clear" />
      </Button>
    </Card>
  );
}
