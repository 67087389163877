import dayjs from "dayjs";
import { PROMO_TYPE_FOC } from "./constants/index.js";
import formatPrice from "./formatPrice.js";
import isDocumentExists from "./isDocumentExists.js";
import validatePromotion from "./validatePromotion.js";

export default function (promotions, { customerId, businessCode, serviceId, date, customerDoc, focStatusDoc }) {
  const customer = customerId
    ? {
        id: customerId,
        ...(isDocumentExists(customerDoc) && !customerDoc.get("orderDate") && { firstTime: true }),
        ...(customerDoc?.get("birthday") && {
          birthday: customerDoc.get("birthday"),
        }),
      }
    : {
        firstTime: true, // Show firstime if customer not selected yet
      };

  return (promotions || [])
    .map((promotion) => {
      const data = validatePromotion(promotion, businessCode, serviceId, customer, date);

      if (data) {
        if (data.type === PROMO_TYPE_FOC) {
          if (focStatusDoc) {
            const usagedAmount = formatPrice(
              [0, ...Object.values(focStatusDoc.data() || {}).filter((amount) => typeof amount === "number")].reduce(
                (a, b) => a + b
              )
            );
            const balance = formatPrice(data.value - usagedAmount);
            return {
              ...data,
              value: balance,
              month: dayjs(date).format("YYYY-MM"),
              ...(balance === 0 && { expired: true }),
            };
          } else {
            return {
              ...data,
              value: 0,
              month: dayjs(date).format("YYYY-MM"),
              expired: true,
            };
          }
        } else {
          return data;
        }
      }
    })
    .filter((data) => data);
}
