import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

export default function ({
  value,
  onChange,
  placeholder,
  addonAfter,
  addonBefore,
  autoFocus,
  status,
  onFocus,
  onBlur,
}) {
  const [search, setSearch] = useState(value);
  const intl = useIntl();

  useEffect(() => {
    if (value !== search) {
      setSearch(value);
    }
  }, [value]);

  useEffect(() => {
    if (typeof search === "string" && value !== search) {
      const handler = setTimeout(() => {
        onChange(search);
      }, 300);

      return () => clearTimeout(handler);
    }
  }, [search]);

  return (
    <Input
      placeholder={placeholder || intl.formatMessage({ defaultMessage: "Search" })}
      autoFocus={autoFocus}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      suffix={addonAfter}
      addonBefore={addonBefore}
      status={status}
      onFocus={onFocus}
      onBlur={onBlur}
      allowClear
    />
  );
}
