"use client";

import { BUSINESS_SECTOR_LAUNDRY } from "@easybiz/utils";
import { FormattedMessage } from "react-intl";
import NameDisplay from "./NameDisplay";
import DateDisplay from "./DateDisplay";
import NA from "./NA";

export default function useOrderCollectionPanel(order) {
  const { collectionPlace, delivery, service } = order?.data() || {};
  const isLaundry = service?.sector === BUSINESS_SECTOR_LAUNDRY;
  const collection = (
    <FormattedMessage
      defaultMessage={"Collect at {storeName}"}
      values={{
        storeName: collectionPlace ? <NameDisplay nameKey={collectionPlace} showKey /> : <NA />,
      }}
    />
  );

  return {
    title: isLaundry ? delivery?.date && <DateDisplay date={delivery.date} /> : collection,
    subTitle: isLaundry ? collection : null,
  };
}
