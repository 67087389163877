import { BarcodeOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function OrderIdIcon({ orderId }) {
  const intl = useIntl();

  if (navigator.clipboard) {
    return (
      <Button
        type="text"
        icon={<BarcodeOutlined />}
        onClick={() => {
          navigator.clipboard
            .writeText(orderId)
            .then(() =>
              message.success(
                intl.formatMessage({
                  defaultMessage: "Order ID copied to your clipboard",
                })
              )
            )
            .catch((err) => message.error(err.message));
        }}
      >
        <FormattedMessage tagName={'span'} defaultMessage={"Order ID"} />
      </Button>
    );
  } else {
    return null;
  }
}
