import { FormattedMessage } from "react-intl";
import Dialog from "../Dialog";

export default function ({ open, onCancel }) {
  return (
    <Dialog
      open={open}
      title={<FormattedMessage defaultMessage={"Sales data changed, please run preview report and check again"} />}
      okText={<FormattedMessage defaultMessage={"Run preview report"} />}
      onOk={onCancel}
      onCancel={onCancel}
    ></Dialog>
  );
}
