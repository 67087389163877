import { useOrderSkipPickUp } from "@easybiz/request";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PopConfirmButton from "../PopConfirmButton";

export default function SkipPickupButton({ order }) {
  const intl = useIntl();
  const { onSubmit, loading } = useOrderSkipPickUp();

  return (
    <PopConfirmButton
      confirmTitle={intl.formatMessage({ defaultMessage: "Skip pick-up schedule?" })}
      loading={loading}
      onConfirm={() =>
        onSubmit({
          orderId: order.id,
          customerId: order.get("customer.id"),
        })
      }
      disabled={!order}
    >
      <FormattedMessage tagName="span" defaultMessage="Skip" />
    </PopConfirmButton>
  );
}
