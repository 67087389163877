"use client";

import { useToast } from "@easybiz/context";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useCheckoutItems, useSetCheckoutParams } from "./CheckoutContext";

export default function useUpdateCheckoutItems() {
  const setParams = useSetCheckoutParams();
  const items = useCheckoutItems();
  const toast = useToast();
  const intl = useIntl();

  const checkSelectFields = useCallback((item, requireQuantity) => {
    if (!item?.productId) {
      toast.info(intl.formatMessage({ defaultMessage: "Product not found" }));
      return false;
    }
    if (!item?.optionId) {
      toast.info(intl.formatMessage({ defaultMessage: "Price option not found" }));
      return false;
    }
    if (typeof item?.unitPrice !== "number") {
      toast.info(intl.formatMessage({ defaultMessage: "Unit price not provided" }));
      return false;
    }
    if (typeof item?.qty !== "number") {
      toast.info(intl.formatMessage({ defaultMessage: "Quantity not provided" }));
      return false;
    }
    if (requireQuantity && item?.qty <= 0) {
      toast.info(intl.formatMessage({ defaultMessage: "Please enter quantity" }));
      return false;
    }

    return true;
  }, []);

  return useCallback(
    ({ itemIndex, ...fields }, salesReturn) => {
      if (Number.isInteger(itemIndex)) {
        if (!Array.isArray(items) || !items[itemIndex]) {
          return toast.info(
            intl.formatMessage({ defaultMessage: "Item not found at {index}" }, { index: `#${itemIndex + 1}` })
          );
        }

        if (fields.qty === 0) {
          // Remove
          if (salesReturn) {
            return setParams((params) => ({
              ...params,
              items: [...items.slice(0, itemIndex), fields, ...items.slice(itemIndex + 1)],
            }));
          } else {
            return setParams((params) => ({
              ...params,
              items: [...items.slice(0, itemIndex), ...items.slice(itemIndex + 1)],
            }));
          }
        } else {
          if (!checkSelectFields(fields)) {
            return;
          }

          // Updates
          setParams((params) => ({
            ...params,
            items: [...items.slice(0, itemIndex), fields, ...items.slice(itemIndex + 1)],
          }));

          return itemIndex;
        }
      } else {
        if (!checkSelectFields(fields, true)) {
          return;
        }

        // Add
        setParams((params) => ({ ...params, items: [...(items || []), fields] }));

        return Array.isArray(items) ? items.length : 0;
      }
    },
    [items, setParams, checkSelectFields]
  );
}
