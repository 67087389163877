"use client";

import { useMemo } from "react";
import DateDisplay from "./DateDisplay";

export default function useOrderHistoryPanel(historyDoc) {
  return useMemo(() => {
    return {
      dataSource:
        historyDoc &&
        (historyDoc.get("logs") || [])
          .sort((a, b) => b.u - a.u)
          .map(({ c, o, u, e, i }) => {
            return {
              key: `${u}`,
              title: c,
              description: (
                <>
                  <DateDisplay timestamp={u} />
                  {` ${o || ""}`}
                </>
              ),
              error: Boolean(e),
              ...(i && { imageUrl: i }),
            };
          }),
    };
  }, [historyDoc]);
}
