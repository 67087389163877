import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useCheckoutService } from "@easybiz/checkout";
import { AmountDisplay } from "@easybiz/component";
import { translate } from "@easybiz/utils";
import { useServiceAddonQuery } from "@easybiz/web-firebase";
import { Input, List, message, Space } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import IconButton from "../IconButton";
import Title from "../Title";

export default ({ style, selects, onChange }) => {
  const service = useCheckoutService();
  const addons = useServiceAddonQuery(service?.id, true);
  const intl = useIntl();

  const [otherName, setOtherName] = useState("");

  return (
    <List
      style={style}
      loading={!addons}
      dataSource={[
        ...(addons || []),
        ...(selects || []).filter((select) => !addons || !addons.find((addon) => addon.id === select.id)),
      ]}
      renderItem={(addon) => {
        const selected = selects && selects.find((other) => other.id === addon.id);

        return (
          <List.Item
            actions={[
              <Space>
                {addon.surcharge && (
                  <Title type={selected ? "success" : null}>
                    +
                    <AmountDisplay value={addon.surcharge} />
                  </Title>
                )}
                <IconButton
                  icon={selected ? <DeleteOutlined /> : <PlusOutlined />}
                  danger={selected}
                  onClick={() => {
                    const index = (selects || []).findIndex((other) => other.id === addon.id);
                    let newAddons;
                    if (index >= 0) {
                      newAddons = [...selects.slice(0, index), ...selects.slice(index + 1)];
                    } else {
                      newAddons = [
                        ...(selects || []),
                        {
                          id: addon.id,
                          ...(addon.title && { title: addon.title }),
                          ...(addon.code && { code: addon.code }),
                          ...(addon.surcharge && {
                            surcharge: addon.surcharge,
                          }),
                        },
                      ];
                    }

                    onChange(newAddons);
                  }}
                />
              </Space>,
            ]}
          >
            <List.Item.Meta title={translate(addon.title)} description={addon.code ? <b>[{addon.code}]</b> : null} />
          </List.Item>
        );
      }}
      footer={
        <List.Item
          actions={[
            <IconButton
              icon={<PlusOutlined />}
              onClick={() => {
                if (!otherName)
                  return message.info(
                    intl.formatMessage({
                      defaultMessage: "Please enter add-on name",
                    })
                  );

                const index = (selects || []).findIndex((other) => other.id === otherName);

                if (index >= 0) {
                  return message.info(
                    intl.formatMessage({ defaultMessage: `{name} already added` }, { name: otherName })
                  );
                }

                onChange([...(selects || []), { id: otherName, title: otherName }]);
                setOtherName("");
              }}
            />,
          ]}
        >
          <List.Item.Meta
            title={
              <Input
                value={otherName}
                onChange={(e) => setOtherName(e.target.value)}
                placeholder={intl.formatMessage({ defaultMessage: "Other" })}
              />
            }
          />
        </List.Item>
      }
    />
  );
};
