import { JOB_OPERATION_RESCHEDULE, OPERATION_GROUP_JOB } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import useActionCallback from "../useActionCallback";

export default (callback) => {
  const intl = useIntl();

  const onSubmit = useCallback(({ taskId, optionsId, schedule, returnSchedule, option, worker, note, address }) => {
    return [
      OPERATION_GROUP_JOB,
      JOB_OPERATION_RESCHEDULE,
      {
        ...(optionsId && { optionsId }),
        ...(schedule && { schedule }),
        ...(option && { option }),
        ...(worker && { worker }),
        ...(note && { note }),
        ...(address && { address }),
        ...(returnSchedule && { returnSchedule }),
        taskId,
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, intl.formatMessage({ defaultMessage: "Rescheduled" }));
};
