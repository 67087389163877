import { PictureOutlined, PrinterOutlined, TagsOutlined } from "@ant-design/icons";
import {
  useAutoCusReceipt,
  useAutoInternalReceipt,
  useAutoLabel,
  useSetAutoCusReceipt,
  useSetAutoInternalReceipt,
  useSetAutoLabel,
  useSetShowPhoto,
  useShowPhoto,
} from "@easybiz/context";
import { Dialog, QuantityUpdateBar } from "@easybiz/web-admin";
import { Avatar, List, Switch, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ open, onCancel }) => {
  const autoCusReceipt = useAutoCusReceipt();
  const setAutoCusReceipt = useSetAutoCusReceipt();
  const autoInternalReceipt = useAutoInternalReceipt();
  const setAutoInternalReceipt = useSetAutoInternalReceipt();
  const autoLabel = useAutoLabel();
  const setAutoLabel = useSetAutoLabel();
  const showPhoto = useShowPhoto();
  const setShowPhoto = useSetShowPhoto();

  return (
    <Dialog
      title={<FormattedMessage tagName="span" defaultMessage="Local Settings" />}
      open={open}
      onCancel={onCancel}
      okType="default"
      okText={<FormattedMessage tagName="span" defaultMessage="Close" />}
      onOk={onCancel}
    >
      <List
        dataSource={[
          {
            icon: <PrinterOutlined />,
            title: (
              <FormattedMessage
                tagName="span"
                defaultMessage="Automatically print customer receipts after new order created"
              />
            ),
            description:
              autoCusReceipt > 0 ? (
                <FormattedMessage
                  tagName={"b"}
                  defaultMessage={"Automatically print {count} copy of customer receipt after every new order created"}
                  values={{ count: autoCusReceipt }}
                />
              ) : null,
            extra: (
              <QuantityUpdateBar
                minValue={0}
                prefix={"x"}
                value={autoCusReceipt}
                maxValue={5}
                onChange={setAutoCusReceipt}
                disableInput
              />
            ),
          },
          {
            icon: <PrinterOutlined />,
            title: (
              <FormattedMessage
                tagName="span"
                defaultMessage="Automatically print internal receipts after new order created"
              />
            ),
            description:
              autoInternalReceipt > 0 ? (
                <FormattedMessage
                  tagName={"b"}
                  defaultMessage={"Automatically print {count} copy of internal receipt after every new order created"}
                  values={{ count: autoInternalReceipt }}
                />
              ) : null,
            extra: (
              <QuantityUpdateBar
                minValue={0}
                prefix={"x"}
                value={autoInternalReceipt}
                maxValue={5}
                onChange={setAutoInternalReceipt}
                disableInput
              />
            ),
          },
          {
            icon: <TagsOutlined />,
            title: (
              <FormattedMessage tagName="span" defaultMessage="Automatically print labels after new order created" />
            ),
            extra: <Switch checked={autoLabel} onChange={setAutoLabel} />,
          },
          {
            icon: <PictureOutlined />,
            title: <FormattedMessage tagName="span" defaultMessage="Show photos overs order list" />,
            extra: <Switch checked={showPhoto} onChange={setShowPhoto} />,
          },
        ]}
        renderItem={({ icon, title, description, extra }) => {
          return (
            <List.Item actions={[extra]}>
              <List.Item.Meta
                avatar={<Avatar icon={icon} />}
                title={title}
                description={<Typography.Text type="success">{description}</Typography.Text>}
              />
            </List.Item>
          );
        }}
      />
    </Dialog>
  );
};
