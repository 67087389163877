import {
  PROCEDURE_DISCHARGED,
  PROCEDURE_DRIVER_COLLECTED,
  PROCEDURE_PENDING_COLLECTION,
  PROCEDURE_PLANT_CHECK_IN,
  PROCEDURE_PLANT_CHECK_OUT,
  PROCEDURE_PLANT_CONFIRM_ORDER,
  PROCEDURE_PLANT_LABELING,
  PROCEDURE_PLANT_PACKING,
  PROCEDURE_PLANT_SERVICE_COMPLETE,
  PROCEDURE_PLANT_SERVICING,
  PROCEDURE_STORAGE,
  PROCEDURE_PRODUCTION_CALENDAR,
  PROCEDURE_STORE_CHECK_IN,
  PROCEDURE_STORE_CHECK_OUT,
  PROCEDURE_STORE_RECEIVED,
} from "@easybiz/utils";
import { useIntl } from "react-intl";

export default (procedure, isAgent, processing) => {
  const intl = useIntl();

  switch (procedure) {
    case PROCEDURE_PENDING_COLLECTION:
      return intl.formatMessage({ defaultMessage: "Pending collect" });
    case PROCEDURE_DRIVER_COLLECTED:
      return intl.formatMessage({ defaultMessage: "Collected" });
    case PROCEDURE_STORE_RECEIVED:
      if (processing) {
        return intl.formatMessage({ defaultMessage: "Store check in" });
      } else {
        return intl.formatMessage({ defaultMessage: "Store received" });
      }
    case PROCEDURE_PLANT_LABELING:
      if (processing) {
        return intl.formatMessage({ defaultMessage: "Label" });
      } else {
        return intl.formatMessage({ defaultMessage: "Labeled" });
      }
    case PROCEDURE_STORE_CHECK_OUT:
      if (processing) {
        return intl.formatMessage({ defaultMessage: "Store check out" });
      } else {
        return intl.formatMessage({ defaultMessage: "Store checked out" });
      }
    case PROCEDURE_PLANT_CHECK_IN:
      return intl.formatMessage({ defaultMessage: "Production check in" });
    case PROCEDURE_PLANT_CONFIRM_ORDER:
      return intl.formatMessage({ defaultMessage: "Confirm order" });
    case PROCEDURE_PLANT_SERVICING:
      return intl.formatMessage({ defaultMessage: "Servicing" });
    case PROCEDURE_PLANT_SERVICE_COMPLETE:
      return intl.formatMessage({ defaultMessage: "Service complete" });
    case PROCEDURE_PLANT_PACKING:
      return intl.formatMessage({ defaultMessage: "Packing" });
    case PROCEDURE_PLANT_CHECK_OUT:
      return intl.formatMessage({ defaultMessage: "Production check out" });
    case PROCEDURE_STORE_CHECK_IN:
      return intl.formatMessage({ defaultMessage: "Store check in" });
    case PROCEDURE_DISCHARGED:
      if (processing) {
        return intl.formatMessage({ defaultMessage: "Discharge" });
      } else {
        return intl.formatMessage({ defaultMessage: "Discharged" });
      }
    case PROCEDURE_STORAGE:
      return intl.formatMessage({ defaultMessage: "Storage" });
    case PROCEDURE_PRODUCTION_CALENDAR:
      return intl.formatMessage({ defaultMessage: "Production calendar" });
    default:
      return procedure || intl.formatMessage({ defaultMessage: "Unknown" });
  }
};
