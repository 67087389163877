"use client";

import React, { createContext, useContext, useMemo } from "react";
import { useBusinessCode } from "./AuthenticationContext";
import { usePOSSettings } from "./RealmContext";

const CashDrawerContext = createContext();

export function CashDrawerProvider({ cashDrawer, children }) {
  const { depositCheck } = usePOSSettings();
  const enabled = Boolean(depositCheck);

  const context = useMemo(() => {
    return {
      enabled,
      cashDrawer,
    };
  }, [cashDrawer, enabled]);

  return <CashDrawerContext.Provider value={context}>{children}</CashDrawerContext.Provider>;
}

export const useIsCashManagementEnabled = () => useContext(CashDrawerContext).enabled;
export const useIsPendingStartDrawer = () => {
  const { cashDrawer } = useContext(CashDrawerContext);
  const enabled = useIsCashManagementEnabled();

  return Boolean(enabled && cashDrawer && !cashDrawer.get("startTime"));
};

export const useCashDrawer = () => useContext(CashDrawerContext).cashDrawer;

export const useNextDayDepositAmount = () => {
  const businessCode = useBusinessCode();
  const { startingCashShops, depositAmount } = usePOSSettings();

  if (Array.isArray(startingCashShops)) {
    const setting = startingCashShops.find(
      ({ businessCodes }) => Array.isArray(businessCodes) && businessCodes.find((code) => code === businessCode)
    );
    if (setting && typeof setting.amount === "number") {
      return setting.amount;
    }
  }

  return depositAmount;
};
