import { langZh as componentZh } from "@easybiz/component";
import { langZh as contextZh } from "@easybiz/context";
import { langZh as hookZh } from "@easybiz/hook";
import { langZh as posZh } from "@easybiz/pos";
import { langZh as requestZh } from "@easybiz/request";
import { CLIENT_POS } from "@easybiz/utils";
import { AdminWebApplication, langZh as antdZh } from "@easybiz/web-admin";
import { ErrorBoundary, langZh as webComponentZh } from "@easybiz/web-component";
import { enableIndexedDb, langZh as firebaseZh, initFirebase } from "@easybiz/web-firebase";
import React from "react";
import POSWebApplication from "./POSWebApplication";
import appZh from "./lang/zh.json";

// Must call before any firestore call
const offlineEnabled = window.localStorage ? !window.localStorage.getItem("disableoffline") : true;

const app = initFirebase({
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  siteKey: process.env.REACT_APP_siteKey,
});

if (offlineEnabled) {
  enableIndexedDb(app);
}

const CHINESE = Object.assign(
  {},
  appZh,
  posZh,
  componentZh,
  contextZh,
  requestZh,
  hookZh,
  webComponentZh,
  firebaseZh,
  antdZh
);

export default function () {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <AdminWebApplication offlineEnabled={offlineEnabled} client={CLIENT_POS} CHINESE={CHINESE}>
          <POSWebApplication />
        </AdminWebApplication>
      </ErrorBoundary>
    </React.StrictMode>
  );
}
